import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect } from 'react';
import './open-to-work.css';
import { AppContext } from "../../contexts/AppContext";
import pdp from '../../assets/images/open-to-work-photo-de-profil.png';
import iphone from '../../assets/images/iphone profil.png';

const OpenToWork = () => {
    const { setActivePageForHeader } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.setAttribute('data-page', 'search');
        setActivePageForHeader("search");
    
        return () => {
          document.body.removeAttribute('data-page');
          setActivePageForHeader("");
        }
      }, [setActivePageForHeader]);
    
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return <div>
        {/** Hero */}
        <div className="open-to-work-container">
            <h1>Découvrez</h1>
            <h2>Label <span style={{color: "#00c05f"}}>Open to Work</span></h2>
            <div className="button-group">
                <button className="button" onClick={() => {}}>Fonctionnalités</button>
                <button className="button active" onClick={() => {}}>Je recrute</button>
                <button className="button" onClick={() => {}}>Je cherche un poste</button>
            </div>
        </div>
        {/** End Hero */}

        <div className="open-to-work-title-container">
          <h1 className="text-3xl sm:text-5xl md:text-7xl">Découvrez le Label <br/><span className="text-[#00c05f]">Open to Work</span></h1>
        </div>

        <div className="fonctionnalites-content">
    <div className="fonctionnalite-wrapper2">
      <img src={pdp} alt="" className="imgzone"/>
      <div className="textzone">
          <h2>Recrutez des perles de beauté formées et avec de l'expérience</h2>
        <h4>Panel de professionnelles de la beauté</h4>
          <p>Annuaire des professionnels de la beauté disponibles sur Planibeauty, mettant à disposition leur profil, pour des missions et/ou contrat</p>
        <h4>Profil certifiés </h4>
        <p>Entretien effectué par l'équipe Planibeauty en amont d'une inscription sur Planibeauty et diplôme ou certificat de formation disponible</p>
        <h4>Tableau de bord recruteur</h4>
          <p>Sélectionner et rentrer en contact avec les profils susceptibles de vous intéresser</p>
        <h4>Notification mail ou sms</h4>
          <p>Une perle de beauté est souvent compliqué à trouver, activez les notifications afin d'être alerté des nouveaux profils selon vos critères</p>
      </div>
    </div>
  </div>

        <div className="fonctionnalites-content">
    <div className="fonctionnalite-wrapper1">
        <div className="textzone">
            <h2>Activez l'option <span className="text-[#00c05f]">Open to Work</span></h2>
          <h4>Missions multiples</h4>
            <p>Vous souhaitez rajouter des missions en institut en plus de votre clientèle, activez votre profil lorsque vous le souhaitez</p>
          <h4>Contrats variés</h4>
          <p>Intérim, CDD, CDI... Temps plein / Temps partiel, sélectionnez pour quel type de contrat vous souhaitez être contacté</p>
          <h4>Profil open to work</h4>
            <p>Mettez en avant vos diplômes/formations et expérience à travers un portfolio, bien plus parlant qu'un CV</p>
          <h4>Statistique PlaniBeauty un atout majeur</h4>
            <p>Partagez vos statistiques Planibeauty sur votre profil <span className="text-[#00c05f]">Open to Work</span>, pour montrer sur quellle prestation vous performez</p>
        </div>

        <img src={iphone} alt="" className="imgzone"/>
    </div>
    </div>
    </div>
}


export default OpenToWork;