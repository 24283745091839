import React, { useContext, useEffect, useState } from 'react';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';
import './BeauticianGestionCaisse.css';
import beauticianApi from '../../../api/beautician.api';
import ShowMonthDetailsModal from './gestion-caisse/ShowMonthDetailsModal';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { CalendarClock, Euro, PieChart, Search, SlidersHorizontal, Users, TrendingUp } from 'lucide-react';
import appointmentApi from '../../../api/appointement.api';
import Accordion from 'react-bootstrap/Accordion';
import { Table } from 'react-bootstrap';

// Importation des imags des catégories
import beauteRegard from "../../../assets/prestations/beaute_regard.png";
import coiffure from "../../../assets/prestations/coiffure.png";
import epilation from "../../../assets/prestations/epilation.png";
import maquillage from "../../../assets/prestations/maquillage.png";
import onglerie from "../../../assets/prestations/onglerie.png";
import soinDuCorps from "../../../assets/prestations/soin du corps.png";
import soinDuVisage from "../../../assets/prestations/soin du visage.png";
import soinDentaire from "../../../assets/prestations/soins dentaire.png";

const categoryImages = {
  "Soin du visage": soinDuVisage,
  "Soin dentaire": soinDentaire,
  "Maquillage": maquillage,
  "Épilation": epilation,
  "Onglerie": onglerie,
  "Beauté du regard": beauteRegard,
  "Soin du corps": soinDuCorps,
  "Coiffure": coiffure
}

const formateDate = (date = new Date()) => {
  return date.toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  })
}

const checkDateValidation = (appointment_date, start_time) => {
  const dateTime = new Date(appointment_date);
  const [hours, minutes, seconds] = start_time.split(':').map(Number);

  dateTime.setHours(hours);
  dateTime.setMinutes(minutes);
  dateTime.setSeconds(seconds);

  return dateTime >= new Date().setHours(0, 0) && dateTime <= new Date().setHours(24, 0);
}

import {
  LineChart,
  Line,
  PieChart as PChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre"
]

const RenderPaiments = ({ profileData, selectedMonth }) => {
  const paimentsType = {
    acompte: "Acompte",
    online: "Paiments en ligne",
    onsite: "Paiments sur site"
  }

  const [paimentsData, setPaimentsData] = useState({
    acompte: 0,
    online: 0,
    onsite: 0,
    global: 0
  })

  const [filteredAppointment, setFilteredAppointment] = useState({
    acompte: [],
    online: [],
    onsite: []
  })

  const [activeDate, setActiveDate] = useState({
    start: new Date(new Date().setDate(1)),
    end: new Date()
  })

  const fetchRevenueInfoByMonth = async (beautician_id, month) => {
    try {
      const response = await appointmentApi.getAppointmentByBeautician(beautician_id)

      let start_month = new Date()
      start_month.setDate(1) // Début du mois en cours
      start_month.setHours(0, 0, 0, 0)

      let end_month = new Date()

      if (month) {
        const tmp_date = new Date(month)

        if (tmp_date.getMonth() <= start_month.getMonth()) {
          start_month = new Date(tmp_date.getFullYear(), tmp_date.getMonth() - 1, 1) // Début du mois donné
          end_month = new Date(tmp_date.getFullYear(), tmp_date.getMonth(), 0) // Fin du mois donné
        }
      }

      setActiveDate({
        start: start_month,
        end: end_month
      })

      const tmpFilteredAppointment = response.filter(appointment =>
        new Date(appointment.appointment_date) <= end_month
        && new Date(appointment.appointment_date) >= start_month
      )

      const groupedAppointments = tmpFilteredAppointment.reduce((acc, appointment) => {
        const type = appointment.payment_type || "unknown" // Sécurisation si `payment_type` est null

        if (!acc[type]) {
          acc[type] = []
        }

        acc[type].push(appointment)
        return acc
      }, { acompte: [], online: [], onsite: [] })

      setFilteredAppointment(groupedAppointments)

      const calculatePayments = (appointments) => {
        const acompteTotal = appointments.acompte.reduce((sum, appt) => sum + parseFloat(appt.total_prices || 0), 0)
        const onlineTotal = appointments.online.reduce((sum, appt) => sum + parseFloat(appt.total_prices || 0), 0)
        const onsiteTotal = appointments.onsite.reduce((sum, appt) => sum + parseFloat(appt.total_prices || 0), 0)

        return {
          acompte: acompteTotal,
          online: onlineTotal,
          onsite: onsiteTotal,
          global: acompteTotal + onlineTotal + onsiteTotal
        }
      }

      setPaimentsData(calculatePayments(groupedAppointments))
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (profileData)
      fetchRevenueInfoByMonth(profileData.beautician_id, selectedMonth, "")
  }, [profileData, selectedMonth])

  return (
    <div>
      <h1 className="my-3 text-center text-lg">Paiment du {formateDate(activeDate.start)} au {formateDate(activeDate.end)}</h1>

      {/* Détails des paiments */}
      <div className="px-4 flex gap-2">
        {/* Total Global */}
        <div className="p-2 flex flex-col items-center justify-center font-bold border rounded shadow">
          <h2 className="text-gray-700">Total global</h2>
          <h2 className="text-green-600">{paimentsData.global} €</h2>
        </div>

        {/* Les autres types */}
        <div className="p-2 text-sm flex-1 flex flex-col gap-2 border rounded shadow">
          {Object.entries(paimentsData)
            .filter(([key]) => key !== "global") // Exclure "global"
            .map(([key, value]) => (
              <span key={key} className={`${key === "onsite" && "text-green-600"} flex justify-between`}>
                <span>{paimentsType[key]}</span>
                <span>{value}€</span>
              </span>
            ))}
        </div>
      </div>

      {/* Filtre pour la recherche */}
      <div className="p-4 pb-0 flex gap-2 items-center justify-end">
        {/* L'entrée */}
        <div className="flex items-center px-2 py-1 border bg-gray-100 rounded-full">
          <input type="text" className="bg-transparent" placeholder="Rechercher un paiment" />
          <button className="p-1 rounded-full bg-[#bd193b]">
            <Search stroke="#fff" />
          </button>
        </div>

        {/* Button du filtre */}
        <button>
          <SlidersHorizontal stroke="#bd193b" />
        </button>
      </div>

      {/* Détails des rendez-vous */}
      <Accordion
        defaultActiveKey="0"
        className="p-4 flex flex-col gap-2"
      >
        {Object.entries(filteredAppointment).map(([key, value], index) => (
          <Accordion.Item key={`${key}-${index}`} eventKey={index}>
            <Accordion.Header>
              <img
                width={32}
                height={32}
                className="rounded-full me-3"
              />
              {paimentsType[key]}
            </Accordion.Header>
            <Accordion.Body>
              {value.length > 0 ?
                <Table striped bordered hover responsive>
                  <thead>
                    <tr className="*:text-sm">
                      <th>#</th>
                      <th>Date rendez-vous</th>
                      <th>Heure</th>
                      <th>Nom du client</th>
                      <th>Services</th>
                      <th>Total coût</th>
                    </tr>
                  </thead>
                  <tbody>
                    {value.map((appt, index) => (
                      <tr key={appt.id} className="*:text-sm">
                        <td>{index + 1}</td>
                        <td>{new Date(appt.appointment_date).toLocaleDateString('fr-FR', { day: '2-digit', month: 'long', year: 'numeric' })}</td>
                        <td>{appt.start_time} - {appt.end_time}</td>
                        <td>{appt.first_name} {appt.last_name}</td>
                        <td>
                          {appt.service_name} - {appt.total_prices}€
                        </td>
                        <td>{appt.total_prices}€</td>
                      </tr>
                    ))}
                  </tbody>
                </Table> :
                <p className="text-center">Aucune données disponible</p>
              }
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      <p className="text-center text-[#bd193b] underline cursor-pointer">
        Demander un export de mes revenus
      </p>
    </div>
  )
}

const RenderCA = ({ profileData, selectedMonth }) => {
  const [activeDate, setActiveDate] = useState(new Date());
  const [caActiveData, setCAActiveData] = useState(
    months.map(month => ({
      month,
      total: "0.00"
    }))
  );
  const [previousMonth, setPreviousMonth] = useState(
    months.map(month => ({
      month,
      total: "0.00",
      year: new Date().getFullYear()
    }))
  );
  const [n_1Data, setN_1Data] = useState({
    ca: 0,
    clients: 0,
    occupationRate: 0,
    rdv: 0
  });

  // Fonction utilitaire pour calculer les totaux mensuels avec année
  const calculateMonthlyTotals = (appointments, year) => {
    const filteredAppointments = appointments.filter((appt) =>
      year === new Date(appt.appointment_date).getFullYear()
    );

    const paymentsByMonth = filteredAppointments.reduce((acc, appointment) => {
      const date = new Date(appointment.appointment_date);
      const monthIndex = date.getMonth();

      if (!acc[monthIndex]) {
        acc[monthIndex] = [];
      }

      acc[monthIndex].push(appointment);
      return acc;
    }, Array.from({ length: 12 }, () => []));

    return paymentsByMonth.map((monthAppointments, index) => {
      const total = monthAppointments.reduce((sum, appointment) => {
        return sum + parseFloat(appointment.total_prices || 0);
      }, 0);

      return {
        month: months[index],
        total: total.toFixed(2),
        monthIndex: index,
        year: year
      };
    });
  };

  const fetchRevenueInfoByMonth = async (beautician_id, month) => {
    try {
      const response = await appointmentApi.getAppointmentByBeautician(beautician_id);
      let end_month = new Date();
      let selectedMonthIndex = new Date().getMonth();
      let selectedYear = new Date().getFullYear();

      // Récupération des détails du mois sélectionné
      if (month) {
        const tmp_date = new Date(month);
        selectedMonthIndex = tmp_date.getMonth();
        selectedYear = tmp_date.getFullYear();

        if (tmp_date.getMonth() <= activeDate.getMonth() && tmp_date.getFullYear() <= activeDate.getFullYear()) {
          end_month = new Date(tmp_date.getFullYear(), tmp_date.getMonth(), 0);
        }

        setActiveDate(new Date(tmp_date.getFullYear(), tmp_date.getMonth(), 0));
      }

      // Calcul des données pour l'année en cours
      const currentYearData = calculateMonthlyTotals(response, new Date().getFullYear());
      const currentYearTotals = currentYearData.slice(0, end_month.getMonth() + 1);
      setCAActiveData(currentYearTotals);

      // Calcul des données pour l'année précédente
      const previousYearData = calculateMonthlyTotals(response, new Date().getFullYear() - 1);

      // Calcul des 3 mois précédents le mois sélectionné (peut inclure des mois de l'année précédente)
      let previousMonths = [];
      if (selectedMonthIndex >= 3) {
        // Cas normal: les 3 mois sont dans la même année
        previousMonths = currentYearData.slice(selectedMonthIndex - 3, selectedMonthIndex);
      } else {
        // Cas où on doit remonter à l'année précédente
        const monthsFromPreviousYear = 3 - selectedMonthIndex;

        previousMonths = [
          ...previousYearData.slice(12 - monthsFromPreviousYear),
          ...currentYearData.slice(0, selectedMonthIndex)
        ];
      }

      setPreviousMonth(previousMonths);

      // Récupération des détails de l'année précédente complète pour n_1Data
      const pastYearsAppointments = response.filter((appt) =>
        new Date().getFullYear() - 1 === new Date(appt.appointment_date).getFullYear()
      );

      const timeToMinutes = (time) => {
        const [h, m, s] = time.split(":").map(Number);
        return h * 60 + m;
      };

      // Calcul du temps total disponible dans la semaine (hors pause)
      const totalDisponible = profileData.availabilities ? profileData.availabilities.reduce((sum, dispo) => {
        const matin = timeToMinutes(dispo.break_start) - timeToMinutes(dispo.start_time);
        const aprèsMidi = timeToMinutes(dispo.end_time) - timeToMinutes(dispo.break_end);
        return sum + matin + aprèsMidi;
      }, 0) : 0;

      // Calcul du temps total réservé par les rendez-vous
      const totalRéservé = pastYearsAppointments.reduce((sum, rdv) => {
        return sum + (timeToMinutes(rdv.end_time) - timeToMinutes(rdv.start_time));
      }, 0);

      // Calcul du taux d'occupation
      const tauxOccupation = totalDisponible > 0 ? (totalRéservé / totalDisponible) * 100 : 0;

      setN_1Data({
        clients: [...new Set(pastYearsAppointments.map(appointment => appointment.user_id))].length,
        ca: pastYearsAppointments.reduce((sum, appt) => sum + appt.total_prices, 0),
        rdv: pastYearsAppointments.length,
        occupationRate: tauxOccupation.toFixed(2)
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (profileData)
      fetchRevenueInfoByMonth(profileData.beautician_id, selectedMonth);
  }, [profileData, selectedMonth]);

  return (
    <div>
      <h1 className="my-3 text-center text-lg">CA {months[activeDate.getMonth()]} {activeDate.getFullYear()}</h1>

      <div className="mx-2 p-2 flex flex-col gap-2 shadow rounded">
        <div className="px-4 flex items-center justify-between">
          <h2 className="text-lg">Chiffre d'affaires</h2>
          <TrendingUp size={20} />
        </div>
        <div className="">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={caActiveData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip formatter={(value) => `${value}€`} />
              <Line
                type="linear"
                dataKey="total"
                stroke="#0088FE"
                strokeWidth={2}
                offset="0"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Filtre pour la recherche */}
      <div className="p-4 pb-0 flex gap-2 items-center justify-end">
        {/* Button du filtre */}
        <button>
          <SlidersHorizontal stroke="#bd193b" />
        </button>
      </div>

      <Accordion
        defaultActiveKey="0"
        className="p-4 px-2 pb-0 flex flex-col gap-2"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <img
              width={32}
              height={32}
              className="rounded-full me-3"
            />
            N-1
          </Accordion.Header>
          <Accordion.Body
            className="flex flex-col flex-wrap items-center justify-around"
          >
            <h1 className="text-center text-lg">CA {months[activeDate.getMonth()]} {activeDate.getFullYear() - 1}</h1>

            <div className="pt-2 grid grid-cols-2 gap-2">
              {[
                {
                  title: "Chiffre d'affaire",
                  value: n_1Data.ca + " €",
                  color: "#b9e8cd",
                  icon: <Euro size={16} stroke="#45cf8d" />
                },
                {
                  title: "Client",
                  value: n_1Data.clients,
                  color: "#bbe7f5",
                  icon: <Users size={16} stroke="#7abaf7" />
                },
                {
                  title: "Taux d'occupation",
                  value: n_1Data.occupationRate + "%",
                  color: "#d1c0ee",
                  icon: <PieChart size={16} stroke="#5c14eb" />
                },
                {
                  title: "Rdv",
                  value: n_1Data.rdv,
                  color: "#f7ddb2",
                  icon: <CalendarClock size={16} stroke="#f5c67a" />
                }
              ].map((data, key) => (
                <div
                  key={`pro-data-${key}`}
                  className="flex items-center py-2 p-3 gap-2 bg-gray-100 rounded-lg"
                >
                  <div className="p-1 rounded" style={{ backgroundColor: data.color }}>
                    {data.icon}
                  </div>

                  <div className="flex flex-col">
                    <span className="text-[.75em]">{data.title}</span>
                    <span className="text-lg font-semibold">{data.value}</span>
                  </div>
                </div>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion
        defaultActiveKey="0"
        className="p-4 px-2 flex flex-col gap-2"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <img
              width={32}
              height={32}
              className="rounded-full me-3"
            />
            Mois précédents
          </Accordion.Header>
          <Accordion.Body
            className="flex flex-wrap items-center justify-around gap-3"
          >
            {previousMonth.map((data, key) => (
              <div
                key={key}
                className="p-2 px-3 flex flex-col items-center bg-gray-100 rounded"
              >
                <span>{data.month} {data.year}</span>
                <span className="text-lg font-semibold">{data.total} €</span>
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <p className="text-center text-[#bd193b] underline cursor-pointer">
        Demander un export de mes revenus
      </p>
    </div>
  )
}

const RenderClients = ({ profileData }) => {
  const [newClients, setNewClients] = useState([])
  const [bestClients, setBestClients] = useState([])

  const getData = async (beautician_id) => {
    try {
      const response = await appointmentApi.getAppointmentByBeautician(beautician_id)

      setNewClients(response.filter(r => checkDateValidation(r.created_at, r.start_time) && r.status !== "completed"))

      // Obtenir les meilleurs clients
      const now = new Date();

      const filteredClients = response.reduce((result, rdv) => {
        const dateRdv = new Date(rdv.appointment_date);
        const estPendingDepasse = rdv.status === 'confirmed' && dateRdv < now;
        const estCompleted = rdv.status === 'completed';

        if (estCompleted || estPendingDepasse) {
          const cleClient = `${rdv.user_id}-${rdv.first_name}-${rdv.last_name}`;

          // Initialiser l'objet client s'il n'existe pas
          if (!result[cleClient]) {
            result[cleClient] = {
              count: 0,
              totalPrice: 0
            };
          }

          // Incrémenter le compteur et ajouter le prix
          result[cleClient].count += 1;
          result[cleClient].totalPrice += parseFloat(rdv.total_prices) || 0;
        }

        return result;
      }, {});

      setBestClients(
        Object.entries(filteredClients)
          .map(([cle, { count, totalPrice }]) => {
            const [userId, firstName, lastName] = cle.split('-');
            return {
              user_id: parseInt(userId),
              first_name: firstName,
              last_name: lastName,
              nombre_rdv: count,
              total_price: totalPrice.toFixed(2) // Formatage à 2 décimales
            };
          })
          .sort((a, b) => b.nombre_rdv - a.nombre_rdv)
          .slice(0, 3)
      );
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData(profileData.beautician_id)
  }, [profileData])

  const AppointmentCard = ({ title, rdvCount, price, highlight = false }) => {
    return (
      <div className={`p-4 mb-3 rounded-lg shadow-sm ${highlight ? 'bg-amber-50 border-l-4 border-amber-400' : 'bg-white'}`}>
        <h2 className="text-lg font-medium text-gray-800 mb-2">{title}</h2>
        <div className="flex justify-between">
          <span className="font-semibold text-gray-600">{rdvCount} rdv</span>
          <span className="text-gray-500 italic">{price}</span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="p-4 pb-0 flex gap-2 items-center justify-end">
        {/* L'entrée */}
        <div className="flex items-center px-2 py-1 border bg-gray-100 rounded-full">
          <input type="text" className="bg-transparent" placeholder="Rechercher un client" />
          <button className="p-1 rounded-full bg-[#bd193b]">
            <Search stroke="#fff" />
          </button>
        </div>

        {/* Button du filtre */}
        <button>
          <SlidersHorizontal stroke="#bd193b" />
        </button>
      </div>

      <Accordion defaultActiveKey="0" className="m-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <img
              width={32}
              height={32}
              className="rounded-full me-3"
            />
            Nouveaux clients
          </Accordion.Header>

          <Accordion.Body>
            {newClients.length > 0 ?
              <Table striped bordered hover responsive>
                <thead>
                  <tr className="*:text-sm">
                    <th>#</th>
                    <th>Date rendez-vous</th>
                    <th>Heure</th>
                    <th>Nom du client</th>
                    <th>Services</th>
                    <th>Total coût</th>
                  </tr>
                </thead>
                <tbody>
                  {value.map((appt, index) => (
                    <tr key={appt.id} className="*:text-sm">
                      <td>{index + 1}</td>
                      <td>{new Date(appt.appointment_date).toLocaleDateString('fr-FR', { day: '2-digit', month: 'long', year: 'numeric' })}</td>
                      <td>{appt.start_time} - {appt.end_time}</td>
                      <td>{appt.first_name} {appt.last_name}</td>
                      <td>
                        {appt.service_name} - {appt.total_prices}€
                      </td>
                      <td>{appt.total_prices}€</td>
                    </tr>
                  ))}
                </tbody>
              </Table> :
              <p className="text-center">Aucune données disponible</p>
            }
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <img
              width={32}
              height={32}
              className="rounded-full me-3"
            />
            Meilleurs clients
          </Accordion.Header>
          <Accordion.Body>
            {bestClients.length > 0 ?
              <div>
                <p className="text-end text-sm text-[#bd193b] cursor-pointer">Envoyer un code promo au podium</p>

                {bestClients.map((client, key) =>
                  <AppointmentCard
                    key={key}
                    title={`${client.last_name} ${client.first_name}`}
                    rdvCount={client.nombre_rdv}
                    price={client.total_price}
                  />
                )}
              </div> :
              <p className="text-center">Aucune données à afficher</p>
            }
          </Accordion.Body>
        </Accordion.Item>
      </Accordion >

      <p className="text-center text-[#bd193b] underline cursor-pointer">
        Demander un export de mes revenus
      </p>
    </div >
  )
}

const RenderServices = ({ profileData }) => {
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8']

  const [servicesData, setServicesData] = useState([])
  const { prestations } = useContext(BeauticianPortalContext)

  const [filteredData, setFilteredData] = useState([])

  const getServicesData = async () => {
    try {
      // Récupération des sous-catégories du prestataire
      const beauticianAppointment = (await appointmentApi.getAppointmentByBeautician(profileData.beautician_id))
        .filter(r => {
          const appointement_date = new Date(r.appointment_date)

          return (r.status === "completed" || r.status === "confirmed") && appointement_date < new Date()
        })

      const serviceCount = beauticianAppointment.reduce((acc, appointment) => {
        const { service_name } = appointment;

        // Ajouter la durée au total du service
        if (!acc[service_name]) {
          acc[service_name] = {
            name: service_name,
            value: 1
          };
        } else {
          acc[service_name].value++; // Ajouter la durée au total si le service existe déjà
        }

        return acc;
      }, {});

      // Calcul du total de rendez-vous
      const totalCount = Object.values(serviceCount).reduce((sum, category) => sum + category.value, 0)

      // Mise à jour des données des services
      setServicesData(Object.values(serviceCount).map(category => ({
        name: category.name,
        value: category.value,
        percentage: ((category.value / totalCount) * 100).toFixed(2)
      })))

      setFilteredData(prestations.map(category => {
        // Filtrer les items pour ne garder que ceux dans serviceCount
        const filteredItems = category.items.filter(item =>
          Object.keys(serviceCount).includes(item.service_subcategory_name)
        ).map(item => ({
          name: item.service_subcategory_name,
          nombre_de_rendez_vous: 1, // Vous pouvez ajuster cette valeur
          total: 1 * parseFloat(item.beautician_subcategory_price) // Calcul du total
        }));

        // Ne retourner la catégorie que si elle contient des items filtrés
        if (filteredItems.length > 0) {
          return {
            category_name: category.category_name,
            items: filteredItems
          };
        }
        return null;
      }).filter(Boolean))

    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getServicesData()
  }, [])

  return (
    <div>
      <div className="m-4 p-4 border shadow rounded">
        <h2 className="text-lg">Répartition des services</h2>
        <div className="">
          <ResponsiveContainer width="100%" height={300}>
            <PChart>
              <Pie
                data={servicesData}
                innerRadius={60}
                outerRadius={100}
                paddingAngle={5}
                dataKey="value"
              >
                {servicesData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PChart>
          </ResponsiveContainer>
          <div className="pt-2 flex flex-wrap items-center justify-center gap-2 border-t">
            {servicesData.map((service, index) => (
              <div key={index} className="legend-item">
                <span
                  className="size-2 rounded-sm"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}
                />
                <span className="text-sm">{service.name}</span>
                <span className="text-sm font-semibold">{service.percentage}%</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="px-4 pb-4">
        {filteredData.length > 0 &&
          <Accordion defaultActiveKey="0">
            {filteredData.map((category, index) => (
              <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>
                  <img
                    width={32}
                    height={32}
                    className="rounded-full me-3"
                    src={categoryImages[category.category_name.includes("pilation") ? "Épilation" : category.category_name]}
                  />
                  {category.category_name}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="flex flex-col gap-2">
                    {category.items.map((item, itemIndex) => (
                      <div
                        key={itemIndex}
                        className="flex items-center justify-between"
                      >
                        <span>{item.name}</span>
                        <span>{item.nombre_de_rendez_vous} rdv</span>
                        <span>{item.total}€</span>
                      </div>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>}
      </div>

      <p className="text-center text-[#bd193b] underline cursor-pointer">
        Demander un export de mes revenus
      </p>
    </div>
  )
}

const BeauticianGestionCaisse = () => {
  const { profileData } = useContext(BeauticianPortalContext);

  const [revenueInfo, setRevenueInfo] = useState(null);
  const [showMonthDetailsModal, setShowMonthDetailsModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);

  const [activeTab, setActiveTab] = useState("Paiments");

  const [clients, setClients] = useState(0);
  const [appointements, setAppointments] = useState(0);
  const [occupationRate, setOccupationRate] = useState(0);

  const today = new Date();
  const formattedTodayDate = format(today, 'd MMMM yyyy', { locale: fr });

  const fetchRevenueInfo = async (beautician_id) => {
    try {
      const response = await beauticianApi.getAllAppointmentInfoByBeauticianId(localStorage.getItem('token'), beautician_id);
      if (response.success) {
        setRevenueInfo(response.data);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const fetchAppointements = async (beautician_id) => {
    try {
      const response = await appointmentApi.getAppointmentByBeautician(beautician_id);

      const timeToMinutes = (time) => {
        const [h, m, s] = time.split(":").map(Number)
        return h * 60 + m
      }

      // Calcul du temps total disponible dans la semaine (hors pause)
      const totalDisponible = profileData.availabilities ? profileData.availabilities.reduce((sum, dispo) => {
        const matin = timeToMinutes(dispo.break_start) - timeToMinutes(dispo.start_time)
        const aprèsMidi = timeToMinutes(dispo.end_time) - timeToMinutes(dispo.break_end)
        return sum + matin + aprèsMidi
      }, 0) : 0

      // Calcul du temps total réservé par les rendez-vous
      const totalRéservé = response.reduce((sum, rdv) => {
        return sum + (timeToMinutes(rdv.end_time) - timeToMinutes(rdv.start_time))
      }, 0)

      // Calcul du taux d'occupation
      const tauxOccupation = (totalRéservé / totalDisponible) * 100

      setOccupationRate(tauxOccupation.toFixed(2))

      setAppointments(response.length);
      setClients([...new Set(response.map(appointment => appointment.user_id))].length);
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (profileData) {
      fetchRevenueInfo(profileData.beautician_id);
      fetchAppointements(profileData.beautician_id);
    }
  }, [profileData]);

  const PAYMENT_TYPE = {
    full: 'Paiement complet',
    deposit: 'Acompte',
    onsite: 'Paiement sur site',
  }

  const handleCloseShowMonthDetailsModal = () => {
    setShowMonthDetailsModal(false);
  }

  const handleClickMonth = (month, payment_type) => {
    setSelectedMonth(month);
    setSelectedPaymentType(payment_type);
    setShowMonthDetailsModal(true);
  }

  return (
    <>
      <div className="hidden md:block">
        <div className="agenda-container p-6 bg-gray-100 rounded-lg shadow-md">

          <div className="agenda-header mb-4">
            <h4 className="text-xl font-bold text-gray-800">Revenu mensuel à la date du {formattedTodayDate}</h4>
          </div>

          <div className="space-y-6">
            <h5>Paiement par carte bancaire</h5>
            {/* Section des revenus mensuels */}
            <div className="monthly-earnings">
              <ul className="overflow-y-auto max-h-[50vh] space-y-3 p-3">
                {revenueInfo && revenueInfo.monthlyEarnings.length > 0 ? (
                  revenueInfo.monthlyEarnings.map((item, index) => (
                    <li key={index} onClick={() => handleClickMonth(item.month, item.payment_type)} className="bg-white cursor-pointer hover:bg-gray-200 p-4 rounded-lg shadow flex justify-between items-center">
                      <span className="text-gray-600">{format(new Date(item.month), 'MMMM yyyy', { locale: fr })}</span>
                      <span className="text-gray-500">{PAYMENT_TYPE[item.payment_type]}</span>
                      <div>
                        <div className="text-yellow-600 mb-2 font-semibold">Total payé en ligne : {item.total_earnings} €</div>
                        <div className="text-green-600 font-semibold">Total coût des services : {item.total_prices} €</div>
                      </div>
                    </li>
                  ))
                ) : (
                  <p className="text-gray-500">Aucune donnée disponible pour les revenus mensuels.</p>
                )}
              </ul>
            </div>

            <div className="space-y-6">
              <h5>Paiement sur site</h5>
              {/* Section des revenus mensuels */}
              <div className="monthly-earnings">
                <ul className="overflow-y-auto max-h-[50vh] space-y-3 p-3">
                  {revenueInfo && revenueInfo.monthlyEarningsOnSite.length > 0 ? (
                    revenueInfo.monthlyEarningsOnSite.map((item, index) => (
                      <li key={index} onClick={() => handleClickMonth(item.month, item.payment_type)} className="bg-white cursor-pointer hover:bg-gray-200 p-4 rounded-lg shadow flex justify-between items-center">
                        <span className="text-gray-600">{format(new Date(item.month), 'MMMM yyyy', { locale: fr })}</span>
                        <span className="text-gray-500">{PAYMENT_TYPE[item.payment_type]}</span>
                        <div>
                          <div className="text-green-600 font-semibold">Total coût des services : {item.total_prices} €</div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <p className="text-gray-500">Aucune donnée disponible pour les revenus mensuels sur site.</p>
                  )}
                </ul>
              </div>
            </div>


            {/* Section des totaux globaux */}
            <h5>Total global</h5>
            <div className='flex flex-col md:flex-row flex-wrap'>

              <div className="grand-total w-full md:w-2/3">
                <ul className="space-y-2 p-3">
                  {revenueInfo && revenueInfo.grandTotal.length > 0 ? (
                    revenueInfo.grandTotal.map((item, index) => (
                      <li key={index} className="bg-white p-4 rounded-lg shadow flex justify-between items-center">
                        <span className="text-gray-500">{PAYMENT_TYPE[item.payment_type]}</span>
                        <div>
                          <div className="text-yellow-600 mb-2 font-semibold">Total global payé en ligne : {item.grand_total} €</div>
                          <div className="text-green-600 font-semibold">Total global coût des services : {item.total_prices} €</div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <p className="text-gray-500">Aucune donnée disponible pour le total global.</p>
                  )}
                </ul>
              </div>
              <div className="w-full md:w-1/3 p-3">
                <div className="bg-white p-4 rounded-lg shadow text-center">
                  <h4 className="text-4xl font-semibold text-gray-700 mb-2">Total global</h4>
                  <h6 className="text-3xl font-bold text-green-600">
                    {(revenueInfo && (revenueInfo.grandTotalAll.total_prices || 0)) || 0} €
                  </h6>
                </div>
              </div>

            </div>
          </div>

          {/* Modal show details par mois */}
          {showMonthDetailsModal &&
            <ShowMonthDetailsModal
              show={showMonthDetailsModal}
              handleClose={handleCloseShowMonthDetailsModal}
              selectedMonth={selectedMonth}
              selectedPaymentType={selectedPaymentType}
              beautician_id={profileData.beautician_id}
            />
          }
        </div>
      </div>

      <div className="md:hidden">
        <h1 className="pt-2 text-2xl text-center font-bold">Mes revenus</h1>

        <div className="flex flex-row-reverse px-4">
          <select
            className="px-3 py-2 border rounded-2xl"
            onChange={(e) => setSelectedMonth(new Date(new Date().setMonth(e.target.value)))}
          >
            <option disabled selected>Mois</option>
            {[
              "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
              "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
            ].map((month, index) => (
              <option
                key={index} value={index + 1}
              >
                {month}
              </option>
            ))}
          </select>
        </div>

        <div className="p-4 pt-2 grid grid-cols-2 gap-2">
          {[
            {
              title: "Chiffre d'affaire",
              value: (revenueInfo?.grandTotalAll?.total_prices || 0) + " €",
              color: "#b9e8cd",
              icon: <Euro size={16} stroke="#45cf8d" />
            },
            {
              title: "Client",
              value: clients,
              color: "#bbe7f5",
              icon: <Users size={16} stroke="#7abaf7" />
            },
            {
              title: "Taux d'occupation",
              value: occupationRate + "%",
              color: "#d1c0ee",
              icon: <PieChart size={16} stroke="#5c14eb" />
            },
            {
              title: "Rdv",
              value: appointements,
              color: "#f7ddb2",
              icon: <CalendarClock size={16} stroke="#f5c67a" />
            }
          ].map((data, key) => (
            <div
              key={`pro-data-${key}`}
              className="flex items-center py-2 p-3 gap-2 bg-gray-100 rounded-lg"
            >
              <div className="p-1 rounded" style={{ backgroundColor: data.color }}>
                {data.icon}
              </div>

              <div className="flex flex-col">
                <span className="text-[.75em]">{data.title}</span>
                <span className="text-lg font-semibold">{data.value}</span>
              </div>
            </div>
          ))}
        </div>

        <div className="py-2 px-4 flex justify-between border-t border-b">
          {["Paiments", "CA", "Clients", "Services"].map((tab, key) => (
            <span
              key={`tabs-${key}`}
              onClick={() => setActiveTab(tab)}
              className={`px-2 ${activeTab === tab && "text-white bg-[#bd193b]"
                } font-semibold rounded-xl cursor-pointer duration-200`}
            >
              {tab}
            </span>
          ))}
        </div>

        {/* Affichage principale scrollable */}
        <div className="flex-1 h-[calc(100vh-200px)] overflow-y-auto">
          {activeTab === "Paiments" &&
            <RenderPaiments
              profileData={profileData}
              selectedMonth={selectedMonth}
            />
          }
          {activeTab === "CA" &&
            <RenderCA
              profileData={profileData}
              selectedMonth={selectedMonth}
            />
          }
          {activeTab === "Clients" &&
            <RenderClients
              profileData={profileData}
            />
          }
          {activeTab === "Services" &&
            <RenderServices
              profileData={profileData}
            />
          }
        </div>
      </div>
    </>
  );
};

export default BeauticianGestionCaisse;