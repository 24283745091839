import React, { useEffect, useState } from "react"
import serviceCategoriesApi from "../../../api/service-categories.api"
import serviceApi from "../../../api/service.api"
import beauticianApi from "../../../api/beautician.api"

import { Modal, Badge } from "react-bootstrap"
import { Star, MapPin, Wallet, CheckCircle, XCircle, Search, Users, Calendar, ChevronRight, ChevronLeft } from "lucide-react"
import Swal from "sweetalert2"
import recruitersApi from "../../../api/recruiters.api"

const menuItems = [
  { title: "Trouver", icon: <Search size={20} />, id: "find" },
  { title: "Repérés", icon: <Users size={20} />, id: "spotted" },
  { title: "Recrutés", icon: <CheckCircle size={20} />, id: "hired" },
  { title: "Entretiens", icon: <Calendar size={20} />, id: "interviews" }
]

const titles = {
  find: "🔍 Rechercher un professionnel",
  spotted: "👀 Professionnels repérés",
  hired: "✅ Professionnels recrutés",
  interviews: "📅 Entretiens planifiés"
}

const BeauticianInformation = ({ beautician, show, handleClose }) => {
  if (!beautician) return null

  const handleHiring = () => {
    try {
      Swal.fire({
        title: "Recrutement",
        html: `Recruter ${beautician.business_name} ?`,
        showConfirmButton: true,
        confirmButtonText: "Oui",
        confirmButtonColor: "#bd193b",
        showCancelButton: true,
        cancelButtonText: "Annuler"
      })
      .then(async(res) => {
        if(res.isConfirmed) {
          const token = localStorage.getItem("token")
          const userId = localStorage.getItem("userId")

          const results = await recruitersApi.hireBeautician(token, beautician.id, userId)
        }
      })
    }
    catch(err) {
      Swal.fire("Recrtutement", err.message, "error")
    }
    try {
      Swal.fire({
        title: "Recrutement",
        html: `Recruter ${beautician.business_name} ?`,
        showConfirmButton: true,
        confirmButtonText: "Oui",
        confirmButtonColor: "#bd193b",
        showCancelButton: true,
        cancelButtonText: "Annuler"
      })
      .then(async(res) => {
        if(res.isConfirmed) {
          const token = localStorage.getItem("token")
          const userId = localStorage.getItem("userId")

          const results = await recruitersApi.hireBeautician(token, beautician.id, userId)
        }
      })
    }
    catch(err) {
      Swal.fire("Recrtutement", err.message, "error")
    }
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="flex items-center">
          <h1 className="text-lg">{beautician.business_name}</h1>
        </Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        <p className="flex items-center gap-1"><MapPin size={18} className="me-2" /> {beautician.city}</p>
        
        <p className="flex items-center gap-1"><Wallet size={18} className="me-2" /> Catégorie : <Badge bg="info">{beautician.budget_category}</Badge></p>
        
        <p className="flex items-center gap-1"><Star size={18} className="me-2 text-warning" /> 
          {beautician.average_rating} ({beautician.comment_count} avis)
        </p>
        
        <p className="flex items-center gap-1">
          {beautician.is_available ? 
            <CheckCircle size={18} className="me-2 text-success" /> : 
            <XCircle size={18} className="me-2 text-danger" />
          }
          {beautician.is_available ? "Disponible" : "Non disponible"}
        </p>

        <h6 className="mt-3">Modes de paiement :</h6>
        <ul>
          {beautician.payment_modes?.full && <li>Paiement complet</li>}
          {beautician.payment_modes?.onsite && <li>Sur place</li>}
          {beautician.payment_modes?.deposit && <li>Acompte ({beautician.deposit_percentage}%)</li>}
        </ul>
      </Modal.Body>

      <Modal.Footer>
        <button className="px-3 py-2 bg-gray-500 text-white rounded" onClick={handleClose}>Annuler</button>
        <button className="px-3 py-2 bg-[#bd193b] text-white rounded" onClick={handleHiring}>Recruter</button>
        <button className="px-3 py-2 bg-[#bd193b] text-white rounded" onClick={handleHiring}>Recruter</button>
      </Modal.Footer>
    </Modal>
  )
}

const BeauticianManagement = () => {
  const [activeTab, setActiveTab] = useState("find")
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResult, setSearchResult] = useState([])
  const [categoryFilter, setCategoryFilter] = useState(-1)
  const [allCategories, setAllCategories] = useState("")
  const [selectedBeautician, setSelectedBeautician] = useState({})
  const [showBeauticianInformation, setShowBeauticianInformation] = useState(false)

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(5)

  const status = {
    pending: "En attente",
    confirmed: "Confirmé",
    planified: "RDV planifié",
    "A voir": "A voir"
  }

  const handleSearch = (e) => {
    paginate(1)
    setSearchTerm(e.target.value)
  }

  const handleShowInformation = (beautician) => {
    setSelectedBeautician(beautician)
    setShowBeauticianInformation(true)
  }

  const handleBeauticianInformationModalClose = () => {
    setShowBeauticianInformation(false)
    setSelectedBeautician({})
  }

  useEffect(() => {
    const getAllCategories = async () => {
      try {
        const categories = await serviceCategoriesApi.getAllCategories()

        setAllCategories([{ category_id: -1, category_name: "Toutes les catégories" }, ...categories])
      }
      catch (err) {
        console.error("Loading categories: ", err)
      }
    }

    getAllCategories()
  }, [])

  useEffect(() => {
    const fetchSearchResult = async () => {
      const recruiter_id = localStorage.getItem("userId")
      const beauticianHiringStatus = await recruitersApi.getHiringsByRecruiter(recruiter_id)
      const beauticianIdsOnHiring = beauticianHiringStatus.data.map(hiring => hiring.beautician_id)

      if (categoryFilter == -1) {
        const results = (await beauticianApi.getAllAvailableBeauticiansWithAbonnement()).data
          .filter(pro =>
            searchTerm ? pro.business_name.toLowerCase().startsWith(searchTerm.toLowerCase()) : pro
          )
          .map(pro => ({
              ...pro,
              status: beauticianIdsOnHiring.includes(pro.id) ?
                beauticianHiringStatus.data.filter(p => p.beautician_id === pro.id)[0]?.status :
                "A voir"
            }))

        setSearchResult(results)
      }
      else {
        try {
          const results = await serviceApi.getBeauticiansByServiceId(categoryFilter)

          if (Array.isArray(results)) {
            const beauticians = await Promise.all(
              results.map(async (b) => {
                const beautician = await beauticianApi.getBeauticianById(b.beautician_id)

                return {...beautician.data}
              })
            )

            setSearchResult(
              beauticians
                .filter(pro =>
                  searchTerm ? pro?.business_name.toLowerCase().startsWith(searchTerm.toLowerCase()) : pro
                )
            )
          } else {
            setSearchResult([])
          }

        } catch (error) {
          console.error("Loading search results: ", error)
        }
      }
    }

    fetchSearchResult()
  }, [categoryFilter, searchTerm])

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = searchResult.slice(indexOfFirstItem, indexOfLastItem)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  return (
    <div className="p-4 md:p-8 bg-gray-100 min-h-screen">
      <h1 className="text-xl md:text-2xl border-b pb-3 font-semibold mb-4">Recrutement des professionnels</h1>

      {/* MENU - Responsive */}
      <nav className="grid grid-cols-2 md:flex gap-2 md:gap-4 mb-6">
        {menuItems.map(item => (
          <button
            key={item.id}
            className={`flex items-center justify-center md:justify-start gap-2 px-4 py-2 rounded-lg transition text-sm md:text-base 
              ${activeTab === item.id ? "bg-white shadow font-semibold" : "text-gray-600 hover:bg-gray-200"}`}
            onClick={() => setActiveTab(item.id)}
          >
            {item.icon}
            {item.title}
          </button>
        ))}
      </nav>

      {/* FORMULAIRE DE RECHERCHE */}
      <div className="bg-white p-4 rounded-lg shadow text-gray-700">
        <h2 className="text-lg font-semibold mb-4">{titles[activeTab]}</h2>

        <form className="flex flex-col md:flex-row gap-4">
          {/* Champ de recherche */}
          <div className="relative w-full">
            <Search className="absolute left-3 top-3 text-gray-500" size={20} />
            <input
              type="text"
              placeholder="Nom du professionnel..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-[#bd193b] outline-none"
              value={searchTerm}
              onChange={() => handleSearch(e)}
            />
          </div>

          {/* Filtre par catégorie */}
          <select
            className="border py-2 px-4 rounded-lg focus:ring-2 focus:ring-[#bd193b]"
            value={categoryFilter}
            onChange={e => setCategoryFilter(e.target.value)}
          >
            {Array.isArray(allCategories) && allCategories.map((cat, key) =>
              <option key={`cat-keys-${key}`} value={cat.category_id}>{cat.category_name}</option>
            )}
          </select>
        </form>
      </div>

      {/* LISTE DES PROFESSIONNELS - Responsive */}
      <section className="mt-6 bg-white p-4 rounded-lg shadow">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold mb-4">{titles[activeTab]}</h2>

          <select
            className="border outline-[#bd193b] px-2 py-1 rounded"
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            {[5, 10, 20, 100].map(items =>
              <option key={`ppages-key-${items}`} value={items}>{items}</option>
            )}
          </select>
        </div>

        {/* TABLEAU SUR GRAND ÉCRAN */}
        <table className="hidden md:table w-full border-collapse">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-2 text-left">Nom</th>
              <th className="p-2 text-left">Note</th>
              <th className="p-2 text-left">Statut</th>
              <th className="p-2 text-left">Contact</th>
              <th className="p-2 text-left"></th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map(pro => (
                <tr key={pro.id} className="border-t hover:bg-gray-100" onClick={() => handleShowInformation(pro)}>
                  <td className="p-2">{pro.business_name}</td>
                  <td className="p-2 flex items-center gap-1">
                    <Star size={16} className="text-yellow-500" /> {pro.average_rating}/5
                  </td>
                  <td className="p-2">
                    <span
                      className={`px-2 py-1 text-xs rounded-full 
                        ${pro.status === "confirmed" ? "bg-green-200 text-green-800" :
                          pro.status === "pending" ? "bg-yellow-200 text-yellow-800" :
                            "bg-gray-200 text-gray-800"}`}
                    >
                      {status[pro.status]}
                    </span>
                  </td>
                  <td className="p-2 text-blue-600 cursor-pointer">{pro.email}</td>
                  <td className="p-2">
                    <button onClick={(e) => e.stopPropagation()}>Recruter</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="p-4 text-center text-gray-500">Aucun professionnel trouvé.</td>
              </tr>
            )}
          </tbody>
        </table>

        {/* CARTES SUR MOBILE */}
        <div className="md:hidden space-y-4">
          {currentItems.length > 0 ? (
            currentItems.map(pro => (
              <div key={pro.id} className="p-4 bg-gray-50 rounded-lg shadow-md">
                <h3 className="font-semibold text-lg">{pro.business_name}</h3>
                <p className="flex items-center gap-1 text-yellow-500 text-sm">
                  <Star size={16} /> {pro.average_rating}/5
                </p>
                <span
                  className={`inline-block px-2 py-1 text-xs rounded-full mt-2 
                    ${pro.status === "confirmed" ? "bg-green-200 text-green-800" :
                      pro.status === "pending" ? "bg-yellow-200 text-yellow-800" :
                        "bg-gray-200 text-gray-800"}`}
                >
                  {status[pro.status]}
                </span>
                <p className="text-blue-600 text-sm mt-2">{pro.email}</p>
              </div>
            ))
          ) : (
            <p className="text-gray-500 text-center">Aucun professionnel trouvé.</p>
          )}
        </div>

        {/* Pagination */}
        <div className="gap-4 flex items-center justify-center mt-4">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="p-2 text-gray-400 rounded-full *:cursor-pointer"
          >
            <ChevronLeft />
          </button>
          <span className="text-gray-400">{`${currentPage} / ${parseInt(searchResult.length / itemsPerPage) + 1}`}</span>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= searchResult.length}
            className="p-2 text-gray-400 rounded-full *:cursor-pointer"
          >
            <ChevronRight />
          </button>
        </div>
      </section>

      <BeauticianInformation
        beautician={selectedBeautician}
        show={showBeauticianInformation}
        handleClose={() => handleBeauticianInformationModalClose()}
      />
    </div>
  )
}

export default BeauticianManagement
