import { Routes, Route } from 'react-router-dom'

import TeleConseillerPortalLayout from './TeleConseillerPortalLayout'

// Les différents composants du Routes
import Dashboard from '../../../components/portals/tele-conseiller/Dashboard'
import LeadsPro from '../../../components/portals/tele-conseiller/LeadsPro'
import FichePro from '../../../components/portals/tele-conseiller/FichePro'
import Suivis from '../../../components/portals/tele-conseiller/Suivis'
import Agendas from '../../../components/portals/tele-conseiller/Agendas'
import Document from '../../../components/portals/tele-conseiller/Document'
import Factures from '../../../components/portals/tele-conseiller/Factures'
import Profile from '../../../components/portals/tele-conseiller/Profile'
import Contact from '../../../components/portals/tele-conseiller/Contact'

const TeleConseillerPortal = () => {
    return (
        <Routes>
            <Route element={<TeleConseillerPortalLayout />}>
                <Route index element={<Dashboard />} />
                <Route path="leads-pro" element={<LeadsPro />} />
                <Route path="fiche-pro" element={<FichePro />} />
                <Route path="suivis" element={<Suivis />} />
                <Route path="agendas" element={<Agendas />} />
                <Route path="document" element={<Document />} />
                <Route path="factures" element={<Factures />} />
                <Route path="profile" element={<Profile />} />
                <Route path="contact" element={<Contact />} />
            </Route>
        </Routes>
    )
}

export default TeleConseillerPortal
