import React from 'react'
import { ChevronLeft, ChevronRight, Search, SlidersHorizontal } from 'lucide-react'
import FicheData from './FicheData'

const FichePro = () => {
    return (
        <section className="h-full overflow-hidden flex flex-col">
            <h1 className="p-4 text-4xl font-bold text-center">Fiche pro</h1>

            <div className="flex-1 p-4 bg-gray-100 rounded-xl overflow-hidden flex flex-col">

                <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
                    {/* Bouttons d'action */}
                    <div className="space-x-4">
                        <button className="px-3 py-2 text-sm text-white bg-[#bd193b] rounded-full">Créer une fiche</button>
                        <button className="px-3 py-2 text-sm text-white bg-[#bd193b] rounded-full">Lien inscription</button>
                    </div>

                    {/* Barre de recherche */}
                    <div className="w-full md:w-auto flex px-3 md:items-center gap-2">
                        <div className="relative flex-1 flex items-center">
                            <input type="text" placeholder="Rechercher un pro" className="px-4 py-2 w-full outline-[#bd193b] rounded-full" />
                            <Search size={24} className="absolute right-0 me-2 bg-white" />
                        </div>

                        <button className="cursor-pointer *:cursor-pointer">
                            <SlidersHorizontal />
                        </button>
                    </div>
                </div>

                <div className="py-3 flex items-center gap-4 font-semibold">
                    <span>Limite par page</span>
                    <span>10</span>
                    
                    {/* Pagination */}
                    <div className="flex items-center gap-3">
                        <button className="text-sm cursor-pointer">
                            <ChevronLeft size={26} />
                        </button>
                        <span>1</span>
                        <button className="text-sm cursor-pointer">
                            <ChevronRight size={26} />
                        </button>
                    </div>
                </div>

                <div className="flex-1 bg-white p-2 overflow-hidden flex flex-col">
                    <h3 className="text-sm border-b-4 pb-2">Liste des Professionnelles</h3>
                    <div className="flex-1 overflow-hidden">
                        <FicheData />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FichePro
