import React, { useContext, useEffect, useState } from 'react';
import { API_URL, BASE_URL } from '../../utils/constants';
import MySpinner from '../loader/Spinner';
import { Clipboard, Search, ChevronLeft, ChevronRight, User, Crown, HandCoins, Award, ChevronDown } from "lucide-react";
import toast from 'react-hot-toast';
import { fr } from 'date-fns/locale/fr';
import { format } from 'date-fns/format';
import DataTable from 'react-data-table-component';
import affiiliationPaymentApi from '../../api/affiliation-payments.api';
import { AppContext } from '../../contexts/AppContext';
import { showErrorMessage, showSuccessMessage } from '../../utils/messageHelper';
import { SearchContext } from '../../context/SearchContext';
import useIsMobile from '../../hooks/useIsMobile';

const months = [
	"Janvier",
	"Février",
	"Mars",
	"Avril",
	"Mai",
	"Juin",
	"Juillet",
	"Août",
	"Septembre",
	"Octobre",
	"Novembre",
	"Décembre"
]

const ProgrammeAffiliation = ({ beautician_id, slug }) => {

	const { connectedUser } = useContext(AppContext);
	const isMobile = useIsMobile();

	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState({
		month: new Date().getMonth() + 1,
		year: new Date().getFullYear()
	});
	const [loading, setLoading] = useState(false);
	const [activeNav, setActiveNav] = useState('inscrit');

	const [allData, setAllData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [allStats, setAllStats] = useState({
		inscriptions: 0,
		essais: 0,
		abonnements: 0,
		revenus: 0
	});

	const [statut, setStatut] = useState('en attente');

	const [monthStats, setMonthStats] = useState({
		inscriptions: 0,
		essais: 0,
		abonnements: 0,
		revenus: 0
	});

	const [affiliationPayment, setAffiliationPayment] = useState([]);
	const [reloadTrigger, setReloadTrigger] = useState(false);

	const columns = [
		{
			name: 'Nom commercial',
			selector: row => row.business_name || `${row.first_name} ${row.last_name}`,
			sortable: true,
			center: true,
		},
		{
			name: 'Date inscription',
			selector: row => format(new Date(row.created_at), "dd MMMM yyyy à HH:mm", { locale: fr }),
			sortable: true,
			center: true,
		},
		{
			name: 'Date de l’essai',
			selector: row => row.trial_start ?
				`${format(new Date(row.trial_start), "dd MMMM yyyy à HH:mm", { locale: fr })} au ${format(new Date(row.trial_end), "dd MMMM yyyy à HH:mm", { locale: fr })}` :
				"pas d'essai",
			sortable: true,
			center: true,
		},
		{
			name: 'Date de prélèvement',
			selector: row => row.abonnement_status === "active" ?
				format(new Date(row.abonnement_start_date), 'dd MMMM yyyy à HH:mm', { locale: fr }) :
				(row.trial_end ? format(new Date(row.trial_end), 'dd MMMM yyyy à HH:mm', { locale: fr }) : '-'),
			sortable: true,
			center: true,
		},
		{
			name: 'Type abonnement',
			selector: row => row.offre_name ? `Formule ${row.offre_name} ${parseInt(row.offre_price)}€` : 'Aucun abonnement',
			sortable: true,
			center: true,
		},
		{
			name: 'Abonnement validé',
			selector: row => row.abonnement_status,
			sortable: true,
			center: true,
			cell: row => (
				<span className={`font-bold ${row.abonnement_status === "active" ? "text-green-600" : row.abonnement_status === "trialing" ? "text-yellow-600" : "text-red-600"}`}>
					{row.abonnement_status || 'Non'}
				</span>
			)
		}
	];


	const copierLien = async (lienAffiliation) => {

		try {
			await navigator.clipboard.writeText(lienAffiliation);
			toast.success("Lien copié !");
		} catch (error) {
			toast.error("Échec de la copie");
		}
	}

	const filterData = (data) => {
		const filtered = data.filter((item) => {
			const date = new Date(item.created_at);
			return date.getMonth() + 1 === filter.month && date.getFullYear() === filter.year;
		});

		if (search) {
			const searchValue = search.toLowerCase();
			const searchFiltered = filtered.filter((item) => {
				return item.business_name.toLowerCase().includes(searchValue) || item.first_name.toLowerCase().includes(searchValue) || item.last_name.toLowerCase().includes(searchValue);
			});
			setFilteredData(searchFiltered);
			return;
		}

		setFilteredData(filtered);
	}

	useEffect(() => {
		if (filter) {
			filterData(allData);
			const _monthStats = getAllStats(allData.filter((item) => {
				const date = new Date(item.created_at);
				return date.getMonth() + 1 === filter.month && date.getFullYear() === filter.year;
			}));
			setMonthStats(_monthStats);

			getPaymentStatus(_monthStats, affiliationPayment);
		}
	}, [filter, SearchContext]);

	useEffect(() => {

		const fetchAffiliedUsers = async () => {
			try {

				const affiliation_payments = await affiiliationPaymentApi.getAllByBeauticianId(localStorage.getItem('token'), beautician_id);
				setAffiliationPayment(affiliation_payments);

				const response = await fetch(`${API_URL}/affiliation/get-all-affilied-user/${beautician_id}`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"Authorization": `Bearer ${localStorage.getItem("token")}`
					}
				});
				const data = await response.json();
				setAllData(data);
				filterData(data);
				const _allStats = getAllStats(data);
				setAllStats(_allStats);
				const _monthStats = getAllStats(data.filter((item) => {
					const date = new Date(item.created_at);
					return date.getMonth() + 1 === filter.month && date.getFullYear() === filter.year;
				}));
				setMonthStats(_monthStats);
				getPaymentStatus(_monthStats, affiliation_payments);
			} catch (error) {
				console.error(error);
			}
		}


		if (beautician_id) {
			setLoading(true);
			fetchAffiliedUsers();
			setTimeout(() => {
				setLoading(false);
			}, 1000);
		}

	}, [beautician_id, reloadTrigger]);


	const getAllStats = (data) => {
		const stats = data.reduce((acc, item) => {
			acc.inscriptions += 1;
			if (item.abonnement_status === "active") {
				acc.abonnements += 1;
				acc.revenus += Number.parseFloat(item.offre_price);
			}
			if (item.abonnement_status === "trialing") {
				acc.essais += 1;
			}
			return acc;
		}, { inscriptions: 0, essais: 0, abonnements: 0, revenus: 0 });

		return stats;
	}

	const handleFilterChange = (isYear, step) => {
		isYear ?
			setFilter(prev => ({ ...prev, year: prev.year + step })) :
			setFilter(prev => {
				let nextMonth = prev.month + step
				let nextYear = prev.year

				if (nextMonth < 1) {
					nextYear--
					nextMonth = 12
				}
				else if (nextMonth > 12) {
					nextYear++
					nextMonth = 1
				}

				return { year: nextYear, month: nextMonth }
			})
	}

	const customStyles = {
		headCells: {
			style: {
				backgroundColor: '#881225',
				color: 'white',
				fontWeight: 'bold',
				textAlign: 'center',
				borderRight: '4px solid white',
			},
		},
	};

	const getPaymentStatus = (_monthStats, affiliation_payments) => {
		let today = new Date();
		if (filter.month >= today.getMonth() + 1 && filter.year >= today.getFullYear()) {
			setStatut('en attente');
			return;
		} else {
			if (_monthStats.revenus <= 0) {
				setStatut('aucun paiement');
				return;
			}

			if (affiliation_payments.some((element) => element.month === filter.month && element.year === filter.year)) {
				setStatut('payé');
				return;
			}

			setStatut('non payé');
			return;
		}
	}

	const PaymentStatut = () => {
		switch (statut) {
			case 'en attente':
				return <div className='px-3 py-1 rounded-3xl bg-gray-500 text-white text-sm'>En attente</div>
			case 'payé':
				return <div className='px-3 py-1 rounded-3xl bg-green-500 text-white text-sm'>Payé</div>;
			case 'non payé':
				return <div className='px-3 py-1 rounded-3xl bg-red-500 text-white text-sm'>Non Payé</div>;
			case 'aucun paiement':
				return <div className='px-3 py-1 rounded-3xl bg-yellow-500 text-white text-sm'>Aucun paiement</div>;
			default:
				return '';
		}
	}

	const handlePayment = async (month, year) => {
		try {
			await affiiliationPaymentApi.create(localStorage.getItem('token'), { month: month, year: year, beauticianId: beautician_id })
			showSuccessMessage('Le statut du payment a été changé avec succès!', () => {
				setReloadTrigger(prev => !prev);
			});
		} catch (error) {
			console.log(error);
			showErrorMessage('Une erreur est survenue lors du traitement de la requête!');
		}
	}

	if (!beautician_id || loading) {
		return <MySpinner height={"80vh"} />
	}

	return (
		<div className="affiliation affiliation-container">
			<div className="p-1 md:p-3">
				<h2 className='text-xl md:text-2xl text-center md:text-left'>Mon programme d'affiliation</h2>
			</div>
			<div className="px-3 pb-3 min-h-screen">
				{/* Lien d'affiliation */}
				<div className="bg-[#881225] text-white px-4 py-2 rounded-xl text-sm md:text-xl flex gap-2 justify-between items-center">
					<div className='truncate'>
						<span className="font-bold">Lien d’affiliation :</span>{" "}
						<span className="underline cursor-pointer">{BASE_URL}/affiliation-inscription/{slug}</span>
					</div>
					<div>

						<Clipboard className="cursor-pointer"
							size={20}
							onClick={() => copierLien(`${BASE_URL}/affiliation-inscription/${slug}`)}
						/>
					</div>
				</div>
				{!isMobile ? <>

					{/* Statistiques globales */}
					<h3 className='text-2xl my-3 px-4'>Total</h3>
					<div className="kpi-grid">
						{[
							{ label: "Inscriptions", value: allStats.inscriptions, icon: <User className='stat-icon' color='#0ea5e9' />, color: '#0ea5e9' },
							{ label: "Essais", value: allStats.essais, icon: <Award className='stat-icon' color='#8b5cf6' />, color: '#8b5cf6' },
							{ label: "Abonnements", value: allStats.abonnements, icon: <Crown className='stat-icon' color="#f59e0b" />, color: '#f59e0b' },
							{ label: "Revenus affiliés", value: `${allStats.revenus * 0.3}€`, icon: <HandCoins className='stat-icon' color='#10b981' />, color: "#10b981" },
						].map((stat, index) => (
							<div key={index} className="kpi-card">
								<div className="kpi-icon" style={{ backgroundColor: `${stat.color}15`, color: stat.color }}>
									{stat.icon}
								</div>
								<div className="kpi-info">
									<span className="kpi-title">{stat.label}</span>
									<div className="kpi-stats">
										<span className="kpi-value">{stat.value}</span>
									</div>
								</div>
							</div>
						))}
					</div>

					{/* Détails mensuels */}
					<div className="flex justify-between items-center mt-4 mb-2 px-4">
						<h3 className='text-2xl'>Détails</h3>
						<div className='flex gap-2 items-center'>
							{(statut === 'non payé' && connectedUser.user_type === 'admin') &&
								<button className='btn btn-secondary' onClick={() => handlePayment(filter.month, filter.year)}>Marquer comme payé</button>}
							<div>
								<PaymentStatut />
							</div>
						</div>
					</div>
					<div className="flex justify-between flex-col md:flex-row items-center">
						<div className="flex items-center gap-2 text-sm md:text-lg">
							<div className="flex items-center gap-2">
								<button className="cursor-pointer p-2 rounded-full hover:bg-gray-100" onClick={() => handleFilterChange(false, -1)}><ChevronLeft className='cursor-pointer' size="24" /></button>

								<button>{months[filter.month - 1]}</button>

								<button className="cursor-pointer p-2 rounded-full hover:bg-gray-100" onClick={() => handleFilterChange(false, 1)}><ChevronRight className='cursor-pointer' size="24" /></button>
							</div>

							<div className="flex items-center gap-2">
								<button className="cursor-pointer p-2 rounded-full hover:bg-gray-100" onClick={() => handleFilterChange(true, -1)}><ChevronLeft className='cursor-pointer' size="24" /></button>

								<button>{filter.year}</button>

								<button className="cursor-pointer p-2 rounded-full hover:bg-gray-100" onClick={() => handleFilterChange(true, 1)}><ChevronRight className='cursor-pointer' size="24" /></button>
							</div>

						</div>
						<div className="relative ">
							<Search className="absolute left-3 top-2.5 text-gray-400" size={18} />
							<input
								type="text"
								placeholder="Rechercher un professionnel"
								className="pl-10 pr-4 py-2 border rounded-lg w-full md:w-64 focus:ring focus:ring-blue-300"
								value={search}
								onChange={(e) => setSearch(e.target.value)}
							/>
						</div>
					</div>

					<div className="kpi-grid mt-2">
						{[
							{ label: "Inscriptions", value: monthStats.inscriptions, icon: <User className='stat-icon' color='#0ea5e9' />, color: '#0ea5e9' },
							{ label: "Essais", value: monthStats.essais, icon: <Award className='stat-icon' color='#8b5cf6' />, color: '#8b5cf6' },
							{ label: "Abonnements", value: monthStats.abonnements, icon: <Crown className='stat-icon' color="#f59e0b" />, color: '#f59e0b' },
							{ label: "Revenus affiliés", value: `${monthStats.revenus * 0.3}€`, icon: <HandCoins className='stat-icon' color='#10b981' />, color: "#10b981" },
						].map((stat, index) => (
							<div key={index} className="kpi-card">
								<div className="kpi-icon" style={{ backgroundColor: `${stat.color}15`, color: stat.color }}>
									{stat.icon}
								</div>
								<div className="kpi-info">
									<span className="kpi-title">{stat.label}</span>
									<div className="kpi-stats">
										<span className="kpi-value">{stat.value}</span>
									</div>
								</div>
							</div>
						))}
					</div>

					{/* Tableau des inscriptions */}
					<div className="mt-3">
						<DataTable
							columns={columns}
							data={filteredData}
							pagination
							highlightOnHover
							defaultSortFieldId={1}
							customStyles={customStyles}
							responsive
						/>
					</div>
				</>
					: <div>
						<div className="w-full flex justify-between mt-3 border-t border-b border-gray-300 p-2">
							<div onClick={() => setActiveNav('inscrit')} className={`cursor-pointer px-3 rounded-xl w-[48%] text-center ${activeNav === 'inscrit' ? 'text-white bg-[#710f26]' : 'text-gray-800 hover:bg-[#710f26] hover:text-white'}`}>Inscrits</div>
							<div onClick={() => setActiveNav('revenu')} className={`cursor-pointer px-3 rounded-xl w-[48%] text-center ${activeNav === 'revenu' ? 'text-white bg-[#710f26]' : 'text-gray-800 hover:bg-[#710f26] hover:text-white'}`}>Revenus affiliés</div>
						</div>
						{activeNav === 'inscrit' && <>
							<div className='flex justify-between items-center py-1 px-4 mt-3 bg-[#f5f5ef] rounded-3xl'><h3 className='text-xl'>Nouveaux inscrits</h3><ChevronDown size={24} /></div>
							<div className="flex justify-between items-center mt-4 mb-2">
								<h3 className='text-base'>Statut payment</h3>
								<div className='flex gap-2 items-center'>
									{(statut === 'non payé' && connectedUser.user_type === 'admin') &&
										<button className='btn btn-secondary' onClick={() => handlePayment(filter.month, filter.year)}>Marquer comme payé</button>}
									<div>
										<PaymentStatut />
									</div>
								</div>
							</div>
							<div className="flex justify-between w-full items-center">
								<div className="flex items-center text-sm md:text-lg">
									<div className="flex items-center gap-1">
										<button className="cursor-pointer p-1 rounded-full hover:bg-gray-100" onClick={() => handleFilterChange(false, -1)}><ChevronLeft className='cursor-pointer' size="24" /></button>

										<button>{months[filter.month - 1]}</button>

										<button className="cursor-pointer p-1 rounded-full hover:bg-gray-100" onClick={() => handleFilterChange(false, 1)}><ChevronRight className='cursor-pointer' size="24" /></button>
									</div>

									<div className="flex items-center">
										<button className="cursor-pointer p-1 rounded-full hover:bg-gray-100" onClick={() => handleFilterChange(true, -1)}><ChevronLeft className='cursor-pointer' size="24" /></button>

										<button>{filter.year}</button>

										<button className="cursor-pointer p-1 rounded-full hover:bg-gray-100" onClick={() => handleFilterChange(true, 1)}><ChevronRight className='cursor-pointer' size="24" /></button>
									</div>

								</div>
								<div className="relative ">
									{/* <Search className="absolute left-3 top-2.5 text-gray-400" size={18} /> */}
									<input
										type="text"
										placeholder="Rechercher un professionnel"
										className="p-2 w-32 border rounded-lg focus:ring focus:ring-blue-300"
										value={search}
										onChange={(e) => setSearch(e.target.value)}
									/>
								</div>
							</div>

							<div className="flex gap-2 justify-center mt-2">
								{[
									{ label: "Inscriptions", value: monthStats.inscriptions, icon: <User className='stat-icon' color='#0ea5e9' />, color: '#0ea5e9' },
									{ label: "Essais", value: monthStats.essais, icon: <Award className='stat-icon' color='#8b5cf6' />, color: '#8b5cf6' },
									{ label: "Abonnements", value: monthStats.abonnements, icon: <Crown className='stat-icon' color="#f59e0b" />, color: '#f59e0b' },
									{ label: "Revenus affiliés", value: `${monthStats.revenus * 0.3}€`, icon: <HandCoins className='stat-icon' color='#10b981' />, color: "#10b981" },
								].map((stat, index) => (
									<div key={index} className="p-1 rounded w-32 flex flex-col justify-center items-center gap-1 shadow-sm">
										<div className="p-0.5 rounded" style={{ backgroundColor: `${stat.color}15`, color: stat.color }}>
											{stat.icon}
										</div>
										<div className="kpi-info !text-center">
											<div className="!text-center">
												<span className="text-xl font-semibold !text-center">{stat.value}</span>
											</div>
											<span className="kpi-title">{stat.label}</span>
										</div>
									</div>
								))}
							</div>
							<div className="mt-3">
								<DataTable
									columns={columns}
									data={filteredData}
									pagination
									highlightOnHover
									defaultSortFieldId={1}
									customStyles={customStyles}
									responsive
								/>
							</div>

							<div className='flex justify-between items-center py-1 px-4 mt-3 bg-[#f5f5ef] rounded-3xl'><h3 className='text-xl'>Total inscriptions</h3><ChevronDown size={24} /></div>

							<div className="flex gap-2 justify-center mt-3">
								{[
									{ label: "Inscriptions", value: allStats.inscriptions, icon: <User className='stat-icon' color='#0ea5e9' />, color: '#0ea5e9' },
									{ label: "Essais", value: allStats.essais, icon: <Award className='stat-icon' color='#8b5cf6' />, color: '#8b5cf6' },
									{ label: "Abonnements", value: allStats.abonnements, icon: <Crown className='stat-icon' color="#f59e0b" />, color: '#f59e0b' },
									{ label: "Revenus affiliés", value: `${allStats.revenus * 0.3}€`, icon: <HandCoins className='stat-icon' color='#10b981' />, color: "#10b981" },
								].map((stat, index) => (
									<div key={index} className="p-1 rounded w-32 flex flex-col justify-center items-center gap-1 shadow-sm">
										<div className="p-0.5 rounded" style={{ backgroundColor: `${stat.color}15`, color: stat.color }}>
											{stat.icon}
										</div>
										<div className="kpi-info !text-center">
											<div className="!text-center">
												<span className="text-xl font-semibold !text-center">{stat.value}</span>
											</div>
											<span className="kpi-title">{stat.label}</span>
										</div>
									</div>
								))}
							</div>
						</>}

						{activeNav === 'revenu' && <>

						</>}
					</div>}
				{connectedUser.user_type === 'beautician' && <div className='text-center mt-5'>
					<p className='text-xl font-semibold text-[#710f26]'>Pour tout abonnement souscrit avec votre lien, vous obtenez 30% de leur 1ère mensualité</p>
					<a href='#' className='text-[#710f26] underline'>Demander un export de mes revenus</a>
				</div>}
			</div>
		</div>
	);


}

export default ProgrammeAffiliation;