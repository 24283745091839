import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { FaCamera } from 'react-icons/fa6';
import Swal from 'sweetalert2';
import beauticianApi from '../../api/beautician.api';
import userApi from '../../api/user.api';
import offreApi from '../../api/offre.api';
import PasswordField from '../../components/common/PasswordField';
import { API_URL, BASE_URL } from '../../utils/constants';
import defaultProfile from '../../assets/images/default-profile.png';
import { showErrorMessage, showWarningMessage } from '../../utils/messageHelper';

const RegisterAffiliationForm = ({ slug, selectedType }) => {

    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [nomCommercial, setNomCommercial] = useState('');
    const [adresse, setAdresse] = useState('');
    const [email, setEmail] = useState('');
    const [numero, setNumero] = useState('');
    const [motDePasse, setMotDePasse] = useState('');
    const [confirmMotDePasse, setConfirmMotDePasse] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [preview, setPreview] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [message, setMessage] = useState('');

    // States for error messages
    const [errors, setErrors] = useState({
        nom: '',
        prenom: '',
        nomCommercial: '',
        adresse: '',
        email: '',
        numero: '',
        motDePasse: '',
        confirmMotDePasse: '',
        selectedFile: '',
        agree_terms: '',
    });

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => setPreview(e.target.result);
            reader.readAsDataURL(file);
            setUploadStatus('');
        }
    };

    const handleUpload = async () => {
        // if (!selectedFile) {
        //     Swal.fire('Erreur', 'Veuillez sélectionner une image.', 'error');
        //     return '';
        // }

        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const response = await fetch(`${API_URL}/upload`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                return result.fileName;
            } else {
                const error = await response.json();
                throw error;
                // setUploadStatus(`Erreur: ${error.error}`);
                // return '';
            }
        } catch (error) {
            console.error('Error during upload:', error);
            // Swal.fire('Erreur', "Erreur lors de l'upload.", 'error');
            return null;
        }
    };

    const deleteImage = async (imageName) => {
        try {
            const response = await fetch(`${API_URL}/delete-image-file`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ image_name: imageName }),
            });

            if (response.success) {
                return await response.json();
            } else {
                const error = await response.json();
                return error.message;
            }
        } catch (error) {
            console.error('Error during deleting file:', error);
            return error;
        }
    };

    const validateFields = () => {
        let valid = true;
        const newErrors = {};


        if (!nom) {
            newErrors.nom = 'Le nom est requis.';
            valid = false;
        }
        if (!prenom) {
            newErrors.prenom = 'Le prénom est requis.';
            valid = false;
        }
        if (!email) {
            newErrors.email = "L'email est requis.";
            valid = false;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                newErrors.email = "L'adresse e-mail n'est pas valide.";
                valid = false;
            }
        }
        if (!numero) {
            newErrors.numero = 'Le numéro de téléphone est requis.';
            valid = false;
        } else {
            const phoneRegex = /^[0-9]{10}$/;
            if (!phoneRegex.test(numero)) {
                newErrors.numero = 'Le numéro de téléphone n\'est pas valide!';
                valid = false;
            }
        }
        if (!adresse) {
            newErrors.adresse = 'L\'adresse est requise.';
            valid = false;
        }
        if (!motDePasse) {
            newErrors.motDePasse = 'Le mot de passe est requis.';
            valid = false;
        } else if (motDePasse.length < 6) {
            newErrors.motDePasse = 'Le mot de passe doit contenir au moins 6 caractères.';
            valid = false;
        }
        if (motDePasse !== confirmMotDePasse) {
            newErrors.confirmMotDePasse = 'Les mots de passe ne correspondent pas.';
            valid = false;
        }
        // if (!selectedFile) {
        //     newErrors.selectedFile = 'Veuillez sélectionner une image.';
        //     valid = false;
        // }

        if (!agreeTerms) {
            newErrors.agree_terms = 'Veuillez accepter les conditions d\'utilisation.';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };


    const handleCreateUser = async () => {
        if (!validateFields()) {
            showWarningMessage('Veuillez remplir tous les champs');
            return;
        }

        setIsLoading(true);
        setMessage('');

        try {

            // on recupere l'offre classique
            const offres = await offreApi.getAllOffer();
            let offre = null;
            if (Array.isArray(offres)) {
                if(selectedType === 'indépendant') {
                offre = offres.find((obj) => obj.name.toLowerCase().includes("classique"));
                } else {
                    offre = offres.find((obj) => obj.name.toLowerCase().includes("équipe"));
                }
            }

            if (!offre) {
                showErrorMessage('Aucune offre n\'a été trouvé!');
                setIsLoading(false);
                return;
            }
                
                Swal.fire({
                    title: 'Chargement...',
                    text: 'Veuillez patienter...',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let filename = null;
                if(selectedFile) {
                    filename = await handleUpload();
                }

                // if (filename !== '') {
                    const user = {
                        first_name: nom,
                        last_name: prenom,
                        phone: numero,
                        email: email,
                        password: motDePasse,
                        confirmPassword: confirmMotDePasse,
                        is_account_active: true,
                        agree_terms: true,
                        is_active: true
                    };

                    try {
                        const user_create = await userApi.createBeauticianUser(user);

                        if (user_create.user) {
                            const userId = user_create.user.id;

                            try {

                                const parain = await beauticianApi.getBeauticianBySlug(slug, true);

                                const create_beautician = await beauticianApi.createBeauticianProfile({
                                    user_id: userId,
                                    address: adresse,
                                    description: '',
                                    budget_category: 'economic',
                                    profile_photo: filename,
                                    business_name: nomCommercial,
                                    type_beautician: selectedType,
                                    is_active: true,
                                    is_available: false,
                                    parain_id: parain.data.id,
                                });

                                if (create_beautician.success) {

                                    Swal.close();
                                    setIsLoading(false);
                                    setTimeout(() => {
                                        Swal.fire(
                                            'Inscritpion effectué avec succès',
                                            'Vous pouvez maintenant vous connecter avec vos identifiants.',
                                            'success'
                                        ).then(() => {
                                            window.location.href = "/?status=new-user";
                                        });
                                    }, 200);

                                } else {
                                    await userApi.deleteAccount(userId);
                                    if(filename) {
                                        await deleteImage(filename);
                                    }
                                    Swal.close();
                                    setIsLoading(false);
                                    setTimeout(() => {
                                        Swal.fire('Erreur', create_beautician.message || 'Erreur lors de la création du profil.', 'error');
                                    }, 200);
                                }
                            } catch (error) {
                                await userApi.deleteAccount(userId);
                                if(filename) {
                                    await deleteImage(filename);
                                }
                                Swal.close();
                                setIsLoading(false);
                                setTimeout(() => {
                                    Swal.fire('Erreur', error.message || 'Erreur lors de la création du profil.', 'error');
                                }, 200);
                            }
                        } else {
                            if(filename) {
                                await deleteImage(filename);
                            }
                            Swal.close();
                            setIsLoading(false);
                            setTimeout(() => {
                                Swal.fire('Erreur', user_create.message || 'Erreur lors de la création du profil.', 'error');
                            }, 200);
                        }
                    } catch (error) {
                        if(filename) {
                            await deleteImage(filename);
                        }
                        Swal.close();
                        setIsLoading(false);
                        setTimeout(() => {
                            Swal.fire('Erreur', error.message || 'Erreur lors de la création de l\'utilisateur.', 'error');
                        }, 200);
                    }
                
            // }
        } catch (error) {
            setIsLoading(false);
            setTimeout(() => {
                Swal.fire('Erreur', error.message || 'Erreur lors de la création de l\'utilisateur.', 'error');
            }, 200);
        }
    };

    const handleSubmit = async () => {

        await handleCreateUser();

    }

    return <React.Fragment>
        <div className="max-w-4xl px-3 py-5 shadow-sm bg-white rounded mx-auto">
            <div className="form-header">
                <h2>Commencez l'aventure</h2>
                <p>Remplissez le formulaire ci-dessous pour nous rejoindre</p>
            </div>
            <Form onSubmit={(e) => e.preventDefault()}>
                <div className='text-center mb-4'>
                    <label className="flex flex-col items-center gap-2 cursor-pointer">
                        {preview ? (
                            <img
                                src={preview}
                                alt="Preview"
                                className="w-20 h-20 object-cover rounded-full border"
                            />
                        ) : (
                            <img
                                src={defaultProfile}
                                alt="Preview"
                                className="w-20 h-20 object-cover rounded-full border"
                            />
                        )}
                        <div className='flex gap-2 items-center mb-2'>
                            <FaCamera />
                            {preview ? <span>Modifier la photo de profil</span> : <span>Ajouter une photo de profil</span>}
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                        {errors.selectedFile && <div className="text-danger">{errors.selectedFile}</div>}
                    </label>
                </div>

                <Row className='mb-2'>
                    <Col md={6}>
                        <Form.Group controlId="formNom">
                            <Form.Label>Nom*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nom"
                                value={nom}
                                onChange={(e) => setNom(e.target.value)}
                            />
                            {errors.nom && <div className="text-danger">{errors.nom}</div>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group as={Col} controlId="formPrenom">
                            <Form.Label>Prénom(s)*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Prénom(s)"
                                value={prenom}
                                onChange={(e) => setPrenom(e.target.value)}
                            />
                            {errors.prenom && <div className="text-danger">{errors.prenom}</div>}
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Col md={6}>
                        <Form.Group controlId="formNomCommercial">
                            <Form.Label>Nom commercial</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nom commercial"
                                value={nomCommercial}
                                onChange={(e) => setNomCommercial(e.target.value)}
                            />
                            {errors.nomCommercial && <div className="text-danger">{errors.nomCommercial}</div>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formAdresse">
                            <Form.Label>Adresse postal*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Adresse postal"
                                value={adresse}
                                onChange={(e) => setAdresse(e.target.value)}
                            />
                            {errors.adresse && <div className="text-danger">{errors.adresse}</div>}
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mb-2'>
                    <Col md={6}>
                        <Form.Group as={Col} controlId="formEmail">
                            <Form.Label>E-mail*</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="E-mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group as={Col} controlId="formNumero">
                            <Form.Label>Numéro de téléphone*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Numéro de téléphone"
                                value={numero}
                                onChange={(e) => setNumero(e.target.value)}
                            />
                            {errors.numero && <div className="text-danger">{errors.numero}</div>}
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mb-2'>
                    <Col md={6}>
                        <Form.Group as={Col} controlId="formMotDePasse">
                            <Form.Label>Mot de passe*</Form.Label>
                            <PasswordField
                                value={motDePasse}
                                onChange={(e) => setMotDePasse(e.target.value)}
                                placeholder="Mot de passe"
                                label=""
                                required
                            />
                            {errors.motDePasse && <div className="text-danger">{errors.motDePasse}</div>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group as={Col} controlId="formConfirmMotDePasse">
                            <Form.Label>Vérification mot de passe*</Form.Label>
                            <PasswordField
                                value={confirmMotDePasse}
                                onChange={(e) => setConfirmMotDePasse(e.target.value)}
                                placeholder="Vérification mot de passe"
                                label=""
                                required
                            />
                            {errors.confirmMotDePasse && <div className="text-danger">{errors.confirmMotDePasse}</div>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col md={12}>
                        <Form.Group controlId="formAgreeTerms" className="mb-0">
                            <Form.Check
                                type="checkbox"
                                label="J'accepte les termes et les conditions d'utilisation (CGU et CGV)"
                                checked={agreeTerms}
                                onChange={(e) => setAgreeTerms(e.target.checked)}
                            />
                            {errors.agree_terms && <div className="text-danger">{errors.agree_terms}</div>}
                            <p className='mt-3 text-justify'>Lire les <a href={`${process.env.NODE_ENV === 'production' ? BASE_URL : 'http://localhost:3000'}/ConditionsGenerales`} target='_blank' rel="noreferrer" className='text-blue-600 hover:text-blue-700 hover:underline cursor-pointer'>Conditions Générales d'Utilisation et Conditions Générales de Ventes</a></p>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <div className="w-full">
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="submit-button"
                    disabled={isLoading}
                >
                    Valider votre inscription
                </button>
            </div>
        </div>
    </React.Fragment>
}

export default RegisterAffiliationForm;