import React from 'react';
import './Pagination.css';

function Pagination({ currentPage, totalItems, itemsPerPage, onPageChange }) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="my-pagination">
      <div className="my-pagination-info">
        Limite par page
        <span className="my-pagination-limit">{itemsPerPage}</span>
      </div>
      
      <div className="my-pagination-controls">
        <button 
          className="my-pagination-btn my-pagination-prev"
          onClick={handlePrevious}
          disabled={currentPage === 1}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </button>
        
        <span className="my-pagination-current">{currentPage}</span>
        
        <button 
          className="my-pagination-btn my-pagination-next"
          onClick={handleNext}
          disabled={currentPage === totalPages}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </button>
      </div>
    </div>
  );
}

export default Pagination;