import Swal from "sweetalert2"
import { Modal } from "react-bootstrap"

import Calendar from "react-calendar"
import 'react-calendar/dist/Calendar.css'

import { getMonday, getWeekDates } from "./utils"

import trendingApi from "../../../api/trending.api"

const AddToTrendingPopUp = ({ show, setShow, selectedBeautician, trendsTrigger}) => {
    const handleClosePanel = () => {
        setShow(false)
    }

    const addBeauticianToTrending = async(beautician_id, selectedDate) => {
        try {
            const activeDate = getWeekDates(getMonday(selectedDate))
            const results = await trendingApi.addToTrending(beautician_id, selectedDate)

            if(results.success) {
                Swal.fire("Réussi", `Professionnelle ajouté aux tendances du ${activeDate[0]} au ${activeDate[1]}`, "success")
            }
        }
        catch(err) {
            console.error(err.message)
        }
    }

    const handleAddBeauticianToTrending = (beautician_id, business_name, selectedDate) => {
        const checkStatusAndAddToTrending = async () => {
            try {
                const beauticianTrending = await trendingApi.getTrendingByBeauticianId(beautician_id)

                if (Array.isArray(beauticianTrending.data) && beauticianTrending.data.length > 0) {
                    const formatDate = (date) => {
                        const d = new Date(date)
                        const day = String(d.getDate()).padStart(2, '0')
                        const month = String(d.getMonth() + 1).padStart(2, '0')
                        const year = d.getFullYear()
                        return `${day}/${month}/${year}`
                    }
                    
                    const getWeekRange = (weekStart) => {
                        const start = new Date(weekStart)
                        const end = new Date(start)
                        end.setDate(start.getDate() + 6) // Ajoute 6 jours pour obtenir la fin de la semaine
                        return `${formatDate(start)} au ${formatDate(end)}`
                    }
    
                    const weekRanges = beauticianTrending.data.map(entry => getWeekRange(entry.week_start))
    
                    // Construire le texte HTML
                    let htmlContent = `
                        <p>
                            Êtes-vous sûr(e) de vouloir ajouter <strong>${business_name}</strong> à la liste des tendances ?
                        </p>
                        <p>
                            Cette professionnelle a déjà été dans les tendances durant les semaines suivantes :
                        </p>
                    `
    
                    weekRanges.forEach(week => {
                        htmlContent += `<p>- ${week}</p>`
                    })
    
                    Swal.fire({
                        title: "Ajout au tendance",
                        html: htmlContent,
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonText: 'Oui',
                        cancelButtonText: 'Non',
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            addBeauticianToTrending(beautician_id, selectedDate)
                            trendsTrigger(prev => !prev)
                            setShow(false)
                        }
                    })
                    return
                }
    
                addBeauticianToTrending(beautician_id, selectedDate)
            } catch (err) {
                console.error(err.message)
            }
        }
    
        checkStatusAndAddToTrending()
    }

    const handleSelectedDate = (date) => {
        const getMonday = (date) => {
            const d = new Date(date)
            const day = d.getDay() // 0 (Dimanche) à 6 (Samedi)
            const diff = d.getDate() - day + (day === 0 ? -6 : 1) // Ajuste pour que lundi soit le début
            return new Date(d.setDate(diff))
        }
    
        const formatDate = (date) => {
            const d = new Date(date)
            const day = String(d.getDate()).padStart(2, '0')
            const month = String(d.getMonth() + 1).padStart(2, '0')
            const year = d.getFullYear()
            return `${day}/${month}/${year}`
        }
    
        const getWeekRange = (date) => {
            const monday = getMonday(date)
            const sunday = new Date(monday)
            sunday.setDate(monday.getDate() + 6) // Ajoute 6 jours pour atteindre dimanche
            return {
                start: formatDate(monday),
                end: formatDate(sunday),
            }
        }
    
        const selectedWeek = getWeekRange(date) // Calcule la semaine complète pour la date sélectionnée
    
        trendingApi.getTrendingByBeauticianId(selectedBeautician.id)
            .then(async(beauticianTrending) => {
                const selectedDateTrending = await trendingApi.getTrendingByWeek(date)

                if(Array.isArray(selectedDateTrending.data) && selectedDateTrending.data.length >= 15) {
                    Swal.fire("Tendances", "Le nombre maximal(15) de tendances pour cette semaine a été atteint, il n'est plus possible d'en ajouter.", "error")
                    return
                }

                if (Array.isArray(beauticianTrending.data) && beauticianTrending.data.length > 0) {
                    // Vérifiez si une des semaines existantes chevauche la semaine sélectionnée
                    const existingWeeks = beauticianTrending.data.map(entry => getWeekRange(entry.week_start))
                    const isConflict = existingWeeks.some(week =>
                        week.start === selectedWeek.start && week.end === selectedWeek.end
                    )
    
                    if (isConflict) {
                        Swal.fire({
                            title: "Erreur",
                            text: `Cette professionnelle est déjà dans les tendances pour la semaine du ${selectedWeek.start} au ${selectedWeek.end}.`,
                            icon: "error",
                            confirmButtonText: "OK"
                        })
                        return
                    }
                }
    
                // Si pas de conflit, continuez avec l'ajout
                Swal.fire({
                    title: "Ajout d'une professionnelle aux tendances",
                    text: `Vous voulez ajouter ${selectedBeautician.business_name} dans la tendance de la semaine du ${selectedWeek.start} au ${selectedWeek.end}`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Non',
                }).then((result) => {
                    if (result.isConfirmed) {
                        handleAddBeauticianToTrending(selectedBeautician.id, selectedBeautician.business_name, date)
                    }
                })
            })
            .catch(err => console.error(err.message))
    }    

    return (
    <Modal
        centered
        show={show}
        onHide={handleClosePanel}
    >
        <Modal.Header>
            <span className="text-center text-xl">Ajouter <b>{selectedBeautician.business_name && selectedBeautician.business_name.substring(0, 20)}</b> aux tendances</span>
        </Modal.Header>
        <Modal.Body>
            <div className="mb-3">
                <span className="text-xl text-gray-700 font-semibold">Choisissez une date: </span>
            </div>
            <Calendar
                onClickDay={handleSelectedDate}
                minDate={new Date()}
            />
        </Modal.Body>
    </Modal>)
}

export default AddToTrendingPopUp