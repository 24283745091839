import { useState } from "react";
import { registerLinksApi } from "../../../api/register_links.api";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { showErrorMessage, showSuccessMessage, showWarningMessage } from "../../../utils/messageHelper";

const CreateRegisterLinkModal = ({ showModal, setShowModal, setReloadTrigger }) => {

    const [months, setMonths] = useState("");
    const [identification, setIdentification] = useState("");

    const handleClose = () => {
        setReloadTrigger(prev => !prev);
        setShowModal(false);
    };

    const submit = async () => {

        if (!months) {
            showWarningMessage('Veuillez preciser le mois!');
            return;
        }

        try {

            let days = months === "10" ? 10 : null;
            await registerLinksApi.create(localStorage.getItem('token'), months !== "10" ? months : null, days, identification, localStorage.getItem('userId')); // on verifie seulement si monts === 10 et si oui c'est un jour XD
            showSuccessMessage('Lien créé avec succès!');
            setReloadTrigger(prev => !prev);
            setShowModal(false);
        } catch (error) {
            console.log(error);
            showErrorMessage('Une erreur s\'est produite!')
        }
    };

    return (
        <>
            {/* Modal Principal */}
            <Modal show={showModal} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Créer un lien d'inscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <Form.Group>
                                <Form.Label>Sélectionnez le nombre de mois :</Form.Label>
                                <Form.Select value={months} onChange={(e) => setMonths(e.target.value)}>
                                    <option value="">-- Choisir --</option>
                                    <option value="10">10 jours</option>
                                    <option value="1">1 mois</option>
                                    <option value="2">2 mois</option>
                                    <option value="6">6 mois</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row class="mt-3">
                        <Col md={12}>
                            <Form.Group>
                                <Form.Label>Identification (nom du professionnel, etc...) :</Form.Label>
                                <Form.Control type="text" value={identification} onChange={(e) => setIdentification(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Fermer</Button>
                    <Button variant="success" onClick={submit}>Créer</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateRegisterLinkModal;