import MySpinner from "../../../components/loader/Spinner";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import AttributeAbonnementModal from "./AttributeAbonnementModal";
import abonnementApi from "../../../api/abonnement.api";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { fr } from "date-fns/locale";

const AttribAbonnement = () => {

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [reloadTrigger, setReloadTrigger] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterOffre, setFilterOffre] = useState("all"); // 'all', 'offre_x', etc.
    const [filterMonths, setFilterMonths] = useState("all"); // 'all', '1', '2', '6'


    const fetchUsers = async () => {
        setLoading(true);
        const res = await abonnementApi.getAllAbonnementByType(localStorage.getItem('token'), 'attribued');
        setUsers(res.data || []);
        setLoading(false);
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [reloadTrigger]);


    function calculateMonthsDifference(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const yearDiff = end.getFullYear() - start.getFullYear();
        const monthDiff = end.getMonth() - start.getMonth();

        return yearDiff * 12 + monthDiff;
    }

    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
            width: "70px",
        },
        {
            name: "Nom et prénom",
            selector: (row) => row.user_name,
            sortable: true,
        },
        {
            name: "Offre",
            selector: (row) => row.offre_name,
            sortable: true,
        },
        {
            name: "Nombre de mois",
            cell: (row) => calculateMonthsDifference(row.start_date, row.end_date),
            sortable: true,
        },
        {
            name: "Date début",
            selector: (row) => format(new Date(row.start_date), "d MMMM yyyy à HH:mm", { locale: fr }),
            sortable: true
        },
        {
            name: "Date fin",
            selector: (row) => format(new Date(row.end_date), "d MMMM yyyy à HH:mm", { locale: fr }),
            sortable: true,
        }
    ];

    // Styles personnalisés pour la table
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: "50vh", // Définissez ici votre hauteur minimale souhaitée
            },
        },
        rows: {
            style: {
                minHeight: "50px",
                cursor: "pointer"
            },
        },
        headCells: {
            style: {
                fontWeight: "bold",
                backgroundColor: "#f7f7f7",
                fontSize: "14px"
            },
        },
        cells: {
            style: {
                padding: "5px",
                fontSize: "14px"
            },
        },
    };

    const paginationOptions = {
        rowsPerPageText: 'Lignes par page',
        rangeSeparatorText: 'sur',
        noRowsPerPage: false,
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Tout',
    };

    const filteredUsers = users.filter((user) => {
        // Vérifie le terme de recherche
        const matchesSearch = user.user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.offre_name.toLowerCase().includes(searchTerm.toLowerCase());
    
        // Vérifie le filtre offre
        const matchesOffre = filterOffre === "all" || user.offre_name === filterOffre;
    
        // Vérifie le filtre nombre de mois
        const months = calculateMonthsDifference(user.start_date, user.end_date);
        const matchesMonths = filterMonths === "all" || months === parseInt(filterMonths);
    
        return matchesSearch && matchesOffre && matchesMonths;
    });
    

    return (
        <main className="min-h-[72vh]">
            {loading ? (
                <div className="w-full">
                    <MySpinner height={"60vh"} />
                </div>
            ) : (
                <div>
                    <div className="p-2 md:p-6 bg-gray-100 rounded-lg">
                        <div className="flex justify-between items-center gap-2">
                            <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-6">Liste des abonnements attribués</h2>
                            <button
                                onClick={() => setShowModal(true)}
                                className="px-2 md:px-4 py-2 text-sm md:text-base text-white bg-[#bd193b] flex items-center gap-2 rounded shadow"
                            >
                                <FaPlus />
                                Attribuer un abonnement
                            </button>
                        </div>
                        <div className="flex flex-col md:flex-row justify-between gap-4 mb-4">
                            {/* Champ de recherche */}
                            <input
                                type="text"
                                placeholder="Rechercher..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="border p-2 rounded w-full md:w-1/3"
                            />

                            {/* Filtre Offre */}
                            <select
                                value={filterOffre}
                                onChange={(e) => setFilterOffre(e.target.value)}
                                className="border p-2 rounded w-full md:w-1/4"
                            >
                                <option value="all">Toutes les offres</option>
                                {[...new Set(users.map((user) => user.offre_name))].map((offre) => (
                                    <option key={offre} value={offre}>{offre}</option>
                                ))}
                            </select>

                            {/* Filtre Nombre de Mois */}
                            <select
                                value={filterMonths}
                                onChange={(e) => setFilterMonths(e.target.value)}
                                className="border p-2 rounded w-full md:w-1/4"
                            >
                                <option value="all">Tous les mois</option>
                                <option value="1">1 mois</option>
                                <option value="2">2 mois</option>
                                <option value="6">6 mois</option>
                            </select>
                        </div>

                        <DataTable
                            columns={columns}
                            data={filteredUsers}
                            onRowClicked={() => { }}
                            pagination
                            highlightOnHover
                            responsive
                            customStyles={customStyles}
                            paginationComponentOptions={paginationOptions}
                        />
                    </div>
                    {/* Modal pour créer ou modifier une offre */}
                    {showModal &&
                        <AttributeAbonnementModal showModal={showModal} setShowModal={setShowModal} setReloadTrigger={setReloadTrigger} />}
                </div>
            )}
        </main>
    );
}

export default AttribAbonnement;