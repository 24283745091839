
import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import { FaSave, FaCamera } from 'react-icons/fa';
import { deleteProfileImage, getProfileImageUrl } from "../../../../../utils/functions";
import beauticianApi from "../../../../../api/beautician.api";
import userApi from "../../../../../api/user.api";
import { API_URL } from "../../../../../utils/constants";

const InfoPerso = ({ profileData, setReloadTrigger }) => {

    //profile info user
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [infoUser, setInfoUser] = useState({
        type_beautician: '',
        first_name: '',
        last_name: '',
        phone: '',
        address: '',
        description: '',
        business_name: ''
    });

    const token = localStorage.getItem("token");

    useEffect(() => {
        if (profileData) {
            setInfoUser({
                type_beautician: profileData.type_beautician,
                first_name: profileData.first_name,
                last_name: profileData.last_name,
                phone: profileData.phone,
                address: profileData.address,
                description: profileData.description,
                business_name: profileData.business_name
            })
        }
    }, [profileData]);

    const handleUserInfoChange = (field, value) => {
        setInfoUser((prev) => ({ ...prev, [field]: value }));
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => setPreview(e.target.result);
            reader.readAsDataURL(file);
            setUploadStatus('');
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            Swal.fire('Erreur', 'Veuillez sélectionner une image.', 'error');
            return '';
        }

        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const response = await fetch(`${API_URL}/upload`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                return result.fileName;
            } else {
                const error = await response.json();
                setUploadStatus(`Erreur: ${error.error}`);
                return '';
            }
        } catch (error) {
            console.error('Error during upload:', error);
            Swal.fire('Erreur', "Erreur lors de l'upload.", 'error');
            return '';
        }
    };

    const validateInfoUserFields = (infoUser) => {
        if (!infoUser.last_name || !infoUser.first_name || !infoUser.phone || !infoUser.address) {
            Swal.fire('Erreur', 'Veuillez remplir les champs : nom, prénom(s), email, téléphone, adresse!', 'error');
            return false;
        }

        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(infoUser.phone)) {
            Swal.fire('Erreur', 'Le numéro de téléphone n\'est pas valide!', 'error');
            return false;
        }

        return true;
    };

    const handleSaveUserInfo = async () => {
        if (!validateInfoUserFields(infoUser)) return;

        const userInfo = {
            phone: infoUser.phone,
            user_id: profileData.user_id,
            first_name: infoUser.first_name,
            last_name: infoUser.last_name,
            email: profileData.email // on definit ceci pour ne pas construire une autre méthode d'update de profile beautician
        }

        const beauticianData = {
            type_beautician: infoUser.type_beautician,
            address: infoUser.address,
            business_name: infoUser.business_name,
            description: infoUser.description
        }

        Swal.fire({
            title: 'Chargement...',
            text: 'Veuillez patienter...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {

            const update_profile = await userApi.updateProfile(token, userInfo);

            if (update_profile.success) {
                try {

                    if (selectedFile) {
                        if(profileData.profile_photo) {
                            await deleteProfileImage(profileData.profile_photo);
                        }
                        const filename = await handleUpload();
                        if (filename !== '') {
                            beauticianData.profile_photo = filename;
                        }
                    }

                    const update_beautician = await beauticianApi.updateBeautician(token, profileData.beautician_id, beauticianData);
                    if (update_beautician.success) {
                        Swal.close();
                        setTimeout(() => {
                            Swal.fire("Succès", 'Modification reuissi!', 'success');
                        }, 200);
                        setReloadTrigger((prev) => !prev);
                    } else {
                        Swal.close();
                        setTimeout(() => {
                            Swal.fire('Erreur', update_beautician.message || 'Erreur lors de la modification du profil.', 'error');
                        }, 200);
                    }

                } catch (error) {
                    Swal.close();
                    setTimeout(() => {
                        Swal.fire('Erreur', error.message || 'Erreur lors de la modification du profil.', 'error');
                    }, 200);
                }
            } else {
                Swal.close();
                setTimeout(() => {
                    Swal.fire('Erreur', update_profile.message || 'Erreur lors de la modification du profil.', 'error');
                }, 200);
            }
        } catch (error) {
            Swal.close();
            setTimeout(() => {
                Swal.fire('Erreur', error.message || 'Erreur lors de la modification du profil.', 'error');
            }, 200);
        }

    }

    return <React.Fragment>
        <div className="flex justify-center w-full">

            <label className="flex flex-col items-center gap-2 cursor-pointer mb-2">
                {preview ? (
                    <img
                        src={preview}
                        alt="Preview"
                        className="w-20 h-20 object-cover rounded-full border"
                    />
                ) : <img
                    src={getProfileImageUrl(profileData.profile_photo)}
                    alt="Preview"
                    className="w-20 h-20 object-cover rounded-full border"
                />
                }
                <div className='flex gap-2 items-center mb-2'><FaCamera /><span>Modifier la photo de profil</span></div>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
            </label>
        </div>
        <div className="form-row">
        <div className="form-group">
                <label htmlFor="type_beautician">Type de profil</label>
                <select id="type_beautician" name="type_beautician" value={infoUser.type_beautician} onChange={(e) => handleUserInfoChange('type_beautician', e.target.value)}>
                    <option value={'établissement'}>Etablissement</option>
                    <option value={'indépendant'}>Indépendant</option>
                </select>
            </div>
            <div className="form-group">
                <label>Nom commercial</label>
                <input type="text" value={infoUser.business_name} onChange={(e) => handleUserInfoChange('business_name', e.target.value)} />
            </div>
        </div>
        <div className="form-row">
            <div className="form-group">
                <label>Nom *</label>
                <input type="text" value={infoUser.last_name} onChange={(e) => handleUserInfoChange('last_name', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Prénom(s) *</label>
                <input type="text" value={infoUser.first_name} onChange={(e) => handleUserInfoChange('first_name', e.target.value)} />
            </div>
        </div>
        <div className="form-row">
            <div className="form-group">
                <label>Téléphone *</label>
                <input type="tel" value={infoUser.phone} onChange={(e) => handleUserInfoChange('phone', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Adresse *</label>
                <input type="text" value={infoUser.address} onChange={(e) => handleUserInfoChange('address', e.target.value)} />
            </div>
        </div>
        <div className="form-group">
            <label>Description</label>
            <textarea
                className="w-full h-24 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 resize-none shadow-sm placeholder-gray-400"
                placeholder="Description" value={infoUser.description} onChange={(e) => handleUserInfoChange('description', e.target.value)}
            ></textarea>
        </div>
        <div className="flex justify-end">
            <button
                onClick={handleSaveUserInfo}
                className="ms-auto px-3 py-2 flex gap-2 items-center text-white bg-[#bd193b] rounded shadow"
            >
                <FaSave /> Enregistrer la modification
            </button>
        </div>
    </React.Fragment>
}

export default InfoPerso;