import React from "react";
import reseaux_sociaux from '../../assets/images/reseaux sociaux.png';
import pc from '../../assets/images/formation.png';
import main from '../../assets/images/main dans la main.png';


const Reason = () => {
    const features = [
        {
            title: "Accompagnement & Suivis",
            description:
                "PlaniBeauty, c’est plus qu’un simple annuaire : nous sommes à vos côtés à chaque étape de votre parcours.",
            points: [
                { title: "Structuration", text: "Organisez et optimisez votre activité en toute simplicité." },
                { title: "Gestion", text: "Simplifiez la gestion de votre planning et de vos clients." },
                { title: "Suivi personnalisé", text: "Bénéficiez d’un accompagnement adapté à vos besoins." },
            ],
            icon: "👏",
            image: main,
            alt: "Main",
            image_class: "absolute -bottom-4 right-0 bg-white/30 backdrop-blur p-1"
        },
        {
            title: "Communication",
            description: "PlaniBeauty vous aide à booster votre visibilité avec un accompagnement complet.",
            points: [
                { title: "Digital", text: "Packs de gestion des réseaux sociaux, création de contenus et stratégies marketing." },
                { title: "Templates exclusifs", text: "Stories et publications prêtes à l’emploi pour un feed harmonieux." },
                { title: "Conseils personnalisés", text: "Des recommandations adaptées à votre activité pour maximiser votre impact." },
            ],
            icon: "💬",
            image: reseaux_sociaux,
            alt: "Réseaux sociaux",
            image_class: "absolute -bottom-10 right-0"
        },
        {
            title: "Formations & Ateliers",
            description: "PlaniBeauty vous aide à perfectionner votre savoir-faire et à enrichir votre offre.",
            points: [
                { title: "Formations métier et commerciales", text: "Développez de nouvelles compétences pour élargir votre carte de prestations." },
                { title: "Ateliers hebdomadaires", text: "Restez à jour sur les tendances et les meilleures pratiques." },
                { title: "Conseils stratégiques", text: "Apprenez à optimiser votre activité et à fidéliser votre clientèle." },
            ],
            icon: "🎓",
            image: pc,
            alt: "ordinateur",
            image_class: "absolute -bottom-4 right-0 bg-white/50 backdrop-blur p-1"
        },
    ];

    return (
        <section className="py-12 max-w-7xl mx-auto px-3">
            <h2 className="text-2xl md:text-3xl font-bold text-center mb-10">
                Pourquoi tout faire seul ? PlaniBeauty vous accompagne à chaque étape
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-10">
                {features.map((feature, index) => (
                    <div key={index} className="cursor-pointer relative bg-[linear-gradient(to_bottom_right,_#c82e4d_20%,_#f5afbe_50%,_#c82e4d_80%)] text-white py-6 px-6 rounded-3xl shadow-lg transition-transform hover:scale-105">
                        <div className="mb-4 text-2xl font-semibold text-center">
                            {feature.title}
                        </div>
                        <p className="text-base mb-4 text-center">{feature.description}</p>
                        <ul className="space-y-2 p-0 text-justify">
                            {feature.points.map((point, i) => (
                                <li key={i} className="text-base pe-10">
                                    <span className="font-semibold">{point.title}</span> – {point.text}
                                </li>
                            ))}
                        </ul>
                        <div className={feature.image_class} style={{ maskImage: "radial-gradient(circle, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 100%)" }}>
                            <img src={feature.image} alt={feature.alt} className="w-16" />
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Reason;