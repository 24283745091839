import tarifimg from "../../assets/fonctionnalites/fonct_tarif.png";
const FonctionnaliteTarifs = () => {
  return <div className="fonctionnalites-content">
    <div className="tarif-wrapper">
      <div className="text-tarifs">

        <img src={tarifimg} alt="" className="tarif-img" />

        <h4>Un tarif simple et transparent</h4>
        <h3>49€</h3>
        <button className="com-btn" onClick={() => {
          window.location.href = "/ajouter-etablissement"
        }}>Découvrir gratuitement</button>

        <div className="suggestions">
          <p>Toutes les fonctionnalités PlaniBeauty Pro inclues</p>
          <p>Offre sans engagement</p>
          <p>Essai gratuit de 10j</p>
        </div>

      </div>
    </div>
  </div>
}

export default FonctionnaliteTarifs;