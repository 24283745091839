// src/api/service.api.js
import { API_URL } from "../utils/constants";

const serviceApi = {
    // Liste de tout les services
    getAllServices: async () => {
        try {
            const response = await fetch(`${API_URL}/services`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération du liste des services');
        }
    },
    getBeauticiansByServiceId: async (service_id) => {
        try {
            const response = await fetch(`${API_URL}/services/beautician-service/${service_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération du liste des esthéticiennes ayant cette catégorie');
        }
    },
    // Liste de tout les services
    getServicesByBeautician: async (id) => {
        try {
            const response = await fetch(`${API_URL}/services/beautician/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération du liste des services de l\'esthéticienne');
        }
    },
    // Obtention d'un service par id
    getServicesById: async(id) => {
        try {
            const response = await fetch(`${API_URL}/services/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération du service');
        }
    },
    // Obtention d'un sous service par id
    getServicesSubByID: async(id) => {
        try {
            const response = await fetch(`${API_URL}/services/subcategories/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération du sous service');
        }
    },
    // Obtention du sous service par id du category
    getServicesSubByServiceID: async(category_id) => {
        try {
            const response = await fetch(`${API_URL}/services/subcategories/category/${category_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des sous services');
        }
    },
    // Créer un service
    createService: async(token, serviceData) => {
        try {
            const response = await fetch(`${API_URL}/services`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(serviceData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la création du service');
        }
    },
    // Mettre à jour un service
    updateService: async(token, serviceID, serviceData) => {
        try {
            const response = await fetch(`${API_URL}/services/${serviceID}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(serviceData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jours du service');
        }
    },

    // Supprimer un service
    deleteService: async(token, serviceID) => {
        try {
            const response = await fetch(`${API_URL}/services/${serviceID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la suppresion du service');
        }
    }
};

export default serviceApi;