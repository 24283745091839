import { EllipsisVertical } from 'lucide-react'
import React, { useState, useEffect, useRef } from 'react'
import DataTable from 'react-data-table-component'

const ActionMenu = ({ row }) => {
    const [isOpen, setIsOpen] = useState(false)
    const menuRef = useRef(null)

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false)
        }
    }

    const menus = [
        { text: "Modifier les informations personnelles", bg: "bg-yellow-300", action: () => {} },
        { text: "Rajouter à l'agenda", bg: "bg-green-600", action: () => {} },
        { text: "Envoyer une plaquette d'information", color: "text-gray-800", action: () => {} },
        { text: "Voir et modifier les caractéristiques", bg: "bg-blue-600", action: () => {} },
    ]

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div className="relative inline-block" ref={menuRef}>
            <button
                onClick={(e) => {
                    e.stopPropagation()
                    setIsOpen(!isOpen)
                }}
                className="p-1 rounded-full hover:bg-gray-200 cursor-pointer *:cursor-pointer relative z-0"
            >
                <EllipsisVertical size={20} />
            </button>

            {isOpen && (
                <div
                    className="absolute right-0 z-[1000] mt-2 w-[300px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    style={{
                        position: 'fixed', // Change from absolute to fixed
                        // Calculate position based on button position
                        top: `${menuRef.current?.getBoundingClientRect().bottom}px`,
                        left: `${menuRef.current?.getBoundingClientRect().right - 300}px`,
                    }}
                >
                    <div className="absolute -z-10 size-3 top-0 right-2 bg-white -translate-y-1/2 rounded-sm rotate-45"></div>
                    <div className="p-1">
                        {menus.map((item, key) => (
                            <button
                                key={`menu-action-${key}`}
                                onClick={item.action}
                                className={`
                                    ${item.bg ? item.bg + " text-white" : "text-gray-700"}
                                    flex w-full items-center px-4 py-2 text-sm`}
                            >
                                {item.text}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

const columns = [
    {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
        width: '70px',
    },
    {
        name: 'Type',
        selector: row => row.type,
        sortable: true,
        width: '100px',
    },
    {
        name: 'Nom',
        selector: row => row.nom,
        sortable: true,
        width: '150px',
    },
    {
        name: 'Abonnement',
        selector: row => row.abonnement ? 'Actif' : 'Inactif',
        cell: row => (
            <span style={{ color: row.abonnement ? 'green' : 'red' }}>
                {row.abonnement ? 'Actif' : 'Inactif'}
            </span>
        ),
        width: '100px',
    },
    {
        name: 'Ville',
        selector: row => row.ville,
        sortable: true,
        width: '120px',
    },
    {
        name: 'Téléphone',
        selector: row => row.telephone,
        width: '130px',
    },
    {
        name: 'Date Création',
        selector: row => new Date(row.date_creation).toLocaleDateString('fr-FR'),
        sortable: true,
        width: '120px',
    },
    {
        name: 'Disponibilité',
        cell: row => (
            <span style={{ color: row.disponibilite ? 'green' : 'red' }}>
                {row.disponibilite ? 'Disponible' : 'Indisponible'}
            </span>
        ),
        width: '120px',
    },
    {
        name: 'Prochain rdv',
        selector: row => row.prochain_rdv ? new Date(row.prochain_rdv).toLocaleString('fr-FR') : 'Aucun',
        width: '180px',
    },
    {
        name: 'Commentaire',
        selector: row => row.commentaire || '-',
        wrap: true,
        width: '200px',
    },
    {
        name: 'Action',
        cell: row => <ActionMenu row={row} />,
        ignoreRowClick: true,
        width: '80px', // Réduit un peu la largeur
    },
]

const handleEdit = (id) => {
    alert(`Modifier l'entrée ${id}`)
}

const handleDelete = (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette entrée ?')) {
        alert(`Supprimer l'entrée ${id}`)
    }
}

const data = [
    {
        "id": 1,
        "type": "Professionnel",
        "nom": "Dupont Jean",
        "abonnement": true,
        "ville": "Paris",
        "telephone": "0123456789",
        "date_creation": "2023-01-15T00:00:00",
        "disponibilite": true,
        "prochain_rdv": "2023-06-20T14:30:00",
        "commentaire": "Client fidèle depuis 2 ans"
    },
    {
        "id": 2,
        "type": "Professionnel",
        "nom": "SARL Technologie",
        "abonnement": false,
        "ville": "Lyon",
        "telephone": "0456789012",
        "date_creation": "2023-03-10T00:00:00",
        "disponibilite": false,
        "prochain_rdv": null,
        "commentaire": "Nouveau fournisseur à tester"
    },
]

const FicheData = () => {
    const [loading, setLoading] = useState(false)
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setTableData(data)
            setLoading(false)
        }, 1000)
    }, [])

    return (
        <div className="h-full overflow-auto relative"> {/* Ajout de relative ici */}
            <DataTable
                columns={columns}
                data={tableData}
                progressPending={loading}
                highlightOnHover
                responsive
                striped
                noDataComponent="Aucune donnée disponible"
                fixedHeader
                fixedHeaderScrollHeight="calc(100% - 60px)"
                customStyles={{
                    table: {
                        style: {
                            minWidth: 'max-content',
                            width: '100%',
                            position: 'relative', // Important
                            zIndex: 0, // S'assurer que la table est en dessous
                        },
                    },
                    tableWrapper: {
                        style: {
                            height: '100%',
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            position: 'relative',
                        },
                    },
                    cells: {
                        style: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                    },
                }}
            />
        </div>
    )
}

export default FicheData