import { BadgeEuro, Crown, HandHelping, Heart } from "lucide-react"

export const Trial = () => {
    return (
        <div className="relative h-10 w-10 flex items-center justify-center rotate-[25deg]">
            {[1, 2, 3].map((circle) => (
                <div
                    key={`circle-key-${circle}`}
                    className={`absolute rounded-full border-2 border-gray-600 ${
                        circle === 1 ? "h-10 w-10" : 
                        circle === 2 ? "h-8 w-8 border-dotted border-red-500" : 
                        "h-6 w-6"
                    }`}
                ></div>
            ))}

            <div className="px-[.5em] py-[.25em] text-[.5em] text-white font-semibold bg-red-500 whitespace-nowrap rounded z-50">FREE TRIAL</div>
        </div>
    )
}

export const Abonnement = ({ size }) => {
    return (
        <div className="p-2 flex items-center justify-center text-white bg-yellow-500 rounded-full">
            <Crown size={size} />
        </div>
    )
}

export const PackCM = ({ size }) => {
    return (
        <div className="relative px-2 py-1 flex items-center justify-center bg-[#bd193b] rounded">
            <Heart fill="white" stroke="white" size={size} className="z-50" />
            <span className="text-white z-50">1</span>

            <div className="absolute w-5 h-5 top-1/2 bg-[#bd193b] rotate-45 rounded-sm"></div>
        </div>
    )
}

export const Revenus = ({ size }) => {
    return (
        <div className="flex flex-col items-center -space-y-1 text-green-600">
            <BadgeEuro size={size * .5} />
            <HandHelping size={size} />
        </div>
    )
}