import ctimg from "../../assets/fonctionnalites/clientelle.png";
const FonctionnaliteClientelle = () => {
  return <div className="fonctionnalites-content">
    <div className="fonctionnalite-wrapper2">
      <img src={ctimg} alt="" className="imgzone"/>
      <div className="textzone">
          <h2>Chouchoutez votre clientèle.</h2>
        <h4>Fiche client complète et personnalisable</h4>
          <p>Historique des rendez-vous, préférences, notes, coordonnées ou bien blocage de certains clients en cas de problèmes</p>
        <h4>codes promo</h4>
        <p>Générer vos codes promo directement depuis votre espace professionnel</p>
        <h4>messagerie</h4>
          <p>Échangez avec vos client(e)s pour faciliter la communication</p>
        <h4>Campagnes marketing</h4>
          <p>Fidéliser votre clientèle à l’aide d’envoi d’offres promotionnelles par sms et/ou mail</p>
      </div>
    </div>
  </div>
}

export default FonctionnaliteClientelle;