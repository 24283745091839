import { Star } from "lucide-react"

const RenderStars = ({ star_count }) => (
    <div className="flex items-center gap-1">
        {[...Array(5)].map((_, i) => (
            <div key={i} className="">
                <Star
                    key={i}
                    size={14}
                    fill={star_count >= i + 1 ? '#bd193b' : 'None'}
                    stroke={star_count >= i + 1 ? '#bd193b' : '#000'}
                />
            </div>
        ))}
    </div>
)

export default RenderStars