import stimg from "../../assets/fonctionnalites/statistiques.png";
const FonctionnaliteStatistiques = () => {
  return <div className="fonctionnalites-content">
    <div className="fonctionnalite-wrapper1">
        <div className="textzone">
            <h2>Gestion des statistiques.</h2>
          <h4>Suivi des performances</h4>
            <p>Nombre de réservations, nombre de clients, chiffre d'affaires..</p>
          <h4>rapports clients</h4>
          <p>Identification des clients réguliers, des nouveaux clients...</p>
          <h4>Best-Sellers & Offres à Optimiser</h4>
            <p>Analysez vos prestations les plus demandées et optimisez les moins demandés</p>
          <h4>Suivi des avis clients</h4>
            <p>Analyse des avis clients pour améliorer les services</p>
        </div>

        <img src={stimg} alt="" className="imgzone"/>
    </div>
  </div>
}

export default FonctionnaliteStatistiques;