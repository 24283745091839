import React from "react";
import ReactSwitch from "react-switch";
import { showErrorMessage, showSuccessMessage } from "../../../utils/messageHelper";
import beauticianApi from "../../../api/beautician.api";


const BlockedForDayStatut = ({is_blocked_for_day, beautician_id, action}) => {

    const handleIsBlockedForDayChange = async (isBlocked, beautician_id) => {
		try {
			const response = await beauticianApi.updateIsBlockedForDay(localStorage.getItem('token'), beautician_id, !isBlocked);
			if (response.success) {
				showSuccessMessage("Le statut de la journée a été modifié avec succès.");
                if(action) action();
				// setReloadTrigger(prev => !prev);
			} else {
				showErrorMessage("Une erreur s'est produite lors de la modification du statut de la journée.");
			}
		} catch (error) {
			console.error("Erreur lors de la modification du statut de la journée :", error);
			showErrorMessage("Une erreur s'est produite lors de la modification du statut de la journée.");
		}
	}

    return <div className={`text-sm my-2 flex items-center gap-2 p-1 border rounded-xl ${is_blocked_for_day ? "border-green-400" : "border-red-400"}`}>
    <ReactSwitch
        checked={is_blocked_for_day}
        onChange={() => handleIsBlockedForDayChange(is_blocked_for_day, beautician_id)}
        offColor="#374151" // Couleur désactivée
        onColor="#4ade80" // Couleur activée
        uncheckedIcon={false}
        checkedIcon={false}
        width={40} // Largeur du switch
        height={20} // Hauteur du switch
        handleDiameter={15}
    /> Bloqué pour la journée
</div>;

}

export default BlockedForDayStatut;