import React, { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Navigate,
  Outlet,
  Route,
  useLocation
} from 'react-router-dom';
import { BookingProvider } from './context/BookingContext';
import { BeauticianPortalProvider } from './context/BeauticianPortalContext';
import SEOWrapper from './components/SEOWrapper';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Toaster } from 'react-hot-toast';

// Layouts et composants de page
import Layout from './components/layout/Layout';
import Home from './pages/home/Home';
import Concept from './pages/concept/Concept';
import Fonctionnalités from './pages/fonctionnalities/Fonctionnalites';
import Search from './pages/search/Search';
import Contact from './pages/contact/Contact';
import Dashboard from './pages/dashboard/Dashboard';
import Users from './pages/dashboard/user/Users';
import NotFound from './pages/notFound/NotFound';
import Loader from './components/loader/Loader';
import Abonnements from "./pages/tarifs/Abonnements";
import BeauticianProfile from './pages/beautician/BeauticianProfile';
import AjoutEtablissement from './pages/ajoutEtablissement/AjoutEtablissement';
import BookingPage from './pages/booking/BookingPage';
import BeauticianPortal from './pages/portals/Beauticians/BeauticianPortal';
import ClientPortal from './pages/portals/client/ClientPortal';
import ConditionsGenerales from './pages/conditionsGenerales/ConditionsGenerales';
import { MessageProvider } from './contexts/MessageContext';
import Messaging from './pages/message/Messaging';
import PlanibeautyPro from './pages/planibeauty-pro/PlanibeautyPro';

// Styles
import './App.css';
import Swal from 'sweetalert2';
import { AppContextProvider } from './contexts/AppContext';

import { API_URL } from './utils/constants';
import SearchContextProvider from './context/SearchContext';
import { HeaderContextProvider } from './contexts/HeaderContext';
import EmailConfirmation from './pages/EmailConfirmation';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
//import { TestMessage } from './components/test/test';
import OffreAbonnement from './pages/dashboard/offre-abonnement/OffreAbonnement';
import Statistics from './pages/dashboard/stats/Statistics';
import HomeSetting from './components/dashboard/HomeSetting';
import AllTesteur from './pages/dashboard/testeur/AllTesteur';
import SuiviDesProfessionnels from './pages/dashboard/gestion-caisse/SuiviDesProfessionnels';
import PacksCommunication from './pages/packs-communication/PacksCommunication';
import NeedToAcceptTerms from './pages/NeedToAcceptTerms';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AttribAbonnement from './pages/dashboard/attrib-abonnement/AttribAbonnement';
import RecruteurPortal from './pages/portals/recruteur/RecruteurPortal';
import { TeleConseillerPortalProvider } from './context/TeleConseillerPortalContext';
import TeleConseillerPortal from './pages/portals/tele-conseiller/TeleConseillerPortal';
import LandingCom from './pages/landing/LandingCom';
import RegisterLinks from './pages/dashboard/register-links/RegisterLinks';
import RegisterWithLink from './pages/register/RegisterWithLink';
import SuiviOperateurs from './pages/dashboard/suivi-operateurs/SuiviOperateurs';
import SuiviTeleprospecteurs from './pages/dashboard/suivi-teleprospecteurs/SuiviTeleprospecteurs';
import RegisterAffiliation from './pages/register/RegisterAffiliation';
import OpenToWork from './pages/open-to-work/OpenToWork';
import SubscriptionWithLink from './pages/subscription/SubscriptionWithLink';
import Leads from './pages/dashboard/leads/Leads';
// import Test from './pages/Test';

// Protection des routes avec gestion des rôles
const AuthWrapper = () => {
  const token = localStorage.getItem('token');

  const location = useLocation();

  if (!token) {
    return <Navigate to="/?log=1" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

// Protection des routes admin/opérateur
const AdminWrapper = () => {
  const token = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');

  if (!token || !['admin', 'operator'].includes(userType)) {
    return <Navigate to="/" replace />;
  }

  return <div style={{userSelect: 'text'}}>
      <Outlet />
    </div>
    ;
};

// Protection des routes beautician
const BeauticianWrapper = () => {
  const token = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');

  if (!token || userType !== 'beautician') {
    return <Navigate to="/" replace />;
  }

  return (
    <BeauticianPortalProvider>
      <Outlet />
    </BeauticianPortalProvider>
  );
};

// Protection des routes tele conseiller
const TeleConseillerWrapper = () => {
  const token = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');

  const allowedRoles = ["tele_conseiller", "beautician"];

  if (!token || !allowedRoles.includes(userType)) {
    return <Navigate to="/" replace />;
  }

  return (
    <TeleConseillerPortalProvider>
      <Outlet />
    </TeleConseillerPortalProvider>
  );
};

//search context
const SearchWrapper = () => {
  return <SearchContextProvider>
    <Outlet />
  </SearchContextProvider>
}

//routes clients 
const ClientWrapper = () => {
  const token = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');

  if (!token || userType !== 'client') {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

// routes recruteur
const RecruteurWrapper = () => {
  const token = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');

  if (!token || userType !== 'recruteur') {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

// Layout principal avec BookingProvider
const LayoutWrapper = () => (
  <BookingProvider>
    <HeaderContextProvider>
      <Layout>
        <Outlet />
      </Layout>
    </HeaderContextProvider>
  </BookingProvider>
);

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const minLoadingTime = 1000;
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, minLoadingTime);

    return () => clearTimeout(loadingTimeout);
  }, []);

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const response = await fetch(`${API_URL}/users/verify-token-validity`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Token invalide ou expiré');
        }
      } catch (error) {
        localStorage.clear();
        await Swal.fire('Informations', 'Votre session a expiré. Veuillez vous reconnecter.', 'warning');
        window.location.reload();
      }
    };

    checkToken();
  }, []);

  return (
    <AppContextProvider>
      <DndProvider backend={HTML5Backend}>
        <MessageProvider>
          <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
            <SEOWrapper>
              <Loader isLoading={isLoading} />
              <Routes>
                {/* Routes publiques avec Layout */}
                <Route element={<LayoutWrapper />}>
                  <Route index element={<Home />} />
                  <Route path="concept" element={<Concept />} />
                  <Route path="fonctionnalities" element={<Fonctionnalités />} />

                  {/*search wrapper */}
                  <Route element={<SearchWrapper />}>
                    <Route path="search" element={<Search />} />
                  </Route>

                  {/* <Route path='test' element={<Test />} /> */}
                  <Route path="messaging" element={<Messaging />} />
                  {/*<Route path="/test-message" element={<TestMessage />} />*/}
                  <Route path="contact" element={<Contact />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="abonnements" element={<Abonnements />} />
                  <Route path="beauticians/:slug" element={<BeauticianProfile />} />
                  <Route path="beauticians/:slug/booking" element={<BookingPage />} />
                  <Route path="ajouter-etablissement" element={<AjoutEtablissement />} />
                  <Route path="inscription/:slug" element={<RegisterWithLink />} />
                  <Route path="ConditionsGenerales" element={<ConditionsGenerales />} />
                  <Route path="planibeauty-pro" element={<PlanibeautyPro />} />
                  <Route path="packs-communication" element={<PacksCommunication />} />
                  <Route path="open-to-work" element={<OpenToWork />} />

                  <Route element={<AuthWrapper />}>
                    {/** route qui necessite une authentification */}
                    <Route path="accept-terms" element={<NeedToAcceptTerms />} />

                    {/* Routes clients */}
                    <Route element={<ClientWrapper />}>
                      <Route path='client-dashboard/*' element={<ClientPortal />} />
                    </Route>

                    {/** routes recruteurs */}
                    <Route element={<RecruteurWrapper />}>
                      <Route path='recruteur-dashboard/*' element={<RecruteurPortal />} />
                    </Route>
                  </Route>

                  <Route path="404" element={<NotFound />} />
                </Route>

                <Route path="confirm-user-registration" element={<EmailConfirmation />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password" element={<ResetPassword />} />

                {/* Route Landing Com */}
                <Route path="essaigratuit" element={<LandingCom />} />

                {/** Route inscription affiliation */}
                <Route path="affiliation-inscription/:slug" element={<RegisterAffiliation />} />
                {/** Route pour activation abonnement */}
                <Route path="subscription/:slug" element={<SubscriptionWithLink />} />

                {/* Routes Beautician */}
                <Route element={<AuthWrapper />}>
                  <Route element={<BeauticianWrapper />}>
                    <Route path="beautician-dashboard/*" element={<BeauticianPortal />} />
                  </Route>
                </Route>

                {/* Routes teleconseiller */}
                <Route element={<AuthWrapper />}>
                  <Route element={<TeleConseillerWrapper />}>
                    <Route path="tele-conseiller-dashboard/*" element={<TeleConseillerPortal />} />
                  </Route>
                </Route>

                {/* Routes authentifiées */}
                <Route element={<AuthWrapper />}>
                  {/* Routes Admin/Opérateur */}
                  <Route element={<AdminWrapper />}>
                    <Route path="dashboard" element={<Dashboard />}>
                      <Route index element={<Statistics />} />
                      {/* Autres routes dashboard admin */}
                      <Route path='offres-abonnements' element={<OffreAbonnement />} />
                      <Route path='utilisateurs' element={<Users />} />
                      <Route path='parametre-accueil' element={<HomeSetting />} />
                      <Route path='testeurs' element={<AllTesteur />} />
                      <Route path='attribuer-abonnement' element={<AttribAbonnement />} />
                      <Route path='liens-inscription' element={<RegisterLinks />} />
                      <Route path='suivi-des-operateurs' element={<SuiviOperateurs />} />
                      <Route path='suivi-des-professionnels' element={<SuiviDesProfessionnels />} />
                      <Route path='suivi-des-teleprospecteurs' element={<SuiviTeleprospecteurs />} />
                      <Route path='leads' element={<Leads  />} />
                    </Route>
                  </Route>
                </Route>

                {/* Redirection par défaut */}
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Routes>
              <Toaster position="top-right" />
            </SEOWrapper>
          </BrowserRouter>
        </MessageProvider>
      </DndProvider>
    </AppContextProvider>
  );
}

export default App;