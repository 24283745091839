import React, { useState, useContext, useRef, useEffect } from 'react';
import { Bell, LogOut, Menu, UserCircle, ChevronDown, MessageCircle, Copy, X, Clock } from 'lucide-react'; // Ajouté MessageCircle
import './BeauticianPortalHeader.css';
import { BASE_URL, REDIRECT_AFTER_LOGOUT } from '../../../utils/constants';
import Swal from 'sweetalert2';
import { AppContext } from '../../../contexts/AppContext';
import { NavLink, useNavigate } from 'react-router-dom';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';
import { useMessage } from '../../../contexts/MessageContext';
import toast from 'react-hot-toast';
import appointmentApi from '../../../api/appointement.api';


const NotificationBadge = ({ unreadNotificationCount }) => {

  const navigate = useNavigate();

  return <div className="notifications-menu">
    <button
      className="icon-button"
      onClick={() => navigate('/beautician-dashboard/notifications')}
    >
      <Bell size={20} />
      <span className="notification-indicator">{unreadNotificationCount}</span>
    </button>
  </div>;
}

const BeauticianPortalHeader = ({
  isSidebarOpen,
  onSidebarToggle,
}) => {
  const { connectedUser, setIsLoggedIn, unreadNotificationCount } = useContext(AppContext);
  const { isParentLoading, profileData, abonnementActive, isTesteur } = useContext(BeauticianPortalContext);
  
  const [pendingAppointment, setPendingAppointment] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const { unreadMessages } = useMessage();

  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Référence pour le dropdown

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const getPendingAppointment = async () => {
    if(profileData && profileData.beautician_id) {
      const appointment = await appointmentApi.getAppointmentByBeautician(profileData.beautician_id)
      setPendingAppointment(appointment.filter(app => app.status === "pending").length)
    }
  }

  useEffect(() => {
    getPendingAppointment()
  }, [profileData])

  // Effet pour gérer le clic à l'extérieur
  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Si le clic est en dehors du dropdown, fermer le dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    // Ajouter l'écouteur d'événement au document
    document.addEventListener('click', handleOutsideClick);

    // Nettoyer l'écouteur lors du démontage
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleLogout = () => {
    Swal.fire({
      title: "Voulez vous vraiment se deconnecter?",
      icon: "question",
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui"
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        setIsLoggedIn(false);
        window.location.href = REDIRECT_AFTER_LOGOUT;
      }
    })
  }

  const copierLien = async (lienAffiliation) => {

    try {
      await navigator.clipboard.writeText(lienAffiliation);
      toast.success("Lien copié !");
    } catch (error) {
      toast.error("Échec de la copie");
    }
    setShowDropdown(false);
  }

  return (
    <header className="app-header">
      <div className="header-wrapper">
        {/* Left section */}
        <div className="header-left">
          <button className="menu-button" onClick={onSidebarToggle}>
            {isSidebarOpen ? <X size={22} /> : 
            <Menu size={22} /> }
          </button>
        </div>

        {/* Right section */}
        <div className="header-right">

          {(!isParentLoading && profileData?.is_active && abonnementActive) && <>
            <NavLink
                to="/beautician-dashboard/agenda"
                className="relative text-gray-600 no-underline"
                onClick={() => {
                  setShowDropdown(false);
                  navigate("/beautician-dashboard/agenda");
                }}
              >
                <Clock size={20} />
                {pendingAppointment > 0 && (
                  <span className="absolute mt-2 left-[50%] -top-4 bg-[#bd193b] rounded-full text-white px-2 text-[.75em]">
                    {pendingAppointment > 99 ? '99+' : pendingAppointment}
                  </span>
                )}
              </NavLink>

            <NotificationBadge unreadNotificationCount={unreadNotificationCount} />

            <NavLink
                to="/beautician-dashboard/messaging" // Changé pour correspondre au routage global
                className="relative text-gray-600 no-underline"
                onClick={() => {
                  setShowDropdown(false);
                  navigate('/beautician-dashboard/messaging'); // Force la navigation vers la même route que le UserMenu
                }}
              >
                <MessageCircle size={20} style={{ marginRight: '8px' }} />
                {unreadMessages > 0 && (
                  <span className="absolute mt-2 left-[50%] -top-4 bg-[#bd193b] rounded-full text-white px-2 text-[.75em]">
                    {unreadMessages > 99 ? '99+' : unreadMessages}
                  </span>
                )}
              </NavLink>
          </>
          }

          <div className="user-menu relative" ref={dropdownRef}>
            <button
              className="user-profile-button"
              onClick={(e) => {
                e.preventDefault();
                toggleDropdown();
              }}
            >
              <UserCircle size={28} />
              <span className="user-name">{profileData?.first_name} {profileData?.last_name}</span>
              <ChevronDown size={16} />
              {/* {unreadMessages > 0 && <span className="message-indicator" />} S'assurer que cette ligne est présente */}
            </button>
            {showDropdown && (
              <div className="absolute mt-2 right-0 w-64 bg-gray-50 shadow-md rounded-lg p-2 z-50">
                <div className="text-sm text-gray-600 p-2">
                  <strong></strong> {profileData?.email}
                </div>
                {(!isParentLoading && profileData?.is_active && abonnementActive) && <>
                  {/* <NavLink
                    to="/messaging" // Changé pour correspondre au routage global
                    className="dropdown-link"
                    onClick={() => {
                      setShowDropdown(false);
                      navigate('/messaging'); // Force la navigation vers la même route que le UserMenu
                    }}
                  >
                    <MessageCircle size={20} style={{ marginRight: '8px' }} />
                    Messages
                    {unreadMessages > 0 && (
                      <span className="message-count">
                        {unreadMessages > 99 ? '99+' : unreadMessages}
                      </span>
                    )}
                  </NavLink> */}


                  <div className="p-2 hover:bg-gray-100">
                    <a
                      href={`/beauticians/${profileData?.slug}`}
                      className="text-blue-600 no-underline hover:text-blue-800 text-sm"
                    >
                      Accéder à ma page
                    </a>
                  </div>

                  {isTesteur && <div className="p-2 hover:bg-gray-100">
                    <div
                      onClick={() => copierLien(`${BASE_URL}/affiliation-inscription/${profileData?.slug}`)}
                      className="w-full cursor-pointer text-blue-600 hover:text-blue-800 text-sm flex justify-between items-center"
                    >
                      <span className='cursor-pointer'>Lien d'affiliation</span><Copy className='cursor-pointer' size={20} />
                    </div>
                  </div>}
                </>
                }
                <button className="px-3 gap-2 flex items-center text-gray-600" aria-label="Déconnexion" onClick={handleLogout}>
                    <LogOut size={20} /> Se déconnecter
                  </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default BeauticianPortalHeader;