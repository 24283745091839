import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import {
  LayoutDashboard,
  UserCircle,
  Scissors,
  Calendar,
  BarChart2,
  Settings,
  Clock,
  Euro,
  MessageCircle,
  BriefcaseBusiness,
  HeartHandshake,
} from 'lucide-react';
import './BeauticianPortalSidebar.css';

import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';
import { AppContext } from '../../../contexts/AppContext';
import { useMessage } from '../../../contexts/MessageContext'; // Ajout du context message


const BeauticianPortalSidebar = ({ onLinkClick }) => {

  const { profileData, isParentLoading, abonnementActive, isTesteur } = useContext(BeauticianPortalContext);
  const { setNotificationChangeTrigger } = useContext(AppContext);
  const { unreadMessages } = useMessage(); // Récupération des messages non lus

  const location = useLocation();

  // Liste des chemins spécifiques
  const pathsToTriggerAction = [
    "/beautician-dashboard/",
    "/beautician-dashboard/profile",
    "/beautician-dashboard/services",
    "/beautician-dashboard/agenda",
    "/beautician-dashboard/historiques",
    "/beautician-dashboard/stats",
    "/beautician-dashboard/settings",
    "/beautician-dashboard/notifications",
    "/beautician-dashboard/programme-affiliation",
    "/beautician-dashboard/open-to-work",
    "/beautician-dashboard/messaging",
  ];

  useEffect(() => {
    if (pathsToTriggerAction.includes(location.pathname)) {
      setNotificationChangeTrigger(prev => !prev);
    }
  }, [location.pathname]);

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (!isParentLoading && profileData) {

      if (profileData.is_active) {
        setMenuItems(([
          {
            title: "Tableau de bord",
            icon: <LayoutDashboard size={20} />,
            path: "/beautician-dashboard/",
            exact: true,
            end: true,
            disabled: false
          },
          {
            title: "Messages",
            icon: <MessageCircle size={20} />,
            path: "/beautician-dashboard/messaging",
            hasNotification: unreadMessages > 0,
            notificationCount: unreadMessages,
            disabled: !abonnementActive
          },
          {
            title: "Mon Profil",
            icon: <UserCircle size={20} />,
            path: "/beautician-dashboard/profile",
            disabled: false
          },
          {
            title: "Mes Services",
            icon: <Scissors size={20} />,
            path: "/beautician-dashboard/services",
            disabled: false
          },
          {
            title: "Agenda",
            icon: <Calendar size={20} />,
            path: "/beautician-dashboard/agenda",
            disabled: !abonnementActive
          },
          {
            title: "Historiques",
            icon: <Clock size={20} />,
            path: "/beautician-dashboard/historiques",
            disabled: !abonnementActive
          },
          {
            title: "Revenu mensuel",
            icon: <Euro size={20} />,
            path: "/beautician-dashboard/revenu-mensuel",
            disabled: !abonnementActive
          },
          {
            title: "Statistiques",
            icon: <BarChart2 size={20} />,
            path: "/beautician-dashboard/stats",
            disabled: !abonnementActive
          },
          {
            title: "Programme affilié",
            icon: <HeartHandshake size={20} />,
            path: "/beautician-dashboard/programme-affiliation",
            disabled: false,
          },
          {
            title: "Open to work",
            icon: <BriefcaseBusiness size={20} />,
            path: "/beautician-dashboard/open-to-work",
            disabled: true //!abonnementActive
          },
          {
            title: "Paramètres",
            icon: <Settings size={20} />,
            path: "/beautician-dashboard/settings",
            disabled: false
          },
        ]));
      }
    }
  }, [profileData, isParentLoading, abonnementActive, unreadMessages]);

  return (
    <div className="sidebar-inner">
      <div className="sidebar-branding">
        <Link to="/" className="sidebar-brand-text">Planibeauty</Link>
      </div>
  
      <nav className="sidebar-nav">
        {menuItems.map((item, index) => {
  
          if (item.disabled) {
            return (
              <div
                key={index}
                className="sidebar-link opacity-50 cursor-not-allowed flex items-center"
                title={item.title}
              >
                <span className="sidebar-link-icon cursor-not-allowed">
                  {item.icon}
                  {item.hasNotification && (
                    <span className="sidebar-message-count">
                      {item.notificationCount > 9 ? '9+' : item.notificationCount}
                    </span>
                  )}
                </span>
                <span className="sidebar-link-text cursor-not-allowed">{item.title}</span>
              </div>
            );
          }

          if(!isTesteur && item.path === '/beautician-dashboard/programme-affiliation') {
            return null;
          }
  
          return (
            <NavLink
              key={index}
              to={item.path}
              className={({ isActive }) =>
                `sidebar-link ${isActive ? 'sidebar-link-active' : ''}`
              }
              onClick={onLinkClick}
              end={item.exact}
              title={item.title}
            >
              <span className="sidebar-link-icon">
                {item.icon}
                {item.hasNotification && (
                  <span className="sidebar-message-count">
                    {item.notificationCount > 9 ? '9+' : item.notificationCount}
                  </span>
                )}
              </span>
              <span className="sidebar-link-text">{item.title}</span>
            </NavLink>
          );
        })}
      </nav>
    </div>
  );
  
};

export default BeauticianPortalSidebar;
