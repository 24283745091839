import React from 'react'
import { Outlet } from 'react-router-dom'

import Sidebar from '../../../components/portals/tele-conseiller/Sidebar'
import Navbar from '../../../components/portals/tele-conseiller/Navbar'
import TeleConseillerSidebarContextProvider from '../../../context/TeleConseillerContext'

const TeleConseillerPortalLayout = () => {
    return (
        <TeleConseillerSidebarContextProvider>
            <main className="w-full h-screen flex overflow-hidden">
                <Sidebar />
                
                <div className="flex-1 flex flex-col overflow-hidden">
                    <Navbar />
                    <div className="flex-1 overflow-auto">
                        <Outlet />
                    </div>
                </div>
            </main>
        </TeleConseillerSidebarContextProvider>
    )
}

export default TeleConseillerPortalLayout
