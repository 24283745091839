import beauticianApi from "../api/beautician.api";
import smsApi from "../api/sms.api";
import userApi from "../api/user.api";
import { formatFrenchPhoneNumberForBrevo } from "./functions";

const sendSmsCreateRdvNotification = async (beautician_id, message) => {
    try {
        // Récupérer les informations de l'esthéticienne
        const beautician = await beauticianApi.getBeauticianById(beautician_id);
        message = `Bonjour ${beautician.data.business_name || beautician.data.first_name + " " + beautician.data.last_name},\n${message}`;
        await smsApi.sendSmsNotification(
            formatFrenchPhoneNumberForBrevo(beautician.data.phone),
            message
        );
    } catch (error) {
        console.error(error);
    }
}

const sendSmsChangeRdvStatusNotification = async (phoneNumber, message) => {
    try {
        await smsApi.sendSmsNotification(
            formatFrenchPhoneNumberForBrevo(phoneNumber), 
            message
        );
    } catch (error) {
        console.error(error);
    }
}

const sendSmsAccountStatusNotification = async (user_id, status) => {

    // Récupérer les informations de l'utilisateur
    const user = await userApi.getUserById(localStorage.getItem('token'), user_id);

    try {
        let message = `Votre compte a été ${status === 'active' ? 'activé' : 'désactivé'} par l'équipe Planibeauty! Pour plus d'informations, verifiez votre boîte mail ou contacter le support.\nRDV & messages sur https://www.planibeauty.fr `;
        
        await smsApi.sendSmsNotification(
            formatFrenchPhoneNumberForBrevo(user.phone),
            message,
        );
    } catch (error) {
        console.log(error);
    }
}

export { sendSmsCreateRdvNotification, sendSmsChangeRdvStatusNotification, sendSmsAccountStatusNotification };