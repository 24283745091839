import "./UserData.css"
import styled from "styled-components";

import { useContext, useState, useEffect, useRef, useMemo } from "react"
import { FaComment, FaEllipsisVertical, FaEye, FaFire, FaLink, FaPencil, FaTrash } from "react-icons/fa6"
import { dashboardContext } from "../../pages/dashboard/Dashboard";
import { differenceInDays, format } from "date-fns";
import DataTable from "react-data-table-component";
import { FaCheck } from "react-icons/fa";
import Swal from "sweetalert2";
import beauticianApi from "../../api/beautician.api";
import withReactContent from 'sweetalert2-react-content';
import ReactSwitch from 'react-switch';
import { fr } from "date-fns/locale";
import { Pen, Plus } from "lucide-react";
import trendingApi from "../../api/trending.api";
import { motion } from "motion/react";
import 'react-calendar/dist/Calendar.css';

import RenderStars from "./RenderStars";
import TrendingPanel from "./Trending/TrendingPanel";
import AddToTrendingPopUp from "./Trending/AddToTrendingPopUp";
import { checkIfProCanBeAvailable } from "../../utils/functions";
import { showWarningMessage } from "../../utils/messageHelper";
import SendSubscriptionLinkModal from "./userData/SendSubscriptionLinkModal";
import { useSearchParams } from "react-router-dom";

const CustomDropdown = ({ editUser, deleteRow, showSiteInfo, showComment, acceptRegistration, showAddToTrendingPanel, is_active, showSendLink = false, showSendLinkAction = () => { } }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState("bottom"); // "top" ou "bottom"
    const dropdownRef = useRef(null); // Ref pour le dropdown
    const { activePage } = useContext(dashboardContext);

    const toggleDropdown = () => setIsOpen((prev) => !prev);

    const callAction = (action) => {
        setIsOpen(false);
        action();
    };

    // Fermer le dropdown en cliquant en dehors
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Ajouter le gestionnaire d'événements
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Nettoyer l'événement
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Ajuster la position du menu en fonction de l'espace disponible
    useEffect(() => {
        if (isOpen && dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            // Si le menu dépasse vers le bas, afficher vers le haut
            if (rect.bottom > (windowHeight - 250)) {
                setDropdownPosition("top");
            } else {
                setDropdownPosition("bottom");
            }
        }
    }, [isOpen]);

    return (
        <div className="text-center relative" ref={dropdownRef}>
            {/* Bouton de déclenchement */}
            <button
                className="p-2 rounded-full hover:bg-gray-200"
                onClick={toggleDropdown}
                aria-expanded={isOpen}
                aria-haspopup="true"
            >
                <FaEllipsisVertical />
            </button>

            {/* Menu déroulant */}
            {isOpen && (
                <div
                    className={`absolute w-96 p-2 bg-gray-100 shadow-lg border border-gray-300 rounded-md ${dropdownPosition === "top" ? "bottom-10" : "top-10"
                        } right-2`}
                    style={{ zIndex: "1000" }}
                >
                    {(['all', 'indépendant', 'établissement'].includes(activePage)) && <>
                        {!is_active && <button
                            onClick={() => callAction(acceptRegistration)}
                            className="bg-green-500 flex items-center text-white gap-2 px-4 py-2 hover:bg-green-600 w-full text-left"
                        >
                            <FaCheck /> Accepter l'inscription du professionnel
                        </button>}
                    </>
                    }
                    <button
                        onClick={() => callAction(editUser)}
                        className="flex items-center bg-yellow-400 gap-2 px-4 py-2 hover:bg-yellow-500 w-full text-left"
                    >
                        <FaPencil /> Modifier les informations personnelles
                    </button>
                    <button
                        onClick={() => callAction(deleteRow)}
                        className="flex items-center bg-red-500 text-white gap-2 px-4 py-2 hover:bg-red-600 w-full text-left"
                    >
                        <FaTrash /> Supprimer le profil
                    </button>
                    {(['all', 'indépendant', 'établissement'].includes(activePage)) && <>
                        <button
                            onClick={() => callAction(showSiteInfo)}
                            className="flex items-center bg-blue-500 text-white gap-2 px-4 py-2 hover:bg-blue-600 w-full text-left"
                        >
                            <FaEye /> Voir et modifier les caractéristiques
                        </button>
                        {showSendLink &&
                            <button
                                onClick={() => callAction(showSendLinkAction)}
                                className="flex items-center gap-2 px-4 py-2 bg-green-300 hover:bg-green-400 w-full text-left"
                            >
                                <FaLink /> Envoyer lien pour abonnement
                            </button>}
                        <button
                            onClick={() => callAction(showComment)}
                            className="flex items-center gap-2 px-4 py-2 bg-gray-200 hover:bg-gray-300 w-full text-left"
                        >
                            <FaComment /> Voir les avis
                        </button>
                        <button
                            onClick={() => callAction(showAddToTrendingPanel)}
                            className="flex items-center gap-2 px-4 py-2 bg-gray-200 hover:bg-gray-300 w-full text-left"
                        >
                            <FaFire /> Ajouter aux tendances
                        </button>
                    </>}

                </div>
            )}
        </div>
    );
};

const StyledCellWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const UserData = ({ datas }) => {
    const {
        setActiveID,
        showSiteInformation,
        showDeletionModal,
        showEditModal,
        setUserData,
        showUserSheetModal,
        activePage,
        setShowBeauticianCommentaire,
        setReloadTrigger,
        setDynamicUserData,
        trends
    } = useContext(dashboardContext);

    const searchParams = new URLSearchParams(window.location.search);

    // Récupérer les valeurs depuis l'URL ou utiliser des valeurs par défaut
    const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
    const [rowsPerPage, setRowsPerPage] = useState(Number(searchParams.get("rowsPerPage")) || 10);
    const initialSortColumn = searchParams.get("sortColumn") || "id";
    const [sortColumn, setSortColumn] = useState(initialSortColumn);
    const initialSortDirection = searchParams.get("sortDirection") || "asc";
    const [sortDirection, setSortDirection] = useState(initialSortDirection);

    const [trendsList, setTrendsList] = useState([])
    const [trendsTrigger, setTrendsTrigger] = useState(false)
    const [showAddTrending, setShowAddTrending] = useState(false)
    const [showAddToTrending, setShowAddToTrending] = useState(false)
    const [activeBeautician, setActiveBeautician] = useState({})

    const [selectedRows, setSelectedRows] = useState([]);
    const [showSendLinkModal, setShowSendLinkModal] = useState(false);
    const [activeRow, setActiveRow] = useState(null);

    const handleRowClick = (row) => {
        setActiveID(row['id']);
        setUserData(row);
        setDynamicUserData(row);
        setTimeout(() => {
            showUserSheetModal(true);
        }, 200);
    };

    const handleSelectAll = () => {
        const allIds = datas.map((row) => row.id);
        setSelectedRows(selectedRows.length === datas.length ? [] : allIds);
    };

    const handleRowSelect = (rowId) => {
        setSelectedRows((prevSelected) =>
            prevSelected.includes(rowId)
                ? prevSelected.filter((id) => id !== rowId)
                : [...prevSelected, rowId]
        );
    };

    const deleteRow = (row) => {
        setActiveID(row["id"])
        showDeletionModal(true)
    }

    const editUser = (row) => {
        setActiveID(row['id']);
        setUserData(row)
        showEditModal(true)
    }

    const showSiteInfo = (row) => {
        setUserData(row)
        setActiveID(row['id']);
        showSiteInformation(true);
    }

    const showComment = (row) => {
        setActiveID(row['id']);
        setShowBeauticianCommentaire(true);
    }

    const showAddToTrendingPanel = (row) => {
        setActiveBeautician(row)
        setShowAddToTrending(true)
    }

    const showSendLinkAction = (row) => {
        setActiveRow(row);
        setShowSendLinkModal(true);
    }

    const MySwal = withReactContent(Swal);

    const handleAvailabilityChange = async (row) => {
        // Mettre à jour la disponibilité via une API ou une méthode
        const check = await checkIfProCanBeAvailable(row.id);

        if (!row.is_available && !check) {
            showWarningMessage('Vous devez ajouter au moins un service/prestation pour rendre le profil disponible!');
            return;
        }

        MySwal.fire({
            title: 'Confirmation',
            text: `Voulez-vous vraiment modifier la disponibilité de ${row.first_name} ${row.last_name} ?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Mettre à jour la disponibilité via une API ou une méthode
                await beauticianApi.updateAvailability(localStorage.getItem('token'), row.id, { is_available: !row.is_available });
                setReloadTrigger(prev => !prev);
            }
        });
    };

    const acceptRegistration = (row) => {
        Swal.fire({
            title: "Voulez-vous vraiment accepter l'inscription?",
            icon: "question",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Non",
            confirmButtonText: "Oui, accepter"
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Afficher le loader
                Swal.fire({
                    title: 'Traitement en cours...',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                try {
                    await beauticianApi.acceptRegistration(
                        localStorage.getItem('token'),
                        row['id'],
                        row['email'],
                        row['first_name'] + ' ' + row['last_name']
                    );

                    // Indiquer le succès
                    Swal.fire({
                        title: "Succès!",
                        text: "L'inscription a été acceptée.",
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        setReloadTrigger(prev => !prev);
                    });
                } catch (error) {
                    // Gestion des erreurs
                    Swal.fire({
                        title: "Erreur",
                        text: "Une erreur est survenue lors de l'acceptation de l'inscription.",
                        icon: "error",
                    });
                }
            }
        });
    };

    const getDiffDay = (start_date, end_date) => {
        if (!start_date || !end_date) return null;
        const date1 = new Date(start_date);
        const date2 = new Date(end_date);
        return differenceInDays(date2, date1);
    }

    const formatAbonnement = (row) => {

        if (row.is_testeur) return 'Testeur';

        if (row.offre_name === null || row.offre_name === undefined || row.offre_name === 'Aucun') return <p>Aucun abonnement <br /><b>{row.promo_code_sent ? '\nCode promo envoyé' : ''}</b></p>;

        return <div>
            <b>Formule :</b> {row.offre_name} {row.offre_price} €<br />
            {!row.abonnement_is_attribued && (<><b>Statut :</b> <span className={row.abonnement_status === 'active' ? (getDiffDay(new Date(), row.abonnement_end_date) > 0 ? 'text-green-500' : 'text-red-400') : (row.abonnement_status === 'trialing' ? 'text-yellow-500' : 'text-red-400')}>{row.abonnement_status === 'trialing' ? 'Essai' : (row.abonnement_status === 'canceled' ? 'Annulé' : (row.abonnement_status === 'active' ? (getDiffDay(new Date(), row.abonnement_end_date) > 0 ? 'active' : 'expiré') : row.abonnement_status))}</span></>)}
            {row.abonnement_is_attribued && (<><b>Statut : </b> <span className={getDiffDay(new Date(), row.abonnement_end_date) > 0 ? 'text-green-500' : 'text-red-400'}>{getDiffDay(new Date(), row.abonnement_end_date) > 0 ? 'Essai' : 'Expiré'}</span></>)}
            {(row.abonnement_status === 'trialing' && getDiffDay(new Date(), row.abonnement_trial_end) !== null) && <div>{getDiffDay(new Date(), row.abonnement_trial_end)} jours restants</div>}
            {(row.abonnement_is_attribued && getDiffDay(new Date(), row.abonnement_end_date) !== null && getDiffDay(new Date(), row.abonnement_end_date) > 0) && <div>{getDiffDay(new Date(), row.abonnement_end_date)} jours restants</div>}
            {/** prochain prélévement pour les abonnements non attribués */}
            {!row.abonnement_is_attribued && (row.abonnement_status === 'active' && getDiffDay(new Date(), row.abonnement_end_date) > 0) && <div><b>Prélèvement :</b> {format(new Date(row.abonnement_status === 'trialing' ? row.abonnement_trial_end : row.abonnement_end_date), "dd/MM/yyyy", { locale: fr })}</div>}
            {!row.abonnement_is_attribued && (row.abonnement_status === 'trialing' && getDiffDay(new Date(), row.abonnement_trial_end) > 0) && <div><b>Prélèvement :</b> {format(new Date(row.abonnement_status === 'trialing' ? row.abonnement_trial_end : row.abonnement_end_date), "dd/MM/yyyy", { locale: fr })}</div>}
            {!row.abonnement_is_attribued && row.abonnement_promo_code_id === 'active' && <div>Par code promo</div>}
            {row.abonnement_is_attribued && <div><strong>Attribué</strong></div>}
        </div>
    }

    const paginationOptions = {
        rowsPerPageText: 'Lignes par page',
        rangeSeparatorText: 'sur',
        noRowsPerPage: false,
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Tout',
    };


    // Styles personnalisés pour la table
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: "50vh", // Définissez ici votre hauteur minimale souhaitée
            },
        },
        rows: {
            style: {
                minHeight: "50px",
                cursor: "pointer"
            },
        },
        headCells: {
            style: {
                fontWeight: "bold",
                backgroundColor: "#f7f7f7",
                fontSize: "14px"
            },
        },
        cells: {
            style: {
                padding: "5px",
                fontSize: "14px"
            },
        },
    };

    // Colonnes de la table
    const columns = ['all', 'indépendant', 'établissement'].includes(activePage) ? [
        {
            id: "id",
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
            width: "70px",
        },
        {
            id: "nom",
            name: "Nom et prénom",
            selector: (row) => `${row.last_name + " " + row.first_name}`,
            sortable: true,
        },
        {
            id: "nom_commercial",
            name: "Nom commercial",
            selector: (row) => `${row.business_name ? row.business_name : row.last_name + " " + row.first_name}`,
            sortable: true,
        },
        {
            id: "abonnement",
            name: "Abonnement",
            selector: (row) => row.offre_name,
            cell: (row) => formatAbonnement(row),
            sortable: true,
            minWidth: "200px",
        },
        {
            id: "ville",
            name: "Ville",
            selector: (row) => row.city || "-",
            sortable: true
        },
        {
            id: "inscrit_par",
            name: "Inscrit par",
            selector: (row) => (row.creator_name),
            cell: (row) => (row.creator_name),
            sortable: true,
        },
        {
            id: "date_creation",
            name: "Date création",
            selector: (row) => new Date(row.created_at).getTime(),
            cell: (row) => format(new Date(row.created_at), "d MMMM yyyy à HH:mm", { locale: fr }),
            sortable: true,
            // sortFunction: (rowA, rowB) => {
            //     const dateA = new Date(rowA.created_at);
            //     const dateB = new Date(rowB.created_at);
            //     return dateA - dateB; // Tri croissant
            // },
        },
        {
            id: "disponibilite",
            name: "Disponibilité",
            selector: (row) => row.is_available,
            sortable: true,
            cell: (row) => (
                <div className="flex justify-center items-center w-full">
                    <ReactSwitch
                        checked={(row.is_available !== 'undefined' && row.is_available !== undefined) ? row.is_available : false}
                        onChange={() => handleAvailabilityChange(row)}
                        offColor="#f87171" // Couleur désactivée
                        onColor="#4ade80" // Couleur activée
                        uncheckedIcon={false}
                        checkedIcon={false}
                        width={40} // Largeur du switch
                        height={20} // Hauteur du switch
                        handleDiameter={15}
                    />
                </div>
            )
        },
        {
            id: "status_compte",
            name: "Status du compte",
            selector: (row) => row.is_account_active,
            sortable: true,
            cell: (row) => (
                <div className="flex justify-center items-center w-full">
                    {row.is_account_active ?
                        <span className="bg-green-500 px-2 py-1 text-sm text-white rounded">Activé</span> :
                        <span className="bg-yellow-500 px-2 py-1 text-sm rounded">Desactivé</span>}
                </div>
            ),
        },
        {
            id: "cb",
            name: "CB",
            selector: (row) => (row.customer_id ? true : false),
            width: "70px",
            cell: (row) => (
                <div className="flex justify-center items-center w-full">
                    {row.customer_id ?
                        <span className="bg-green-500 px-2 py-1 text-sm text-white rounded">Oui</span> :
                        <span className="bg-red-500 text-white px-2 py-1 text-sm rounded">Non</span>}
                </div>
            ),
            sortable: true
        },
        {
            id: 'action',
            name: "Actions",
            cell: (row) => (
                <div className=" w-full flex justify-center items-center">
                    <StyledCellWrapper>
                        <CustomDropdown
                            editUser={() => editUser(row)}
                            deleteRow={() => deleteRow(row)}
                            showComment={() => showComment(row)}
                            addToTrends={() => { }} //addToTrends(row)
                            showSiteInfo={() => showSiteInfo(row)}
                            showAddToTrendingPanel={() => showAddToTrendingPanel(row)}
                            is_active={row.is_active}
                            acceptRegistration={() => acceptRegistration(row)}
                            showSendLink={((row.offre_name === null || row.offre_name === undefined || row.offre_name === 'Aucun' || !row.is_testeur) && !row.customer_id) ? true : false}
                            showSendLinkAction={() => showSendLinkAction(row)}
                        />
                    </StyledCellWrapper>
                </div>
            ),
            ignoreRowClick: true, // Empêche les clics sur cette cellule d'interagir avec les lignes
            allowoverflow: "true", // Contrôle l'affichage dans la cellule
            width: "90px",
        }
    ] : [
        {
            id: "check",
            name: (
                <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedRows.length === datas.length}
                />
            ),
            cell: (row) => (
                <input
                    type="checkbox"
                    className="ms-2"
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleRowSelect(row.id)}
                />
            ),
            width: "80px",
            ignoreRowClick: true,
        },
        {
            id: "id",
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
            width: "80px",
        },
        {
            id: "nom",
            name: "Nom et prénom",
            selector: (row) => `${row.last_name} ${row.first_name}`,
            sortable: true,
        },
        {
            id: "telephone",
            name: "Téléphone",
            selector: (row) => row.phone,
            sortable: true,
        },
        {
            id: "date_creation",
            name: "Date création",
            selector: (row) => new Date(row.created_at).getTime(),
            cell: (row) => format(new Date(row.created_at), "d MMMM yyyy à HH:mm", { locale: fr }),
            sortable: true,
            // sortFunction: (rowA, rowB) => {
            //     const dateA = new Date(rowA.created_at);
            //     const dateB = new Date(rowB.created_at);
            //     return dateA - dateB; // Tri croissant
            // },
        },
        {
            id: "status_compte",
            name: "Status du compte",
            selector: (row) => row.is_account_active,
            sortable: true,
            cell: (row) => (
                <div className="flex justify-center items-center w-full">
                    {row.is_account_active ?
                        <span className="bg-green-500 px-2 py-1 text-sm text-white rounded">Activé</span> :
                        <span className="bg-yellow-500 px-2 py-1 text-sm rounded">Desactivé</span>}
                </div>
            ),
        },
        {
            id: "action",
            name: "Actions",
            cell: (row) => (
                <div className=" w-full flex justify-center items-center">
                    <StyledCellWrapper>
                        <CustomDropdown
                            editUser={() => editUser(row)}
                            deleteRow={() => deleteRow(row)}
                            showSiteInfo={() => showSiteInfo(row)}
                            showComment={() => showComment(row)}
                            showAddToTrendingPanel={() => showAddToTrendingPanel(row)}
                        />
                    </StyledCellWrapper>
                </div>
            ),
            ignoreRowClick: true, // Empêche les clics sur cette cellule d'interagir avec les lignes
            allowoverflow: "true", // Contrôle l'affichage dans la cellule
            width: "100px",
        }
    ];

    // Mettre à jour les paramètres d'URL
    const updateSearchParams = (newParams) => {
        const params = new URLSearchParams(window.location.search);

        // Mettre à jour ou ajouter les nouveaux paramètres
        Object.keys(newParams).forEach((key) => {
            if (newParams[key] === null) {
                params.delete(key); // Supprime si la valeur est `null`
            } else {
                params.set(key, newParams[key]);
            }
        });

        // Mettre à jour l'URL sans recharger la page
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.replaceState(null, "", newUrl);
    };

    // Gestion du changement de page
    const handlePageChange = (newPage) => {
        setPage(newPage);
        updateSearchParams({ page: newPage });
    };

    // Gestion du changement du nombre de lignes par page
    const handlePerRowsChange = (newRowsPerPage, newPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
        updateSearchParams({ rowsPerPage: newRowsPerPage, page: newPage });
    };

    // Gestion du tri
    const handleSort = (column, sortDirection) => {
        const columnId = column.id; // Utilisation de l'ID de la colonne
    
        setSortColumn(columnId);  // Mettre à jour le state avec l'ID
        setSortDirection(sortDirection);
    
        updateSearchParams({
            sortColumn: columnId,  // Utiliser l'ID dans les query params
            sortDirection
        });
    };
    

    const sortedData = useMemo(() => {
        return [...datas].sort((a, b) => {
            const col = columns.find(col => col.id === sortColumn); // Comparer avec l'ID
            if (!col || !col.selector) return 0;

            if (col.id === "date_creation") {
                const dateA = new Date(a.created_at);
                const dateB = new Date(b.created_at);
                return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
            }

            if(col.selector) {
                const valA = col.selector(a);
                const valB = col.selector(b);
    
                if (valA < valB) return sortDirection === "asc" ? -1 : 1;
                if (valA > valB) return sortDirection === "asc" ? 1 : -1;
            }
            return 0;
        });
    }, [datas, sortColumn, sortDirection]);



    useEffect(() => {
        const getTrendsDetails = async () => {
            try {
                const result = await trendingApi.getActiveTrending()

                setTrendsList(result.data)
            }
            catch (error) {
                Swal.fire("Erreur", error.message, "error")
            }
        }

        getTrendsDetails()
    }, [trends, trendsTrigger])


    return (
        <div>
            {datas.length > 0 ?
                <div className="w-full">
                    <DataTable
                        columns={columns}
                        data={sortedData}
                        onRowClicked={handleRowClick}
                        pagination
                        highlightOnHover
                        responsive
                        customStyles={customStyles}
                        paginationComponentOptions={paginationOptions}
                        paginationPerPage={rowsPerPage}
                        paginationDefaultPage={page}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        sortServer={false} // Ici, le tri est géré en front
                        onSort={handleSort}
                        defaultSortFieldId={sortColumn}  // Utilise l'ID pour le tri
                        defaultSortAsc={sortDirection === "asc"}
                    />
                    {(["all", "indépendant", "établissement"].includes(activePage)) && <>
                        <h1 className="text-xl my-2 pb-2 border-b-4">Professionnelles aux tendances</h1>
                        {trendsList.length > 0 ?
                            <section
                                className="my-4 flex items-center w-full justify-center flex-wrap gap-4"
                            >
                                {trendsList.map((tr, key) => (
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: .5 }}
                                        key={key} className="w-1/5 p-3 border rounded shadow cursor-pointer"
                                    >
                                        <h1 className="text-lg cursor-pointer">{
                                            tr.business_name.length > 15 ? tr.business_name.slice(0, 15) + "..." : tr.business_name
                                        }</h1>
                                        <div className="">
                                            <RenderStars star_count={tr.average_rating} />
                                        </div>
                                    </motion.div>
                                ))}

                                <button
                                    onClick={() => setShowAddTrending(true)}
                                    className="flex items-center gap-2 text-gray-500 font-semibold hover:underline"
                                >
                                    <Pen size={16} />
                                    Modifier
                                </button>
                            </section> :
                            <p className="pt-4 gap-2 flex items-center justify-center text-lg text-gray-400">
                                Aucune professionnelle à afficher.
                                <button
                                    onClick={() => setShowAddTrending(true)}
                                    className="flex items-center gap-1 text-gray-500 font-semibold hover:underline"><Plus size={16}
                                    />
                                    Ajouter
                                </button>
                            </p>
                        }
                    </>}
                </div> :
                <div className="p-5 text-center">
                    <p className="text-gray-700 text-xl">Aucun enregitrements à afficher...</p>
                </div>
            }

            <TrendingPanel
                show={showAddTrending}
                setShow={setShowAddTrending}
                trigger={setTrendsTrigger}
            />

            <AddToTrendingPopUp
                show={showAddToTrending}
                setShow={setShowAddToTrending}
                selectedBeautician={activeBeautician}
                trendsTrigger={setTrendsTrigger}
            />

            {showSendLinkModal && activeRow && <SendSubscriptionLinkModal
                showModal={showSendLinkModal}
                setShowModal={setShowSendLinkModal}
                row={activeRow}
                setActiveRow={setActiveRow}
                setReloadTrigger={setReloadTrigger}
            />}
        </div>
    );
}

export default UserData