import React, { useRef, useEffect } from 'react';

const MyTrustPilot = () => {

    // Create a reference to the <div> element which will represent the TrustBox
    const ref = useRef(null);

    useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);

    return (
        <div
            ref={ref} // We need a reference to this element to load the TrustBox in the effect.
            className="trustpilot-widget"
            data-locale="fr-FR"
            data-template-id="56278e9abfbbba0bdcd568bc"
            data-businessunit-id="67b8116b8525b99cf90afd3c"
            data-style-height="auto"
            data-style-width="100%"
        >
            <a
                href="https://fr.trustpilot.com/review/planibeauty.fr"
                target="_blank"
                rel="noopener noreferrer"
            >Trustpilot</a>
        </div>
    );
};

export default MyTrustPilot;