import { Pencil, Trash } from "lucide-react";
import React, { useState, useEffect } from "react";
import employeeApi from '../../../../../api/employees.api.js';
import CreateEditCollaboratorModal from "./CreateEditCollaboratorModal.jsx";
import { showErrorMessage, showSuccessMessage, showWarningMessage } from "../../../../../utils/messageHelper.js";
import Swal from "sweetalert2";
import abonnementApi from "../../../../../api/abonnement.api.js";


const ListeCollaborateurs = ({ from_admin, beautician_id, user_id }) => {

    const [collaborateurs, setCollaborateurs] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedCollaboarator, setSelectedCollaboarator] = useState(null);
    const [reload, setReload] = useState(false);

    const fetchCollaborateurs = async () => {
        try {
            const res = await employeeApi.getAllEmployeesByBeauticianId(beautician_id);
            setCollaborateurs(res.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des collaborateurs : ', error);
            showErrorMessage('Erreur lors de la récupération des collaborateurs');
        }
    };

    useEffect(() => {
        fetchCollaborateurs();
    }, []);

    useEffect(() => {
        if (reload) {
            fetchCollaborateurs();
        }
    }, [reload]);

    const handleSave = async (collaborator, isCreate) => {
        try {
            if (isCreate) {
                const res = await employeeApi.create(localStorage.getItem('token'), collaborator);

                // on met à jour le subscription de l'utilisateur si le nombre de collaborateurs est supérieur à 3
                if (!from_admin && collaborateurs.length >= 3) {
                    const subscription = await abonnementApi.getAbonnementByUserId(localStorage.getItem('token'), user_id);
                    if (subscription) {
                        await abonnementApi.updateSubscriptionWhenAddingCollaborator(localStorage.getItem('token'), user_id, collaborateurs.length + 1);
                    }
                }
                showSuccessMessage('Collaborateur créé avec succès', () => {
                    setReload(prev => !prev);
                    setShowModal(false);
                });
            } else {
                const res = await employeeApi.update(localStorage.getItem('token'), collaborator.id, collaborator);
                showSuccessMessage('Collaborateur mis à jour avec succès', () => {
                    setReload(prev => !prev);
                    setShowModal(false);
                });
            }
        }
        catch (error) {
            console.error('Erreur lors de la sauvegarde du collaborateur : ', error);
            showErrorMessage('Erreur lors de la sauvegarde du collaborateur');
        }
    }

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            text: "Vous ne pourrez pas revenir en arrière! Le prix de l'abonnement sera ajusté en conséquence",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await employeeApi.delete(localStorage.getItem('token'), id);

                    // on met à jour le subscription de l'utilisateur si le nombre de collaborateurs est supérieur à 3 
                    if (!from_admin && collaborateurs.length > 3) {
                        const subscription = await abonnementApi.getAbonnementByUserId(localStorage.getItem('token'), user_id);
                        if (subscription) {
                            await abonnementApi.updateSubscriptionWhenRemovingCollaborator(localStorage.getItem('token'), user_id, collaborateurs.length - 1);
                        }
                    }

                    showSuccessMessage('Collaborateur supprimé avec succès', () => {
                        setReload(prev => !prev);
                    });
                } catch (error) {
                    console.error('Erreur lors de la suppression du collaborateur : ', error);
                    showErrorMessage('Erreur lors de la suppression du collaborateur');
                }
            }
        });
    }

    const addClick = () => {

        if (from_admin) {
            if (collaborateurs.length >= 3) {
                showWarningMessage('Le nombre maximum de collaborateurs qu\'un admin peut ajouter est de 3 collaborateurs');
                return;
            }
        } else {
            if (collaborateurs.length >= 3) {
                showWarningMessage("Au dessus de 3 collaborateurs, 5€ par collaborateur supplémentaire vous sera facturé", () => {
                    setSelectedCollaboarator(null);
                    setReload(prev => !prev);
                });
                return;
            }
        }

        setSelectedCollaboarator(null);
        setShowModal(true);
    }

    return <div className="w-full">
        <div className="flex justify-between items-center mb-2 w-full">
            <h4>Listes des collaboraeturs</h4>
            <button
                className="btn btn-primary"
                onClick={addClick}
            >Ajouter collaboraetur</button>
        </div>
        <div className="flex justify-between w-full">
            <div className="w-full">
                <div className="flex gap-2 flew-wrap w-full">
                    {collaborateurs.map(collaborateur => {
                        return <div key={collaborateur.id} className="p-2 rounded-lg bg-gray-200 flex gap-2 items-center">
                            <span>{collaborateur.name}</span>
                            <button
                                className="btn btn-warning btn-sm"
                                onClick={() => {
                                    setSelectedCollaboarator(collaborateur);
                                    setShowModal(true);
                                }}
                            ><Pencil size={14} /></button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(collaborateur.id)}
                            ><Trash size={14} /></button>
                        </div>
                    })}
                    {collaborateurs.length === 0 && <div className="text-center">Aucun collaborateur trouvé</div>}
                </div>
            </div>
        </div>
        {showModal && <CreateEditCollaboratorModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            collaborator={selectedCollaboarator}
            onSave={handleSave}
            beautician_id={beautician_id}
        />}
    </div>
}

export default ListeCollaborateurs;