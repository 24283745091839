import React, { useEffect, useState, useContext } from 'react';  // Un seul import React avec tous les hooks
import { Helmet } from 'react-helmet-async';
import './Fonctionnalites.css';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';  // Import du contexte
import FonctionnaliteHero from '../../components/fonctionnalites/FonctionnaliteHero';
import FonctionnaliteTitle from '../../components/fonctionnalites/FonctionnaliteTitle';
import FonctionnaliteAgenda from '../../components/fonctionnalites/FonctionnaliteAgenda';
import FonctionnalitePayement from '../../components/fonctionnalites/FonctionnalitePayement';
import FonctionnaliteStatistiques from '../../components/fonctionnalites/FonctionnaliteStatistiques';
import FonctionnaliteClientelle from '../../components/fonctionnalites/FonctionnaliteClientelle';
import FonctionnaliteCommunaute from '../../components/fonctionnalites/FonctionnaliteCommunaute';
import FonctionnaliteCommunication from '../../components/fonctionnalites/FonctionnaliteCommunication';
import FonctionnaliteTarifs from '../../components/fonctionnalites/FonctionnaliteTarifs';
import LineEnd from '../../components/fonctionnalites/LineEnd';
const Fonctionnalités = () => {
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const { setActivePageForHeader } = useContext(AppContext);

  useEffect(() => {
    setIsMounted(true);
    window.scrollTo(0, 0);
    
    // Définir l'état du header
    document.body.setAttribute('data-page', 'search');
    setActivePageForHeader("search");

    return () => {
      setIsMounted(false);
      document.body.removeAttribute('data-page');
      setActivePageForHeader("");
    }
  }, [setActivePageForHeader]);
 
  return (
    <>
      <Helmet>
      <title>Nos fonctionnalités | PlaniBeauty</title>
      <meta name="description" content="Découvrez comment PlaniBeauty révolutionne la réservation de prestations beauté. Une plateforme simple et intuitive pour connecter clients et professionnels." />
      <link rel="canonical" href="https://www.planibeauty.fr/concept" />
      <meta property="og:title" content="Les fonctionnalités PlaniBeauty | Réservation beauté" />
      <meta property="og:description" content="PlaniBeauty connecte clients et professionnels de la beauté. Découvrez notre concept unique." />
      </Helmet>

      <div className={`${isMounted ? "mounted" : ""}`}>
        <FonctionnaliteHero />
        <FonctionnaliteTitle/>
        <FonctionnaliteAgenda/>
        <FonctionnalitePayement/>
        <FonctionnaliteStatistiques/>
        <FonctionnaliteClientelle/>
        <FonctionnaliteCommunaute/>
        <FonctionnaliteCommunication/>
        <LineEnd/>
        <FonctionnaliteTarifs/>
      </div>
    </>
  );
};

export default Fonctionnalités;