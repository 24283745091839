import MySpinner from "../../../components/loader/Spinner";
import React, { useEffect, useState } from "react";
import { FaPlus, FaTrash, FaCopy } from "react-icons/fa6";
import CreateRegisterLinkModal from "./CreateRegisterLinkModal";
import { registerLinksApi } from "../../../api/register_links.api";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { fr } from "date-fns/locale";
import { REACT_APP_URL } from "../../../utils/constants";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

const RegisterLinks = () => {
    const [loading, setLoading] = useState(true);
    const [links, setLinks] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [reloadTrigger, setReloadTrigger] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterUsed, setFilterUsed] = useState("all"); // 'all', 'true', 'false'

    const fetchLinks = async () => {
        setLoading(true);
        const res = await registerLinksApi.getAll(localStorage.getItem('token'));
        setLinks(res || []);
        setLoading(false);
    };

    useEffect(() => {
        fetchLinks();
    }, []);

    useEffect(() => {
        fetchLinks();
    }, [reloadTrigger]);

    const handleCopy = (link) => {
        navigator.clipboard.writeText(link);
        toast.success("Lien copié dans le presse-papiers !");
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: "Êtes-vous sûr ?",
            text: "Cette action est irréversible !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Oui, supprimer !",
            cancelButtonText: "Annuler",
        }).then(async (result) => {
            if (result.isConfirmed) {
                await registerLinksApi.delete(id, localStorage.getItem('token'));
                setReloadTrigger(prev => !prev);
                toast.success("Lien supprimé avec succès !");
            }
        });
    };

    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
            width: "70px",
        },
        {
            name: "Lien",
            selector: (row) => `${REACT_APP_URL}/inscription/${row.slug}`,
            sortable: true,
        },
        {
            name: "Mois offerts",
            selector: (row) => row.months,
            cell: (row) => row.months || 'Aucun',
            sortable: true,
        },
        {
            name: "Jours offerts",
            selector: (row) => row.days,
            cell: (row) => row.days || 'Aucun',
            sortable: true,
        },
        {
            name: "Indentification",
            selector: (row) => row.identification,
            sortable: true,
        },
        {
            name: "Expiration",
            selector: (row) => format(new Date(row.expires_at), "d MMMM yyyy à HH:mm", { locale: fr }),
            sortable: true,
        },
        {
            name: "Utilisé",
            selector: (row) => row.is_used,
            cell: (row) => row.is_used ? 'OUI' : 'NON',
            sortable: true,
        },
        {
            name: "Créé par",
            selector: (row) => row.first_name + ' ' + row.last_name,
            cell: (row) => ((row.first_name && row.last_name) ? (row.first_name + " " + row.last_name) : 'Inconnu') + " le " + format(new Date(row.created_at), "d MMMM yyyy à HH:mm", { locale: fr }),
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="flex gap-2">
                    <button onClick={() => handleCopy(`${REACT_APP_URL}/inscription/${row.slug}`)} className="bg-blue-500 text-white text-base px-3 py-2 rounded hover:bg-blue-700">
                        <FaCopy />
                    </button>
                    <button onClick={() => handleDelete(row.id)} className="bg-red-500 text-white text-base px-3 py-2 rounded hover:bg-red-700">
                        <FaTrash />
                    </button>
                </div>
            ),
        },
    ];

    const filteredLinks = links.filter((link) => {
        // Vérifie si le lien correspond au terme de recherche
        const matchesSearch = (link.first_name && link.first_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (link.last_name && link.last_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (link.identification && link.identification.toLowerCase().includes(searchTerm.toLowerCase()));
    
        // Vérifie si le lien correspond au filtre utilisé/non utilisé
        const matchesFilter = 
            filterUsed === "all" ||
            (filterUsed === "true" && link.is_used) ||
            (filterUsed === "false" && !link.is_used);
    
        return matchesSearch && matchesFilter;
    });
    


    return (
        <main className="min-h-[72vh]">
            {loading ? (
                <div className="w-full">
                    <MySpinner height={"60vh"} />
                </div>
            ) : (
                <div>
                    <div className="p-2 md:p-6 bg-gray-100 rounded-lg">
                        <div className="flex justify-between items-center gap-2">
                            <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-6">Liste des liens d'inscriptions</h2>
                            <button
                                onClick={() => setShowModal(true)}
                                className="px-2 md:px-4 py-2 text-sm md:text-base text-white bg-[#bd193b] flex items-center gap-2 rounded shadow"
                            >
                                <FaPlus />
                                Créer un lien
                            </button>
                        </div>
                        <div className="flex justify-between items-center gap-2 mb-4">
                            <input
                                type="text"
                                placeholder="Rechercher un lien..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="border p-2 rounded w-full md:w-1/3"
                            />
                            <select
                                value={filterUsed}
                                onChange={(e) => setFilterUsed(e.target.value)}
                                className="border p-2 rounded w-full md:w-1/5"
                            >
                                <option value="all">Tout</option>
                                <option value="true">Utilisé</option>
                                <option value="false">Non utilisé</option>
                            </select>

                        </div>

                        <DataTable
                            columns={columns}
                            data={filteredLinks}
                            pagination
                            highlightOnHover
                            responsive
                        />
                    </div>
                    {showModal && (
                        <CreateRegisterLinkModal showModal={showModal} setShowModal={setShowModal} setReloadTrigger={setReloadTrigger} />
                    )}
                </div>
            )}
        </main>
    );
};

export default RegisterLinks;