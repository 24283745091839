import pimg from "../../assets/fonctionnalites/manage_payement.png";
const FonctionnalitePayement = () => {
  return <div className="fonctionnalites-content">
    <div className="fonctionnalite-wrapper2">
      <img src={pimg} alt="" className="imgzone"/>
      <div className="textzone">
          <h2>Des paiements simplifiés,<br></br>vos revenus sécurisés.</h2>
        <h4>Paiement en ligne sécurisé </h4>
          <p>Via carte bancaire</p>
        <h4>Paiement sur place </h4>
        <p>Choisissez un règlement au moment du rendez-vous</p>
        <h4>Acomptes à la réservation</h4>
          <p>Possibilité de demander un acompte pour sécuriser vos rendez-vous</p>
        <h4>protection contre les NO-SHOWS</h4>
          <p>Choisissez une facturation automatique des pénalités en cas de no-show ou d’annulation tardive</p>
      </div>
    </div>
  </div>
}

export default FonctionnalitePayement;