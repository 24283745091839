import React, { useContext, useEffect, useRef, useState } from "react"

import Logo from "../../../../assets/images/logoBlanc.png"

import { Link } from "react-router-dom"
import { Leads, Suivis } from "./CustomIcons"

import {
    CalendarClock,
    Euro,
    FileText,
    LayoutDashboard,
    Mail,
    UserCheck,
    UserCircle
} from "lucide-react"
import useIsMobile from "../../../../utils/useIsMobile"

import { TeleconseillerSidebarContext } from "../../../../context/TeleConseillerContext"

const NavItems = {
    main: [
        { url: "", icon: (size = 24) => <LayoutDashboard size={size} />, text: "Tableau de bord" },
        { url: "leads-pro", icon: (size = 24) => <Leads size={size} />, text: "Leads pro" },
        { url: "fiche-pro", icon: (size = 24) => <UserCheck size={size} />, text: "Fiche pro" },
        { url: "suivis", icon: (size = 24) => <Suivis size={size} />, text: "Suivis" },
        { url: "agendas", icon: (size = 24) => <CalendarClock size={size} />, text: "Agendas" },
        { url: "document", icon: (size = 24) => <FileText size={size} />, text: "Document" }
    ],
    footer: [
        { url: "factures", icon: (size = 24) => <Euro size={size} />, text: "Factures" },
        { url: "profile", icon: (size = 24) => <UserCircle size={size} />, text: "Mon profil" },
        { url: "contact", icon: (size = 24) => <Mail size={size} />, text: "Contact" }
    ],
}

const Sidebar = () => {
    const { isSidebarOpen, setIsSidebarOpen } = useContext(TeleconseillerSidebarContext)
    
    const isMobile = useIsMobile()
    const sidebarRef = useRef(null)

    const handleRedirect = () => {
        if(isMobile) {
            setIsSidebarOpen(false)
        }
    }

    const handleClickOutside = (e) => {
        if(
            isSidebarOpen &&
            sidebarRef.current &&
            !sidebarRef.current.contains(e.target)
        ) {
            setIsSidebarOpen(false)
        }
    }

    useEffect(() => {
        if(isMobile) {
            document.addEventListener("mousedown", handleClickOutside)
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [isSidebarOpen, isMobile])

    useEffect(() => {
        if(isMobile) {
            setIsSidebarOpen(false)
        }
        else {
            setIsSidebarOpen(true)
        }
    }, [isMobile])

    return (
        <aside
            ref={sidebarRef}
            className={`
                ${isMobile ? "absolute" : "relative"}
                ${isMobile ? isSidebarOpen ? "translate-x-[0%]" : "-translate-x-[100%]" : "translate-x-[0%]"}
                h-full px-3 py-4 flex flex-col gap-6 bg-[#bd193b] text-white duration-300 z-50
            `}
        >
            {/* Logo */}
            <a
                href="/"
                className="no-underline"
            >
                <img
                    src={Logo}
                    width={200}
                />
            </a>

            {/* Menu pricipal */}
            <section className="flex flex-col gap-2">
                {NavItems.main.map((item, key) =>
                    <Link
                        key={`nav-items-main-${key}`}
                        to={`/tele-conseiller-dashboard/${item.url}`}
                        className="p-2 flex items-center gap-3 text-white no-underline cursor-pointer hover:bg-gray-200 hover:bg-opacity-10 rounded duration-200"
                        onClick={handleRedirect}
                    >
                        {item.icon()}
                        {item.text}
                    </Link>
                )}
            </section>

            {/* Menu footer */}
            <section className="mt-auto flex flex-col gap-2">
                <div className="mx-auto w-1/2 h-1 bg-white rounded"></div>
                {NavItems.footer.map((item, key) =>
                    <Link
                        key={`nav-items-footer-${key}`}
                        to={`/tele-conseiller-dashboard/${item.url}`}
                        className="p-2 flex items-center gap-3 text-white no-underline cursor-pointer hover:bg-gray-200 hover:bg-opacity-10 rounded duration-200"
                        onClick={handleRedirect}
                    >
                        {item.icon()}
                        {item.text}
                    </Link>
                )}
            </section>
        </aside>
    )
}

export default Sidebar
