import React, { useEffect, useState } from "react"
import MySpinner from "../../../components/loader/Spinner"
import { Table, Pagination, Modal } from "react-bootstrap"
import beauticianApi from "../../../api/beautician.api"

import { ChevronLeft, ChevronRight } from "lucide-react"
import userApi from "../../../api/user.api"
import abonnementApi from "../../../api/abonnement.api"

const OperatorDetails = ({ operator, showModal, setShowModal, setSelectedOperator }) => {
    const [beauticiansCreated, setBeauticiansCreated] = useState([])

    const handleCloseModal = () => {
        setShowModal(false)
        setSelectedOperator(null)
    }

    useEffect(() => {
        if (!operator || !operator.id)
            return

        const fetchBeauticiansCreated = async () => {
            const res = await beauticianApi.getBeauticiansByCreatorId(localStorage.getItem("token"), operator.id)

            if (res.success) {
                const beauticians = await Promise.all(res.data.map(async(b) => {
                    const abonnement = await abonnementApi.getAllAbonnementByUserId(localStorage.getItem("token"), b.user_id)

                    return {...b, abonnement}
                }))

                setBeauticiansCreated(beauticians)
            }
        }

        fetchBeauticiansCreated()
    }, [operator])

    return (
        <Modal
            centered
            size="lg"
            show={showModal}
            onHide={handleCloseModal}
        >
            <Modal.Header>
                <h1 className="text-xl">Professionnels inscrits</h1>
            </Modal.Header>
            <Modal.Body className="overflow-x-auto">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Nom commercial</th>
                            <th>Date d'inscription</th>
                            <th>Type d'abonnement</th>
                            <th>Date abonnement</th>
                            <th>Delai abonnement</th>
                            <th>Date essai</th>
                        </tr>
                    </thead>
                    <tbody>
                        {beauticiansCreated.map((b, index) => (
                            <tr
                                key={b.id}
                                className="cursor-pointer"
                                onClick={() => handleRowClick(op)}
                            >
                                <td>{b.id}</td>
                                <td>{b.business_name}</td>
                                <td>{new Date(b.created_at).toLocaleString("fr-FR", { day: "2-digit", month: "long", "year": "numeric" })}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={handleCloseModal}
                    className="ms-auto px-3 py-2 rounded text-white bg-[#bd193b]"
                >
                    Fermer
                </button>
            </Modal.Footer>
        </Modal>
    )
}

const SuiviOperateurs = () => {
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [selectedOperator, setSelectedOperator] = useState({})

    const [filter, setFilter] = useState({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        thisWeek: false
    })

    const [operators, setOperators] = useState([])
    const [limitPerPage, setLimitPerPage] = useState(10)
    const [trigger, setTrigger] = useState(false)
    const [pages, setPages] = useState(1)
    const [maxPages, setMaxPages] = useState(0)

    const status = {
        unpayed: "Non payé",
        payed: "Payé",
        upcoming: "À venir"
    }

    const fetchOperators = async () => {
        setLoading(true)
        const res = await userApi.selectUsersByType(localStorage.getItem("token"), "operator")

        if (res.success) {
            const ops = await Promise.all(res.data.map(async (op) => {
                const users_registered = await beauticianApi.getBeauticiansByCreatorId(localStorage.getItem("token"), op.id)

                return { ...op, users_count: users_registered.data.length }
            }))

            setOperators(ops)
            setMaxPages(Math.ceil(res.data.length / limitPerPage) | 1)
        }

        setLoading(false)
    }

    const handleRowClick = (operator) => {
        setShowModal(true)
        setSelectedOperator(operator)
    }

    useEffect(() => {
        fetchOperators()
    }, [filter, trigger])

    useEffect(() => {
        setMaxPages(Math.ceil(operators.length / limitPerPage)) // Recalculer les pages si limitPerPage change
        setPages(1) // Réinitialiser la page active si la limite par page change
    }, [limitPerPage])

    // Filtrer les professionnels en fonction de la page active
    const displayedOperator = operators.slice((pages - 1) * limitPerPage, pages * limitPerPage)

    const months = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
    ]

    const handleFilterChange = (isYear, step) => {
        isYear ?
            setFilter(prev => ({ ...prev, year: prev.year + step })) :
            setFilter(prev => {
                let nextMonth = prev.month + step
                let nextYear = prev.year

                if (nextMonth < 1) {
                    nextYear--
                    nextMonth = 12
                }
                else if (nextMonth > 12) {
                    nextYear++
                    nextMonth = 1
                }

                return { year: nextYear, month: nextMonth }
            })
    }

    return (
        <main className="min-h-[72vh]">
            {loading ? (
                <div className="w-full">
                    <MySpinner height={"60vh"} />
                </div>
            ) : (
                <div>
                    <div className="p-6 bg-gray-100 rounded-lg">
                        <header>
                            <h2 className="text-2xl font-bold text-gray-800 mb-6">Suivi des opérateurs</h2>
                        </header>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nom</th>
                                    <th>Date d'inscription</th>
                                    <th>Pro inscrit</th>
                                    <th>Abonnement attribué</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedOperator.map((op, index) => (
                                    <tr
                                        key={op.id}
                                        className="cursor-pointer"
                                        onClick={() => handleRowClick(op)}
                                    >
                                        <td>{(pages - 1) * limitPerPage + index + 1}</td>
                                        <td>{op.last_name} {op.first_name}</td>
                                        <td>{new Date(op.created_at).toLocaleString("fr-FR", { day: "2-digit", month: "long", "year": "numeric" })}</td>
                                        <td>{op.users_count}</td>
                                        <td>0</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    <div className="flex items-center justify-center">
                        <div className="flex items-center gap-2 pt-4 text-lg font-semibold">
                            <span>Limites par pages</span>
                            <select
                                onChange={(e) => setLimitPerPage(Number(e.target.value))}
                                defaultValue={limitPerPage}
                            >
                                {[10, 15, 20, 25, 30, 100].map((item, index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <Pagination>
                            <Pagination.First onClick={() => setPages(1)} disabled={pages === 1} />
                            <Pagination.Prev onClick={() => setPages(pages - 1)} disabled={pages === 1} />
                            {Array.from({ length: maxPages }, (_, index) => index + 1).map((item, index) => (
                                <Pagination.Item
                                    key={index}
                                    active={item === pages}
                                    onClick={() => setPages(item)}
                                >
                                    {item}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => setPages(pages + 1)} disabled={pages === maxPages} />
                            <Pagination.Last onClick={() => setPages(maxPages)} disabled={pages === maxPages} />
                        </Pagination>
                    </div>

                    <OperatorDetails
                        operator={selectedOperator}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        setSelectedOperator={setSelectedOperator}
                    />
                </div>
            )}
        </main>
    )
}

export default SuiviOperateurs