import React from 'react'
import { motion } from "motion/react"
import { FaX } from "react-icons/fa6"
import ProgrammeAffiliation from '../../../components/common/ProgrammeAffiliation'


const ShowAffiliationInfo = ({ setShowAffiliationInfo, selectedBeautician }) => {

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="absolute top-0 z-20 left-0 w-screen h-screen bg-gray-700 bg-opacity-80 flex items-center justify-center"
        >
            <div
                className={`relative w-11/12 min-h-[50vh] p-4 max-h-[90vh] overflow-y-auto flex flex-col gap-2 bg-white border rounded shadow`}
            >
                <button
                    onClick={() => {
                        setShowAffiliationInfo(false);
                    }}
                    className="absolute top-6 right-10"
                >
                    <FaX />
                </button>
                <div className="w-full h-full overflow-scroll">
                    <ProgrammeAffiliation slug={selectedBeautician.slug} beautician_id={selectedBeautician.beautician_id} />
                </div>


            </div>
        </motion.div>
    )
}

export default ShowAffiliationInfo