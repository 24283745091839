import { EllipsisVertical } from 'lucide-react'
import React, { useState, useEffect, useRef } from 'react'
import DataTable from 'react-data-table-component'

const ActionMenu = ({ row }) => {
    const [isOpen, setIsOpen] = useState(false)
    const menuRef = useRef(null)

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false)
        }
    }

    const menus = [
        { text: "Modifier les informations personnelles", bg: "bg-yellow-300", action: () => {} },
        { text: "Rajouter à l'agenda", bg: "bg-green-600", action: () => {} },
        { text: "Envoyer une plaquette d'information", color: "text-gray-800", action: () => {} },
        { text: "Voir et modifier les caractéristiques", bg: "bg-blue-600", action: () => {} },
        { text: "Accéder à la fiche pro", bg: "bg-[#bd193b]", action: () => {} },
    ]

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div className="relative inline-block" ref={menuRef}>
            <button
                onClick={(e) => {
                    e.stopPropagation()
                    setIsOpen(!isOpen)
                }}
                className="p-1 rounded-full hover:bg-gray-200 cursor-pointer *:cursor-pointer relative z-0"
            >
                <EllipsisVertical size={20} />
            </button>

            {isOpen && (
                <div
                    className="absolute right-0 z-[1000] mt-2 w-[300px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    style={{
                        position: 'fixed',
                        top: `${menuRef.current?.getBoundingClientRect().bottom}px`,
                        left: `${menuRef.current?.getBoundingClientRect().right - 300}px`,
                    }}
                >
                    <div className="absolute -z-10 size-3 top-0 right-2 bg-white -translate-y-1/2 rounded-sm rotate-45"></div>
                    <div className="p-1">
                        {menus.map((item, key) => (
                            <button
                                key={`menu-action-${key}`}
                                onClick={item.action}
                                className={`
                                    ${item.bg ? item.bg + " text-white" : "text-gray-700"}
                                    flex w-full items-center px-4 py-2 text-sm`}
                            >
                                {item.text}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

const columns = [
    {
        name: '#',
        selector: row => row.id,
        sortable: true,
        width: '70px',
    },
    {
        name: 'Liens',
        selector: row => row.liens || '-',
        sortable: true,
        width: '150px',
    },
    {
        name: 'Téléphone',
        selector: row => row.telephone || '-',
        width: '130px',
    },
    {
        name: 'Date leads',
        selector: row => row.date_leads ? new Date(row.date_leads).toLocaleDateString('fr-FR') : '-',
        sortable: true,
        width: '120px',
    },
    {
        name: 'Ville',
        selector: row => row.ville || '-',
        sortable: true,
        width: '120px',
    },
    {
        name: 'Code postal',
        selector: row => row.cp || '-',
        width: '100px',
    },
    {
        name: 'Date statut',
        selector: row => row.date_statut ? new Date(row.date_statut).toLocaleDateString('fr-FR') : '-',
        width: '120px',
    },
    {
        name: 'Statut',
        selector: row => row.statut || '-',
        cell: row => (
            <span style={{ 
                color: row.statut === 'ok inscription' ? 'green' : 
                      row.statut === 'liens supprimés' ? 'red' : 
                      row.statut === 'NRP 1' ? 'orange' : 'black'
            }}>
                {row.statut || '-'}
            </span>
        ),
        width: '150px',
    },
    {
        name: 'Commentaire',
        selector: row => row.commentaire || '-',
        wrap: true,
        width: '200px',
    },
    {
        name: 'Pro',
        selector: row => row.pro ? 'Oui' : 'Non',
        cell: row => (
            <span style={{ color: row.pro === 'Oui' ? 'green' : 'red' }}>
                {row.pro ? 'Oui' : 'Non'}
            </span>
        ),
        width: '80px',
    },
    {
        name: 'Action',
        cell: row => <ActionMenu row={row} />,
        ignoreRowClick: true,
        width: '80px',
    },
]

const customStyles = {
    headCells: {
        style: {
            backgroundColor: '#bd193b',
            color: 'white',
            fontWeight: 'bold',
            borderRight: '1px solid white', // Ajout des séparateurs blancs
            '&:last-child': {
                borderRight: 'none', // Supprime le séparateur pour la dernière cellule
            },
        },
    },
    cells: {
        style: {
            color: 'black',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
}

const data = [
    {
        id: 1,
        liens: "https://example.com/lead1",
        telephone: "0612345678",
        date_leads: "2023-05-15",
        ville: "Paris",
        cp: "75001",
        date_statut: "2023-05-20",
        statut: "ok inscription",
        commentaire: "Client intéressé par nos services premium",
        pro: "Oui",
    },
    {
        id: 2,
        liens: "https://example.com/lead2",
        telephone: "0698765432",
        date_leads: "2023-06-10",
        ville: "Lyon",
        cp: "69002",
        date_statut: "2023-06-12",
        statut: "liens supprimés",
        commentaire: "Ne répond plus aux emails",
        pro: "Non",
    },
    {
        id: 3,
        liens: "https://example.com/lead3",
        telephone: "0677889900",
        date_leads: "2023-07-01",
        ville: "Marseille",
        cp: "13001",
        date_statut: "2023-07-05",
        statut: "NRP 1",
        commentaire: "A rappeler dans 1 mois",
        pro: "Oui",
    },
    {
        id: 4,
        liens: "",
        telephone: "0644556677",
        date_leads: "2023-08-22",
        ville: "Toulouse",
        cp: "31000",
        date_statut: "",
        statut: "",
        commentaire: "Nouveau lead à contacter",
        pro: "Non",
    },
]

const LeadsData = () => {
    const [loading, setLoading] = useState(false)
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setTableData(data)
            setLoading(false)
        }, 1000)
    }, [])

    return (
        <div className="h-full overflow-auto relative">
            <DataTable
                columns={columns}
                data={tableData}
                progressPending={loading}
                highlightOnHover
                responsive
                striped
                noDataComponent="Aucune donnée disponible"
                fixedHeader
                fixedHeaderScrollHeight="calc(100% - 60px)"
                customStyles={customStyles}
            />
        </div>
    )
}

export default LeadsData