import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Bell, LogOut, Menu, MessageCircle } from 'lucide-react'
import { TeleconseillerSidebarContext } from '../../../../context/TeleConseillerContext'
import useIsMobile from '../../../../utils/useIsMobile'
import defaultProfileImage from "../../../../assets/images/default-profile.png"

const Navbar = () => {
    const isMobile = useIsMobile()
    const { setIsSidebarOpen } = useContext(TeleconseillerSidebarContext)

    const dropdownRef = useRef(null)
    const dropdownTriggerRef = useRef(null)

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [isAnimating, setIsAnimating] = useState(false)

    const [navItems] = useState([
        { url: "", icon: (size=24) => <MessageCircle size={size} />, notification: 0 },
        { url: "", icon: (size=24) => <Bell className="mt-1" size={size} />, notification: 0 }
    ])

    const toggleDropdown = () => {
        if (isAnimating) return
        setIsAnimating(true)
        setIsDropdownOpen(!isDropdownOpen)
    }

    const handleSidebarOpening = () => {
        setIsSidebarOpen(true)
    }

    const handleEscape = (e) => {
        if (e.key === 'Escape') {
            closeDropdown()
        }
    }

    const closeDropdown = () => {
        setIsAnimating(true)
        setIsDropdownOpen(false)
    }

    const handleTransitionEnd = () => {
        setIsAnimating(false)
    }

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownTriggerRef.current && 
                !dropdownTriggerRef.current.contains(e.target)) {
                closeDropdown()
            }
        }

        if (isDropdownOpen) {
            document.addEventListener('keydown', handleEscape)
            document.addEventListener('mousedown', handleClickOutside)

            return () => {
                document.removeEventListener('keydown', handleEscape)
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }
    }, [isDropdownOpen])

    return (
        <nav className="p-3 flex items-center border-b">
            {isMobile && (
                <button
                    className="cursor-pointer *:cursor-pointer"
                    onClick={handleSidebarOpening}
                    aria-label="Ouvrir le menu"
                >
                    <Menu size={32} />
                </button>
            )}

            <section className="ms-auto flex gap-3 items-center">
                {navItems.map((item, key) => (
                    <Link
                        key={`nav-items-${key}`}
                        to={`/tele-conseiller-dashboard/${item.url}`}
                        className="relative *:text-gray-600 *:cursor-pointer no-underline"
                    >
                        {item.icon()}
                        {item.notification > 0 && (
                            <div className="absolute px-2 p-1 -top-1 -right-3 flex items-center justify-center text-[.60em] text-white bg-[#bd193b] rounded-full">
                                {item.notification > 99 ? "+99" : item.notification}
                            </div>
                        )}
                    </Link>
                ))}

                <div className="relative">
                    <div
                        ref={dropdownTriggerRef}
                        className="flex items-center gap-2 cursor-pointer *:cursor-pointer"
                        aria-expanded={isDropdownOpen}
                        aria-haspopup="true"
                        onClick={toggleDropdown}
                    >
                        <img
                            alt="Photo de profil"
                            src={defaultProfileImage}
                            className="size-10 border rounded-full"
                        />
                        {!isMobile && <span className="font-semibold">Joy Calvet</span>}
                    </div>

                    <div
                        ref={dropdownRef}
                        className={`absolute right-0 top-[125%] bg-white border shadow rounded whitespace-nowrap overflow-hidden transition-all duration-300 ease-in-out ${
                            isDropdownOpen 
                                ? 'opacity-100 translate-y-0 visible' 
                                : 'opacity-0 -translate-y-2 invisible'
                        }`}
                        onTransitionEnd={handleTransitionEnd}
                    >
                        <div 
                            className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 cursor-pointer *:cursor-pointer duration-300"
                            onClick={closeDropdown}
                        >
                            <LogOut size={24} />
                            <span>Se déconnecter</span>
                        </div>
                    </div>
                </div>
            </section>
        </nav>
    )
}

export default Navbar