import { useState, useEffect } from "react"

import Swal from "sweetalert2"
import Calendar from "react-calendar"

import { Modal } from "react-bootstrap"

import { getMonday, getWeekDates } from "./utils"

import { Spinner } from "react-bootstrap"
import { Trash, X } from "lucide-react"
import { FaCalendar } from "react-icons/fa6"

import RenderStars from "../RenderStars"

import trendingApi from "../../../api/trending.api"
import beauticianApi from "../../../api/beautician.api"

import "react-calendar/dist/Calendar.css"

const handleTrendingDeletion = (trending, setTrendingTrigger) => {
    Swal.fire({
        title: "Suppression d'une tendance",
        text: `Êtes-vous sûr(e) de vouloir supprimer ${trending.business_name} de la liste des tendances ?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                const response = await trendingApi.removeTrending(trending.trending_id)
                
                if (response.success) {
                    const dates = getWeekDates(trending.week_start)
                    Swal.fire(
                        "Succès",
                        `${trending.business_name} a été supprimé de la liste des tendances pour la semaine du ${dates[0]} au ${dates[1]}.`,
                        "success"
                    )
                    setTrendingTrigger(prev => !prev)

                } else {
                    throw new Error(response.message)
                }
            } catch (err) {
                Swal.fire("Une erreur est survenue", err.message || "Erreur inconnue", "error")
            }
        }
    })
}

const RenderTrending = ({ selectedDate, setAddBeautician, trendingTrigger, setTrendingTrigger }) => {
    const [trending, setTrending] = useState([])

    useEffect(() => {
        const getTrending = async () => {
            try {
                // Vérification de selectedDate pour récupérer les tendances de la semaine active
                const activeTrending = selectedDate ?
                    await trendingApi.getTrendingByWeek(selectedDate) : await trendingApi.getActiveTrending()

                setTrending(activeTrending.data) // Mettre à jour l'état avec les tendances récupérées
            } catch (err) {
                Swal.fire("Une erreur s'est produite", "Récupération des tendances: " + err.message, "error")
            }
        }

        getTrending()

        // Cleanup de l'état lors du démontage du composant
        return () => {
            setTrending([]) // Réinitialiser les tendances lorsque le composant est démonté
        }
    }, [selectedDate, trendingTrigger])

    return (
        <>
            {(Array.isArray(trending) && trending.length > 0) ? (
                <div className="max-h-[60vh] flex flex-col gap-3 overflow-y-auto">
                    {trending.map((tr, key) => (
                        <div
                            key={"tr" + key}
                            className="flex items-center p-3 rounded shadow border"
                        >
                            <div className="flex flex-col">
                                <span className="text-lg font-semibold">{tr.business_name}</span>
                                <RenderStars star_count={tr.average_rating} />
                            </div>

                            <button
                                className="ms-auto"
                                onClick={() => handleTrendingDeletion(tr, setTrendingTrigger)}
                            >
                                <Trash
                                    size={16}
                                    className="cursor-pointer"
                                />
                            </button>
                        </div>
                    ))}

                    {trending.length < 15 && (
                        <div className="flex">
                            <button
                                onClick={() => setAddBeautician(true)}
                                className="ms-auto px-3 py-2 bg-[#bd193b] text-white text-sm rounded shadow"
                            >
                                Ajouter une professionnelle
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <div className="text-center flex flex-col items-center gap-4">
                    Aucune tendances à afficher sur cette date.
                    
                    <button
                        onClick={() => setAddBeautician(true)}
                        className="px-3 py-2 bg-[#bd193b] text-white text-sm rounded shadow"
                    >
                        Ajouter une professionnelle
                    </button>
                </div>
            )}
        </>
    )
}

const TrendingPanel = ({ show, setShow, trigger }) => {
    const [activeDate, setActiveDate] = useState([])
    const [showCalendar, setShowCalendar] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)
    const [addBeautician, setAddBeautician] = useState(false)
    const [idOnTrending, setIdOnTrending] = useState([])
    const [trendingTrigger, setTrendingTrigger] = useState(false)

    const [searchResult, setSearchResult] = useState([])
    const [searchKeywords, setSearchKeywords] = useState("")
    const [isSearchLoading, setIsSearchLoading] = useState(false)

    const handleDateSelect = (date) => {
        setSelectedDate(date)
        setActiveDate(getWeekDates(date))

        setShowCalendar(false)
    }

    const handleHideModal = () => {
        setShow(false)
        trigger(prev => !prev)
        setShowCalendar(false)

        setSearchResult([])
        setSelectedDate(null)
        setSearchKeywords("")
        setAddBeautician(false)
    }

    const findBeautician = async(keywords) => {
        try {
            setIsSearchLoading(true)
            const results = await beauticianApi.getAllBeauticians("all", 10000)

            if(results.success) {
                const filteredResults = results.data.filter(b => b.business_name.toLowerCase().includes(keywords.toLowerCase()))
                const trendingOnActiveDate = selectedDate ? await trendingApi.getTrendingByWeek(selectedDate) : await trendingApi.getActiveTrending()

                if(trendingOnActiveDate.success) {
                    setSearchResult(filteredResults)
                    setIdOnTrending(trendingOnActiveDate.data.map(tr => tr.beautician_id))
                    // setSearchResult(filteredResults.filter(item => !tmpId.includes(item.id)))

                    setTimeout(() => {
                        setIsSearchLoading(false)
                    }, 500)
                }
            }
        }
        catch(err) {
            console.error(err.message)
            setTimeout(() => {
                setIsSearchLoading(false)
            }, 500)
        }
    }

    const addBeauticianToTrending = async(beautician_id) => {
        try {
            const results = await trendingApi.addToTrending(beautician_id, selectedDate || new Date())

            if(results.success) {
                Swal.fire("Réussi", `Professionnelle ajouté aux tendances du ${activeDate[0]} au ${activeDate[1]}`, "success")
                
                setSearchResult([])
                setSearchKeywords("")
                setAddBeautician(false)
            }
        }
        catch(err) {
            console.error(err.message)
        }
    }

    const handleAddBeauticianToTrending = (beautician_id) => {
        const checkStatusAndAddToTrending = async () => {
            try {
                // Récupérer les tendances existantes pour la date sélectionnée
                const beauticianTrending = await trendingApi.getTrendingByBeauticianId(beautician_id)
                
                if (Array.isArray(beauticianTrending.data) && beauticianTrending.data.length > 0) {
                    const formatDate = (date) => {
                        const d = new Date(date)
                        const day = String(d.getDate()).padStart(2, '0')
                        const month = String(d.getMonth() + 1).padStart(2, '0')
                        const year = d.getFullYear()
                        return `${day}/${month}/${year}`
                    }
                    
                    const getWeekRange = (weekStart) => {
                        const start = new Date(weekStart)
                        const end = new Date(start)
                        end.setDate(start.getDate() + 6) // Ajoute 6 jours pour obtenir la fin de la semaine
                        return `${formatDate(start)} au ${formatDate(end)}`
                    }
    
                    // Générer les plages de semaines existantes
                    const weekRanges = beauticianTrending.data.map(entry => getWeekRange(entry.week_start))
    
                    // Comparer la semaine sélectionnée avec les tendances existantes
                    const selectedWeekRange = getWeekRange(selectedDate)
                    if (weekRanges.includes(selectedWeekRange)) {
                        Swal.fire({
                            title: "Erreur",
                            text: `Cette professionnelle est déjà dans les tendances pour la semaine du ${selectedWeekRange}.`,
                            icon: "error",
                            confirmButtonText: "OK"
                        })
                        return
                    }
                }
    
                // Si aucune correspondance n'est trouvée, ajouter la professionnelle aux tendances
                addBeauticianToTrending(beautician_id)
            } catch (err) {
                console.error(err.message)
            }
        }
    
        checkStatusAndAddToTrending()
    }

    const handleCloseAddPanel = () => {
        setSearchResult([])
        setSearchKeywords("")
        setAddBeautician(false)
    }

    useEffect(() => {
        setActiveDate(getWeekDates(new Date()))
    }, [])

    useEffect(() => {
        findBeautician(searchKeywords)
    }, [searchKeywords])

    return <>
        <Modal
            centered
            show={show}
            onHide={handleHideModal}
        >
            { addBeautician ?
            <div>
                <Modal.Header>
                    <span className="text-xl font-semibold">
                        Ajouter une professionnelle aux tendances
                    </span>
                    <button
                        onClick={handleCloseAddPanel}
                    >
                        <X
                            size={16}
                            className="cursor-pointer"
                        />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <span className="ms-1 mb-2">Saisir un nom :</span>
                        <input
                            type="search"
                            value={searchKeywords}
                            placeholder="Trouver une professionnelle"
                            onChange={(e) => setSearchKeywords(e.target.value)}
                            className="px-3 py-2 rounded shadow border outline-[#b91c1c]"
                        />
                    </form>
                    <div className="flex flex-col">
                        {isSearchLoading ?
                            <div className="mt-3 flex items-center justify-center">
                                <Spinner />
                            </div> :
                            searchKeywords.length <= 0 ?
                                <div className="text-center p-4 text-gray-600 font-semibold">Commencer à saisir un nom.</div> :
                                (Array.isArray(searchResult) && searchResult.length > 0 ) ?
                                    <div className="mt-4 max-h-[60vh] flex flex-col gap-2 overflow-y-auto">
                                        { searchResult.map((b, key) => 
                                            <div
                                                key={"bs-" + key}
                                                className="p-3 flex items-center border rounded shadow"
                                            >
                                                <div className="flex flex-col gap-2">
                                                    <span className="text-lg">{b.business_name}</span>
                                                    <RenderStars star_count={b.average_rating} />
                                                </div>

                                                { !idOnTrending.includes(b.id) &&
                                                <button
                                                    onClick={() => handleAddBeauticianToTrending(b.id, b.business_name)}
                                                    className="ms-auto px-3 py-2 text-white bg-[#b91c1c] rounded shadow"
                                                >
                                                    Ajouter
                                                </button> }
                                            </div>
                                        ) }
                                    </div> :
                                    <div className="text-center p-4">Aucune professionnelle correspondante.</div>
                        }
                    </div>
                </Modal.Body>
            </div> :
            <div>
                <Modal.Header>
                    <span className="text-xl font-semibold">
                        Semaine du {activeDate[0]} au {activeDate[1]}
                    </span>
                    <button
                        onClick={() => setShowCalendar(true)}
                    >
                        <FaCalendar/>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {showCalendar ? 
                        <div>
                            <Calendar
                                onChange={handleDateSelect}
                                value={selectedDate || new Date()}
                                minDate={getMonday(new Date())}
                            />
                        </div> :
                        <div>
                            <RenderTrending
                                selectedDate={selectedDate}
                                setAddBeautician={setAddBeautician}
                                trendingTrigger={trendingTrigger}
                                setTrendingTrigger={setTrendingTrigger}
                            />
                        </div>}
                </Modal.Body>
            </div>}
        </Modal>
    </>
}

export default TrendingPanel