import { useEffect, useState } from "react";
import beauticianApi from "../../../api/beautician.api";
import testeurApi from "../../../api/testeur.api";
import Swal from "sweetalert2";
import { showSuccessMessage } from "../../../utils/messageHelper";
import { Modal, Button, Table, Form, InputGroup } from "react-bootstrap";
import { Search } from "lucide-react";

const AddTesteurModal = ({ showModal, setShowModal, setReloadTrigger, testeurs }) => {
    const [beauticians, setBeauticians] = useState([]);
    const [filteredBeauticians, setFilteredBeauticians] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const handleClose = () => {
        setReloadTrigger(prev => !prev);
        setShowModal(false);
    }

    const fetchAllBeauticians = async () => {
        const res = await beauticianApi.getAllAvailableBeauticians();
        const non_testeurs = [];
        if (Array.isArray(res.data)) {
            res.data.forEach(element => {
                if (!testeurs.some((obj) => obj.user_id === element.user_id)) {
                    non_testeurs.push(element);
                }
            });
        }
        setBeauticians(non_testeurs);
        setFilteredBeauticians(non_testeurs);
    }

    useEffect(() => {
        fetchAllBeauticians();
    }, []);

    useEffect(() => {
        const results = beauticians.filter(beautician =>
            beautician.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            beautician.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            beautician.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            beautician.phone.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredBeauticians(results);
    }, [searchTerm, beauticians]);

    const handleAddTesteur = async (user_id) => {
        Swal.fire({
            icon: "question",
            title: "Voulez vous ajouter l'utilisateur aux testeurs?",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Oui, confirmer",
            cancelButtonText: "Annuler",
            confirmButtonColor: "#bd193b"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await testeurApi.create(localStorage.getItem('token'), { user_id: user_id });
                showSuccessMessage("Testeur ajouté avec succès!");
                setReloadTrigger(prev => !prev);
            }
        });
    }

    return <Modal show={showModal} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>
                Ajouter testeur
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <InputGroup className="mb-3">
                <InputGroup.Text>
                    <Search size={18} />
                </InputGroup.Text>
                <Form.Control
                    placeholder="Rechercher par nom, email ou téléphone..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </InputGroup>
            <div className="max-h-[50vh] overflow-y-auto">
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Nom et prénom</th>
                            <th>Email</th>
                            <th>Téléphone</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredBeauticians.length > 0 && filteredBeauticians.map((item, index) => (
                            <tr key={index}>
                                <td>{item.id}</td>
                                <td>{item.first_name}{' '}{item.last_name}</td>
                                <td>{item.email}</td>
                                <td>{item.phone}</td>
                                <td><button className="px-3 py-1 bg-blue-500 rounded text-white hover:bg-blue-600" onClick={() => handleAddTesteur(item.user_id)}>Ajouter</button></td>
                            </tr>
                        ))}

                        {filteredBeauticians.length === 0 && <tr><td colSpan={5} className="text-center">Aucun résultat trouvé!</td></tr>}
                    </tbody>
                </Table>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Fermer
            </Button>
        </Modal.Footer>
    </Modal>
}

export default AddTesteurModal;