import { useEffect, useState } from 'react';
import tarif1 from '../../../../assets/tarifs/1.png';
import tarif2 from '../../../../assets/tarifs/2.png';
import tarif3 from '../../../../assets/tarifs/3.png';
import tarif4 from '../../../../assets/tarifs/4.png';
import tarif5 from '../../../../assets/tarifs/5.png';
import AbonnementCard from '../../../../pages/tarifs/AbonnementCard';
import { showWarningMessage } from "../../../../utils/messageHelper";
import { BASE_URL } from '../../../../utils/constants';
import abonnementApi from '../../../../api/abonnement.api';


function SubscriptionToggle({ isAnnual, setIsAnnual }) {

    return (
        <div
            className="relative flex items-center bg-[#bc193b] rounded-lg p-2 cursor-pointer w-56"
        // onClick={() => setIsAnnual(!isAnnual)}
        >
            {/* Fond blanc qui se déplace */}
            <div
                className={`absolute top-1 left-1 w-[48%] h-[80%] bg-white rounded shadow-md transition-all duration-300 
            ${isAnnual ? "translate-x-full" : "translate-x-0"}`}
            />

            {/* Texte Mensuelle */}
            <div onClick={() => setIsAnnual(false)} className={`relative cursor-pointer flex-1 text-center text-sm font-semibold transition-colors duration-300 
            ${!isAnnual ? "text-black" : "text-white"}`}>
                Mensuelle
            </div>

            {/* Texte Annuelle */}
            <div onClick={() => setIsAnnual(true)} className={`relative cursor-pointer flex-1 text-center text-sm font-semibold transition-colors duration-300 
            ${isAnnual ? "text-black" : "text-white"}`}>
                Annuelle
            </div>
        </div>
    );
}

const EmptyAbonnement = ({ profileData }) => {

    const [isAnnual, setIsAnnual] = useState(false);


    const [alreadySubscribed, setAlreadySubscribed] = useState(false);


    useEffect(() => {
        const fetchSubscription = async () => {
            const res = await abonnementApi.getAbonnementByUserId(localStorage.getItem("token"), profileData.user_id);
            if (res && res.length > 0) {
                setAlreadySubscribed(true);
            }
        }

        fetchSubscription();
    }, []);


    const offres = [
        {
            title: "Formule Classic",
            price: "49",
            image: tarif1,
            features: [
                "Agenda en ligne",
                "Interface personnalisée",
                "Page professionnelle",
                "Moyen de paiement en ligne ou en espèces",
                "Possibilité d'acomptes",
                "Statistiques",
                "Priorité pour les formations"
            ],
            onClick: () => {
                // if (profileData.type_beautician === "indépendant") {
                //     window.location.href = "/beautician-dashboard/settings?active=abonnement&offre=classique";
                // } else {
                //     showWarningMessage("Vous ne pouvez pas souscrire à cette offre car vous êtes un établissement, choisissez plutôt la formule équipe");
                // }
                window.location.href = "/beautician-dashboard/settings?active=abonnement&show_form=true";
            },
            is_dispo: true,
            m_auto: false,
            addButton: true,
            buttonText: !alreadySubscribed ? "Commencer l'essai de 10 jours" : null,
            second_button: true
        },
        {
            title: "Formule équipe",
            price: "59",
            padd: "Inclus 3 membres de l'équipe",
            image: tarif4,
            description: "Accedez à la formule accompagnement avec une période d'essai ",
            features: [
                "Agenda de trois membres de votre équipe, afin d'optimiser au mieux les rendez-vous",
                "+5 € par utilisateur supplémentaire",
            ],
            onClick: () => {
                // if (profileData.type_beautician === "établissement") {
                //     window.location.href = "/beautician-dashboard/settings?active=abonnement&offre=équipe";
                // }
                // else {
                //     showWarningMessage("Vous ne pouvez pas souscrire à cette offre car vous êtes un indépendant, choisissez plutôt la formule classique");
                // }
                window.location.href = "https://calendly.com/contact-planibeauty/inscription-site-planibeauty";
            },
            is_dispo: true,
            m_auto: false,
            addButton: true,
            buttonText: !alreadySubscribed ? "Commencer l'essai de 10 jours" : null,
            // buttonText: "Commencer l'essai de 10 jours"
            second_button: true
        },
        {
            title: "Formule Accompagnement",
            price: "55",
            description: "Accedez à la formule classique, avec un accompagnement pour vous lancer et une periode d'essai",
            image: tarif2,
            features: [
                "Assister à des ateliers hebdomadaires ou mensuel(Communication, perfectionnement métier, gestion, administration",
                "Pack communication offert sur un mois",
            ],
            onClick: () => {
                window.location.href = "https://calendly.com/contact-planibeauty/inscription-site-planibeauty";
            },
            is_dispo: true,
            m_auto: false,
            addButton: true,
            buttonText: "Commencer l'essai de 10 jours",
            second_button: true
        },
        {
            title: "Formule Formation",
            price: "49",
            description: "Accedez à la formule accompagnement avec 6 mois offerts si souscription à une formation en ligne PlaniBeauty",
            image: tarif3,
            features: [
                "Suivi de vos nouvelles prestations par un référents",
                "Accompagnement continu",
                "Accès à la communauté des professionnels de la beauté",
            ],
            onClick: () => {
                window.location.href = "https://calendly.com/contact-planibeauty/inscription-site-planibeauty";
            },
            is_dispo: true,
            m_auto: false,
            addButton: true,
            buttonText: "Commencer l'essai de 10 jours",
            second_button: true
        },

        {
            title: "Formule Caisse",
            price: "00.0",
            image: tarif5,
            features: [
                "Formule Classic",
                "Outils caisse",
                "Priorité pour les formations pour votre équipe"
            ],
            onClick: () => {
                window.location.href = "https://calendly.com/contact-planibeauty/inscription-site-planibeauty";
            },
            is_dispo: true,
            m_auto: false,
            addButton: true,
            buttonText: "Rester informé"
        }
    ];

    const annual_offres = [
        {
            title: "Formule équipe",
            price: "550",
            padd: "Inclus 3 membres de l'équipe",
            image: tarif4,
            description: "Accedez à la formule accompagnement avec une période d'essai ",
            features: [
                "Agenda de trois membres de votre équipe, afin d'optimiser au mieux les rendez-vous",
                "+5 € par utilisateur supplémentaire",
            ],
            // onClick: () => {
            //     if(profileData.type_beautician === "établissement") {
            //     window.location.href = "/beautician-dashboard/settings?active=abonnement&offre=équipe";
            //     }
            //     else {
            //         showWarningMessage("Vous ne pouvez pas souscrire à cette offre car vous êtes un indépendant, choisissez plutôt la formule classique");
            //     }
            // },
            onClick: () => {
                window.location.href = "https://calendly.com/contact-planibeauty/inscription-site-planibeauty";
            },
            is_dispo: true,
            m_auto: false,
            addButton: true,
            buttonText: "Commencer l'essai de 10 jours",
            second_button: true
        },
        {
            title: "Formule Accompagnement",
            price: "470",
            description: "Accedez à la formule classique, avec un accompagnement pour vous lancer et une periode d'essai",
            image: tarif2,
            features: [
                "Assister à des ateliers hebdomadaires ou mensuel(Communication, perfectionnement métier, gestion, administration",
                "Pack communication offert sur un mois",
            ],
            onClick: () => {
                window.location.href = "https://calendly.com/contact-planibeauty/inscription-site-planibeauty";
            },
            is_dispo: true,
            m_auto: false,
            addButton: true,
            buttonText: "Commencer l'essai de 10 jours",
            second_button: true
        },
        {
            title: "Formule Formation",
            price: "390",
            description: "Accedez à la formule accompagnement avec 6 mois offerts si souscription à une formation en ligne PlaniBeauty",
            image: tarif3,
            features: [
                "Suivi de vos nouvelles prestations par un référents",
                "Accompagnement continu",
                "Accès à la communauté des professionnels de la beauté",
            ],
            onClick: () => {
                window.location.href = "https://calendly.com/contact-planibeauty/inscription-site-planibeauty";
            },
            is_dispo: true,
            m_auto: false,
            addButton: true,
            buttonText: "Commencer l'essai de 10 jours",
            second_button: true
        },

        {
            title: "Formule Caisse",
            price: "00.0",
            image: tarif5,
            features: [
                "Formule Classic",
                "Outils caisse",
                "Priorité pour les formations pour votre équipe"
            ],
            onClick: () => {
                window.location.href = "https://calendly.com/contact-planibeauty/inscription-site-planibeauty";
            },
            is_dispo: true,
            m_auto: false,
            addButton: true,
            buttonText: "Rester informé"
        }
    ];

    return (
        <div className="">

            {/* Conteneur vidéo locale */}
            <div className="w-full max-w-3xl mx-auto">
                <div className="relative">
                    <iframe
                        className="w-full min-h-[30vh] md:min-h-[60vh] rounded-lg shadow-lg"
                        src="https://www.youtube.com/embed/IJSXNN3qFmw?origin=www.planibeauty.fr"
                        title="Vidéo YouTube"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        referrerPolicy="strict-origin-when-cross-origin"
                    ></iframe>
                </div>
            </div>
            <div className="mt-5">

                <h3 className="font-bold">Choisissez la formule adapté à vos besoins</h3>
                <div className="flex justify-end my-3">
                    <SubscriptionToggle isAnnual={isAnnual} setIsAnnual={setIsAnnual} />
                </div>

                {/* Carte d'abonnement */}
                <div className="flex flex-wrap gap-3 justify-center">

                    {
                        !isAnnual && offres.map((offre, index) => (
                            <AbonnementCard key={index} sm={true} {...offre} />
                        ))
                    }

                    {
                        isAnnual && annual_offres.map((offre, index) => (
                            <AbonnementCard key={index} sm={true} {...offre} />
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default EmptyAbonnement;
