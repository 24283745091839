import React from 'react';
import './ActionButtons.css';

function ActionButtons({ onStatusChange, selectedLeads }) {
    const isDisabled = selectedLeads.length === 0;

    return (
        <div className='space-y-4'>
            <div className="action-buttons">
                <button
                    className="l-btn btn-attribuer"
                    disabled={isDisabled}
                >
                    Attribuer
                </button>

                <button
                    className="l-btn btn-desattribuer"
                    disabled={isDisabled}
                >
                    Désattribuer
                </button>

                <button
                    className="l-btn btn-changer-statut"
                    disabled={isDisabled}
                    onClick={() => onStatusChange('Nouveau statut')}
                >
                    Changer de statut
                </button>

                <button
                    className="l-btn btn-supprimer"
                    disabled={isDisabled}
                >
                    Supprimer
                </button>

                <button
                    className="l-btn btn-supprimer-commentaires"
                    disabled={isDisabled}
                >
                    Supprimer les commentaires
                </button>

            </div>
            <div>
                <div className="flex gap-3">
                    <button className="l-btn btn-creer">
                        Créer une fiche
                    </button>

                    <button className="l-btn btn-importer">
                        Importer les leads
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ActionButtons;