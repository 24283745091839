import { API_URL } from "../utils/constants";

const affiiliationPaymentApi = {

    getAllByBeauticianId: async (token, beautician_id) => {
        try {
            const response = await fetch(`${API_URL}/affiliation-payment/get-all-by-beautician-id/${beautician_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            return response.json();
        } catch (error) {
            console.error('Erreur dans getAllByBeauticianId:', error);
            throw error;
        }
    },

    create: async (token, data) => {
        try {
            const response = await fetch(`${API_URL}/affiliation-payment/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de l\'enregistrement du paiement');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans create affiliation payment :', error);
            throw error;
        }
    }
}


export default affiiliationPaymentApi;