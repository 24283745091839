import React from 'react';
import { User, Building, Users } from 'lucide-react';
import './TileSelector.css';
import salonImage from '../../assets/images/établissement.png';
import beautyImage from '../../assets/images/indépendant.png';
import clientImage from '../../assets/images/client.png';
import recruteurImage from '../../assets/images/recruteur.png';
import logo from '../../assets/images/logoRouge.png';


const TileSelector = ({ onSelect }) => {
  const [hoveredTile, setHoveredTile] = React.useState(null);

  const options = [
    {
      title: "Je suis indépendant",
      description: "Je propose des prestations beautés et je souhaite être répertorié dans l'annuaire planibeauty",
      icon: <User size={24} />,
      type: 'indépendant',
      image: beautyImage
    },
    {
      title: "Je suis un établissement",
      description: "Je suis un établissement proposons des prestations beautés et je souhaite être répertorié dans l'annuaire planibeauty",
      icon: <Building size={24} />,
      type: 'établissement',
      image: salonImage
    },
    {
      title: "Je suis un recruteur",
      description: "Trouvez facilement les meilleurs talents pour votre entreprise ! Publiez vos offres, gérez les candidatures et recrutez en toute simplicité.",
      icon: <Users size={24} />,
      type: 'recruteur',
      image: recruteurImage
    },
    {
      title: "Je suis un client",
      description: "Je suis à la recherche des prestataires beauté, je crée un compte pour avoir accès facilement aux prestataires et à mes rdv",
      icon: <Users size={24} />,
      type: 'client',
      image: clientImage
    },
    // {
    //   title: "Je suis une conseillère beauté",
    //   description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea, modi temporibus neque natus animi sit?",
    //   icon: <Users size={24} />,
    //   type: 'tele_conseiller',
    //   image: beautyImage
    // },
  ];

  return (
    <div className="tiles-container">
      <div className="tiles-header">
        <div className='flex gap-4 items-center justify-center'>
        <h1>Rejoignez</h1>
        <img src={logo} alt="Logo planibeauty" className='w-40' />
        </div>
        <h3>Choisissez votre profil</h3>
        <p>
          Professionnels, une fois inscrits nos équipes prendront le soin de vous contacter. <br />
          Vous pourrez sélectionner via votre interface la date du rendez-vous.
        </p>
      </div>

      <div className="tiles-grid">
        {options.map((option) => (
          <div
            key={option.type}
            className={`tile ${hoveredTile === option.type ? 'hovered' : ''}`}
            onClick={() => onSelect(option.type)}
            onMouseEnter={() => setHoveredTile(option.type)}
            onMouseLeave={() => setHoveredTile(null)}
          >
            <div className="tile-icon">
              {option.icon}
            </div>
            <div className="tile-image">
              <img 
                src={option.image} 
                alt={option.title}
                onError={(e) => {
                  e.target.src = `https://via.placeholder.com/300x200?text=${option.title}`;
                }}
              />
            </div>
            <div className="tile-content">
              <h3>{option.title}</h3>
              <p>{option.description}</p>
              {["établissement", "indépendant"].includes(option.type) && (
                <p className='mt-3'><b>Benéficiez d'un essai gratuit de 10 jours</b></p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TileSelector;