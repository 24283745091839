import React, { createContext, useState } from 'react'

export const TeleconseillerSidebarContext = createContext({
    isSidebarOpen: true,
    setIsSidebarOpen: () => {}
})

const TeleConseillerSidebarContextProvider = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true)

    const value = {
        isSidebarOpen,
        setIsSidebarOpen
    }

    return (
        <TeleconseillerSidebarContext.Provider value={value}>
            {children}
        </TeleconseillerSidebarContext.Provider>
    )
}

export default TeleConseillerSidebarContextProvider
