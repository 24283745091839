import React, { useContext, useEffect, useState } from 'react';
import { MapPin, Calendar, Gift, Plus, Star, MessageSquare, ChevronRight, Check } from 'lucide-react';
import { BASE_URL } from '../../utils/constants';
import defaultProfile from '../../assets/images/default-profile.png';
import BeauticianGallery from './BeauticianGallery'
import { formatTime } from '../../utils/functions';
import './BeauticianInfo.css';
import ratingApi from '../../api/rating.api';
import AddReview from './AddReview';
import { HeaderContext } from '../../contexts/HeaderContext';
import { AppContext } from '../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { conversationStarter } from '../../utils/conversation';
import { motion } from 'motion/react';
import Swal from 'sweetalert2';
import userApi from '../../api/user.api';

const openReview = (openReviewModal) => {
  openReviewModal(true)
}

const getCategoryRating = (categoriesArray = [], categorieName) => {
  // console.log('Categories Array:', categoriesArray);
  // console.log('Looking for category:', categorieName);
  const found = categoriesArray.filter(r => r.category === categorieName)[0];
  // console.log('Found rating:', found);
  return found?.average_rating || "0.0"
}

const CustomAccordion = ({ prestations }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    window.location.href = `#${prestations[index].category_name.toLowerCase().replaceAll(' ', '_')}`;
    //setOpenIndex(openIndex === index ? null : index); // Ferme si déjà ouvert
  };

  return (
    <div className="info-specialties">
      <h2 className="text-2xl font-bold mb-2">Spécialités</h2>
      <div className="">
        {prestations.length > 0 ? prestations.map((prestation, index) => (
          <AccordionItem
            key={index}
            prestation={prestation}
            isOpen={openIndex === index}
            onClick={() => toggleAccordion(index)}
          />
        )) : <p>Pas de prestations enregistrées</p>}
      </div>
    </div>
  );
};

const AccordionItem = ({ prestation, isOpen, onClick }) => {
  return (
    // <div className="relative">
    <div
      onClick={onClick}
      className={`w-full text-left px-1 py-1 bg-gray-50 border-b border-gray-200 hover:bg-gray-200 cursor-pointer`}
    >
      <div className="flex justify-between items-center cursor-pointer">
        <span className="font-medium cursor-pointer">{prestation.category_name}</span>
        <span><ChevronRight size={16} /></span>
      </div>
    </div>

    // </div>
  );
};

const BeauticianInfo = ({ beautician, prestations = [], shedule = [], openReviewModal, showAddReviewPanel, setShowAddReviewPanel, setReloadTrigger }) => {
  const { setIsLoginModalOpen } = useContext(HeaderContext);
  const [isInFavorite, setIsInFavorite] = useState(false);
  const [ratingByCategory, setRatingByCategory] = useState([]);
  const { connectedUser } = useContext(AppContext);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(() => window.innerWidth <= 768);
  const [activeNav, setActiveNav] = useState('specialites');
  
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("userId");

  const handleAddCommentModal = (e) => {
    e.stopPropagation()
    setShowAddReviewPanel(true)
  }

  const handleAddToFavorite = () => {
    if(!token && !user_id) {
      setIsLoginModalOpen(true);

      return;
    }

    Swal.fire({
      title: "Préférences",
      html: `${isInFavorite ? "Retirer" : "Ajouter"} <strong>${beautician.business_name}</strong> ${isInFavorite ? "des" : "aux"} préférences ?`,
      icon: "question",
      showConfirmButton: true,
      confirmButtonText: `Oui, ${isInFavorite ? "retirer":"ajouter"}`,
      confirmButtonColor: "#bd193b",
      showCancelButton: true,
      cancelButtonText: "Annuler"
    })
    .then(async(res) => {
      if(res.isConfirmed) {
        const response = isInFavorite ?
          await userApi.deleteUserPreferences(token, user_id, beautician.id):
          await userApi.createUserPreferences(token, user_id, beautician.id);

        if(response.success) {
          Swal.fire("Préférences", `<strong>${beautician.business_name}</strong> est ${isInFavorite ? "retiré des" : "ajouté aux"} préférences!`, "success");
          setReloadTrigger(prev => !prev)
        }
        else {
          Swal.fire("Préférences", `Une erreur est survénue: ${response.message}`, "error");
        }
      }
    })
    .catch(err => {
      Swal.fire("Préférences", `Une erreur est survénue: ${err.message}`, "error");
    })
  }

  const startConversation = () => {
    conversationStarter.startBeauticianConversation(beautician, connectedUser, navigate);
  };

  const getImageUrl = (profilePhoto) => {
    if (!profilePhoto) return defaultProfile;
    try {
      return `${BASE_URL}/images/beauticians/${encodeURIComponent(profilePhoto)}`;
    } catch (error) {
      console.error('Error with image path:', error);
      return defaultProfile;
    }
  };

  useEffect(() => {
    const getBeauticianRatingCategory = async () => {
      try {
        // console.log('Fetching ratings for beautician:', beautician?.id);
        const response = await ratingApi.getRatingByBeauticianId(beautician?.id);
        // console.log('Complete API Response:', response);

        if (response && response.data) {
          // console.log('Setting rating data:', response.data);
          setRatingByCategory(response.data);
        } else {
          // console.warn('No data in API response');
          setRatingByCategory([]);
        }
      }
      catch (error) {
        console.error('Error fetching ratings:', error);
        setRatingByCategory([]);
      }
    };

    if (beautician && beautician.id) {
      getBeauticianRatingCategory();
    }
  }, [beautician?.id]);

  useEffect(() => {
    const getFavoritesList = async() => {
      if(token && user_id) {
        try {
          const response = await userApi.getUserPreferences(token, user_id)

          if(response.success) {
            setIsInFavorite(response.data.map((pref) => pref.beautician_id).includes(beautician.id))
          }
        }
        catch(err) {
          console.log(err)
        }
      }
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    getFavoritesList()

    window.addEventListener("resize", handleResize);

    // Nettoyage pour éviter les fuites de mémoire
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const tabs = [
    { id: "specialites", label: "Spécialités" },
    { id: "disponibilite", label: "Disponibilités" },
    { id: "notes", label: "Notes/avis" },
    { id: "photos", label: "Photos" }
  ];

  if (!beautician) {
    return <div>Loading...</div>;
  }

  return (
    <div className="info-wrapper">
      <div className="info-layout">
        {/* Profile column with photo and buttons */}
        <div className="info-profile">
          <div className="info-photo-container">
            <img
              src={getImageUrl(beautician.profile_photo)}
              alt={beautician.business_name || beautician.first_name}
            />
          </div>
          <div className="info-actions">
            <div className="info-actions-top">
              <button className="info-gift-btn" onClick={handleAddToFavorite}>
                {isInFavorite ? <Check size={18} /> : <Star size={18} />}
                Favoris
              </button>
              <button className="info-booking-btn" onClick={() => window.location.href = "#prestation"}>
                <Calendar size={18} />
                Prendre RDV
              </button>
            </div>
            {connectedUser && connectedUser.id !== beautician.user_id && (
              <button
                className="info-message-btn"
                onClick={startConversation}
              >
                <MessageSquare size={18} />
                Contacter
              </button>
            )}
          </div>
        </div>

        {/* Rest of the existing layout remains unchanged */}
        <div className="info-details">
          <h1>{beautician.business_name || `${beautician.first_name} ${beautician.last_name}`}</h1>
          <div className="info-location">
            <MapPin size={18} />
            <span>{beautician.city}</span>
          </div>
          {!isMobile &&
            <div className="info-specialties">
              <CustomAccordion prestations={prestations} />
            </div>
          }
          <div>
            <a href="#about" className="text-second underline cursor-pointer">En savoir plus</a>
          </div>
        </div>

        {isMobile && <div>
          {/* Navigation des tabs avec animation */}
          <div className="flex justify-between items-center gap-2 border-b-2 border-gray-300 relative">
            {tabs.map((tab) => (
              <div
                key={tab.id}
                onClick={() => setActiveNav(tab.id)}
                className={`relative text-sm pb-2 font-semibold cursor-pointer transition-all px-1 ${activeNav === tab.id ? "text-[#870022]" : "text-gray-600"
                  }`}
              >
                {tab.label}
                {activeNav === tab.id && (
                  <motion.div
                    layoutId="underline"
                    className="absolute bottom-0 left-0 w-full h-1 bg-[#870022] rounded-full"
                  />
                )}
              </div>
            ))}
          </div>

          {/* Contenu des tabs avec animation */}
          <motion.div
            key={activeNav}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
            className="mt-4"
          >
            {activeNav === "specialites" && <CustomAccordion prestations={prestations} />}

            {activeNav === "disponibilite" && (
              <div className="info-schedule">
                <h2 className="text-lg font-semibold mb-2">Disponibilités</h2>
                <div className="grid gap-2">
                  {shedule.map((element, index) => (
                    <div key={index} className="flex justify-between items-center bg-gray-100 p-2 rounded-lg">
                      <span className="font-medium">{element.day.slice(0, 3)}</span>
                      <span className="text-gray-700">
                        {element.start_time !== "-" && element.end_time !== "-"
                          ? `${formatTime(element.start_time)} - ${formatTime(element.end_time)}`
                          : "Fermé"}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {activeNav === "notes" && (
              <div className="info-rating cursor-pointer">
                <div className="info-rating-header flex items-center justify-between">
                  <h3 className="text-lg font-semibold">Note globale</h3>
                  <button
                    className="text-[#870022] flex items-center gap-1 font-semibold underline"
                    onClick={() => openReview(openReviewModal)}
                  >
                    Voir les avis ({beautician.comment_count})
                    {connectedUser?.user_type === "client" && (
                      <span onClick={handleAddCommentModal}>
                        <Plus size="18px" />
                      </span>
                    )}
                  </button>
                </div>

                <div className="info-rating-container mt-3">
                  <div className="text-2xl font-bold text-[#870022]">{beautician.average_rating || "0.0"}</div>

                  <div className="mt-2 space-y-2">
                    {[
                      { label: "Accueil", key: "Welcome" },
                      { label: "Propreté", key: "Cleanliness" },
                      { label: "Cadre & Ambiance", key: "Ambiance" },
                      { label: "Qualité de la prestation", key: "Service Quality" }
                    ].map((item) => (
                      <div key={item.key} className="flex justify-between items-center">
                        <span>{item.label}</span>
                        <span className="flex items-center gap-1 text-[#870022] font-medium">
                          {getCategoryRating(ratingByCategory, item.key)}
                          <Star size={16} fill="#870022" />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {activeNav === "photos" && <BeauticianGallery beautician_id={beautician.id} />}
          </motion.div>
        </div>
        }

        {!isMobile && <div className="info-schedule">
          <h2>Disponibilités</h2>
          <div className="info-schedule-grid">
            {shedule.map((element, index) => (
              <div key={index} className="info-schedule-row">
                <span className="info-day">{element.day.split('')[0]}</span>
                <span className="info-status">{(element.start_time != '-' && element.end_time != "-") ? `${formatTime(element.start_time)} - ${formatTime(element.end_time)}` : 'Fermé'}</span>
              </div>
            ))}
          </div>
        </div>}

        {/* Rating Section */}
        {!isMobile &&
          <div className="info-rating cursor-pointer">
            <div className="info-rating-header flex items-center justify-between">
              <div className="info-rating-title">Note globale</div>

              <button className="text-second flex items-center gap-1 font-semibold underline" onClick={() => openReview(openReviewModal)}>
                Voir les avis ({beautician.comment_count})
                {(connectedUser && connectedUser?.user_type === 'client') &&
                  <span
                    className="cursor-pointer"
                    onClick={handleAddCommentModal}
                  >
                    <Plus size="18px" />
                  </span>}
              </button>
            </div>

            <div className="info-rating-container">
              <div className="info-rating-global">
                <div className="info-rating-score">{beautician.average_rating || "0.0"}</div>
              </div>

              <div className="info-rating-list">
                <div className="info-rating-item">
                  <span>Accueil</span>
                  <span className="flex items-center gap-1">
                    {getCategoryRating(ratingByCategory, "Welcome")}
                    <Star size={16} fill="#000" />
                  </span>
                </div>
                <div className="info-rating-item">
                  <span>Propreté</span>
                  <span className="flex items-center gap-1">
                    {getCategoryRating(ratingByCategory, "Cleanliness")}
                    <Star size={16} fill="#000" />
                  </span>
                </div>
                <div className="info-rating-item">
                  <span>Cadre & Ambiance</span>
                  <span className="flex items-center gap-1">
                    {getCategoryRating(ratingByCategory, "Ambiance")}
                    <Star size={16} fill="#000" />
                  </span>
                </div>
                <div className="info-rating-item">
                  <span>Qualité de la prestation</span>
                  <span className="flex items-center gap-1">
                    {getCategoryRating(ratingByCategory, "Service Quality")}
                    <Star size={16} fill="#000" />
                  </span>
                </div>
                {/* <div className="info-rating-footer">
              <span className="count font-bold">{`${beautician.comment_count}`}</span>
              <span>{`client${beautician.comment_count > 1 ? 's' : ''} ${beautician.comment_count > 1 ? 'ont' : 'a'} partagé ${beautician.comment_count > 1 ? "leur" : "son"} avis`}</span>
            </div> */}
              </div>
            </div>
          </div>
        }
      </div>

      {!isMobile &&
        <BeauticianGallery beautician_id={beautician.id} />
      }

      <AddReview
        beautician_id={beautician.id}
        showAddReviewPanel={showAddReviewPanel}
        setShowAddReviewPanel={setShowAddReviewPanel}
        setReloadTrigger={setReloadTrigger}
      />
    </div>
  );
};

export default BeauticianInfo;