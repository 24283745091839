import { Table } from "react-bootstrap";
import testeurApi from "../../../api/testeur.api";
import MySpinner from "../../../components/loader/Spinner";
import React, { useEffect, useRef, useState } from "react";
import { FaEllipsisVertical, FaEye, FaPlus, FaX } from "react-icons/fa6";
import AddTesteurModal from "./AddTesteurModal";
import Swal from "sweetalert2";
import { showSuccessMessage } from "../../../utils/messageHelper";
import ShowAffiliationInfo from "./ShowAffiliationInfo";
import styled from "styled-components";


const MyDropDown = ({ showAffiliationInfo, removeTesteur }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState("bottom"); // "top" ou "bottom"
    const dropdownRef = useRef(null);

    const toggleDropdown = () => setIsOpen((prev) => !prev);

    const callAction = (action) => {
        setIsOpen(false);
        action();
    };

    // Fermer le dropdown en cliquant en dehors
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Ajouter le gestionnaire d'événements
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Nettoyer l'événement
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Ajuster la position du menu en fonction de l'espace disponible
    useEffect(() => {
        if (isOpen && dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            // Si le menu dépasse vers le bas, afficher vers le haut
            if (rect.bottom > (windowHeight - 250)) {
                setDropdownPosition("top");
            } else {
                setDropdownPosition("bottom");
            }
        }
    }, [isOpen]);

    return (
        <div className="text-center relative" ref={dropdownRef}>
            {/* Bouton de déclenchement */}
            <button
                className="p-2 rounded-full hover:bg-gray-200"
                onClick={toggleDropdown}
                aria-expanded={isOpen}
                aria-haspopup="true"
            >
                <FaEllipsisVertical />
            </button>

            {/* Menu déroulant */}
            {isOpen && (
                <div
                    className={`absolute w-96 p-2 bg-gray-100 shadow-lg border border-gray-300 rounded-md ${dropdownPosition === "top" ? "bottom-10" : "top-10"
                        } right-2`}
                    style={{ zIndex: "1000" }}
                >

                    <button
                        onClick={() => callAction(removeTesteur)}
                        className="flex items-center bg-red-500 text-white gap-2 px-4 py-2 hover:bg-red-600 w-full text-left"
                    >
                        <FaX /> Retirer des testeurs
                    </button>

                    <button
                        onClick={() => callAction(showAffiliationInfo)}
                        className="bg-green-500 flex items-center text-white gap-2 px-4 py-2 hover:bg-green-600 w-full text-left"
                    >
                        <FaEye /> Détails revenus affiliés
                    </button>
                </div>
            )}
        </div>
    );
}

const StyledCellWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

const AllTesteur = () => {

    const [loading, setLoading] = useState(true);
    const [testeurs, setTesteurs] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [reloadTrigger, setReloadTrigger] = useState(false);
    const [selectedBeautician, setSelectedBeautician] = useState(null);
    const [showAffiliationInfo, setShowAffiliationInfo] = useState(false);

    const fetchTesteurs = async () => {
        setLoading(true);
        const res = await testeurApi.getAll(localStorage.getItem('token'));
        setTesteurs(res.data || []);
        setLoading(false);
    }

    useEffect(() => {
        fetchTesteurs();
    }, []);

    useEffect(() => {
        fetchTesteurs();
    }, [reloadTrigger]);

    const handleDeleteTesteur = async (id) => {
        Swal.fire({
            icon: "question",
            title: "Voulez vous supprimer le testeur?",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Oui, confirmer",
            cancelButtonText: "Annuler",
            confirmButtonColor: "#bd193b"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await testeurApi.delete(localStorage.getItem('token'), id);
                showSuccessMessage("Testeur supprimé avec succès!");
                setReloadTrigger(prev => !prev);
            }
        });
    }

    return (
        <main className="min-h-[72vh]">
            {loading ? (
                <div className="w-full">
                    <MySpinner height={"60vh"} />
                </div>
            ) : (
                <div>
                    <div className="p-2 md:p-6 bg-gray-100 rounded-lg min-h-[87vh]">
                        <div className="flex justify-between items-center gap-2">
                            <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-6">Liste des testeurs</h2>
                            <button
                                onClick={() => setShowModal(true)}
                                className="px-2 md:px-4 py-2 text-sm md:text-base text-white bg-[#bd193b] flex items-center gap-2 rounded shadow"
                            >
                                <FaPlus />
                                Ajouter un testeur
                            </button>
                        </div>
                        <div className="h-full w-full overflow-y-auto">

                        <Table striped bordered hover responsive className="h-full">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nom commercial</th>
                                    <th>Nom et prénom</th>
                                    <th>Inscription affiliation</th>
                                    <th>CA affiliation</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {testeurs.length > 0 && testeurs.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.business_name}</td>
                                        <td>{item.first_name}{' '}{item.last_name}</td>
                                        <td>{item.affiliation_count}</td>
                                        <td>{Number.parseFloat(item.total_affiliation_revenue) * 0.3} €</td>
                                        <td className="relative">
                                            <div className=" w-full relative flex justify-center items-center">
                                                <StyledCellWrapper>

                                                    <MyDropDown removeTesteur={() => handleDeleteTesteur(item.id)} showAffiliationInfo={() => {
                                                        setSelectedBeautician(item);
                                                        setShowAffiliationInfo(true);
                                                    }} />
                                                </StyledCellWrapper>
                                            </div>
                                        </td>
                                    </tr>
                                ))}

                                {testeurs.length === 0 && <tr><td colSpan={5} className="text-center">Aucun enregistrement à afficher!</td></tr>}
                            </tbody>
                        </Table>
                        </div>

                    </div>
                    {/* Modal pour créer ou modifier une offre */}
                    {showModal &&
                        <AddTesteurModal showModal={showModal} setShowModal={setShowModal} testeurs={testeurs} setReloadTrigger={setReloadTrigger} />}
                    {showAffiliationInfo && <ShowAffiliationInfo setShowAffiliationInfo={setShowAffiliationInfo} selectedBeautician={selectedBeautician} />}
                </div>
            )}
        </main>
    );
}

export default AllTesteur;