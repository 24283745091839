import React from "react";
import './AbonnementCard.css';

const AbonnementCard = ({ title, price, padd, description, image, features, onClick, is_dispo, m_auto = true, addButton = false, buttonText, sm = false, second_button = false,
    code_promo_button = false, onCodePromoClick = () => {}, achat_normal_button = false, onAchatNormalClick = () => {}, free_trial_button = false, onFreeTrialClick = () => {},
}) => {

    const handleClick = () => {
        if(addButton || code_promo_button || achat_normal_button || free_trial_button) {
            // ne rien faire
        } else {
            onClick();
        }
    }

    const onSecondButtonClick = () => {
        window.open('https://calendly.com/contact-planibeauty/inscription-site-planibeauty');
    }

    return (
        <div className={`tarif-card ${is_dispo ? 'cursor-pointer' : 'disabled cursor-not-allowed'} ${!m_auto && '!mx-0'} ${sm ? '!w-56 !p-2' : ''}`} onClick={handleClick}>
            {!is_dispo && <div className="watermark">**Bientôt disponible**</div>}
            <h2 className={`${sm ? '!text-base !m-0': ''}`}>{title}</h2>
            <div className={`price ${sm ? '!p-0': ''}`}>
                <span className={`amount ${sm ? '!text-base': ''}`}>{price} €</span>
                <span className="period">/mois</span>
                <p className="priceaddon">{padd}</p>
            </div>
            <p className={`description ${sm ? '!pb-0 my-1': ''}`}>
                {description}
            </p>
            <ul className={`features-list ${sm ? '!p-0': ''}`}>
                {features.map((feature, index) => <li key={index}>{feature}</li>)}
            </ul>
            <div className="tarif-illustration">
                <img
                    src={image}
                    alt="Interface agenda"
                />
            </div>
            {addButton && 
            <button onClick={onClick} className={`bg-[#bd193b] p-2 w-full text-center text-white rounded mt-3 transition-all delay-100 hover:bg-[#d83556] hover:scale-105 ${sm ? 'text-sm' : ''}`}>{buttonText ?? 'Souscrire'}</button>
            }
            {second_button &&
            <button onClick={onSecondButtonClick} className={`bg-[#bd193b] p-2 w-full text-center text-white rounded mt-3 transition-all delay-100 hover:bg-[#d83556] hover:scale-105 ${sm ? 'text-sm' : ''}`}>Prendre rendez-vous</button>
            }
            {free_trial_button &&
            <button onClick={onFreeTrialClick} className={`bg-[#bd193b] p-2 w-full text-center text-white rounded mt-3 transition-all delay-100 hover:bg-[#d83556] hover:scale-105 ${sm ? 'text-sm' : ''}`}>Essai gratuit de 10 jours</button>
            }
            {code_promo_button &&
            <button onClick={onCodePromoClick} className={`bg-[#bd193b] p-2 w-full text-center text-white rounded mt-3 transition-all delay-100 hover:bg-[#d83556] hover:scale-105 ${sm ? 'text-sm' : ''}`}>Utiliser un code promo</button>
            }
            {achat_normal_button &&
            <button onClick={onAchatNormalClick} className={`bg-[#bd193b] p-2 w-full text-center text-white rounded mt-3 transition-all delay-100 hover:bg-[#d83556] hover:scale-105 ${sm ? 'text-sm' : ''}`}>Acheter</button>
            }
        </div>);
}

export default AbonnementCard;