import React, { useEffect, useState, useRef } from "react"

import "./LandingCom.css"

// import { BASE_URL } from "../../utils/constants"

import { motion } from "motion/react"
import { ArrowUpRight, Check, Heart, Star, ThumbsUp } from "lucide-react"

import Logo from "../../assets/images/logoBlanc.png"
import page1Cover from "../../assets/images/landing/page-1.jpg"
import page1Frame from "../../assets/images/landing/iphone frame.png"
import agendaCover from "../../assets/images/landing/agenda.png"
import videoCover from "../../assets/images/landing/video.png"
import dashboardCover from "../../assets/images/landing/dashboard.png"
// import GoogleTagManager from "../../components/GoogleTagManager"
const reviewCover = new URL("../../assets/images/landing/reviews.jpg", import.meta.url).href

const Page1 = () => {
    const listes = [
        { index: "Agenda intelligent", content: "Optimisez votre emploi du temps" },
        { index: "Rappels automatiques", content: "Diminuez les no-shows" },
        { index: "Outils marketing", content: "Fidélisez et attirez plus de clients" },
        { index: "Communauté & entraide", content: "Rejoignez un réseau d’entrepreneurs engagés du secteur de la beauté" },
        { content: "Et bien plus !" }
    ]

    return (
        <section className="page-1">
            {/* Pour afficher l'image */}
            <div className="col-span-1 flex items-center justify-center">
                <motion.aside
                    className="w-full hidden md:block h-4/6 relative"
                    initial={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.5 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: false, amount: 0.25 }}
                >
                    {/* Image de fond */}
                    <img
                        src={page1Frame}
                        className="absolute left-[50%] top-[50%] h-full -translate-x-[25%] -translate-y-[50%]"
                    />

                    {/* Image au-dessus */}
                    <img
                        src={page1Cover}
                        alt="Couverture de planibeauty"
                        className="absolute left-[50%] top-[50%] h-[94%] -translate-x-[21.5%] -translate-y-[50.5%] rounded-3xl"
                    />
                </motion.aside>

            </div>

            {/* Pour les autres éléments du premier page */}
            <motion.div
                className="page-1-hero"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1}}
                transition={{ duration: 1 }}
                viewport={{ once: false, amount: .25 }}
            >
                <aside className="size-36">
                    <img src={Logo} alt="Logo Planibeauty" />
                </aside>

                <div className="w-full flex flex-col items-center gap-2 md:gap-6">
                    <aside className="flex flex-col items-center">
                        <h1 className="page-1-title">La plateforme tout-en-un</h1>
                        <h3 className="page-1-subtitle">pour développer votre activité beauté !</h3>
                    </aside>

                    <ul className="w-full md:w-[70%] flex flex-col text-white gap-1 md:gap-2 list-disc">
                        {listes.map((item, key) => (
                            <li key={`list-key-${key}`} className="flex items-start">
                                <Check className={`${item.index ? "text-white" : "text-transparent"} mt-1 mr-2`} />
                                <span>
                                    {item.index && <span className="font-semibold">{item.index} – </span>}
                                    {item.content}
                                </span>
                            </li>
                        ))}
                    </ul>

                    <aside className="flex flex-col md:flex-row items-start gap-2 md:gap-6">
                        <div className="flex flex-col gap-1">
                            {/* <a
                                href="https://calendly.com/contact-planibeauty/inscription-site-planibeauty?month=2025-03"
                                target="_blank"
                                className="px-4 py-3 text-sm md:text-xl text-center text-gray-800 bg-white rounded no-underline cursor-pointer"
                            >
                                Commencer mon essai gratuit
                            </a> */}
                            <a
                                href="https://www.planibeauty.fr/ajouter-etablissement"
                                target="_blank"
                                rel="noreferrer"
                                className="px-4 py-3 text-sm md:text-xl text-center text-gray-800 bg-white rounded no-underline cursor-pointer"
                            >
                                Commencer mon essai gratuit
                            </a>
                            <small className="page-1-button-text">Aucun engagement - Annulez à tout moment</small>
                        </div>

                        <a
                            href="https://calendly.com/contact-planibeauty/inscription-site-planibeauty"
                            target="_blank"
                            rel="noreferrer"
                            className="px-4 py-3 text-sm w-full md:w-auto md:text-xl text-center text-gray-800 bg-white rounded no-underline cursor-pointer"
                        >
                            Échanger avec une conseillère
                        </a>
                    </aside>
                </div>
            </motion.div>
        </section>
    )
}

const LearnMore = () => {
    const indicators = [
        { text: "d’annulations en moins en appliquant des frais pour no-show", value: 50 },
        { text: "de paiements sécurisés", value: 100 },
        { text: "de commission pour Planibeauty", value: 0 },
        { text: "d’augmentation des taux de réservation", value: 35 },
    ]

    const textList = [
        "Optimisation de votre planning et remplissage vos créneaux en toute simplicité avec une gestion fluide pour développer votre activité",
        "Réduction des no-shows et annulations de dernières minutes",
        "Visibilité accrue auprès de nouveaux clients"
    ]

    return (
        <section id="en-savoir-plus" className="h-screen pt-8 md:pt-0 flex flex-col snap-start">
            <motion.div
                className="w-full mx-auto md:px-12 pt-8 md:py-8 grid grid-cols-2 md:flex items-start justify-around text-[.75em] max-w-[1280px]"
                initial={{ opacity: 0, y: 100 }}
                transition={{ duration: .5 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: false, amount: .25 }}
            >
                {indicators.map((item, key) =>
                    <aside key={`learn-more-${key}`} className="flex flex-col items-center text-center">
                        <div className="inset-0 bg-gradient-to-b from-[#bd193b] to-pink-500 rounded-full p-[2.5px]">
                            <div className="w-full px-4 md:px-8 py-1 md:py-2 bg-white rounded-full">
                                <span className="text-xl md:text-4xl font-bold text-[#bd193b]">{item.value}</span>
                                <span className="text-gray-500 text-lg ml-2">%</span>
                            </div>
                        </div>
                        <p className="mt-2 text-black text-[.5em] md:text-xl max-w-[200px]">
                            {item.text}
                        </p>
                    </aside>)}
            </motion.div>

            <div className="flex-1 px-6 md:px-24 md:grid md:grid-cols-2 items-center overflow-y-hidden">
                <motion.aside
                    className="flex flex-col gap-2"
                    initial={{ opacity: 0, y: 200 }}
                    transition={{ duration: .5 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: false, amount: .25 }}
                >
                    <h1 className="font-semibold text-[1em] md:text-2xl">Gagnez du temps, attirez plus de clients</h1>
                    <div className="text-sm md:text-lg">
                        Gérez vos rendez-vous, réduisez les annulations et attirez plus de clients grâce à notre solution crée pour vous, professionnels de la beauté.

                        <ul className="mt-2 flex flex-col gap-2 list-disc">
                            {textList.map((text, key) =>
                                <li key={`lm-${key}`}>{text}</li>
                            )}
                        </ul>
                    </div>

                    <a
                        href="https://www.planibeauty.fr/ajouter-etablissement"
                        target="_blank"
                        rel="noreferrer"
                        className="px-4 py-3 text-sm md:text-xl text-center text-white bg-[#bd193b] rounded no-underline cursor-pointer"
                    >
                        Révolutionnez votre manière de travailler
                    </a>
                </motion.aside>

                <motion.aside
                    className="hidden h-[60vh] md:flex items-center justify-center"
                    initial={{ opacity: 0, x: 50 }}
                    transition={{ duration: 1 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: false, amount: .25 }}
                >
                    <img className="h-full" src={agendaCover} alt="Couverture agenda" />
                </motion.aside>
            </div>
        </section>
    )
}

const Page3 = () => {
    const iframeRef = useRef(null);
    const textList = [
      "Un profil professionnel complet et optimisé pour valoriser votre savoir-faire",
      "Générez vos codes promo et envoi d'offres promotionnelles directement depuis votre espace pro",
      "Fiche client complète et personnalisable",
      "Accès à une communauté d'experts et d'entrepreneurs engagés du secteur de la beauté"
    ];
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // Pour YouTube, nous devons utiliser l'API iframe pour contrôler la lecture
            if (iframeRef.current) {
              // Nous utilisons postMessage pour communiquer avec l'iframe YouTube
              iframeRef.current.contentWindow.postMessage(
                '{"event":"command","func":"playVideo","args":""}',
                '*'
              );
            }
          } else {
            if (iframeRef.current) {
              // Mettre en pause la vidéo quand elle n'est plus visible
              iframeRef.current.contentWindow.postMessage(
                '{"event":"command","func":"pauseVideo","args":""}',
                '*'
              );
            }
          }
        },
        { threshold: 0.5 }
      );
  
      if (iframeRef.current) {
        observer.observe(iframeRef.current);
      }
  
      return () => {
        if (iframeRef.current) {
          observer.unobserve(iframeRef.current);
        }
      };
    }, []);
  
    return (
      <section className="h-screen flex flex-col items-center gap-4 md:gap-16 snap-start overflow-y-hidden">
        <motion.aside
          className="relative md:pt-6 w-full h-[40vh] flex items-center justify-center"
          initial={{ opacity: 0, y: -100 }}
          transition={{ duration: 0.5 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false, amount: 0.25 }}
        >
          {/* Image de fond */}
          <img
            className="px-2 md:mx-0 absolute top-15 w-full md:w-[50%] h-[28vh] md:h-[40vh]"
            src={videoCover}
            alt="Background"
          />
          {/* YouTube iframe remplaçant la vidéo */}
          <iframe
            ref={iframeRef}
            className="absolute top-12 translate-y-0.5 md:translate-y-0 md:top-4 w-[71.8%] md:w-[36.5%] h-[25.4vh] md:h-[36.7vh] rounded-lg md:rounded-xl translate-x-0.5"
            src="https://www.youtube.com/embed/IJSXNN3qFmw?enablejsapi=1&controls=1&mute=1&playsinline=1"
            title="Vidéo Planibeauty"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </motion.aside>
  
        <motion.aside
          className="page-3-text-container"
          initial={{ opacity: 0, y: 200 }}
          transition={{ duration: 0.5 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false, amount: 0.25 }}
        >
          <h1 className="font-semibold text-center text-[1em] md:text-2xl">
            Fidélisez votre clientèle en un clic
          </h1>
          <p className="text-sm md:text-lg">
            Renforcez la relation avec vos clients grâce à nos outils vous permettant une approche professionnelle et engageante.
          </p>
          <ul className="mt-2 flex flex-col text-sm md:text-lg gap-2 list-disc">
            {textList.map((text, key) => (
              <li key={`text-${key}`}>{text}</li>
            ))}
          </ul>
          <a
            href="https://www.planibeauty.fr/ajouter-etablissement"
            target="_blank"
            rel="noreferrer"
            className="px-4 py-3 text-sm md:text-xl text-center text-white bg-[#bd193b] rounded no-underline cursor-pointer"
          >
            Rejoignez Planibeauty
          </a>
        </motion.aside>
      </section>
    );
  };


const Page4 = () => {
    const textList = [
        "Suivi statistique avancé pour piloter votre activité",
        "Transactions sécurisées pour des paiements garantis",
        "Moins de 2 minutes pour organiser votre planning et optimiser votre chiffre d’affaires"
    ]

    return (
        <section className="h-screen pt-4 px-4 md:px-24 flex flex-col md:flex-row items-center gap-8 md:gap-16 snap-start overflow-hidden">
            <motion.aside
                initial={{ opacity: 0, y: 100 }}
                transition={{ duration: .5 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: false, amount: .25 }}
                className="w-full md:w-3/5"
            >
                <img src={dashboardCover} alt="Couverture dashboard" className="w-full" />
            </motion.aside>

            <motion.aside
                className="w-5/6 md:w-2/5 flex flex-col md:gap-4"
                initial={{ opacity: 0, y: 100 }}
                transition={{ duration: .5 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: false, amount: .25 }}
            >
                <h1 className="font-semibold text-[1em] md:text-2xl">Paiements sécurisés & gestion optimisée</h1>

                <p className="text-sm md:text-lg">Suivez vos performances en temps réel et bénéficiez d’un système de paiement sécurisé pour plus de sérénité.</p>

                <ul className="mt-2 text-sm md:text-lg flex flex-col gap-2 list-disc">
                    {textList.map((text, key) =>
                        <li key={`text-${key}`}>{text}</li>
                    )}
                </ul>

                <a
                    href="https://www.planibeauty.fr/ajouter-etablissement"
                    target="_blank"
                    rel="noreferrer"
                    className="px-4 py-3 text-sm md:text-xl text-center text-white bg-[#bd193b] rounded no-underline cursor-pointer"
                >
                    Développez votre activité
                </a>
            </motion.aside>
        </section>
    )
}

const Reviews = () => {
    const [bgImage, setBgImage] = useState("")

    const floatingStuff = [
        { icon: <ThumbsUp size={24} fill="#bd193b" stroke="#bd193b"/> },
        { icon: <Heart size={24} fill="#bd193b" stroke="#bd193b"/> },
        { icon: <Heart size={24} fill="#bd193b" stroke="#bd193b"/> },
        { icon: <Star size={24} fill="#bd193b" stroke="#bd193b"/> },
    ]

    useEffect(() => {
        const img = new Image()

        img.src = reviewCover
        img.onload = () => setBgImage(reviewCover)
    })

    return (
        <section className="h-screen flex flex-col md:flex-row-reverse snap-start overflow-x-hidden overflow-y-hidden">
            <motion.aside
                className="mx-2 md:mx-0 md:px-11 md:flex md:items-center justify-centermd:h-screen md:w-1/2 py-6"
                initial={{ opacity: 0, y: -100 }}
                transition={{ duration: .5 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: false, amount: .25 }}
            >
                <div className="relative border p-8 rounded-3xl shadow">
                    <div className="flex items-center justify-center">
                        {[1, 2, 3, 4, 5].map(index =>
                            <Star key={`star-${index}`} size={24} fill="#ffd25d" stroke="transparent" />
                        )}
                    </div>
                    <h1 className="mt-2 font-semibold text-[1em] md:text-2xl">Ils en parlent: </h1>

                    <p className="text-sm md:text-lg">“Avec PlaniBeauty, gérer notre activité est devenu beaucoup plus simple !<br />Nos clientes adorent la facilité de réservation et la messagerie qui nous permet d’échanger directement avec elles. On remarque qu'elles prennent rendez-vous plus régulièrement et reviennent avec encore plus de confiance !”</p>
                </div>
            </motion.aside>

            <motion.aside
                className="p-8 flex-1 flex flex-col gap-4 items-start bg-cover"
                style={{
                    backgroundImage: bgImage ? `url(${bgImage})` : "none",
                    backgroundPositionY: "25%"
                }}
                initial={{ opacity: 0, y: 100 }}
                transition={{ duration: .5 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: false, amount: .25 }}
            >
                {floatingStuff.map((stuff, key) => 
                    <aside
                        key={`stuff-${key}`}
                        className={`${key % 2 === 1 && "ms-auto"} flex items-center`}
                    >
                        <div className="mx-10 md:my-2 p-3 bg-white rounded-full">
                            {stuff.icon}
                        </div>
                    </aside>
                )}
            </motion.aside>
        </section>
    )
}

export const FAQ = () => {
    const faqList = [
        {
            title: "Qu'est-ce que PlaniBeauty ?",
            content: "PlaniBeauty est un site, une solution  tout-en-un, conçue pour les professionnels de la beauté pour faciliter votre quotidien et développer votre activité.\nElle vous permet de gérer vos rendez-vous, sécuriser vos paiements, développer votre clientèle et accéder à des outils pour booster votre activité."
        },
        {
            title: "Pourquoi choisir PlaniBeauty plutôt qu’une autre plateforme ?",
            content: "PlaniBeauty vous accompagne dans chaque étape de votre activité pour vous aider à grandir et à vous épanouir.",
            list: [
                "Accompagnement et suivi pour vous aider à développer votre business",
                "Communauté et entraide entre esthéticiennes",
                "Obtenir des contrats supplémentaires, en plus de votre clientèle grâce au label Open to Work",
                "Possibilité d’obtenir des contrats supplémentaires, en plus de votre clientèle grâce au label Open to Work",
                "Formations si vous souhaitez pour développer votre expertise",
                "Packs communication pour booster votre visibilité"
            ],
            footer: "Et bien plus encore !"
        },
        {
            title: "Quels sont les tarifs ?",
            content: "Prenez rendez-vous avec l’une de nos conseillères ! Elle vous présentera toutes nos offres, à partir de 49€ par mois, et activera votre essai gratuit de 10 jours.\n{{ link }}",
            link: {
                name: "📅 Réservez votre appel ici",
                url: "https://calendly.com/contact-planibeauty/inscription-site-planibeauty?month=2025-03"
            }
        },
        {
            title: "Une formation est-elle prévue pour bien utiliser Planibeauty ?",
            content: "Nous proposons un accompagnement en visio pour vous guider sur tous les outils et fonctionnalités.\nNotre équipe est disponible 5 jours sur 7 de 10h à 18h pour répondre à toutes vos questions."
        },
        {
            title: "Comment communiquer avec mes client(e)s sans divulguer mes données personnelles ?",
            content: "Echangez directement avec vos clientes via notre messagerie sécurisée depuis votre espace professionnel sans avoir à partager votre numéro de téléphone.\nFini les DM interminables sur vos réseaux !"
        },
        {
            title: "Que se passe-t-il en cas d’annulation de dernière minute  ?",
            content: "Afin de limiter les annulations de dernière minute et les \"no-shows\", vous pouvez :",
            list: [
                "Exiger un acompte lors de la réservation",
                "Appliquer une pénalité en cas d'annulation tardive",
                "Dès le rendez-vous annulé : Le  créneau est immédiatement remis en ligne et disponible pour une nouvelle réservation !"
            ]
        },
        {
            title: "J’ai besoin d’aide pour gérer mon activité, proposez-vous un accompagnement ?",
            content: "Oui ! Notre Formule Accompagnement à 55€/mois inclut:",
            list: [
                "5 ateliers hebdomadaires :  perfectionnement métier, gestion, administration, et communication ainsi que des ateliers spéciaux mensuels",
                "Essai gratuit de 10 jours pour vous aider à optimiser votre activité",
                "1 mois de pack communication offert pour booster votre visibilité sur vos réseaux",
                "Nos conseillers PlaniBeauty sont à votre disposition 5j/7 pour vous guider et organiser des rendez-vous réguliers afin d’optimiser votre activité"
            ]
        },
        {
            title: "Comment ajouter de nouvelles prestations ?",
            content: "Ajoutez et modifiez vos prestations à tout moment en quelques clics !\nBesoin d’élargir votre offre ?\nFormez-vous auprès de formatrices diplômées et obtenez une certification Qualiopi reconnue pour rassurer et fidéliser vos clientes ! "
        },
        {
            title: "Puis-je proposer des promotions et offres spéciales ?",
            content: "Oui ! Vous pouvez créer et gérer vos promotions, offres spéciales et codes promo directement depuis la plateforme pour fidéliser vos client(e)s et attirer de nouvelles réservations."
        },
        {
            title: "Je manque de temps pour gérer mes réseaux sociaux, comment faire ?",
            content: "Gagnez du temps avec nos packs communication clé en main pour garder une présence en ligne efficace sans effort."
        },
        {
            title: "J’ai une équipe, comment ça marche ?",
            content: "Optez pour la Formule Équipe à 59€/mois, incluant 3 membres (+5€ par utilisateur supplémentaire).\nChaque membre dispose de son propre agenda pour une gestion optimisée des plannings et une meilleure organisation de votre entreprise !"
        }
    ]

    const [active, setActive] = useState(null)

    return (
        <section className="min-h-screen p-8 py-14 md:p-24 snap-start">
            <motion.h1
                className="text-4xl mb-4 md:mb-0 text-[#bd193b]"
                initial={{ opacity: 0, y: 100 }}
                transition={{ duration: .5 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: .25 }}
            >
                FAQ
            </motion.h1>

            <div
                className="grid grid-cols-1 md:grid-cols-3 items-start md:p-4 gap-3 md:gap-4"
            >
                {faqList.map((faq, key) => (
                    <aside
                        key={`faq-${key}`}
                        className="p-8 min-h-[100px] md:min-h-[220px] flex flex-col items-start gap-2 rounded-3xl border shadow-sm md:shadow cursor-pointer"
                        onClick={() => setActive(active === key ? null : key)}
                    >
                        <button
                            className="p-2 rounded-full text-white bg-[#bd193b] transition-transform transform"
                            style={{
                                transform: active === key ? "rotate(45deg)" : "rotate(0deg)",
                            }}
                        >
                            <ArrowUpRight size={24} />
                        </button>
                        <h1 className="text-sm md:text-xl font-normal">{faq.title}</h1>

                        <div
                            className={`${key === active
                                ? "flex flex-col gap-4 opacity-100 max-h-screen transition-all duration-300 ease-in-out"
                                : "opacity-0 max-h-0 overflow-hidden transition-all duration-300 ease-in-out"
                                } text-sm`}
                        >
                            <p>
                                {faq.link ? (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: faq.content
                                                .replace("{{ link }}", `<a className="cursor-pointer" target="_blank" href=${faq.link.url}>${faq.link.name}</a>`)
                                                .replace(/\n/g, "<br />"),
                                        }}
                                    />
                                ) : (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: faq.content.replace(/\n/g, "<br />"),
                                        }}
                                    />
                                )}
                            </p>

                            {faq.list && (
                                <ul className="list-disc">
                                    {faq.list.map((listItem, key) => (
                                        <li key={key}>{listItem}</li>
                                    ))}
                                </ul>
                            )}

                            {faq.footer && <p>{faq.footer}</p>}
                        </div>
                    </aside>
                ))}
            </div>
        </section>
    )
}

const LandingCom = () => {
    useEffect(() => {
        document.title = "Planibeauty"
    }, [])

    return (
        <React.Fragment>
        {/* < GoogleTagManager /> */}
        <div className="overflow-y-scroll scroll-smooth h-screen snap-y snap-mandatory">
            {/* <div> */}
            <Page1 />
            <LearnMore />
            <Page3 />
            <Page4 />
            <Reviews />
            <FAQ />
        </div>
        </React.Fragment>
    )
}

export default LandingCom
