import commuimg from "../../assets/fonctionnalites/communication.png";
const FonctionnaliteCommunication = () => {
  return <div className="fonctionnalites-content">
    <div className="communication-wrapper">
      <div className="textzone-communication">
        <button className="com-btn" onClick={() => {
          window.location.href = "/packs-communication";
        }}>packs communication</button>

        <h4>Renforcez votre présence sur Internet avec
          nos experts dans la communication.</h4>

      </div>

      <img src={commuimg} alt="" className="commu-img" />
    </div>
  </div>
}

export default FonctionnaliteCommunication;