import React from 'react';
import profil_recruteur from '../../assets/images/profil recruteur.png';
import profil_estheticienne from '../../assets/images/profil estheticienne.png';

const OpenToWorkLabel = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-10 font-sans">
      <div className="text-center mb-12">
        <h2 className="text-2xl font-medium mb-4">Encore plus de raisons de rejoindre la communauté !</h2>
        <h1 className="text-5xl font-bold">
          <span className="text-black">Le Label </span>
          <span className="text-green-500">Open to Work</span>
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Colonne de gauche */}
        <div className="relative flex pl-6 md:pl-24">
          <div className="bg-gradient-to-b h-full from-[#f8f7f7] to-[#e1b78e] rounded-xl p-8">
            <div className="mb-8">
              <h3 className="text-xl font-bold text-center mb-2">
                Vous recherchez une perle de beauté pour votre institut
              </h3>
              <p className="text-center font-semibold text-sm mb-6">
                PlaniBeauty vous aide à recruter facilement les meilleurs talents de la beauté
              </p>
            </div>

            <div className="space-y-4 pl-12 md:pl-16 text-justify">
              <div>
                <span className="font-bold text-sm">Panel de professionnels certifiés - </span>
                <span className="text-sm">Profils vérifiés et formés, prêts à intégrer votre équipe.</span>
              </div>

              <div>
                <span className="font-bold text-sm">Annuaire des professionnels disponibles - </span>
                <span className="text-sm">Trouvez rapidement le profil adapté à vos besoins pour une mission ou un contrat.</span>
              </div>

              <div>
                <span className="font-bold text-sm">Tableau de bord recruteur - </span>
                <span className="text-sm">Gérez facilement vos recherches et vos candidatures.</span>
              </div>

              <div>
                <span className="font-bold text-sm">Sélection simplifiée - </span>
                <span className="text-sm">Contactez directement les profils qui vous intéressent.</span>
              </div>

              <div>
                <span className="font-bold text-sm">Notification personnalisée - </span>
                <span className="text-sm">Soyez alerté des nouveaux profils selon vos critères.</span>
              </div>

              <div>
                <span className="font-bold text-sm">Pré-sélection professionnelle - </span>
                <span className="text-sm">Chaque profil est validé après un entretien et la vérification des diplômes.</span>
              </div>
            </div>

          </div>
          <div className="absolute bottom-0 -left-8">
            <img src={profil_recruteur} alt="Professionnelle avec tablette" className="w-32 md:w-52 rounded-bl-xl" />
          </div>
        </div>

        {/* Colonne de droite */}
        <div className="relative flex pe-6 md:pe-24">
          <div className="bg-gradient-to-b h-full from-[#f7f8f7] to-[#99ec99] rounded-xl p-8">
            <div className="mb-8">
              <h3 className="text-xl font-bold text-center mb-2">
                Vous souhaitez mettre vos talents à disposition de d'autre pro
              </h3>
              <p className="text-center font-semibold text-sm mb-6">
                Activez le label Open to Work sur PlaniBeauty et multipliez vos opportunités professionnelles
              </p>
            </div>

            <div className="space-y-4 pr-12 text-justify">
              <div>
                <span className="font-bold text-sm">Missions et contrats variés - </span>
                <span className="text-sm">Intérim, CDD, CDI, temps plein ou partiel : choisissez ce qui vous convient.</span>
              </div>

              <div>
                <span className="font-bold text-sm">Flexibilité totale - </span>
                <span className="text-sm">Activez ou désactivez votre profil à tout moment selon votre disponibilité.</span>
              </div>

              <div>
                <span className="font-bold text-sm">Portfolio professionnel - </span>
                <span className="text-sm">Mettez en avant vos diplômes, formations et expériences pour attirer les recruteurs.</span>
              </div>

              <div>
                <span className="font-bold text-sm">Statistiques PlaniBeauty - </span>
                <span className="text-sm">Montrez vos performances et vos spécialités directement sur votre profil.</span>
              </div>

              <div>
                <span className="font-bold text-sm">Visibilité renforcée - </span>
                <span className="text-sm">Soyez repéré par les recruteurs à la recherche de votre expertise.</span>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 -right-6">
            <img src={profil_estheticienne} alt="Cliente en soins esthétiques" className="rounded-br-xl w-32 md:w-52" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenToWorkLabel;