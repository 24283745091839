import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import { photoEtablissementApi } from '../../api/photo_etablissement.api';
import { API_URL, BASE_URL } from '../../utils/constants';
import './BeauticianGallery.css'
import { beauticiansSubCategoriesApi } from '../../api/beauticians_subcategories.api';

const BeauticianGallery = ({ beautician_id }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [images, setImages] = useState([]);
  const allImages = images;
  const MAX_THUMBNAILS = 4;

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % allImages.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + allImages.length) % allImages.length);
  };

  const openGallery = (index) => {
    setCurrentImageIndex(index);
    setIsGalleryOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
    document.body.style.overflow = 'unset';
  };

  useEffect(() => {
    try {
      const getBeauticianEstablishmentImages = async () => {
        const result = await photoEtablissementApi.getByBauticianId(beautician_id)
        let array = [];
        if (result) {
          array = result.map((r) => `${API_URL}/etablissement/${r.photo_url}`)
        }

        if (array.length < 5) {
          const req_images = await beauticiansSubCategoriesApi.getRandomBeauticianSubcategoriesImages(beautician_id);
          if (req_images && Array.isArray(req_images)) {
            array = array.concat(req_images.map((r) => `${BASE_URL}/images/beauticians_subcategories/${r.photo_url}`))
          }
        }

        setImages(array);
      }
      getBeauticianEstablishmentImages()
    }
    catch (error) {
      console.log(error)
    }
  }, [beautician_id]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isGalleryOpen) return;
      if (e.key === 'ArrowLeft') prevImage();
      if (e.key === 'ArrowRight') nextImage();
      if (e.key === 'Escape') closeGallery();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isGalleryOpen]);

  const remainingPhotos = Math.max(0, allImages.length - MAX_THUMBNAILS);
  const thumbnailImages = allImages.slice(0, MAX_THUMBNAILS);

  return (
    <>
      {images.length > 0 &&
        <div className="gallery-container">
          <div className="gallery-layout">
            {/* Main Carousel */}
            <div className="main-carousel">
              <img
                src={allImages[currentImageIndex]}
                alt="Vue du salon"
                className="carousel-image"
                onClick={() => openGallery(currentImageIndex)}
              />
              <button
                className="carousel-nav-btn prev"
                onClick={(e) => {
                  e.stopPropagation();
                  prevImage();
                }}
              >
                <ChevronLeft />
              </button>
              <button
                className="carousel-nav-btn next"
                onClick={(e) => {
                  e.stopPropagation();
                  nextImage();
                }}
              >
                <ChevronRight />
              </button>
            </div>

            {/* Thumbnail Grid */}
            <div className="prestation-grid">
              {thumbnailImages.map((image, index) => (
                <div
                  key={index}
                  className={`miniature-item ${index === MAX_THUMBNAILS - 1 && remainingPhotos > 0 ? 'last-thumbnail' : ''}`}
                  onClick={() => openGallery(index)}
                >
                  <img src={image} alt={`Service ${index + 1}`} />
                  {index === MAX_THUMBNAILS - 1 && remainingPhotos > 0 && (
                    <div className="remaining-overlay">
                      <span>Voir les {remainingPhotos} photos</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Popup Gallery */}
          {isGalleryOpen && (
            <div className="gallery-popup" onClick={closeGallery}>
              <div className="gallery-popup-content" onClick={e => e.stopPropagation()}>
                <img
                  src={allImages[currentImageIndex]}
                  alt={`Full view ${currentImageIndex + 1}`}
                  className="popup-image"
                />
                <button className="popup-nav-btn prev" onClick={prevImage}>
                  <ChevronLeft size={24} />
                </button>
                <button className="popup-nav-btn next" onClick={nextImage}>
                  <ChevronRight size={24} />
                </button>
                <button className="popup-close" onClick={closeGallery}>
                  <X size={20} />
                </button>
                <div className="popup-counter">
                  {currentImageIndex + 1} / {allImages.length}
                </div>
              </div>
            </div>
          )}
        </div>}
    </>
  );
};

export default BeauticianGallery;