import React, { useContext, useState } from 'react';
import defaultProfile from '../../../assets/images/default-profile.png';
import { BASE_URL } from '../../../utils/constants';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';

import {
	MapPin,
	Mail,
	Phone,
	Edit2,
	Euro,
	User,
	User2Icon
} from 'lucide-react';
import './BeauticianProfile.css';
import BeauticianGallery from '../../beautician/BeauticianGallery';
import { useNavigate } from 'react-router-dom';
import SocialLinkList from '../../common/SocialLinkList';

import withReactContent from 'sweetalert2-react-content';
import ReactSwitch from 'react-switch';
import Swal from 'sweetalert2';
import { Accordion, ListGroup } from 'react-bootstrap';
import beauticianApi from '../../../api/beautician.api';
import MySpinner from '../../loader/Spinner';
import { checkIfProCanBeAvailable } from '../../../utils/functions';
import { showWarningMessage } from '../../../utils/messageHelper';

// Importation des imags des catégories
import beauteRegard from "../../../assets/prestations/beaute_regard.png";
import coiffure from "../../../assets/prestations/coiffure.png";
import epilation from "../../../assets/prestations/epilation.png";
import maquillage from "../../../assets/prestations/maquillage.png";
import onglerie from "../../../assets/prestations/onglerie.png";
import soinDuCorps from "../../../assets/prestations/soin du corps.png";
import soinDuVisage from "../../../assets/prestations/soin du visage.png";
import soinDentaire from "../../../assets/prestations/soins dentaire.png";

const renderSpecialities = (subcategories, isMobile = false) => {
	const categoryImages = {
		"Soin du visage": soinDuVisage,
		"Soin dentaire": soinDentaire,
		"Maquillage": maquillage,
		"Épilation": epilation,
		"Onglerie": onglerie,
		"Beauté du regard": beauteRegard,
		"Soin du corps": soinDuCorps,
		"Coiffure": coiffure
	}

	const groupedByCategory = subcategories.reduce((acc, item) => {
		if (!acc[item.category_name]) {
			acc[item.category_name] = [];
		}
		acc[item.category_name].push(item);
		return acc;
	}, {});

	return <section className="profile-section-portal">
		<h2 className={`${isMobile && "hidden"}`}>Spécialités</h2>
		<Accordion>
			{Object.keys(groupedByCategory).map((category, index) => (
				<Accordion.Item eventKey={index.toString()} key={category}>
					<Accordion.Header>
						<img
							width={32}
							height={32}
							className="rounded-full me-3"
							src={categoryImages[category.includes("pilation") ? "Épilation" : category]}
						/>
						{category}
					</Accordion.Header>
					<Accordion.Body>
						<ListGroup>
							{groupedByCategory[category].map((service, idx) => (
								<ListGroup.Item key={idx}>
									<strong>{service.name}</strong> - {service.price}€ - {service.duration} min
									<p className="text-muted">{service.description}</p>
								</ListGroup.Item>
							))}
						</ListGroup>
					</Accordion.Body>
				</Accordion.Item>
			))}
		</Accordion>
	</section>
}

const BeauticianProfile = () => {
	const navigate = useNavigate();

	const { profileData, setReloadTrigger } = useContext(BeauticianPortalContext);

	const MySwal = withReactContent(Swal);

	const [activeTab, setActiveTab] = useState("specialites");

	const tabs = {
		specialites: "Spécialités",
		horaires: "Horaires",
		infos: "Infos"
	};

	const budget_category = {
		economic: "Economique",
		moderate: "Modérée",
		premium: "Premium"
	}

	const defaultDays = [
		{ day: "Lundi", field: "monday" },
		{ day: "Mardi", field: "tuesday" },
		{ day: "Mercredi", field: "wednesday" },
		{ day: "Jeudi", field: "thursday" },
		{ day: "Vendredi", field: "friday" },
		{ day: "Samedi", field: "saturday" },
		{ day: "Dimanche", field: "sunday" },
	];

	const getImageUrl = (profilePhoto) => {
		if (!profilePhoto) return defaultProfile;
		try {
			return `${BASE_URL}/images/beauticians/${encodeURIComponent(profilePhoto)}`;
		} catch (error) {
			console.error('Error with image path:', error);
			return defaultProfile;
		}
	};

	const handleEditToggle = () => {
		navigate("/beautician-dashboard/settings");
	};

	const handleAvailabilityChange = async (is_available, beautician_id) => {
		// Mettre à jour la disponibilité via une API ou une méthode
		const check = await checkIfProCanBeAvailable(beautician_id);

		if (!is_available && !check) {
			showWarningMessage('Vous devez ajouter au moins un service/prestation pour être disponible!');
			return;
		}

		MySwal.fire({
			title: 'Confirmation',
			text: `Voulez-vous vraiment modifier la disponibilité?`,
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Oui, modifier',
			cancelButtonText: 'Non',
		}).then(async (result) => {
			if (result.isConfirmed) {
				await beauticianApi.updateAvailability(localStorage.getItem('token'), beautician_id, { is_available: !is_available });
				setReloadTrigger(prev => !prev);
			}
		});
	};

	const handleOpenToWorkChange = (is_open_to_work, beautician_id) => {
		MySwal.fire({
			title: 'Confirmation',
			text: `Voulez-vous vraiment changer votre status?`,
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Oui, changer',
			cancelButtonText: 'Non',
		}).then(async (result) => {
			if (result.isConfirmed) {
				await beauticianApi.updateIsOpenToWork(localStorage.getItem('token'), beautician_id, { is_open_to_work: !is_open_to_work });
				setReloadTrigger(prev => !prev);
			}
		});
	};

	if (!profileData) {
		return <MySpinner height={"80vh"} />
	}

	return <>
		<div className="beautician-profile profile-container">
			<div className="flex justify-between items-center gap-2 flex-wrap w-full py-2 md:py-3 px-2 md:px-5">

				<div className="availability-status available">
					<User size={20} />
					<span>Profil {profileData.type_beautician}</span>
				</div>

				<div className="availability-status">
					<ReactSwitch
						checked={profileData.is_available}
						onChange={() => handleAvailabilityChange(profileData.is_available, profileData.beautician_id)}
						offColor="#f87171" // Couleur désactivée
						onColor="#4ade80" // Couleur activée
						uncheckedIcon={false}
						checkedIcon={false}
						width={40} // Largeur du switch
						height={20} // Hauteur du switch
						handleDiameter={15}
					/> Je suis disponible
				</div>
				<div className="availability-status">
					<ReactSwitch
						checked={profileData.is_open_to_work}
						onChange={() => handleOpenToWorkChange(profileData.is_open_to_work, profileData.beautician_id)}
						offColor="#f87171" // Couleur désactivée
						onColor="#4ade80" // Couleur activée
						uncheckedIcon={false}
						checkedIcon={false}
						width={40} // Largeur du switch
						height={20} // Hauteur du switch
						handleDiameter={15}
					/> Open to work
				</div>
				<button
					className="edit-profile-btn"
					onClick={handleEditToggle}
				>
					<Edit2 size={16} />
					<span>Modifier le profil</span>
				</button>
			</div>
			<div className="profile-header">
				<div className="profile-header-content">
					<div className="profile-avatar-wrapper">
						<div className="profile-avatar">
							<img
								src={getImageUrl(profileData.profile_photo)}
								alt="Profile"
								className="avatar-image"
							/>
						</div>
					</div>

					<div className="b-profile-information">
						<div className="profile-titles">
							<h1>{profileData.first_name} {profileData.last_name}</h1>
							<p>{profileData.business_name}</p>
							<p className="bio">{profileData.beautician_description}</p>
						</div>
					</div>
				</div>
			</div>

			<div className="profile-content">
				<div className="profile-main">

					{renderSpecialities(profileData.subcategories)}

					<section className="profile-section-portal">
						<h2>Horaires d'ouverture</h2>
						<div className="schedule-grid">
							{defaultDays.map((disp, index) => {

								let availability = profileData.availabilities.find((obj) => obj.day === disp.field);
								if (availability) {
									return (<div key={index} className="schedule-row">
										<span className="day">{disp.day}</span>
										<span className="hours">{availability.start_time.slice(0, 5)} - {availability.end_time.slice(0, 5)}</span>
									</div>);
								} else {
									return (<div key={index} className="schedule-row">
										<span className="day">{disp.day}</span>
										<span className="hours">Fermé</span>
									</div>);
								}
							})}
							{/*profileData.availabilities.map((availability, index) => (
                
              ))*/}
						</div>
					</section>
				</div>

				<aside className="profile-sidebar">
					<div className="contact-info">
						<h2>Informations de contact</h2>

						<div className="contact-list">
							<div className="contact-item">
								<MapPin size={20} />
								<span>{profileData.address}</span>
							</div>
							<div className="contact-item">
								<Mail size={20} />
								<span>{profileData.email}</span>
							</div>
							<div className="contact-item">
								<Phone size={20} />
								<span>{profileData.phone}</span>
							</div>
							{/*<div className="contact-item">
                <Globe size={20} />
                <span>{profileData.website}</span>
              </div>*/}
						</div>
					</div>

					<section className="profile-section-portal">
						<h2>Liens vers mes réseaux sociaux</h2>
						<SocialLinkList links={profileData.social_networks} />
					</section>

					<div className="availability-card">
						<h2>Catégorie de budget</h2>
						<div className="availability-status available">
							<Euro size={20} />
							<span>{profileData.budget_category === "moderate" ? "Modéré" : (profileData.budget_category === "premium" ? "Premium" : "Economique")}</span>
						</div>
					</div>
				</aside>
			</div>
			{profileData.type_beautician === "établissement" &&
				<div className="p-4">
					<h2 className='text-xl'>Mes collaborateurs</h2>
					<div className="specialities-list">
						{profileData.collaborateurs?.length > 0 ? (profileData.collaborateurs.map((c) => (
							<span key={c.id} className="speciality-tag">
								{c.name}
							</span>
						))) : <p>Aucun collaborateurs à afficher</p>}
					</div>
				</div>}
			<div className="p-4">
				<h2 className='text-xl'>Photos de mon établissement</h2>
				<BeauticianGallery beautician_id={profileData.beautician_id} />
			</div>
		</div>

		{/* Affichage Mobile */}
		<div className="md:hidden flex flex-col w-screen min-h-screen">
			<div className="flex flex-col items-center justify-center my-2 space-y-4">
				<div>
					<img
						src={getImageUrl(profileData.profile_photo)}
						alt="Profile"
						className="size-28 rounded-full bg-white"
					/>
				</div>
				<h1>{profileData.business_name}</h1>
				<p className="flex items-center font-semibold gap-2">
					<User2Icon size={16} /> {profileData.type_beautician}
				</p>

				<div className="flex items-center gap-4">
					<div className={`text-sm flex items-center gap-2 p-1 border rounded-xl ${profileData.is_available ? "border-green-400" : "border-red-400"}`}>
						<ReactSwitch
							checked={profileData.is_available}
							onChange={() => handleAvailabilityChange(profileData.is_available, profileData.beautician_id)}
							offColor="#f87171" // Couleur désactivée
							onColor="#4ade80" // Couleur activée
							uncheckedIcon={false}
							checkedIcon={false}
							width={40} // Largeur du switch
							height={20} // Hauteur du switch
							handleDiameter={15}
						/> Je suis disponible
					</div>
					<div className={`text-sm flex items-center gap-2 p-1 border rounded-xl ${profileData.is_open_to_work ? "border-green-400" : "border-red-400"}`}>
						<ReactSwitch
							checked={profileData.is_open_to_work}
							onChange={() => handleOpenToWorkChange(profileData.is_open_to_work, profileData.beautician_id)}
							offColor="#f87171" // Couleur désactivée
							onColor="#4ade80" // Couleur activée
							uncheckedIcon={false}
							checkedIcon={false}
							width={40} // Largeur du switch
							height={20} // Hauteur du switch
							handleDiameter={15}
						/> Open to work
					</div>
				</div>

				<div className="w-full">
					{profileData.description}
				</div>
			</div>

			{/* Barre de navigations */}
			<div className="w-full px-4 py-2 flex items-center justify-between border-t border-b">
				{["specialites", "horaires", "infos"].map((tab, key) => (
					<span
						key={"tab-" + key}
						onClick={() => setActiveTab(tab)}
						className={`py-1 px-2 rounded-lg ${activeTab === tab && "text-white bg-[#bd193b]"} duration-200`}
					>
						{tabs[tab]}
					</span>
				))}

			</div>

			{/* Onglet spécialités */}
			{activeTab === "specialites" && <>
				<div className="p-4">
					<h2 className="text-sm">Catégorie</h2>

					{renderSpecialities(profileData.subcategories, true)}
				</div>

				<div className="p-4">
					<h2 className="text-sm">Catégorie budget</h2>

					<div className="flex items-center justify-around">
						{["economic", "moderate", "premium"].map(((budget, key) => (
							<div key={'budget-' + key} className="flex flex-col items-center gap-2">
								<div className={`size-4 flex items-center justify-center rounded-full border border-gray-800`}>
									{profileData.budget_category === budget && (<div className={`size-3 rounded-full bg-[#bd193b]`}></div>)}
								</div>

								<span className="text-sm">{budget_category[budget]}</span>
							</div>
						)))}
					</div>
				</div>

				<div className="p-4">
					<h2 className="text-sm">Photos établissement et prestations</h2>

					<BeauticianGallery beautician_id={profileData.beautician_id} />
				</div>
			</>}

			{/* Onglet horaires */}
			{activeTab === "horaires" && <>
				<div className="p-4">
					<h2 className="text-sm">Horaires d'ouvertures hebdomadaires</h2>

					<div className="px-4 py-2 bg-yellow-50 rounded-lg">
						{defaultDays.map((disp, index) => {
							let availability = profileData.availabilities.find((obj) => obj.day === disp.field);
							if (availability) {
								return (<div key={index} className="flex items-center justify-between space-y-2">
									<span className={`${defaultDays.findIndex((day => day.field === disp.field)) === new Date().getDay() - 1 && "text-[#bd193b] font-semibold"}`}>{disp.day}</span>
									<span className="hours">{availability.start_time.slice(0, 5)} - {availability.end_time.slice(0, 5)}</span>
								</div>);
							} else {
								return (<div key={index} className="flex items-center justify-between space-y-2">
									<span className={`${defaultDays.findIndex((day => day.field === disp.field)) === new Date().getDay() - 1 && "text-[#bd193b] font-semibold"}`}>{disp.day}</span>
									<span className="hours">Fermé</span>
								</div>);
							}
						})}
					</div>
				</div>
			</>}

			{/* Onglet infos */}
			{activeTab === "infos" && <>
				<div className="p-4">
					<h2 className="text-sm">Information de contact</h2>
					<div className="px-4 py-2 bg-yellow-50 space-y-4">
						<div className="contact-item">
							<MapPin size={20} stroke="#bd193b" />
							<span className="text-sm">{profileData.address}</span>
						</div>
						<div className="contact-item">
							<Mail size={20} stroke="#bd193b" />
							<span className="text-sm">{profileData.email}</span>
						</div>
						<div className="contact-item">
							<Phone size={20} stroke="#bd193b" />
							<span className="text-sm">{profileData.phone}</span>
						</div>

						<p className="text-center text-[.65em] text-[#bd193b]">
							Mes informations restent confidentielles, lors de la confiramtion du rdv, je pourrais communiquer l'adresse et échanger via la messagerie
						</p>
					</div>
				</div>
				<div className="p-4">
					<h2 className="text-sm">Réseaux sociaux</h2>
					<SocialLinkList links={profileData.social_networks} />
				</div>
			</>}
		</div>
	</>;
};

export default BeauticianProfile;