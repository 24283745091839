import React, { useContext, useEffect, useState } from 'react';
import {
  User,
  Shield,
  Clock,
  CreditCard,
  ChevronRight,
  Euro,
  X,
  ArrowRight,
  TicketPercent,
} from 'lucide-react';
import './BeauticianSettings.css';
import AvailabilitySettings from './settings/AvailabilitySettings';
import PaymentModeSettings from './settings/PaymentModeSettings';
import SecuritySettings from './settings/SecuritySettings';
import ProfileSettings from './settings/ProfileSettings';
import PromoCodesPro from './settings/promo-codes/PromoCodesPro';
import AbonnementSettings from './settings/AbonnementSettings';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';
import { useLocation, useNavigate } from 'react-router-dom';

const BeauticianSettings = () => {
  const location = useLocation();
  const { abonnementActive } = useContext(BeauticianPortalContext);
  const [activeSection, setActiveSection] = useState("profile");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();
  const addQueryParam = (key, value) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    navigate(`${window.location.pathname}?${searchParams.toString()}`);
  };

  const handleClickSettingSection = (section) => {
    setActiveSection(section);
    addQueryParam('active', section);
    setIsMenuOpen(false);
  }

  const handleShowMenu = () => {
    setIsMenuOpen(true);
  }

  const handleHideMenu = () => {
    setIsMenuOpen(false);
  }

  useEffect(() => {
    if (location.search) {
      let params_array = location.search.split("&");
      if (params_array.length > 1) {
        params_array = params_array.filter((param) => param.includes('active'));
      }
      params_array = params_array.map((param) => param.split("=")[1]);
      if (params_array.length > 0) {
        setActiveSection(params_array[0]);
        addQueryParam('active', params_array[0]);
      }
    }
  }, []);

  const settingsSections = [
    {
      id: 'profile',
      title: 'Gérer les informations',
      icon: <User size={20} />,
      description: 'Gérez vos informations personnelles et professionnelles',
      disabled: false
    },
    {
      id: 'payment_mode',
      title: 'Mode de paiement',
      icon: <Euro size={20} />,
      description: 'Configurez vos préférences de mode de paiement',
      disabled: !abonnementActive
    },
    {
      id: 'security',
      title: 'Sécurité et confidentialité',
      icon: <Shield size={20} />,
      description: 'Gérez la sécurité de votre compte',
      disabled: false
    },
    {
      id: 'availability',
      title: 'Disponibilités',
      icon: <Clock size={20} />,
      description: 'Définissez vos horaires de travail',
      disabled: !abonnementActive
    },
    {
      id: 'code_promo',
      title: 'Code promo',
      icon: <TicketPercent size={20} />,
      description: 'Géréz vos codes promos',
      disabled: !abonnementActive
    },
    {
      id: 'abonnement',
      title: 'Abonnements',
      icon: <CreditCard size={20} />,
      description: 'Gérez vos abonnements mensuel',
      disabled: false
    }
  ];

  const renderSettingsContent = () => {
    switch (activeSection) {
      case 'profile':
        return (
          <ProfileSettings />
        );

      case 'payment_mode':
        return (
          <PaymentModeSettings />
        );

      case 'security':
        return (
          <SecuritySettings />
        );

      case 'availability':
        return (
          <AvailabilitySettings />
        );

      case 'code_promo':
        return (
          <PromoCodesPro />
        );

      case 'abonnement':
        return (
          <AbonnementSettings />
        );
      default:
        return null;
    }
  };

  return (
    <div className="settings-container">
      {/* Navigation des paramètres */}
      {!isMenuOpen &&
        <button
          onClick={handleShowMenu}
          className="show-settings-menu-button py-3 px-2 bg-red-200 rounded-lg hover:bg-red-300">
          <ArrowRight size={20} />
        </button>
      }
      <div className={`settings-navigation ${isMenuOpen ? 'settings-navigation-show' : 'settings-navigation-hidden'}`}>
        <div className='hide-settings-menu-button w-full px-3 flex justify-end mb-2'>
          <button
            onClick={handleHideMenu}
            className='py-2 px-3 bg-gray-100 rounded hover:bg-gray-200'><X size={20} /></button>
        </div>
        {settingsSections.map((section) => (
          <button
            key={section.id}
            className={`settings-nav-item ${activeSection === section.id ? 'active' : ''} 
      ${section.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={!section.disabled ? () => handleClickSettingSection(section.id) : undefined}
            disabled={section.disabled} // Désactive le bouton au niveau de l'HTML
          >
            <div className="nav-item-icon cursor-not-allowed">{section.icon}</div>
            <div className="nav-item-content cursor-not-allowed">
              <h3>{section.title}</h3>
              <p>{section.description}</p>
            </div>
            <ChevronRight size={20} className="nav-item-arrow cursor-not-allowed" />
          </button>
        ))}

      </div>

      {/* Contenu des paramètres */}
      <div className="settings-content">
        <div className="settings-header">
          <h2>{settingsSections.find(s => s.id === activeSection)?.title}</h2>
        </div>
        {renderSettingsContent()}
      </div>
    </div>
  );
};

export default BeauticianSettings;