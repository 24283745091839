import comimg from "../../assets/fonctionnalites/communauté.png";
const FonctionnaliteCommunaute = () => {
  return <div className="fonctionnalites-content">
    <div className="fonctionnalite-wrapper1">
        <div className="textzone">
            <h2>Accompagnement &
            Communauté PlaniBeauty.</h2>
          <h4>Ateliers Business & Beauté</h4>
            <p>5 ateliers hebdomadaires pour booster votre gestion, rentabilité et visibilité. Gestion et stratégie d’entreprise,  communication, échange avec des expertes du secteur...</p>
          <h4>Accès Exclusif à des Experts</h4>
          <p>Des formatrices spécialisées pour perfectionner vos prestations et répondre à toutes vos questions</p>
          <h4>Communauté planibeauty</h4>
            <p>Rejoignez un réseau inspirant d'esthéticiennes et professionnels du secteur, partagez vos expériences, échangez des conseils... </p>
          <h4>Conseillers Dédiés à Votre Réussite</h4>
            <p>Des conseillers disponibles par téléphone et email pour vous accompagner et optimiser votre utilisation de PlaniBeauty Pro</p>
        </div>

        <img src={comimg} alt="" className="imgzone"/>
    </div>
  </div>
}

export default FonctionnaliteCommunaute;