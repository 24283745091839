import React, { useState, useContext, useRef, useEffect, useId } from 'react';
import {
  Plus,
  Edit2,
  Trash2,
  Clock,
  Euro,
  Image as ImageIcon,
  MoreVertical,
  Pencil,
  Trash,
  Calendar,
  Phone,
  Mail,
  ChevronRight,
  ChevronLeft
} from 'lucide-react';
import './BeauticianServices.css';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';
import { BASE_URL } from '../../../utils/constants';
import { Modal, Button } from "react-bootstrap";
import { FaPlus } from 'react-icons/fa6';
import Swal from 'sweetalert2';
import { beauticiansSubCategoriesApi } from '../../../api/beauticians_subcategories.api';
import { showErrorMessage, showSuccessMessage } from '../../../utils/messageHelper';
import { formatMoneyNumber } from '../../../utils/functions';
import AddPrestationModal from './prestations/AddPrestationModal';
import ServiceImage from './services/ServiceImage';
import { truncateText } from '../../../utils/stringHelper';
import { useDrag, useDrop } from 'react-dnd';
import Accordion from 'react-bootstrap/Accordion';

// Importation des imags des catégories
import beauteRegard from "../../../assets/prestations/beaute_regard.png";
import coiffure from "../../../assets/prestations/coiffure.png";
import epilation from "../../../assets/prestations/epilation.png";
import maquillage from "../../../assets/prestations/maquillage.png";
import onglerie from "../../../assets/prestations/onglerie.png";
import soinDuCorps from "../../../assets/prestations/soin du corps.png";
import soinDuVisage from "../../../assets/prestations/soin du visage.png";
import soinDentaire from "../../../assets/prestations/soins dentaire.png";

const EditPrestation = ({ show, handleClose, prestation, setReloadTrigger }) => {
  //on redefinit active id
  const { profileData } = useContext(BeauticianPortalContext);
  const activeID = profileData.beautician_id;

  const [localSubCategory, setLocalSubCategory] = useState({
    id: prestation.service_subcategory_id,
    name: prestation.service_subcategory_name,
    price: Number(prestation.beautician_subcategory_price),
    duration: Number(prestation.beautician_subcategory_duration),
    description: prestation.beautician_subcategory_description || "",
    image_list: [],
    image_list_saved: prestation.images,
    is_multiple_appointment: prestation.is_multiple_appointment || false,
    number_of_appointment: prestation.number_of_appointment || 2,
  });

  useEffect(() => {
    setLocalSubCategory({
      id: prestation.service_subcategory_id,
      name: prestation.service_subcategory_name,
      price: Number(prestation.beautician_subcategory_price),
      duration: Number(prestation.beautician_subcategory_duration),
      description: prestation.beautician_subcategory_description || "",
      image_list: [],
      image_list_saved: prestation.images,
      is_multiple_appointment: prestation.is_multiple_appointment || false,
      number_of_appointment: prestation.number_of_appointment || 2,
    });
  }, [prestation]);

  const handleFieldChange = (field, value) => {
    setLocalSubCategory((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleImageUpload = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const fileArray = Array.from(files);
      const imagePreviews = fileArray.map((file) => {
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = (e) => {
            resolve({ id: Date.now() + Math.random(), src: e.target.result, file });
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(imagePreviews).then((images) => {
        setLocalSubCategory((prev) => ({
          ...prev,
          image_list: [...prev.image_list, ...images],
        }));
      });
    }
  };

  const handleImageDelete = (imageId) => {
    setLocalSubCategory((prev) => ({
      ...prev,
      image_list: prev.image_list.filter((image) => image.id !== imageId),
    }));
  };

  const handleSavedImageDelete = async (imageId) => {
    Swal.fire({
      title: "Voullez vous vraiment supprimer cette image?",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non"
    }).then((result) => {
      if (result.isConfirmed) {
        const delete_image = beauticiansSubCategoriesApi.deletePortfolioImageById(imageId, localStorage.getItem("token"));
        if (delete_image) {
          setLocalSubCategory((prev) => ({
            ...prev,
            image_list_saved: prev.image_list_saved.filter((image) => image.id !== imageId),
          }));
        } else {
          showErrorMessage("Impossible de supprimer l'image!");
        }
      }
    });
  };

  const getImageUrl = (profilePhoto) => {
    return `${BASE_URL}/images/beauticians_subcategories/${encodeURIComponent(profilePhoto)}`;
  };

  // Référence pour déclencher l'input file
  const fileInputRef = useRef();

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleEditSubmit = async () => {

    try {
      Swal.fire({
        title: 'Enregistrement en cours...',
        text: 'Veuillez patienter',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Montre le spinner
        },
      });

      const token = localStorage.getItem("token");

      const data = {
        beautician_id: activeID,
        subcategory_id: localSubCategory.id,
        price: Number(localSubCategory.price),
        duration: Number(localSubCategory.duration),
        description: localSubCategory.description,
        is_multiple_appointment: localSubCategory.is_multiple_appointment,
        number_of_appointment: localSubCategory.is_multiple_appointment ? localSubCategory.number_of_appointment : null,
      };

      const response = await beauticiansSubCategoriesApi.saveBeauticiansSubCategories(data, token);

      if (response.success) {
        if (response.data && response.data.subcategory_id) {
          // Mise à jour avec l'ID réel retourné
          const newId = response.data.subcategory_id;

          //on enregistre l'image
          let new_image = null;
          if (localSubCategory.image_list.length > 0) {
            const save_image = await beauticiansSubCategoriesApi.saveImageBeauticianSubcategory(response.data.id, token, localSubCategory.image_list);
            new_image = save_image.data;
            if (!save_image) {
              setTimeout(() => {
                showErrorMessage("Échec lors de l'enregistrement des images!");
              }, 200);
            }
          }

          setLocalSubCategory((prev) => ({
            ...prev,
            id: newId,
            image_list: [],
            image_list_saved: new_image ? [...prev.image_list_saved, new_image] : [...prev.image_list_saved]
          }));

        }
        setReloadTrigger(prev => !prev);
        setTimeout(() => {
          showSuccessMessage("Prestation enregistrée avec succès!");
          handleClose();
        }, 200);
      } else {
        setTimeout(() => {
          showErrorMessage("Échec de la sauvegarde du prestation.");
        }, 200);
      }
    } catch (error) {
      setTimeout(() => {
        showErrorMessage(error || "Une erreur s'est produite lors de l'enregistrement.");
      }, 200);
    }
  };

  return <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton className='p-0'>
      <Modal.Title>{prestation.service_subcategory_name}</Modal.Title>
    </Modal.Header>
    <Modal.Body className='px-0 py-2 overflow-y-auto'>
      <div className="flex flex-col gap-3">
        <div className='flex gap-2 flex-wrap justify-between'>
          <div className="flex flex-col gap-2">
            <label htmlFor='prix'>Prix en €: </label>
            <input
              type="number"
              id="prix"
              value={localSubCategory.price}
              min="1"
              onChange={(e) => handleFieldChange("price", parseFloat(e.target.value) || '')}
              className="w-24 px-2 py-1 border rounded"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor='duration'>Durée en minutes: </label>
            <input
              type="number"
              id="duration"
              value={localSubCategory.duration}
              min="1"
              onChange={(e) => handleFieldChange("duration", parseInt(e.target.value, 10) || '')}
              className="w-24 px-2 py-1 border rounded"
            />
          </div>
        </div>
        <div>
          <textarea
            placeholder="Ajouter une description"
            className="w-full p-3 outline-[#bd193b] rounded border shadow resize-none"
            rows={3}
            value={localSubCategory.description}
            onChange={(e) => handleFieldChange("description", e.target.value)}
          />
        </div>
        <div>
          <h3 className="font-semibold text-xl mb-3">Liste des images</h3>
          <div className='mb-3'>
            {localSubCategory.image_list_saved.length > 0 && <p>Images enregistrées</p>}
            {localSubCategory.image_list_saved.length > 0 ?
              <div className="flex gap-2 flex-wrap mt-2">
                {localSubCategory.image_list_saved.map((image) => (
                  <div
                    key={image.id}
                    className="relative w-24 h-24 border rounded overflow-hidden"
                  >
                    <img
                      src={getImageUrl(image.photo_url)}
                      alt="preview"
                      className="w-full h-full object-cover"
                    />
                    <button
                      onClick={() => handleSavedImageDelete(image.id)}
                      className="absolute top-1 right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center"
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
              :
              <p>Il n'y a pas d'images enregistrées</p>}
          </div>
          <div>
            {localSubCategory.image_list.length > 0 && <p>Nouvelles images</p>}
            <div className="flex gap-2 flex-wrap mt-2">
              {localSubCategory.image_list.map((image) => (
                <div
                  key={image.id}
                  className="relative w-24 h-24 border rounded overflow-hidden"
                >
                  <img
                    src={image.src}
                    alt="preview"
                    className="w-full h-full object-cover"
                  />
                  <button
                    onClick={() => handleImageDelete(image.id)}
                    className="absolute top-1 right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={triggerFileInput}
            className="flex mt-3 items-center gap-2 px-3 py-2 bg-blue-600 text-white rounded shadow hover:bg-blue-500"
          >
            <FaPlus /> Ajouter des images
          </button>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
            ref={fileInputRef}
            style={{ display: "none" }} // Cache l'input file
          />
        </div>
        <div className='mt-3 mb-3'>
          <div className='flex gap-2 items-center'>
            <input
              type='checkbox'
              id={`check-multiple-appointment${localSubCategory.id}`}
              checked={localSubCategory.is_multiple_appointment}
              onChange={(e) => handleFieldChange("is_multiple_appointment", e.target.checked)}
            />
            <label htmlFor={`check-multiple-appointment${localSubCategory.id}`}>Préstations avec plusieurs rendez-vous?</label>
          </div>
          {localSubCategory.is_multiple_appointment &&
            <div className='flex flex-col gap-2 mt-2'>
              <label>Nombre de rendez-vous du préstations :</label>
              <input
                type='number'
                value={localSubCategory.number_of_appointment}
                onChange={(e) => handleFieldChange("number_of_appointment", parseInt(e.target.value, 10) || 2)}
                className='px-3 py-2 bg-gray-50 border border-gray-400 rounded'
                min={2}
                placeholder='Nombre de rendez-vous'
              />
            </div>
          }
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer className='p-0'>
      <Button variant="secondary" onClick={handleClose}>
        Fermer
      </Button>
      <Button variant="primary" onClick={() => handleEditSubmit()}>
        Sauvegarder
      </Button>
    </Modal.Footer>
  </Modal>;
}

const ServiceCategory = ({ category, onDelete, onEdit, onShowAdd, setSelectedCategory }) => {
  const ServiceCategoryId = useId()
  const [items, setItems] = useState(category.items)
  const { profileData } = useContext(BeauticianPortalContext)

  const DraggableItem = ({ item, index, moveItem }) => {
    const [, drag] = useDrag({
      type: "ITEM-" + ServiceCategoryId, // Le type doit être identique dans useDrop
      item: { index }
    })

    const [{ isOver, canDrop }, drop] = useDrop({
      accept: "ITEM-" + ServiceCategoryId, // Le type doit être identique dans useDrag
      drop: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveItem(draggedItem.index, index)
          draggedItem.index = index
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    })

    return (
      <div
        ref={(node) => drag(drop(node))}
        key={item.beautician_subcategory_id}
        className={`${isOver ? 'drop-target-active' : 'drop-target'} service-card`}
      >
        <div className="service-card-header">
          <h4>{item.service_subcategory_name}</h4>
          <div className="service-actions">
            <button className="action-button" onClick={() => onEdit(item)}>
              <Edit2 size={16} />
            </button>
            <button className="action-button delete" onClick={() => onDelete(item.beautician_subcategory_id)}>
              <Trash2 size={16} />
            </button>
          </div>
        </div>

        <div className="service-details">
          <div className="service-info">
            <p className="service-description">{truncateText(item.beautician_subcategory_description, 60)}</p>
            <div className="service-specs">
              <span className="spec-item">
                <Euro size={16} />
                {formatMoneyNumber(item.beautician_subcategory_price)}
              </span>
              <span className="spec-item">
                <Clock size={16} />
                {item.beautician_subcategory_duration} min
              </span>
            </div>
            {item.is_multiple_appointment &&
              <div className="service-specs">
                <span className="spec-item">
                  Rendez-vous multiple : {item.number_of_appointment} rendez-vous
                </span>
              </div>
            }
          </div>
        </div>
        <ServiceImage images={item.images} />
      </div>
    )
  }

  const moveItem = async (fromIndex, toIndex) => {
    const tempItems = [...items]
    const oldDisplayOrder = items.map(i => i.display_order)

    // Recherche des éléments source et destination par leur ordre d'affichage
    const fromItem = tempItems.find(item => item.display_order === fromIndex)
    const toItem = tempItems.find(item => item.display_order === toIndex)

    // Si les éléments sont trouvés, les échanger
    if (fromItem && toItem) {
      try {
        const fromIndexInItems = tempItems.indexOf(fromItem)
        const toIndexInItems = tempItems.indexOf(toItem)

        // Échanger les éléments dans l'état
        tempItems[fromIndexInItems] = toItem
        tempItems[toIndexInItems] = fromItem

        const updatedItems = tempItems.map((item, index) => ({ ...item, display_order: oldDisplayOrder[index] }))

        await Promise.all([updatedItems.forEach(async (i) => {
          const token = localStorage.getItem("token")

          beauticiansSubCategoriesApi.updateBeauticianSubcategoryDisplayOrder(
            token,
            profileData.beautician_id,
            i.service_subcategory_id,
            i.display_order
          )
        })])

        setItems(updatedItems)
      }
      catch (error) {
        console.log(error.message)
      }
    }
  }

  return (
    <div className="service-category">
      <div className="category-header">
        <h3>{category.category_name}</h3>
      </div>

      <div className="prestations-grid">
        {items.map((service) =>
          <DraggableItem
            key={service.display_order}
            index={service.display_order}
            item={service}
            moveItem={moveItem}
          />
        )}
        {/* {category.items.map((service) => (
          <div key={service.beautician_subcategory_id} className="service-card">
            <div className="service-card-header">
              <h4>{service.service_subcategory_name}</h4>
              <div className="service-actions">
                <button className="action-button" onClick={() => onEdit(service)}>
                  <Edit2 size={16} />
                </button>
                <button className="action-button delete" onClick={() => onDelete(service.beautician_subcategory_id)}>
                  <Trash2 size={16} />
                </button>
              </div>
            </div>

            <div className="service-details">
              <div className="service-info">
                <p className="service-description">{truncateText(service.beautician_subcategory_description, 60)}</p>
                <div className="service-specs">
                  <span className="spec-item">
                    <Euro size={16} />
                    {formatMoneyNumber(service.beautician_subcategory_price)}
                  </span>
                  <span className="spec-item">
                    <Clock size={16} />
                    {service.beautician_subcategory_duration} min
                  </span>
                </div>
                {service.is_multiple_appointment &&
                  <div className="service-specs">
                    <span className="spec-item">
                      Rendez-vous multiple : {service.number_of_appointment} rendez-vous
                    </span>
                  </div>
                }
              </div>
            </div>
            <ServiceImage images={service.images} />
          </div>
        ))} */}

        <button className="add-service-card" onClick={() => { setSelectedCategory(category); onShowAdd(); }}>
          <Plus size={24} />
          <span>Ajouter un service</span>
        </button>
      </div>
    </div>
  );
};


const ImageScroller = ({ categoryImages }) => {
  const scrollRef = useRef(null)
  const [images, _setImages] = useState(Object.values(categoryImages)) // Récupère toutes les images
  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage(prev => (prev + 1) % images.length) // Change d'image automatiquement
    }, 5000)

    return () => clearInterval(interval)
  }, [images.length])

  const handleScrollLeft = () => {
    setCurrentImage(prev => (prev - 1 + images.length) % images.length) // Navigue à gauche
  }

  const handleScrollRight = () => {
    setCurrentImage(prev => (prev + 1) % images.length) // Navigue à droite
  }

  return (
    <div className="relative">
      <div className="overflow-hidden w-full">
        <div className="flex items-center justify-center space-x-4" ref={scrollRef}>
          {/* Afficher l'image actuelle */}
          <img src={images[currentImage]} alt="current-category" className="w-[80%] h-auto rounded-lg" />
        </div>
      </div>

      <h1 className="absolute left-[50%] py-1 px-2 text-lg text-center text-white bg-[#bd193b] rounded-lg transform -translate-x-[50%]" style={{ top: "85%", opacity: ".85"}}>
        {Object.keys(categoryImages)[currentImage]}
      </h1>


      {/* Flèche gauche */}
      <button
        onClick={handleScrollLeft}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg"
      >
        <ChevronLeft size={24} />
      </button>

      {/* Flèche droite */}
      <button
        onClick={handleScrollRight}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg"
      >
        <ChevronRight size={24} />
      </button>
    </div>
  )
}

const BeauticianServices = () => {

  const { prestations, allCategories, setReloadTrigger } = useContext(BeauticianPortalContext);
  const [editingPrestation, setEditingPrestation] = useState(null);
  const [showAddPrestation, setShowAddPrestation] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);


  const handleShowAddPrestation = () => setShowAddPrestation(true);
  const handleCloseAddPrestation = () => setShowAddPrestation(false);
  const handleEdit = (prestation) => setEditingPrestation(prestation);
  const handleClose = () => setEditingPrestation(null);

  const categoryImages = {
    "Soin du visage": soinDuVisage,
    "Soin dentaire": soinDentaire,
    "Maquillage": maquillage,
    "Épilation": epilation,
    "Onglerie": onglerie,
    "Beauté du regard": beauteRegard,
    "Soin du corps": soinDuCorps,
    "Coiffure": coiffure
  }

  const handleDeletePrestation = (beautician_subcategory_id) => {
    Swal.fire({
      title: "Voulez vous vraiment supprimer cette préstation?",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer",
      confirmButtonColor: "#dc2626",
      cancelButtonText: `Non`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const delete_req = await beauticiansSubCategoriesApi.deleteBeauticianCategoryById(beautician_subcategory_id, localStorage.getItem('token'));
          if (delete_req.success) {
            showSuccessMessage('Préstation supprimée avec succès!');
            setTimeout(() => {
              setReloadTrigger(prev => !prev);
            }, 300)
          } else {
            showErrorMessage(delete_req.message);
          }
        } catch (error) {
          showErrorMessage(error);
        }
      }
    });
  }

  return <>
    {/* Affichage Desktop */}
    <div className="hidden md:block">
      <div className="services-manager">
        <header className="services-header">
          <div className="header-content">
            <h1>Mes Services</h1>
          </div>

          <div className="header-actions">
            <button className="action-button">
              <MoreVertical size={20} />
            </button>
          </div>
        </header>

        <div className="services-content">
          {prestations.map((category) => (
            <ServiceCategory
              key={category.category_id}
              category={category}
              onDelete={handleDeletePrestation}
              onEdit={handleEdit}
              onShowAdd={handleShowAddPrestation}
              setSelectedCategory={setSelectedCategory}
            />
          ))}
          {allCategories.map((category, index) => {
            if (!prestations.some((obj) => obj.category_id === category.category_id)) {
              return <ServiceCategory
                key={index}
                category={{ category_name: category.category_name, category_id: category.category_id, items: [] }}
                onEdit={() => { }}
                onDelete={() => { }}
                handleShow={() => { }}
                onShowAdd={handleShowAddPrestation}
                setSelectedCategory={setSelectedCategory}
              />;
            }
          })}
        </div>
      </div>
    </div>

    {/* Affichage Mobile */}
    <div className="md:hidden">
      <h1 className="py-2 text-2xl text-center font-bold">Mes services</h1>

      <div className="px-4">
        <h2 className="text-lg font-semibold">Catégorie</h2>
      </div>

      <div className="px-3 py-2 space-y-4">
        <Accordion
          defaultActiveKey="0"
          className="space-y-4"
          flush
        >
          {prestations.map((category, eventKey) => (
            <Accordion.Item
              eventKey={eventKey}
              key={`category-${category.category_id}`}
            >
              <Accordion.Header>
                <img
                  width={32}
                  height={32}
                  className="rounded-full me-3"
                  src={categoryImages[category.category_name.includes("pilation") ? "Épilation" : category.category_name]}
                />
                {category.category_name}</Accordion.Header>
              <Accordion.Body
                className="grid grid-cols-2 gap-2"
              >
                {category.items.map((item) => (
                  <div
                    key={`subcategory-${item.beautician_subcategory_id}`}
                    className="p-2 bg-gray-200 rounded-lg"
                  >
                    <h3 className="text-[.75em] text-center">{item.service_subcategory_name}</h3>

                    <div
                      className="px-3 pb-2 flex items-center justify-between *:cursor-pointer"
                      style={{ borderBottom: "1px solid #ccc" }}
                    >
                      <Pencil
                        size={16}
                        onClick={() => handleEdit(item)}
                      />
                      <Trash
                        size={16}
                        onClick={() => handleDeletePrestation(item.beautician_subcategory_id)}
                      />
                    </div>

                    <div className="px-2 py-2 flex items-center justify-between">
                      <span className="text-sm font-semibold flex items-center gap-1">
                        <Euro size={16} />
                        {formatMoneyNumber(item.beautician_subcategory_price)}
                      </span>

                      <span className="text-sm font-semibold flex items-center gap-1">
                        <Clock size={16} />
                        {item.beautician_subcategory_duration} min
                      </span>
                    </div>

                  </div>
                ))}

                <button
                  className="min-h-4 gap-2 flex flex-col items-center justify-center min-w-4 rounded-lg text-[#ccc] hover:border-[#bd193b] hover:text-[#bd193b] duration-200"
                  style={{ border: "dashed 2px #ccc" }}
                  onClick={() => { setSelectedCategory(category); handleShowAddPrestation(); }}>
                  <Plus size={24} />
                  <span className="text-sm">Ajouter un service</span>
                </button>
              </Accordion.Body>
            </Accordion.Item>))
          }

        </Accordion>
        <Accordion
          defaultActiveKey="0"
          className="space-y-4"
          flush
        >
          {allCategories.map((category, eventKey) => {
            if (!prestations.some((obj) => obj.category_id === category.category_id)) {
              return <Accordion.Item
                eventKey={eventKey}
                key={`category-${category.category_id}`}
              >
                <Accordion.Header>
                  <img
                    width={32}
                    height={32}
                    className="rounded-full me-3"
                    src={categoryImages[category.category_name.includes("pilation") ? "Épilation" : category.category_name]}
                  />
                  {category.category_name}
                </Accordion.Header>
                <Accordion.Body
                  className="grid grid-cols-2 gap-2"
                >
                  <button
                    className="py-4 gap-2 flex flex-col items-center justify-center min-w-4 rounded-lg text-[#ccc] hover:border-[#bd193b] hover:text-[#bd193b] duration-200"
                    style={{ border: "dashed 2px #ccc" }}
                    onClick={() => { setSelectedCategory(category); handleShowAddPrestation(); }}>
                    <Plus size={24} />
                    <span className="text-sm">Ajouter un service</span>
                  </button>
                </Accordion.Body>
              </Accordion.Item>
            }
          })}
        </Accordion>
      </div>

      <div className="p-4">
        <h2 className="text-lg font-semibold">Consulter les prestations en tendance</h2>

        <p className="text-gray-600">Formez-vous afin de rajouter des nouvelles prestations sur votre carte grâce à Planibeauty</p>

        <div className="flex flex-col md:flex-row gap-4 items-center">
          <div className="flex-1">
            <ImageScroller categoryImages={categoryImages} />
          </div>

          <div className="flex-1 p-4">
            <div className="p-4 bg-gray-100 rounded-lg space-y-3">
              <div className="flex items-center gap-3 text-gray-800">
                <Calendar size={24} stroke="#bd193b" />
                <span>
                  Prendre rdv via ce lien avec une conseillère Planibeauty
                </span>
              </div>

              <div className="flex items-center gap-3 text-gray-800">
                <Phone size={24} stroke="#bd193b" />
                <span>01 89 00 00 00</span>
              </div>

              <div className="flex items-center gap-3 text-gray-800">
                <Mail size={24} stroke="#bd193b" />
                <span>contact@planibeauty.com</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    {/* Render EditPrestation modal only when there's a prestation being edited */}
    {editingPrestation && (
      <EditPrestation
        setReloadTrigger={setReloadTrigger}
        show={Boolean(editingPrestation)}
        handleClose={handleClose}
        prestation={editingPrestation}
      />
    )}
    {showAddPrestation && (
      <AddPrestationModal
        show={showAddPrestation}
        handleClose={handleCloseAddPrestation}
        selectedCategory={selectedCategory}
        setReloadTrigger={setReloadTrigger}
      />
    )}
  </>;
};

export default BeauticianServices;