import React from 'react';
import './LeadsTable.css';
import LeadsTableRow from './LeadsTableRow';

function LeadsTable({ leads, selectedLeads, onLeadSelect }) {
  return (
    <div className="leads-table-container">
      <table className="leads-table">
        <thead>
          <tr>
            <th className="th-checkbox">#</th>
            <th>Liens</th>
            <th>Téléphone</th>
            <th>Date leads</th>
            <th>Ville</th>
            <th>CP</th>
            <th>Date statut</th>
            <th>Statut</th>
            <th>Commentaire</th>
            <th>Attribuer à</th>
            <th>Pro</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {leads.map(lead => (
            <LeadsTableRow
              key={lead.id} 
              lead={lead} 
              isSelected={selectedLeads.includes(lead.id)}
              onSelect={() => onLeadSelect(lead.id)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LeadsTable;