import { ArrowDownRight } from "lucide-react";

const CallToAction = () => {
  return (
    <div className="flex flex-col items-center justify-center text-center space-y-8 py-10 px-4">
      <h2 className="text-xl md:text-3xl font-semibold">
        Besoin de plus d’infos ? Nos conseillers sont là pour vous aider
      </h2>
      <button onClick={() => window.location.href = 'https://calendly.com/contact-planibeauty/inscription-site-planibeauty'} className="flex items-center justify-between bg-gray-100 hover:bg-gray-200 transition-all rounded-full px-6 py-3 shadow-md w-full max-w-xs md:max-w-md">
        <span className="text-sm md:text-base font-semibold">
          S’inscrire et prendre rdv avec une conseillère
        </span>
        <span className="bg-red-800 text-white rounded-full p-2 ml-3 flex items-center justify-center hover:bg-red-900 transition-all">
          <ArrowDownRight size={20} />
        </span>
      </button>
    </div>
  );
};

export default CallToAction;
