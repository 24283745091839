import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "../../utils/constants";
import SubscriptionForm from "./SubscriptionForm";
import { subscriptionLinksApi } from "../../api/subscription_links.api";


const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const SubscriptionWithLink = () => {
    const [link, setLink] = useState(null);


    useEffect(() => {
        const checkIfLinkValid = async () => {
            try {
                const url_array = window.location.href.split('/');
                const slug = url_array[url_array.length - 1];
                let res = await subscriptionLinksApi.getBySlug(slug);
                console.log(res);
                setLink(res);
            } catch (error) {
                //window.location.href = "/404";
            }
        }

        checkIfLinkValid();

    }, []);

    return <div className="py-[5vh] min-h-[70vh]">
        {link &&
            <Elements stripe={stripePromise}>
                <SubscriptionForm link={link} />
            </Elements>}
    </div>
}


export default SubscriptionWithLink;