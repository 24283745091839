import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import SelectAppointmentDate from "./SelectAppointmentDate";
import { showErrorMessage, showInformationMessage, showSuccessMessage, showWarningMessage } from "../../../../utils/messageHelper";
import { BeauticianPortalContext } from "../../../../context/BeauticianPortalContext";
import { format } from "date-fns";
import appointmentApi from "../../../../api/appointement.api";
import Swal from "sweetalert2";
import { addMinutesToTime, formatTime, validatePhoneNumber, validEmail } from "../../../../utils/functions";
import { API_URL, BASE_URL } from "../../../../utils/constants";
import { fr } from "date-fns/locale";
import ClientSelect from "./ClientSelect";
import employeeApi from "../../../../api/employees.api";
import { sendSmsChangeRdvStatusNotification } from "../../../../utils/smsServices";


const PlanifyAppointmentModal = ({ showModal, handleCloseModal }) => {

    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedCollaborateur, setSelectedCollaborateur] = useState('');
    const [totalPrices, setTotalPrices] = useState(0);

    const { profileData, setReloadTrigger, prestations } = useContext(BeauticianPortalContext);
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        is_new_address: false,
        new_address: "",
        is_new_time: false,
        new_time: 0,
        commentaire: ""
    });
    const [duration, setDuration] = useState(0);
    const [selectedServices, setSelectedServices] = useState([]);

    const remplirChamps = (selectedClient) => {
        setFormData(prevData => ({
            ...prevData,
            email: selectedClient?.unique_email || '',
            phone: selectedClient?.phone || '',
            first_name: selectedClient?.first_name || '',
            last_name: selectedClient?.last_name || ''
        }));
    };

    useEffect(() => {
        let total_duration = 0;
        let total_prices = 0;
        selectedServices.forEach((element) => {
            total_duration += element.duration;
            total_prices += parseFloat(element.price_at_booking);
        });
        setDuration(total_duration);
        setTotalPrices(total_prices);
    }, [selectedServices]);

    const handleFieldChange = (value, field) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }))
    }

    const handlePlanifyNextAppointment = async () => {

        if (formData.first_name === "" || formData.last_name === "") {
            showWarningMessage('Les champs nom et prénom sont obligatoires!');
            return;
        }

        if (formData.email === "" && formData.phone === "") {
            showWarningMessage("Au moins le champ email ou numéro de téléphone doit être remplis!");
            return;
        }

        if (selectedDate.length === 0) {
            showWarningMessage("Veuillez selectionner au moins un service!");
            return;
        }

        if (!selectedDate) {
            showWarningMessage('Selectionnez une date!');
            return;
        }

        if (!selectedTime) {
            showWarningMessage('Selectionnez l\'heure du rendez-vous!');
            return;
        }

        if (formData.is_new_address && (!formData.new_address || formData.new_address === "")) {
            showWarningMessage('Preciser une adresse si vous choissez l\'option nouveau adresse!');
            return;
        }

        if (formData.is_new_time && (!formData.new_time || formData.new_time <= 0)) {
            showWarningMessage('Veuillez entrez une durée valide si vous choisissez l\'option nouveau durée!');
            return;
        }

        let appointmentData = {
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            phone: formData.phone,
            beautician_id: profileData.beautician_id, // beautician id
            appointment_date: format(selectedDate, 'yyyy-MM-dd'),
            start_time: selectedTime,
            end_time: addMinutesToTime(selectedTime, formData.is_new_time ? formData.new_time : duration),
            services: selectedServices,
            is_planified_appointment: true,
            total_prices: selectedServices.reduce((acc, service) => acc + service.price_at_booking, 0),
            employee_id: selectedCollaborateur ? parseInt(selectedCollaborateur) : null,
            commentaire: formData.commentaire ?? null,
            new_address: formData.new_address ?? null,
        };

        //on verifie si le créneaux est toujours disponible

        const result = await appointmentApi.isAppointmentAvailable(appointmentData.beautician_id, appointmentData.appointment_date, appointmentData.start_time, appointmentData.end_time, selectedCollaborateur ? parseInt(selectedCollaborateur) : null);
        if (result.success && result.is_available) {
            try {
                // Afficher le loader
                Swal.fire({
                    title: 'Veuillez patienter...',
                    text: 'Traitement en cours...',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                // Créer le rendez-vous
                const create_appointment = await appointmentApi.createManualAppointment(
                    localStorage.getItem('token'),
                    appointmentData
                );

                if (create_appointment.success) {

                    //si email du client donné, envoyé email de confirmation
                    if (validEmail(formData.email)) {

                        let services_list = '';
                        appointmentData.services.forEach((service) => {
                            services_list += service.subcategory_name;
                            services_list += ", ";
                        });
                        services_list = services_list.slice(0, -2);

                        let name = profileData.business_name ?? `${profileData.first_name} ${profileData.last_name}`;

                        let collaborateur = null;
                        if (selectedCollaborateur) {
                            const res = await employeeApi.getEmployeeById(localStorage.getItem('token'), parseInt(selectedCollaborateur));
                            if (res.success) {
                                collaborateur = res.data;
                            }
                        }

                        let message = `
                            <p>Nous vous informons que vous avez un rendez-vous planifié ${' '}
                            ${collaborateur ? `chez ${name} avec le collaborateur ${collaborateur.name}` : `avec <b>${name}</b>`} ${' '}
                            le  <b>${format(appointmentData.appointment_date, 'EEEE d MMMM yyyy', { locale: fr })}</b> de <b>${formatTime(appointmentData.start_time)} à ${formatTime(appointmentData.end_time)}</b> pour 
                                les services suivantes : <b>${services_list}</b>.</p>
                                <p>
                                <b>Adresse</b> : ${formData.is_new_address ? formData.new_address : profileData.address} <br />
                                </p>
                                <p>
                                <b>Commentaire</b> : ${formData.commentaire ?? 'Pas de commentaire'} <br />
                                </p>
                            
                        `;

                        const emailData = {
                            to: formData.email,
                            subject: 'Notification de rendez-vous',
                            name: `${formData.first_name} ${formData.last_name}`,
                            message: message,
                            link: `${BASE_URL}`,
                            link_text: 'Visiter le site web',
                        };
                        const send = await fetch(`${API_URL}/email/send-email-notification`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(emailData),
                        });
                    }

                    if(validatePhoneNumber(formData.phone)) {
                        sendSmsChangeRdvStatusNotification(
                            formData.phone,
                            `Bonjour ${formData.first_name} ${formData.last_name},\nVous avez un rendez-vous planifié avec ${profileData.business_name ?? `${profileData.first_name} ${profileData.last_name}`} le ${format(appointmentData.appointment_date, 'dd/MM/yyyy')} de ${formatTime(appointmentData.start_time)} à ${formatTime(appointmentData.end_time)}.\nRDV & messages sur https://www.planibeauty.fr`
                        );
                    }

                    showSuccessMessage('Le rendez-vous a été ajouté à votre agenda!');
                    setReloadTrigger(prev => !prev);
                    setSelectedDate('');
                    setSelectedTime('');
                    setSelectedCollaborateur('');
                    handleCloseModal();
                } else {
                    // Afficher une erreur si la création de la réservation échoue
                    showErrorMessage('Une erreur est survenue lors de la réservation.');
                }
            } catch (error) {
                // Gérer les erreurs générales
                showErrorMessage('Une erreur est survenue. Veuillez réessayer.');
            }
        } else if (result.success && !result.is_available) {
            showInformationMessage('Désolé mais ce créneau n\'est plus disponible, veuillez choisir un autre!');
        } else {
            showErrorMessage("Erreur interne du serveur");
        }

    }

    const handleCancel = () => {
        setSelectedDate('');
        setSelectedTime('');
        setSelectedServices([]);
        setSelectedCollaborateur('');
        handleCloseModal();
    }

    const handleSelectService = (beautician_subcategory) => {
        // si il est présent on le supprime
        if (selectedServices.some((o) => o.beautician_subcategory_id === beautician_subcategory.beautician_subcategory_id)) {
            setSelectedServices(selectedServices.filter((element) => element.beautician_subcategory_id !== beautician_subcategory.beautician_subcategory_id));
        } else { // sinon on ajoute
            let new_array = [...selectedServices, {
                beautician_subcategory_id: beautician_subcategory.beautician_subcategory_id,
                price_at_booking: beautician_subcategory.beautician_subcategory_price,
                duration: beautician_subcategory.beautician_subcategory_duration,
                subcategory_name: beautician_subcategory.service_subcategory_name
            }]
            setSelectedServices(new_array);
        }
    }

    return (
        <Modal
            show={showModal}
            onHide={handleCancel}
            centered
            backdropClassName="secondary-backdrop"
            style={{ zIndex: 1051 }}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Planifier un rendez-vous</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overflow-y-scroll">

                <Row className='mb-2'>
                    <h5>Informations sur le client</h5>
                    <Col md={12} className="mb-3">
                        <Form.Group controlId="client">
                            <Form.Label>Rechercher parmi mes clients pour préremplir les champs</Form.Label>
                            <ClientSelect action={remplirChamps} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="first_name">
                            <Form.Label>Prénom *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Entrez le prénom"
                                name="first_name"
                                value={formData.first_name}
                                onChange={(e) => handleFieldChange(e.target.value, 'first_name')}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="last_name">
                            <Form.Label>Nom *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Entrez le nom"
                                name="last_name" value={formData.last_name}
                                onChange={(e) => handleFieldChange(e.target.value, 'last_name')}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mb-2'>
                    <Col md={6}>
                        <Form.Group controlId="phone">
                            <Form.Label>Téléphone **</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Entrez le numéro de téléphone"
                                name="phone" value={formData.phone}
                                onChange={(e) => handleFieldChange(e.target.value, 'phone')}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="email">
                            <Form.Label>Email **</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Entrez l'email"
                                name="email" value={formData.email}
                                onChange={(e) => handleFieldChange(e.target.value, 'email')}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mt-3">
                        <h5>Selectionnez les services</h5>
                        <div>
                            {prestations.map(category => {
                                return <React.Fragment key={category.category_id}>
                                    <h6>{category.category_name}</h6>
                                    <div className="mb-3 flex items-center gap-3">
                                        {category.items.map(sous_cat => {
                                            return <div className="flex items-center gap-2" key={sous_cat.beautician_subcategory_id}>
                                                <input type="checkbox" checked={selectedServices.some((o) => o.beautician_subcategory_id === sous_cat.beautician_subcategory_id)} onChange={() => handleSelectService(sous_cat)} name={`sous-cat-${sous_cat.beautician_subcategory_id}`} id={`sous-cat-${sous_cat.beautician_subcategory_id}`} />
                                                <label htmlFor={`sous-cat-${sous_cat.beautician_subcategory_id}`} >{sous_cat.service_subcategory_name}</label>
                                            </div>
                                        })}
                                    </div>
                                </React.Fragment>
                            })}
                        </div>
                    </Col>
                </Row>
                {selectedServices.length > 0 && <Row>
                    <Col md={12}>
                        <h5>Selectionnez la date et l'heure</h5>
                        <SelectAppointmentDate
                            selectedDate={selectedDate}
                            selectedTime={selectedTime}
                            setSelectedDate={setSelectedDate}
                            setSelectedTime={setSelectedTime}
                            duration={duration}
                            selectedCollaborateur={selectedCollaborateur}
                            setSelectedCollaborateur={setSelectedCollaborateur}
                        />
                    </Col>
                </Row>}
                {(selectedDate && selectedTime) &&
                    <>
                        <Row className="">
                            <h5 className="mb-3">Informations sur le rendez-vous</h5>
                            <Col md={12} className="">
                                <p><b>Montant total des services :</b> { totalPrices } €</p>
                                {!formData.is_new_time && <p><b>Durée totale :</b> {duration} minutes</p>}
                                <div>
                                    <label htmlFor="new-time">
                                        <input type="checkbox"
                                            name="new-time" id="new-time"
                                            checked={formData.is_new_time} value={formData.is_new_time}
                                            onChange={(e) => handleFieldChange(e.target.checked, 'is_new_time')}
                                        />{" "} Voulez-vous changer la durée?
                                    </label>
                                </div>
                                <div className="mt-2">
                                    {formData.is_new_time && <Form.Group controlId="newTime">
                                        <Form.Label>Nouvelle durée *</Form.Label>
                                        <Form.Control
                                            type="number"
                                            min={0}
                                            placeholder="Entrez la nouvelle durée"
                                            name="newTime"
                                            onChange={(e) => handleFieldChange(e.target.value, 'new_time')}
                                        />
                                    </Form.Group>}
                                </div>
                            </Col>
                            <Col md={12} className="mt-4">
                                {!formData.is_new_address && <p><b>Adresse à envoyer au client :</b> {profileData?.address}</p>}
                                <div className="mt-2">
                                    <label htmlFor="new-address">
                                        <input type="checkbox"
                                            name="new-address" id="new-address"
                                            checked={formData.is_new_address}
                                            value={formData.is_new_address}
                                            onChange={(e) => handleFieldChange(e.target.checked, 'is_new_address')}
                                        />{" "} Voulez-vous changer l'adresse à envoyer au client?
                                    </label>
                                </div>
                                <div>
                                    {formData.is_new_address && <Form.Group controlId="new_address">
                                        <Form.Label>Nouvelle adresse *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Entrez la nouvelle adresse"
                                            name="new_address" value={formData.new_address}
                                            onChange={(e) => handleFieldChange(e.target.value, 'new_address')}
                                        />
                                    </Form.Group>}
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group controlId="commentaire">
                                <Form.Label>Ajouter un commentaire :</Form.Label>
                                <Form.Control as="textarea"
                                    type="text"
                                    rows={3}
                                    placeholder="Commentaire..."
                                    name="commentaire" value={formData.commentaire}
                                    onChange={(e) => handleFieldChange(e.target.value, 'commentaire')}
                                />
                            </Form.Group>
                        </Row>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                    Annuler
                </Button>
                <Button variant="primary" onClick={handlePlanifyNextAppointment}>
                    Planifier le rendez-vous
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PlanifyAppointmentModal;