import React from 'react'
import { Revenus } from './CustomIcon'

const RevenusTeleconseillerDuJour = () => {
	const rappels = [
		{ value: 25, reason: "5 essais", details: "période sans prélèvement" },
		{ value: 75, reason: "5 dépassements d'un leads", details: "prélèvement une fois l'essai fini" },
		{ value: 100, reason: "5 dépassements d'un proche", details: "prélèvement une fois l'essai fini d'un leads de votre part" },
		{ value: 25, reason: "5 dépassements d'un proche", details: "prélèvement une fois l'essai fini d'un leads de votre part", up: true },
		{ value: 50, reason: "1 pack communication", details: "achat d'un pack communication", up: true }
	]

	return (
		<div className="col-span-4 md:col-span-2 min-h-full flex flex-col rounded border shadow-sm bg-white md:overflow-hidden">
			{/* En-tête fixe */}
			<div className="p-4 flex flex-col items-center gap-1 shrink-0">
                <h3 className="text-center text-lg text-gray-600">
                    Revenus Téléconseiller du jour
                </h3>
                <div className="w-1/4 h-1 bg-[#bd193b] rounded"></div>
            </div>

			<div className="flex items-center justify-center">
				<div className="px-4 py-2 flex flex-col items-center gap-2 bg-gray-100 rounded">
					<span>Revenus Téléconseiller</span>

					<div className="flex items-center gap-2">
						<Revenus size={24} />

						<span className="text-xl font-semibold">100 €</span>
					</div>
				</div>
			</div>

			<h3 className="mt-3 text-center text-lg text-[#bd193b]">Rappel revenus Téléconseiller</h3>
			{/* Zone de contenu scrollable */}
			<div className="flex-1 min-h-0 md:overflow-y-auto p-2 py-4 md:p-4">

				{rappels.map((r, key) => (
					<div
						key={`rappel-key-${key}`}
						className="px-2 grid grid-cols-4 gap-0"
					>
						<h1 className={`col-span-1 text-xl font-bold ${r.up && "text-[#bd193b]"}`}>
							{r.up && "+"}{r.value}€
						</h1>
						<p className="col-span-3 text-sm">
							<span className="text-[#bd193b]">{r.reason} : </span>
							<span>{r.details}</span>
						</p>
					</div>
				))}
			</div>
		</div>
	)
}

export default RevenusTeleconseillerDuJour
