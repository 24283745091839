// constants.js
const BASE_URL = process.env.NODE_ENV === "production" 
  ? process.env.REACT_APP_URL_PROD 
  : process.env.REACT_APP_URL;

const API_URL = `${BASE_URL}/api`;

const REDIRECT_AFTER_LOGOUT = process.env.NODE_ENV === "production"
  ? process.env.REACT_APP_URL_PROD
  : "http://localhost:3000";

const REACT_APP_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_URL_PROD : "http://localhost:3000";

const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

// Export pour ES Modules
export { BASE_URL, API_URL, REDIRECT_AFTER_LOGOUT, STRIPE_PUBLISHABLE_KEY, REACT_APP_URL };