import React, { useState, useEffect, useContext } from 'react';
import abonnementApi from '../../../../../api/abonnement.api';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../../../../../utils/constants';
import { showInformationMessage, showSuccessMessage, showWarningMessage } from '../../../../../utils/messageHelper';
import Swal from 'sweetalert2';
import logo from "../../../../../assets/images/planibeauty192.png";
import tarif1 from '../../../../../assets/tarifs/1.png';
import tarif2 from '../../../../../assets/tarifs/2.png';
import tarif3 from '../../../../../assets/tarifs/3.png';
import tarif4 from '../../../../../assets/tarifs/4.png';
import tarif5 from '../../../../../assets/tarifs/5.png';
import offreApi from '../../../../../api/offre.api';
import { BeauticianPortalContext } from '../../../../../context/BeauticianPortalContext';
import ShowHistoryAbonnementModal from './ShowHistoryAbonnementModal';
import promoCodesApi from '../../../../../api/promo_codes.api';
import testeurApi from '../../../../../api/testeur.api';
import AbonnementStatus from './AbonnementStatus';
import PaymentForm from './PaymentForm';
import ShowFactoryModal from './ShowFactureModal';
import { CreditCard, Gift } from 'lucide-react';
import AbonnementCard from '../../../../../pages/tarifs/AbonnementCard';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const AbonnementComponent = ({ userId }) => {

    const { abonnementActive, setAbonnementActive, isOnFreeTrial, trial, profileData, isAbonnementAttribued } = useContext(BeauticianPortalContext);

    //const [months, setMonths] = useState(1);
    const [abonnement, setAbonnement] = useState(null);
    const [reloadLocalTrigger, setReloadLocalTrigger] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [offres, setOffres] = useState([]);
    const [offresIndep, setOffresIndep] = useState([]);
    const [offresEtab, setOffresEtab] = useState([]);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [activeOffer, setActiveOffer] = useState(null);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [showFactureModal, setShowFactureModal] = useState(false);
    const [method, setMethod] = useState(null);
    const [code, setCode] = useState('');
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [isTesteur, setIsTesteur] = useState(false);
    const [existingPromoCode, setExistingPromoCode] = useState(null);
    const [abonnementAuto, setAbonnementAuto] = useState(profileData.abonnement_auto);
    const [step, setStep] = useState(1);

    const appearance = {
        theme: "stripe",
        variables: {
            colorPrimary: "#6772e5",
            colorBackground: "#f6f9fc",
            colorText: "#424770",
            borderRadius: "8px",
        },
    };

    const handleCardOfferClick = (offer, method) => {
        // setMethod(null);
        setSelectedOffer(offer);
        setMethod(method);
        if(method !== "code-promo") {
            setShowPaymentForm(true);
        }
        setStep(3);
        //nextStep();
    }

    const handleSelectMethod = (method) => {
        setShowPaymentForm(false);
        setCode("");
        setExistingPromoCode(null);
        setMethod(method);
        nextStep();
        if (method !== "code-promo") {
            setShowPaymentForm(true);
        }
    }

    const handleCloseHistoryModal = () => {
        setShowHistoryModal(false);
    }

    const handleCloseFactureModal = () => {
        setShowFactureModal(false);
    }

    useEffect(() => {

        const checkTesteur = async () => {
            const is_testeur = await testeurApi.is_user_testeur(localStorage.getItem('token'), userId);
            setIsTesteur(is_testeur);
        }

        async function fetchAbonnementEtOffres() {
            setLoading(true); // Démarrer le spinner
            const token = localStorage.getItem('token');
            try {
                const data = await abonnementApi.getAbonnementByUserId(token, userId);
                let offre_id = null;
                if (data) {
                    offre_id = data.offre_id;
                }
                setAbonnement(data);
                const _offres = await offreApi.getAllOffer();
                let _selectedOffer = _offres.find((element) => element.id === offre_id);
                if (_selectedOffer) {
                    setActiveOffer(_selectedOffer);
                    setSelectedOffer(_selectedOffer);
                }
                setOffres(_offres);
                let _offresIndep = _offres.filter((element) => !element.name.toLowerCase().includes("équipe"));
                let _offresEtab = _offres.filter((element) => !element.name.toLowerCase().includes("classique"));
                setOffresIndep(_offresIndep);
                setOffresEtab(_offresEtab);
            } catch (error) {
                console.error('Erreur lors de la récupération de l\'abonnement:', error);
            } finally {
                setLoading(false); // Arrêter le spinner
            }
        }

        setSelectedOffer(null);
        setCode("");
        setExistingPromoCode(null);
        fetchAbonnementEtOffres();
        checkTesteur();
    }, [userId, reloadLocalTrigger]);

    useEffect(() => {
        const checkUrlParams = async () => {
            const url = new URL(window.location.href);
            const _offre = url.searchParams.get("offre");
            const _show_form = url.searchParams.get("show_form");
            if (_offre) {
                const _offres = await offreApi.getAllOffer();
                let _selectedOffer = _offres.find((element) => element.name.toLowerCase().includes(_offre.toLowerCase()));
                if (_selectedOffer) {
                    setActiveOffer(_selectedOffer);
                    setSelectedOffer(_selectedOffer);
                    setStep(2);
                    setShowForm(true);
                }

            }

            if(_show_form) {
                setShowForm(true);
            }
        }

        checkUrlParams();
    }, []);

    useEffect(() => {
        if (profileData.is_first_login && !abonnementActive) {
            setShowForm(true);
        }
    }, [profileData, abonnementActive]);

    useEffect(() => {
        if (window.location.search.includes('active=abonnement') && !abonnementActive) {
            showInformationMessage("Vous n'avez pas d'abonnement actif, veuillez activer un sur le formulaire ci-dessous!")
        }
    }, [abonnementActive]);

    const handleVerifyCode = async () => {
        if (code === "") {
            showWarningMessage('Veuillez entrez le code!');
        }

        setExistingPromoCode(null);
        setShowPaymentForm(false);

        let data = {
            offre_id: selectedOffer.id,
            code: code
        }

        const res = await promoCodesApi.checkPromotion(localStorage.getItem('token'), data);


        if (Array.isArray(res) && res.length > 0) {
            setExistingPromoCode(res[0]);
            setShowPaymentForm(true);
        } else {
            showWarningMessage('Le code n\'existe pas ou il n\'est plus valide!');
        }
    }

    const handleChangeOfferClick = () => {
        Swal.fire({
            icon: 'question',
            title: 'Important',
            text: 'Changer l\'offre signifie que vous abandonnez l\'abonnement actuel, aucun remboursement ne sera effectué!',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Oui, confirmer",
            cancelButtonText: "Non"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await abonnementApi.endActiveAbonnement(localStorage.getItem('token'), userId);
                setReloadLocalTrigger(prev => !prev);
                setAbonnementActive(false);
                showSuccessMessage("L'offre n'est plus active, vous pouvez acheter un nouveau abonnement!");
            }
        });
    }

    const handleChangeAbonnementAutoStatus = (status) => {
        let title = status ? "Activer l'abonnement automatique?" : "Etes-vous sur d\'annuler l\'abonnement automatique?";
        let text = status ? "Vous pouvez le desactiver à tout moment!" : "Vous pouvez le reactiver dans un délais de trois mois, après ce délais, si il n\'y pas d\'activité, votre compte sera desactivé!";


        Swal.fire({
            icon: 'question',
            title: title,
            text: text,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Oui, confirmer",
            cancelButtonText: "Non"
        }).then(async (result) => {
            if (result.isConfirmed) {

                if (status === false) {
                    const res = await abonnementApi.cancelSubscriptionRenewal(localStorage.getItem('token'), profileData.user_id);
                    setAbonnementAuto(status);
                    if (isOnFreeTrial) {
                        showSuccessMessage("Vous venez de desactiver la réabonnement automatique, votre essaie gratuit est terminée, vous pouvez vous réabonner en choisissant l'offre qui vous convient!");
                        setTimeout(() => {
                            window.location.reload();
                        }, 4000);
                    } else {
                        showSuccessMessage("Vous venez de desactiver la réabonnement automatique, vous pouvez le reactiver à tout moment avant la fin de votre abonnement, aprés ce délais vous devriez en acheter de nouveau pour le réactiver!");
                    }
                } else {
                    showInformationMessage("Cette fonctionnalité n'est pas encore disponible!");
                }

                //await beauticianApi.changeAbonnementAutoStatus(localStorage.getItem('token'), profileData.beautician_id, status);
                //setReloadLocalTrigger(prev => !prev);
            }
        });
    }

    const handleEndAbonnement = () => {
        let title = "Etes-vous sur de résilier l'abonnement courant?";
        let text = "Cette action est irreversible, vous ne pourrez plus accéder à vos outils de travail, vous pouvez acheter un nouveau abonnement à tout moment!";

        Swal.fire({
            icon: 'question',
            title: title,
            text: text,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Oui, confirmer",
            cancelButtonText: "Non"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await abonnementApi.endActiveAbonnement(localStorage.getItem('token'), profileData.user_id);

                showSuccessMessage("Vous venez de terminer votre abonnement courant. Vous pouvez vous réactiver votre abonnement en achetant l'offre qui vous convient!");

                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }
        });
    }

    function calculateMonthsDifference(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const yearDiff = end.getFullYear() - start.getFullYear();
        const monthDiff = end.getMonth() - start.getMonth();

        return yearDiff * 12 + monthDiff;
    }

    const nextStep = () => {
        if (step < 3) {
            setStep(prev => prev + 1);
        }
    }

    const prevStep = () => {
        if (step > 1) {
            setStep(prev => prev - 1);
        }
    }

    const clearForm = () => {
        setStep(1);
        setSelectedOffer(null);
        setMethod(null);
        setCode("");
    }

    const getOffreImages = (offre_name) => {
        if(offre_name.toLowerCase().includes("classique")) {
            return tarif1;
        } else if(offre_name.toLowerCase().includes("équipe")) {
            return tarif4;
        } else if(offre_name.toLowerCase().includes("accompagnement")) {
            return tarif2;
        } else if(offre_name.toLowerCase().includes("formation")) {
            return tarif3;
        } else {
            return tarif5;
        }
    }

    return (
        <div className='px-4 pb-4'>

            {/* {(isOnFreeTrial && trial) &&
                <div>
                    <div className='mt-2 alert alert-warning text-center'>
                        Votre periode d'essaie se terminera le {format(trial.trial_end_date, 'd MMMM yyyy', { locale: fr })}.
                        Votre abonnement sera renouvellé automatiquement à la fin de votre periode d'essaie! Vous pouvez annuler l'abonnement automatique à tout moment.
                    </div>
                    <hr />
                    <div className="">
                        <h5 className='mb-2'>Détails sur l'offre</h5>
                        <p><b>Nom de l'offre : </b>{trial.offre_name}</p>
                        <p><b>Prix par mois : </b>{trial.price_per_month} €</p>
                        <p><b>Date du début : </b>{format(trial.start_date, "d MMMM yyyy à H:m", { locale: fr })}</p>
                        <p><b>Date de fin de la periode d'essaie : </b>{format(trial.trial_end_date, "d MMMM yyyy à H:m", { locale: fr })}</p>
                        <p><b>Date de fin de l'abonnement : </b>{format(trial.end_date, "d MMMM yyyy à H:m", { locale: fr })}</p>
                    </div>
                    <div>
                        {abonnementAuto ?
                            <button onClick={() => handleChangeAbonnementAutoStatus(false)} className='btn btn-danger'>Annuler l'abonnement automatique</button> :
                            <button onClick={() => handleChangeAbonnementAutoStatus(true)} className='btn btn-primary'>Activer l'abonnement automatique</button>
                        }
                    </div>
                </div>
            } */}


            {(isTesteur) &&
                <div className='mt-2 alert alert-warning text-center'>Vous faites partie des testeurs!</div>
            }

            {!isTesteur && <>

                <AbonnementStatus
                    profileData={profileData}
                    setShowHistoryModal={setShowHistoryModal}
                    loading={loading}
                    abonnement={abonnement}
                    abonnementAuto={abonnementAuto}
                    handleChangeAbonnementAutoStatus={handleChangeAbonnementAutoStatus}
                    handleEndAbonnement={handleEndAbonnement}
                    isAbonnementAttribued={isAbonnementAttribued}
                    setShowFactureModal={setShowFactureModal}
                    monthsAttribued={abonnement ? calculateMonthsDifference(abonnement?.start_date, abonnement?.end_date) : 0}
                    setShowForm={setShowForm}
                    isTrial={isOnFreeTrial}
                    trial={trial}
                />
                <div className='mt-4'>
                    {!showForm ? (
                        (!abonnementActive || new Date(abonnement?.end_date) < new Date()) &&
                        <button
                            onClick={() => setShowForm(true)}
                            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            Acheter un abonnement
                        </button>
                    ) : (
                        <div className="mt-4" id="abonnement-form">
                            <hr />
                            <h5>Formulaire d'abonnement</h5>
                            <Elements stripe={stripePromise} options={{ appearance }}>
                                {selectedOffer && <>
                                    <div className="flex items-center space-x-5 mt-3">
                                        <img src={logo} className="h-16" alt="logo planibeauty" />
                                        <h4 className="font-bold">Formule {selectedOffer.name} </h4>
                                    </div>
                                </>
                                }

                                {(method && ["free-trial", "achat-normal"].includes(method)) && <div className="flex items-center space-x-5 mt-3">
                                    <CreditCard color="#bd193b" size={32} />
                                    <h5 className="mb-0 text-xl">
                                        {method === "free-trial" && `${selectedOffer?.price_per_month} € par mois après les 10 jours d'essais`}
                                        {method === "achat-normal" && `${selectedOffer?.price_per_month} € par mois`}
                                    </h5>
                                </div>}

                                {(method && existingPromoCode && ["code-promo"].includes(method)) && <div className="flex items-center space-x-5 mt-3">
                                    <CreditCard color="#bd193b" size={32} />
                                    <h5 className="mb-0 text-xl">
                                        {selectedOffer?.price_per_month} € par mois après les {existingPromoCode?.months_valid} mois d'essais
                                    </h5>
                                </div>}
                                {step === 1 &&
                                    <div className="py-2">
                                        <label>Choisir l'offre :</label>
                                        {(abonnement && new Date(abonnement?.end_date) >= new Date()) ? <div className='py-2'>
                                            <AbonnementCard {...{
                                                        title: "Formule " + activeOffer.name,
                                                        price: activeOffer.price_per_month,
                                                        image: getOffreImages(activeOffer.name),
                                                        features: [
                                                            activeOffer.description
                                                        ],
                                                        onClick: () => {}, //handleCardOfferClick(element),
                                                        is_dispo: true,
                                                        m_auto: false,
                                                        addButton: false,
                                                        sm: true
                                                    }}
                                                    />
                                            <div

                                                className={`shadow-sm border cursor-pointer border-gray-200 hover:bg-green-300 rounded p-3 bg-green-200`}
                                            >
                                                <h4>{activeOffer.name}</h4>
                                                <p>{activeOffer.description}</p>
                                                <h5>{activeOffer.price_per_month} € / mois</h5>
                                            </div>
                                        </div> :
                                            <div className="flex gap-2 py-2">
                                                {
                                                //profileData.type_beautician === "indépendant" && 
                                                offresIndep.map((element, index) => {
                                                    if(element.name.toLowerCase().includes("classique")) {
                                                    return <AbonnementCard key={index} {...{
                                                        title: "Formule " + element.name,
                                                        price: element.price_per_month,
                                                        image: getOffreImages(element.name),
                                                        features: [
                                                            element.description
                                                        ],
                                                        // onClick: () => handleCardOfferClick(element, 'free-trial'),
                                                        // buttonText: "Essaie gratuit de 10 jours",
                                                        is_dispo: true,
                                                        m_auto: false,
                                                        // addButton: true,
                                                        free_trial_button: (abonnement || profileData.promo_code_sent) ? false : true,
                                                        onFreeTrialClick: () => handleCardOfferClick(element, 'free-trial'),
                                                        code_promo_button: profileData.promo_code_sent,
                                                        onCodePromoClick: () => handleCardOfferClick(element, 'code-promo'),
                                                        achat_normal_button: true,
                                                        onAchatNormalClick: () => handleCardOfferClick(element, 'achat-normal'),
                                                        sm: true
                                                    }}
                                                    />
                                                }
                                                else {
                                                    return null;
                                                }
                                                    // return <div
                                                    //     onClick={() => handleCardOfferClick(element)}
                                                    //     key={index}
                                                    //     className={`shadow-sm border cursor-pointer border-gray-200 hover:bg-green-300 rounded p-3 ${selectedOffer?.id === element.id ? 'bg-green-200' : 'bg-gray-50'}`}
                                                    // >
                                                    //     <h4>{element.name}</h4>
                                                    //     <p>{element.description}</p>
                                                    //     <h5>{element.price_per_month} € / mois</h5>
                                                    // </div>
                                                })
                                                }

                                                {/* {profileData.type_beautician === "établissement" && offresEtab.map((element, index) => {
                                                    return <AbonnementCard key={index} {...{
                                                        title: "Formule " + element.name,
                                                        price: element.price_per_month,
                                                        image: getOffreImages(element.name),
                                                        features: [
                                                            element.description
                                                        ],
                                                        onClick: () => handleCardOfferClick(element),
                                                        is_dispo: true,
                                                        m_auto: false,
                                                        addButton: true,
                                                        sm: true
                                                    }}
                                                    />
                                                    // return <div
                                                    //     onClick={() => handleCardOfferClick(element)}
                                                    //     key={index}
                                                    //     className={`shadow-sm border cursor-pointer border-gray-200 hover:bg-green-300 rounded p-3 ${selectedOffer?.id === element.id ? 'bg-green-200' : 'bg-gray-50'}`}
                                                    // >
                                                    //     <h4>{element.name}</h4>
                                                    //     <p>{element.description}</p>
                                                    //     <h5>{element.price_per_month} € / mois</h5>
                                                    // </div>
                                                })
                                                } */}

                                                {/* {
                                                    offres.map((element, index) => {
                                                        return <div
                                                            onClick={() => handleCardOfferClick(element)}
                                                            key={index}
                                                            className={`shadow-sm border cursor-pointer border-gray-200 hover:bg-green-300 rounded p-3 ${selectedOffer?.id === element.id ? 'bg-green-200' : 'bg-gray-50'}`}
                                                        >
                                                            <h4>{element.name}</h4>
                                                            <p>{element.description}</p>
                                                            <h5>{element.price_per_month} € / mois</h5>
                                                        </div>
                                                    })
                                                } */}
                                            </div>
                                        }
                                    </div>
                                }
                                {(step === 2 && selectedOffer) && <div className='py-2'>
                                    <label>Choisir la méthode d'achat:</label>
                                    <div className="flex gap-2 flex-wrap">
                                        {((selectedOffer?.name.toLowerCase().includes("classique") || selectedOffer?.name.toLowerCase().includes("équipe")) && !abonnement) && <div
                                            onClick={() => handleSelectMethod('free-trial')}
                                            className={`shadow-sm border md:max-w-[15vw] cursor-pointer border-gray-200 hover:bg-green-300 rounded p-3 ${method === 'free-trial' ? 'bg-green-200' : 'bg-gray-50'}`}
                                        >
                                            <h4>Essai gratuit</h4>
                                            <p>Periode d'essaie gratuit de 10 jours sur la formule {selectedOffer.name}</p>
                                        </div>
                                        }
                                        {(selectedOffer?.promo_codes.length > 0) &&
                                            <div
                                                onClick={() => handleSelectMethod('code-promo')}
                                                className={`shadow-sm border md:max-w-[15vw] cursor-pointer border-gray-200 hover:bg-green-300 rounded p-3 ${method === 'code-promo' ? 'bg-green-200' : 'bg-gray-50'}`}
                                            >
                                                <h4>Code promo</h4>
                                                <p>Achat d'un abonnement avec une durée determiné avec une remise selon le code</p>
                                            </div>
                                        }
                                        <div
                                            onClick={() => handleSelectMethod('achat-normal')}
                                            className={`shadow-sm border md:max-w-[15vw] cursor-pointer border-gray-200 hover:bg-green-300 rounded p-3 ${method === 'achat-normal' ? 'bg-green-200' : 'bg-gray-50'}`}
                                        >
                                            <h4>Achat normal</h4>
                                            <p>Achat d'un abonnement avec son prix normal!</p>
                                        </div>
                                    </div>
                                </div>
                                }
                                {(step === 3 && selectedOffer && method) &&
                                    <div className="py-2">
                                        {/*method === "achat-normal" && <div><label htmlFor="months" className="block text-sm mb-2 font-medium text-gray-700">
                                                Sélectionner le nombre de mois :
                                            </label>
                                                <input
                                                    type="number"
                                                    id="months"
                                                    value={months}
                                                    onChange={(e) => setMonths(parseInt(e.target.value, 10))}
                                                    className="mt-1 p-2 block w-full rounded-md border border-gray-400"
                                                    min="1"
                                                />
                                            </div>*/}
                                        {method === "code-promo" && <div>
                                            <label htmlFor="code" className="block text-sm mb-2 font-medium text-gray-700">
                                                Entrez le code :
                                            </label>
                                            <div className='flex items-center space-x-5'>
                                                <Gift color="#bd193b" size={32} />
                                                <input
                                                    type="text"
                                                    id="code"
                                                    value={code}
                                                    onChange={(e) => setCode(e.target.value)}
                                                    className="mt-1 p-2 block w-full rounded-md border border-gray-400"
                                                    placeholder='Code promo'
                                                />
                                                <button
                                                    onClick={handleVerifyCode}
                                                    className='py-2 px-3 rounded bg-[#bd193b] hover:bg-[#6f1b2c] text-white'
                                                >Verifier</button>
                                            </div>
                                        </div>}

                                        {/* {(method === "code-promo" && existingPromoCode) &&
                                            <div className='py-2'>
                                                <p className='text-success'>Promo code valide!</p>
                                                <p className='text-success'>Voici les détails de votre offre : une remise de {existingPromoCode.discount_percentage}% pour l'achat de(s) {existingPromoCode.months_valid} premier(s) mois </p>
                                            </div>
                                        } */}
                                        {showPaymentForm &&
                                            <div>
                                                <p className='mt-3 text-info'>Vous conservez la possiblité de desactiver le renouvellement automatique à tout moment, avant la date du prochain prélévement en résiliant votre formule dans votre onglet abonnement.</p>
                                                <p className='mt-3 text-info'>
                                                    Pour souscire à votre formule {selectedOffer?.name}, avec un renouvellement automatique sans engagement, veuillez entrer les informations de votre carte bancaire.
                                                </p>
                                                <PaymentForm
                                                    userId={userId}
                                                    offre={selectedOffer}
                                                    setReloadLocalTrigger={setReloadLocalTrigger}
                                                    setAbonnementActive={setAbonnementActive}
                                                    onCloseForm={() => setShowForm(false)}
                                                    method={method}
                                                    profileData={profileData}
                                                    existingPromoCode={existingPromoCode}
                                                />
                                            </div>}
                                    </div>
                                }
                                <div className="flex gap-2 flex-wrap items-center justify-start">
                                    {step !== 1 && <button
                                        onClick={() => {
                                            if(step === 3) {
                                                setSelectedOffer(null);
                                                setMethod(null);
                                                setCode("");
                                                setShowPaymentForm(false);
                                                setExistingPromoCode(null);
                                                setStep(1);
                                            } else {
                                                prevStep();
                                            }
                                        }}
                                        className="mt-4 bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700"
                                    >
                                        Retour
                                    </button>}
                                    <button
                                        onClick={() => {
                                            setShowForm(false);
                                            clearForm();
                                            // if (!activeOffer) {
                                            //     setSelectedOffer(null);
                                            //     setMethod(null);
                                            //     setCode("");
                                            //     clearForm();
                                            // }
                                        }}
                                        className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                    >
                                        Annuler
                                    </button>
                                </div>

                            </Elements>

                        </div>
                    )}
                </div>
            </>}
            {showHistoryModal && <ShowHistoryAbonnementModal showModal={showHistoryModal} handleCloseModal={handleCloseHistoryModal} user_id={userId} />}
            {showFactureModal && <ShowFactoryModal showModal={showFactureModal} handleCloseModal={handleCloseFactureModal} customerId={profileData.customer_id} />}
        </div>
    );
};

export default AbonnementComponent;