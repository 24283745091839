import { API_URL } from "../utils/constants";

const beauticianApi = {
    getAllBeauticians: async (activePage = "all", limit=null) => {
        try {
            let url = `${API_URL}/beauticians`;
            if(activePage === "home-setting") return { success: false, data: null }
            if (activePage!=='all') url += `?type=${encodeURIComponent(activePage)}`
            if (limit) url += activePage !== 'all' ? `&limit=${limit}` : `?limit=${limit}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching beauticians:', error);
            throw error;
        }
    },

    getPaymentStatus: async (beautician_id) => {
        try {
            const url = `${API_URL}/beauticians/payment/${beautician_id}`;
            
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching beauticians payment status:', error);
            throw error;
        }
    },

    updatePaymentStatus: async (beautician_id, month, year) => {
        try {
            const url = `${API_URL}/beauticians/payment`;
            
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
                body: JSON.stringify({
                    beautician_id,
                    month,
                    year,
                    status: "payed"
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error updating beauticians payment status:', error);
            throw error;
        }
    },

    getAllAvailableBeauticiansWithAbonnement: async (activePage = "all", limit=null, account_active=false) => {
        try {
            const url = `${API_URL}/beauticians/available-with-abonnement`;
            
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching beauticians:', error);
            throw error;
        }
    },

    getAllAvailableBeauticians: async (activePage = "all", limit=null, account_active=false) => {
        try {
            let url = `${API_URL}/beauticians/available`;
            if (activePage!=='all') url += `?type=${encodeURIComponent(activePage)}`
            if (limit) url += activePage !== 'all' ? `&limit=${limit}` : `?limit=${limit}`;
            if (account_active) url += '&account_active=true';
            
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching beauticians:', error);
            throw error;
        }
    },

    getBeauticianById: async (id) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération de l\'esthéticienne: ' + error.message);
        }
    },

    getBeauticianBySlug: async (slug, from_client = false) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/slug/${slug}?from_client=${from_client}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération de l\'esthéticienne: ' + error.message);
        }
    },

    fetchBeauticianDepositPercentageBySlug: async (slug) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/slug/${slug}/deposit-percentage`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération de l\'esthéticienne: ' + error.message);
        }
    },

    searchBeauticians: async (searchParams) => {
        try {
            const queryString = new URLSearchParams(searchParams).toString();
            const response = await fetch(`${API_URL}/beauticians/search?${queryString}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la recherche: ' + error.message);
        }
    },

    getBeauticiansByCreatorId: async(token, creator_id) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/creator/${creator_id}`, {
                method: 'GET',
                Authorization: `Bearer ${token}`,
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération du portfolio: ' + error.message);
        }
    },

    getAllPortfolioPhotos: async (id) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/portfolio`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération du portfolio: ' + error.message);
        }
    },

    getPortfolioPhotoById: async (id, photoId) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/portfolio/${photoId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération de la photo: ' + error.message);
        }
    },

    // Routes protégées
    createBeautician: async (token, beauticianData) => {
        try {
            const response = await fetch(`${API_URL}/beauticians`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(beauticianData)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la création du profil: ' + error.message);
        }
    },

    updateBeautician: async (token, id, updateData) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateData)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error(error.message);
        }
    },

    deleteBeautician: async (token, id) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la suppression du profil: ' + error.message);
        }
    },

    updateAvailability: async (token, id, availabilityData) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/availability`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(availabilityData)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour de la disponibilité: ' + error.message);
        }
    },


    updateIsOpenToWork: async (token, id, data) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/open-to-work`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });
            
            const res = await response.json();
            if (!response.ok) throw new Error(res.message);
            return res;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour de la disponibilité: ' + error.message);
        }
    },

    updateIsBlockedForDay: async (token, id, isBlocked) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/update-blocked-for-day`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({isBlocked: isBlocked})
            });
            
            const res = await response.json();
            if (!response.ok) throw new Error(res.message);
            return res;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour statut blocked day: ' + error.message);
        }
    },

    isBlockedForDay: async (id) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/is-blocked-for-day`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const res = await response.json();
            if (!response.ok) throw new Error(res.message);
            return res;
        } catch (error) {
            throw new Error('Erreur lors de la récupération du statut blocked for day: ' + error.message);
        }
    },

    updateIsFistLogin: async (id) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/update-is-first-login`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour: ' + error.message);
        }
    },

    changeAbonnementAutoStatus: async (token, id, status) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/update-abonnement-auto`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({status: status})
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour: ' + error.message);
        }
    },

    addPortfolioPhoto: async (token, id, photoData) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/portfolio`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(photoData)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de l\'ajout de la photo: ' + error.message);
        }
    },

    deletePortfolioPhoto: async (token, id, photoId) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/portfolio/${photoId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la suppression de la photo: ' + error.message);
        }
    },

    createBeauticianProfile: async (profileData) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/create-profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(profileData)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error(error.message);
        }
    },

    updateBudgetCategorie: async (token, id, dataCatBudget) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/edit-category-budget`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataCatBudget)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour du catégorie budget : ' + error.message);
        }
    },

    acceptRegistration: async (token, beautician_id, email, name) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${beautician_id}/accept-registration`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    name: name,
                    is_active: true
                })
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error(error.message);
        }
    },

    updatePaymentMode: async (token, id, key, value) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/update-payment-mode`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({key: key, value: value})
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour du mode de payment: ' + error.message);
        }
    },

    updateDepositPercentage: async (token, id, value) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/update-deposit-percentage`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({value: value})
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour du taux de l\'acompte :' + error.message);
        }
    },

    updateRefundLimitTimeForDeposit: async (token, id, value) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/update-refund-limit-time-for-deposit`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({value: value})
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour du durée limite de remboursement pour l\'acompte :' + error.message);
        }
    },

    updateBankInfo: async (token, id, bankInfo) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/update-bank-info`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bankInfo)
            });
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour des informations bancaires: ' + error.message);
        }
    },

    getBeauticianPaymentMode: async (beautician_id) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${beautician_id}/payment-mode`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération du mode de payment: ' + error.message);
        }
    },

    getAllAppointmentInfoByBeauticianId: async (token, beautician_id) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${beautician_id}/revenue`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération des infos sur le revenue: ' + error.message);
        }
    },

    getAppointmentInfoByMonthAndBeauticianId: async (token, beautician_id, month, payment_type) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${beautician_id}/revenue-by-month?month=${month}&payment_type=${payment_type}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération des infos sur le revenue: ' + error.message);
        }
    },

    getAllBeauticianWithAppointmentsInfo: async (token) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/all/appointments-info`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            return await response.json();
        } catch (error) {
            console.log(error)
            throw new Error('Erreur lors de la récupération des données des esthéticiennes');
        }  
    },

    updatePromoCodeSentStatus: async (token, id, status) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/update-promo-code-sent`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({promo_code_sent: status})
            });
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour du statut du code promo: ' + error.message);
        }
    }

};

export default beauticianApi;