import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import RegisterAffiliationForm from "./RegisterAffiliationForm";
import testeurApi from "../../api/testeur.api";
import logo from '../../assets/images/logoRouge.png';


const RegisterAffiliation = () => {

    const { connectedUser } = useContext(AppContext);
    const [slug, setSlug] = useState(null);
    const [selectedType, setSelectedType] = useState('indépendant');


    useEffect(() => {
        const checkIfLinkValid = async () => {
            try {
                const url_array = window.location.href.split('/');
                const _slug = url_array[url_array.length - 1];
                let is_testeur = await testeurApi.is_beautician_testeur_by_slug(_slug);
                if(!is_testeur) {
                    window.location.href = "/404";
                    return;
                }
                setSlug(_slug);
            } catch (error) {
                window.location.href = "/404";
            }
        }

        if (connectedUser) {
            window.location.href = "/";
        }

        checkIfLinkValid();

    }, []);

    return <div className="py-[5vh] min-h-[100vh] bg-[#f9ecef]">
        {/*slug && !selectedType &&
            <div className="text-center mt-5 px-3">
                <img src={logo} alt="Logo planibeauty" className="mx-auto w-32 mb-5" />
                <h1 className="text-2xl font-bold">Inscription des Pro sur Planibeauty</h1>
                <p className="text-gray-500 mt-3">Choisissez le type de compte :</p>
                <div className="flex justify-center flex-wrap gap-3 mt-5">
                    <div onClick={() => setSelectedType('indépendant')} className="text-xl font-semibold cursor-pointer p-5 rounded-lg shadow bg-gray-100 hover:bg-green-300 hover:scale-105 transition-all duration-500 ease-in-out">
                        <h1 className="text-3xl">Indépendant</h1>
                    </div>
                    <div onClick={() => setSelectedType('établissement')} className="text-xl font-semibold cursor-pointer p-5 rounded-lg shadow bg-gray-100 hover:bg-green-300 hover:scale-105 transition-all duration-500 ease-in-out">
                        <h1 className="text-3xl">Etablissement</h1>
                    </div>
                </div>
            </div>*/}

        {slug && selectedType &&
            <RegisterAffiliationForm slug={slug} selectedType={selectedType} />
        }
    </div>
}


export default RegisterAffiliation;