import { Calendar, ChevronRight } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

const RendezVousDuJour = () => {
    const rdv = [
        {
            phone: "0601020304",
            appointment_date: new Date(),
            details: "Inscription pour l'essai de 10 jours",
            pro_name: "Pro Sab",
            category: "Esthétic"
        },
        {
            phone: "0601020304",
            appointment_date: new Date(),
            details: "Rapels NRP2",
            type: "LEADS",
            number: "N°1234"
        },
        {
            phone: "0601020304",
            details: "Point pour renouvellement + Pack CM",
            appointment_date: new Date(),
            pro_name: "Pro Sab",
            category: "Esthétic"
        }
    ]

    return (
        <div className="col-span-4 md:col-span-3 min-h-full flex flex-col rounded border shadow-sm bg-white md:overflow-hidden">
            {/* En-tête fixe */}
            <div className="p-4 flex flex-col items-center gap-1 border-b shrink-0">
                <h3 className="flex items-center gap-2 text-lg text-gray-600">
                    <Calendar size={20} />
                    Rendez-vous du jour
                </h3>
                <div className="w-1/4 h-1 bg-[#bd193b] rounded"></div>
                <Link
                    to="/tele-conseiller-dashboard/agendas"
                    className="mt-2 px-3 py-2 flex items-center gap-2 text-gray-500 border rounded no-underline cursor-pointer *:cursor-pointer"
                >
                    Voir tout
                    <ChevronRight size={20} />
                </Link>
            </div>

            {/* Zone de contenu scrollable */}
            <div className="flex-1 min-h-0 md:overflow-y-auto p-2 py-4 md:p-4">
                <div className="space-y-3">
                    {rdv.map((r, key) => (
                        <div
                            key={`rdv-key-${key}`}
                            className="p-3 flex gap-3 items-center bg-gray-100 rounded-lg border-s-4 border-s-green-600"
                        >
                            {/* Contenu de chaque rendez-vous */}
                            <div className="p-1 bg-white font-semibold text-sm rounded shadow-sm">
                                {new Date(r.appointment_date).toLocaleTimeString("fr-FR", { hour12: false })}
                            </div>

                            <div className="flex flex-col gap-1 justify-center">
                                <span className="text-sm font-semibold">{r.type === "LEADS" ? r.type : r.pro_name}</span>
                                <span className="text-sm font-semibold">{r.type === "LEADS" ? r.number : r.category}</span>
                            </div>

                            <div className="col-span-3 flex flex-col gap-1 justify-center">
                                <span className="text-sm">{r.details}</span>
                                <span className="text-sm">{r.phone}</span>
                                <Link
                                    to={r.type === "LEADS" ? "/tele-conseiller-dashboard/leads-pro" : "/tele-conseiller-dashboard/fiche-pro"}
                                    className="text-sm text-[#bd193b] font-semibold cursor-pointer"
                                >
                                    {r.type === "LEADS" ? "Accéder au leads" : "Accéder à la fiche pro"}
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default RendezVousDuJour
