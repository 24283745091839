import React from 'react'
import Resume from './Resume'
import { Search, SlidersHorizontal } from 'lucide-react'
import RendezVousDuJour from './RendezVousDuJour'
import PerformancesDuJour from './PerformancesDuJour'
import RevenusTeleconseillerDuJour from './RevenusTeleconseillerDuJour'

const Dashboard = () => {
	return (
		<section className="p-2 md:p-4 bg-gray-100 md:h-[91.5vh] flex flex-col overflow-hidden">
			{/* Titre principal */}
			<h1 className="text-4xl font-bold text-center mb-6">Mon tableau de bord</h1>

			{/* Résumé des données */}
			<div className="shrink-0">
				<Resume />
			</div>

			{/* Barre de recherche */}
			<div className="grid grid-cols-1 gap-3 md:grid-cols-3 mt-6 shrink-0">
				<h1 className="col-span-2 text-2xl text-center">Mercredi 26 Mars 2025 - 14h00</h1>

				<div className="flex items-center justify-end gap-2">
					<div className="relative flex items-center">
						<input type="text" placeholder="Rechercher un rdv" className="px-4 py-2 outline-[#bd193b] rounded-full" />
						<Search size={24} className="absolute right-0 me-2" />
					</div>
					<button className="cursor-pointer *:cursor-pointer">
						<SlidersHorizontal />
					</button>
				</div>
			</div>

			{/* Conteneur principal pour les trois composants */}
			<div className="flex-1 min-h-0 grid md:grid-cols-7 gap-4 mt-6">
				<RendezVousDuJour />
				<PerformancesDuJour />
				<RevenusTeleconseillerDuJour />
			</div>
		</section>
	)
}

export default Dashboard
