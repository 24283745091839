import React from 'react'

import { Trial, PackCM } from './CustomIcon'
import { History, TrendingUp, UserRoundPen } from 'lucide-react'
import { Link } from 'react-router-dom'

const PerformancesDuJour = () => {
	const data = [
		{ text: "Inscription pro", value: 18, icon: (size=24) => <UserRoundPen size={size} className="text-violet-700" /> },
		{ text: "Souscription", value: 12, icon: (size=24) => <Trial size={size} /> },
		{ text: "Souscription", value: 8, icon: (_size) => <PackCM size={20} /> },
		{ text: "Souscription", value: 8, icon: (size) => <History size={size} /> },
	]

	return (
		<div className="col-span-4 md:col-span-2 min-h-full flex flex-col rounded border shadow-sm bg-white md:overflow-hidden">
            {/* En-tête fixe */}
            <div className="p-4 flex flex-col items-center gap-1 border-b shrink-0">
                <h3 className="flex items-center gap-2 text-lg text-gray-600">
                    <TrendingUp size={20} />
                    Performances du jour
                </h3>
                <div className="w-1/4 h-1 bg-[#bd193b] rounded"></div>
            </div>

            {/* Zone de contenu scrollable */}
            <div className="p-3 grid grid-cols-2 gap-2">
				{data.map((d, key) => (
					<div
						key={`perfs-key-${key}`}
						className="p-2 py-3 flex flex-col bg-gray-100 rounded"
					>
						<h4 className="text-center text-sm font-normal">{d.text}</h4>
						<h4 className="text-center">{d.value}</h4>
						<div className="flex items-center justify-start">
							{d.icon(36)}
						</div>
					</div>
				))}
			</div>

			<div className="flex items-center justify-center">
				<Link
					to=""
					className="text-sm text-[#bd193b] cursor-pointer"
				>
					Consulter le reste de mes statistiques
				</Link>
			</div>
        </div>
	)
}

export default PerformancesDuJour
