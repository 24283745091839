import Logo from "../../assets/images/logoRouge.png"
import { FaPlus } from "react-icons/fa"
import { Menu, X, ChevronDown, ChevronUp } from "lucide-react"
import { useEffect, useState } from "react"
import CreateOperatorModal from "./CreateOperatorModal"
import DashboardUserMenu from "./DashboardUserMenu"
import { REDIRECT_AFTER_LOGOUT } from "../../utils/constants"
import { useNavigate } from "react-router-dom"

const Header = ({ createUser, activePage, setActivePage }) => {
    const [showCreateOperatorModal, setShowCreateOperatorModal] = useState(false)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    const [mobileDropdowns, setMobileDropdowns] = useState({
        users: false,
        attribution: false
    })
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        type: '',
    })

    const navigate = useNavigate()

    const handleCloseCreateOperatorModal = () => setShowCreateOperatorModal(false)
    const handleShowCreateOperatorModal = () => setShowCreateOperatorModal(true)

    const getName = () => {
        let name = ''
        if (isAuthenticated) {
            name = userData.first_name + ' ' + userData.last_name
        }
        return name
    }

    const handleLogout = () => {
        localStorage.clear()
        setIsAuthenticated(false)
        setUserData({
            first_name: '',
            last_name: '',
            email: '',
            type: '',
        })
        window.location.href = REDIRECT_AFTER_LOGOUT
    }

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen)
    }

    const toggleMobileDropdown = (dropdown) => {
        setMobileDropdowns(prev => ({
            ...prev,
            [dropdown]: !prev[dropdown]
        }))
    }

    useEffect(() => {
        const token = localStorage.getItem('token')
        const firstName = localStorage.getItem('firstName')
        const lastName = localStorage.getItem('lastName')
        const userType = localStorage.getItem('userType')
        const email = localStorage.getItem('email')

        if (token && firstName && lastName) {
            setIsAuthenticated(true)
            setUserData({ first_name: firstName, last_name: lastName, email: email, type: userType })
        }
    }, [])

    const handleNavigation = (page, path) => {
        if (path === "/dashboard/utilisateurs") {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('user_type', page);
            if (page !== activePage) {
                searchParams.set('sortColumn', 'id');
                searchParams.set('sortDirection', 'asc');
            }
            navigate(`${path}?${searchParams.toString()}`);
        } else {
            navigate(path)
        }
        setActivePage(page)
        setIsMobileMenuOpen(false)
        setMobileDropdowns({ users: false, attribution: false })
    }

    return (
        <>
            <nav className="relative px-4 md:px-14 shadow">
                <div className="flex items-center justify-between min-h-16">
                    <a href="/" className="flex-shrink-0">
                        <img width="100" src={Logo} alt="Logo" />
                    </a>

                    {/* Mobile menu button */}
                    <div className="md:hidden">
                        <button
                            onClick={toggleMobileMenu}
                            className="p-2 text-gray-600 hover:text-gray-900"
                        >
                            {isMobileMenuOpen ? (
                                <X className="h-6 w-6" />
                            ) : (
                                <Menu className="h-6 w-6" />
                            )}
                        </button>
                    </div>

                    {/* Desktop Navigation */}
                    <ul className="hidden md:flex flex-wrap gap-4 pt-1">
                        <li>
                            <span
                                onClick={() => handleNavigation("dashboard", '/dashboard')}
                                className={`cursor-pointer anchor ${activePage === 'dashboard' ? 'active' : ''}`}
                            >
                                Tableau de bord
                            </span>
                        </li>

                        {/* Users Dropdown */}
                        <li className="relative group">
                            <span className={`cursor-pointer anchor ${['all', 'indépendant', 'établissement', 'operator', 'client', 'admin', 'recruteur', 'tele_conseiller'].includes(activePage) ? 'active' : ''}`}>
                                Utilisateurs
                            </span>

                            <ul className="absolute left-0 top-6 hidden group-hover:block bg-white shadow-lg border rounded mt-2 w-48 p-0 z-10">
                                <li
                                    className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                    onClick={() => handleNavigation("all", '/dashboard/utilisateurs')}
                                >
                                    Professionnels
                                </li>
                                {userData.type === 'admin' && (
                                    <>
                                        <li
                                            className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                            onClick={() => handleNavigation("admin", '/dashboard/utilisateurs')}
                                        >
                                            Administrateurs
                                        </li>
                                        <li
                                            className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                            onClick={() => handleNavigation("operator", '/dashboard/utilisateurs')}
                                        >
                                            Opérateurs
                                        </li>
                                    </>
                                )}
                                <li
                                    className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                    onClick={() => handleNavigation("client", '/dashboard/utilisateurs')}
                                >
                                    Clients
                                </li>
                                <li
                                    className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                    onClick={() => handleNavigation("tele_conseiller", '/dashboard/utilisateurs')}
                                >
                                    Conseillères beauté
                                </li>
                                <li
                                    className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                    onClick={() => handleNavigation("recruteur", '/dashboard/utilisateurs')}
                                >
                                    Recruteurs
                                </li>

                            </ul>
                        </li>

                        {userData.type === 'admin' && (
                            <>
                                <li>
                                    <span
                                        onClick={() => handleNavigation("offre-abonnement", '/dashboard/offres-abonnements')}
                                        className={`cursor-pointer anchor ${activePage === 'offre-abonnement' ? 'active' : ''}`}
                                    >
                                        Offre et abonnement
                                    </span>
                                </li>
                            </>
                        )}

                        <li className="relative group">
                            <span className={`cursor-pointer anchor ${['testeurs', 'attrib-abonnement', 'register-links'].includes(activePage) ? 'active' : ''}`}>
                                Attribution
                            </span>
                            <ul className="absolute left-0 top-6 hidden group-hover:block bg-white shadow-lg border rounded mt-2 w-48 p-0 z-10">
                                <li
                                    className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                    onClick={() => handleNavigation("testeurs", '/dashboard/testeurs')}
                                >
                                    Testeurs
                                </li>
                                <li
                                    className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                    onClick={() => handleNavigation("attrib-abonnement", '/dashboard/attribuer-abonnement')}
                                >
                                    Essaie
                                </li>
                                <li
                                    className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                    onClick={() => handleNavigation("register-links", '/dashboard/liens-inscription')}
                                >
                                    Liens inscriptions
                                </li>
                            </ul>
                        </li>

                        {/* Suivis */}
                        {userData.type === "admin" && <>
                            <li className="relative group">
                                <span className={`cursor-pointer anchor ${['suivi-des-professionnels', 'suivi-des-operateurs', 'suivi-des-teleprospecteurs', 'home-setting'].includes(activePage) ? 'active' : ''}`}>
                                    Suivis
                                </span>
                                <ul className="absolute left-0 top-6 hidden group-hover:block bg-white shadow-lg border rounded mt-2 w-48 p-0 z-10">
                                    <li
                                        className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                        onClick={() => handleNavigation("suivi-des-professionnels", '/dashboard/suivi-des-professionnels')}
                                    >
                                        Professionnels
                                    </li>
                                    <li
                                        className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                        onClick={() => handleNavigation("suivi-des-operateurs", '/dashboard/suivi-des-operateurs')}
                                    >
                                        Opérateurs
                                    </li>
                                    <li
                                        className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                        onClick={() => handleNavigation("suivi-des-teleprospecteurs", '/dashboard/suivi-des-teleprospecteurs')}
                                    >
                                        Téléprospecteurs
                                    </li>
                                    <li
                                        className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                        onClick={() => handleNavigation("home-setting", '/dashboard/parametre-accueil')}
                                    >
                                        Historiques des tendances
                                    </li>
                                </ul>
                            </li>
                        </>}

                        <li>
                            <span
                                onClick={() => handleNavigation("leads", '/dashboard/leads')}
                                className={`cursor-pointer anchor ${activePage === 'leads' ? 'active' : ''}`}
                            >
                                Leads
                            </span>
                        </li>

                    </ul>

                    <div className="hidden md:block">
                        <DashboardUserMenu
                            user_name={getName()}
                            user_email={userData.email}
                            user_type={userData.type}
                            onLogout={handleLogout}
                        />
                    </div>
                </div>

                {/* Mobile Navigation */}
                {isMobileMenuOpen && (
                    <div className="md:hidden">
                        <ul className="pt-2 pb-3 space-y-1">
                            <li>
                                <span
                                    onClick={() => handleNavigation("dashboard", '/dashboard')}
                                    className={`block px-3 py-2 text-base font-medium ${activePage === 'dashboard' ? 'text-rose-700' : 'text-gray-700'}`}
                                >
                                    Tableau de bord
                                </span>
                            </li>

                            {/* Mobile Users Dropdown */}
                            <li>
                                <div
                                    onClick={() => toggleMobileDropdown('users')}
                                    className="flex items-center justify-between px-3 py-2 text-base font-medium text-gray-700 cursor-pointer"
                                >
                                    <span>Utilisateurs</span>
                                    {mobileDropdowns.users ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
                                </div>
                                {mobileDropdowns.users && (
                                    <ul className="bg-gray-50 py-2">
                                        <li
                                            onClick={() => handleNavigation("all", '/dashboard/utilisateurs')}
                                            className="block px-8 py-2 text-base text-gray-700"
                                        >
                                            Professionnels
                                        </li>
                                        {userData.type === 'admin' && (
                                            <>
                                                <li
                                                    onClick={() => handleNavigation("admin", '/dashboard/utilisateurs')}
                                                    className="block px-8 py-2 text-base text-gray-700"
                                                >
                                                    Administrateurs
                                                </li>
                                                <li
                                                    onClick={() => handleNavigation("operator", '/dashboard/utilisateurs')}
                                                    className="block px-8 py-2 text-base text-gray-700"
                                                >
                                                    Operateurs
                                                </li>
                                                <li
                                                    onClick={() => handleNavigation("client", '/dashboard/utilisateurs')}
                                                    className="block px-8 py-2 text-base text-gray-700"
                                                >
                                                    Clients
                                                </li>
                                                <li
                                                    onClick={() => handleNavigation("tele_conseiller", '/dashboard/utilisateurs')}
                                                    className="block px-8 py-2 text-base text-gray-700"
                                                >
                                                    Conseillères beauté
                                                </li>
                                                <li
                                                    onClick={() => handleNavigation("recruteur", '/dashboard/utilisateurs')}
                                                    className="block px-8 py-2 text-base text-gray-700"
                                                >
                                                    Recruteur
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                )}
                            </li>

                            {userData.type === 'admin' && (
                                <>
                                    <li>
                                        <span
                                            onClick={() => handleNavigation("offre-abonnement", '/dashboard/offres-abonnements')}
                                            className={`block px-3 py-2 text-base font-medium ${activePage === 'offre-abonnement' ? 'text-rose-700' : 'text-gray-700'}`}
                                        >
                                            Offre et abonnement
                                        </span>
                                    </li>

                                    {/* Mobile Attribution Dropdown */}
                                    <li>
                                        <div
                                            onClick={() => toggleMobileDropdown('attribution')}
                                            className="flex items-center justify-between px-3 py-2 text-base font-medium text-gray-700 cursor-pointer"
                                        >
                                            <span>Attribution</span>
                                            {mobileDropdowns.attribution ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
                                        </div>
                                        {mobileDropdowns.attribution && (
                                            <ul className="bg-gray-50 py-2">
                                                <li
                                                    onClick={() => handleNavigation("testeurs", '/dashboard/testeurs')}
                                                    className="block px-8 py-2 text-base text-gray-700"
                                                >
                                                    Testeurs
                                                </li>
                                                <li
                                                    onClick={() => handleNavigation("attrib-abonnement", '/dashboard/attribuer-abonnement')}
                                                    className="block px-8 py-2 text-base text-gray-700"
                                                >
                                                    Essaie
                                                </li>
                                                <li
                                                    onClick={() => handleNavigation("register-links", '/dashboard/liens-inscription')}
                                                    className="block px-8 py-2 text-base text-gray-700"
                                                >
                                                    Liens inscriptions
                                                </li>
                                            </ul>
                                        )}
                                    </li>

                                    <li>
                                        <div
                                            onClick={() => toggleMobileDropdown('suivis')}
                                            className="flex items-center justify-between px-3 py-2 text-base font-medium text-gray-700 cursor-pointer"
                                        >
                                            <span>Suivis</span>
                                            {mobileDropdowns.suivis ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
                                        </div>
                                        {mobileDropdowns.suivis && (
                                            <ul className="bg-gray-50 py-2">
                                                <li
                                                    className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                                    onClick={() => handleNavigation("suivi-des-professionnels", '/dashboard/suivi-des-professionnels')}
                                                >
                                                    Professionnels
                                                </li>
                                                <li
                                                    className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                                    onClick={() => handleNavigation("suivi-des-operateurs", '/dashboard/suivi-des-operateurs')}
                                                >
                                                    Opérateurs
                                                </li>
                                                <li
                                                    className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                                    onClick={() => handleNavigation("suivi-des-teleprospecteurs", '/dashboard/suivi-des-teleprospecteurs')}
                                                >
                                                    Téléprospecteurs
                                                </li>
                                                <li
                                                    className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                                    onClick={() => handleNavigation("home-setting", '/dashboard/parametre-accueil')}
                                                >
                                                    Historiques des tendances
                                                </li>
                                            </ul>
                                        )}
                                    </li>


                                    <li>
                                        <span
                                            onClick={() => handleNavigation("leads", '/dashboard/leads')}
                                            className={`block px-3 py-2 text-base font-medium ${activePage === 'leads' ? 'text-rose-700' : 'text-gray-700'}`}
                                        >
                                            Leads
                                        </span>
                                    </li>
                                </>
                            )}
                        </ul>
                        <div className="pt-4 pb-3 border-t border-gray-200">
                            <DashboardUserMenu
                                user_name={getName()}
                                user_email={userData.email}
                                user_type={userData.type}
                                onLogout={handleLogout}
                            />
                        </div>
                    </div>
                )}
            </nav>

            {(['all', 'indépendant', 'établissement', 'operator', 'client', 'admin', 'recruteur', 'tele_conseiller'].includes(activePage)) && (
                <section className="w-full flex items-center justify-between px-4">
                    <div className="flex gap-3 flex-wrap items-center">
                        {['all', 'indépendant', 'établissement', 'client', 'recruteur', 'tele_conseiller'].includes(activePage) &&
                            <button
                                onClick={() => createUser(true)}
                                className="px-2 md:px-4 py-2 text-sm md:text-base text-white bg-[#bd193b] flex items-center gap-2 rounded shadow active:scale-[101%] duration-200"
                            >
                                <FaPlus />
                                {(activePage === "client" || activePage === 'recruteur') ? `Créer un ${activePage}` : (['all', 'indépendant', 'établissement'].includes(activePage) ? "Créer un profil professionnel de beauté" : "Créer un profil conseillère beauté")}
                            </button>}

                        {(userData.type === "admin" && ['operator', 'admin'].includes(activePage)) && (
                            <>
                                <button
                                    onClick={handleShowCreateOperatorModal}
                                    className="px-2 md:px-4 py-2 text-sm md:text-base text-white bg-[#bd193b] flex items-center gap-2 rounded shadow active:scale-[101%] duration-200"
                                >
                                    <FaPlus />
                                    Créer un {activePage === "admin" ? 'administrateur' : 'opérateur'}
                                </button>
                                <CreateOperatorModal
                                    show={showCreateOperatorModal}
                                    handleClose={handleCloseCreateOperatorModal}
                                />
                            </>
                        )}
                    </div>
                </section>
            )}
        </>
    )
}

export default Header;