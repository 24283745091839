import React, { useState } from "react";
import MySpinner from "../../../components/loader/Spinner";
import './Leads.css';
import ActionButtons from "../../../components/dashboard/leads/ActionButtons";
import LeadsFilter from "../../../components/dashboard/leads/LeadsFilter";
import LeadsTable from "../../../components/dashboard/leads/LeadsTable";
import Pagination from "../../../components/dashboard/leads/Pagination";


const Leads = () => {

    const [loading, setLoading] = useState(false);

    const [leads, setLeads] = useState([
        {
            id: 1001,
            lien: 'https://www.pro-de-la-beaute.fr/530022',
            telephone: '06 01 02 03 04',
            dateLeads: '26/03/2026',
            ville: 'Châtillon',
            cp: '92320',
            dateStatut: '26/03/2026',
            statut: 'Ok inscription',
            commentaire: '',
            attribuerA: 'Nom téléconseillère',
            pro: true,
            statutColor: 'green'
        },
        {
            id: 1002,
            lien: 'https://www.pro-de-la-beaute.fr/530022',
            telephone: '06 01 02 03 04',
            dateLeads: '26/03/2026',
            ville: 'Châtillon',
            cp: '92320',
            dateStatut: '26/03/2026',
            statut: 'Liens supprimés',
            commentaire: '',
            attribuerA: 'Nom téléconseillère',
            pro: false,
            statutColor: 'red'
        },
        {
            id: 1003,
            lien: 'https://www.pro-de-la-beaute.fr/530022',
            telephone: '06 01 02 03 04',
            dateLeads: '26/03/2026',
            ville: 'Châtillon',
            cp: '92320',
            dateStatut: '26/03/2026',
            statut: 'NRP 1',
            commentaire: '',
            attribuerA: 'Nom téléconseillère',
            pro: false,
            statutColor: 'black'
        },
        {
            id: 1004,
            lien: 'https://www.pro-de-la-beaute.fr/530022',
            telephone: '06 01 02 03 04',
            dateLeads: '26/03/2026',
            ville: 'Châtillon',
            cp: '92320',
            dateStatut: '26/03/2026',
            statut: 'Pro autre plateforme',
            commentaire: '',
            attribuerA: 'Nom téléconseillère',
            pro: false,
            statutColor: 'black'
        },
        {
            id: 1005,
            lien: 'https://www.pro-de-la-beaute.fr/530022',
            telephone: '06 01 02 03 04',
            dateLeads: '26/03/2026',
            ville: 'Châtillon',
            cp: '92320',
            dateStatut: '26/03/2026',
            statut: 'Leads attribué',
            commentaire: '',
            attribuerA: 'Nom téléconseillère',
            pro: false,
            statutColor: 'blue'
        },
        {
            id: 1006,
            lien: 'https://www.pro-de-la-beaute.fr/530022',
            telephone: '06 01 02 03 04',
            dateLeads: '26/03/2026',
            ville: 'Châtillon',
            cp: '92320',
            dateStatut: '26/03/2026',
            statut: 'Ok inscription',
            commentaire: '',
            attribuerA: 'Nom téléconseillère',
            pro: true,
            statutColor: 'green'
        }
    ]);

    const [selectedLeads, setSelectedLeads] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const handleLeadSelect = (id) => {
        if (selectedLeads.includes(id)) {
            setSelectedLeads(selectedLeads.filter(leadId => leadId !== id));
        } else {
            setSelectedLeads([...selectedLeads, id]);
        }
    };

    const handleStatusChange = (newStatus) => {
        // Logique pour changer le statut des leads sélectionnés
        const updatedLeads = leads.map(lead => {
            if (selectedLeads.includes(lead.id)) {
                return { ...lead, statut: newStatus };
            }
            return lead;
        });
        setLeads(updatedLeads);
    };

    const handleSearchChange = (searchTerm) => {
        // Logique de recherche
        console.log('Recherche:', searchTerm);
    };

    return (
        <main className="min-h-[72vh]">
            {loading ? (
                <div className="w-full">
                    <MySpinner height={"60vh"} />
                </div>
            ) : (
                <div className="leads">
                    <div className="action-section">
                        <ActionButtons
                            onStatusChange={handleStatusChange}
                            selectedLeads={selectedLeads}
                        />
                        <LeadsFilter onSearch={handleSearchChange} />
                    </div>

                    <div className="pagination-section">
                        <Pagination
                            currentPage={currentPage}
                            totalItems={leads.length}
                            itemsPerPage={itemsPerPage}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>

                    <div className="table-section">
                        <LeadsTable
                            leads={leads}
                            selectedLeads={selectedLeads}
                            onLeadSelect={handleLeadSelect}
                        />
                    </div>
                </div>
            )}
        </main>
    );
}

export default Leads;