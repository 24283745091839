import { format } from "date-fns";
import { fr } from "date-fns/locale";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { formatTime } from "../../../utils/functions";


const CancelConfirmedAppointmentModal = ({showCancelModal, setShowCancelModal, cancelReason, setCancelReason, appointment, isSubmitting, validationError, cancelConfirmedAppointment}) => {

    return <Modal
    show={showCancelModal}
    onHide={() => {
        setCancelReason('');
        setShowCancelModal(false);
    }}
    centered
    backdropClassName="secondary-backdrop"
    style={{ zIndex: 1051 }}
>
    <Modal.Header closeButton>
        <Modal.Title>Annuler le rendez-vous</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <h5>{!appointment.is_planified_appointment ?
            "Rendez vous classique" :
            (appointment.user_id ?
                "Rendez-vous multiple planifié" :
                "Rendez-vous planifié manuellement")
        }</h5>
        <p>Rendez-vous du {format(appointment.appointment_date, "EE dd MMMM yyyy", {locale: fr})} à {formatTime(appointment.start_time)}</p>
        {!appointment.is_planified_appointment ?
            <p className="text-yellow-500">NB: Pour l'annulation d'un rendez-vous classique, si il y a un service à multiple rendez-vous, tous les prochains rendez-vous planifiés seront annulés aussi!</p> :
            (appointment.user_id ?
                <p className="text-yellow-500">NB: Pour l'annulation d'un rendez-vous multiple planifié, tous les prochains rendez-vous y compris le rendez-vous en question seront annulés!</p> :
                <p className="text-yellow-500">NB: Pour l'annulation d'un rendez-vous manuel, le rendez-vous sera supprimé de votre agenda!</p>
            )
        }
        {appointment.payment_type !== "onsite" && <p className="text-yellow-500">Vous serez remboursé à 90% de la somme!</p>}
        <p>L'action est irréversible. Veuillez préciser la raison de l'annulation :</p>
        <Form>
            <Form.Group controlId="cancelReason">
                <Form.Control
                    as="textarea"
                    rows={4}
                    style={{ resize: 'none', minHeight: '100px' }}
                    placeholder="Saisissez la raison ici..."
                    value={cancelReason}
                    onChange={(e) => setCancelReason(e.target.value)}
                    disabled={isSubmitting}
                />
            </Form.Group>
            {validationError && (
                <div style={{ color: 'red', marginTop: '10px', fontSize: '14px' }}>
                    {validationError}
                </div>
            )}
        </Form>
    </Modal.Body>
    <Modal.Footer>
        <Button
            variant="danger"
            onClick={() => {
                cancelConfirmedAppointment(
                    appointment.id,
                    appointment.beautician_id,
                    `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`,
                    appointment.appointment_date,
                    appointment.start_time,
                    appointment.end_time,
                    appointment.is_planified_appointment,
                    appointment.user_id,
                    appointment.services,
                    appointment.payment_type,
                    appointment.payment_intent,
                    appointment.appointment_id
                );
            }}
            disabled={isSubmitting}
        >
            {isSubmitting ? 'Annulation...' : 'Confirmer l\'annulation'}
        </Button>
        <Button
            variant="secondary"
            onClick={() => {
                setCancelReason('');
                setShowCancelModal(false);
            }}
            disabled={isSubmitting}
        >
            Annuler
        </Button>
    </Modal.Footer>
</Modal>;

}

export default CancelConfirmedAppointmentModal;