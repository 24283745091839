import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Modal, Button, Table, Form } from "react-bootstrap";
import { showWarningMessage } from "../../../utils/messageHelper";

const SendPromoCodeModal = ({ showModal, setShowModal, promo_codes, business_name, handleSentOffreInstructionClick, id }) => {
    const [filteredPromoCodes, setFilteredPromoCodes] = useState(promo_codes || []);
    const [searchTerm, setSearchTerm] = useState("");

    const handleClose = () => {
        setShowModal(false);
    };

    // Filtrer les résultats en fonction du terme de recherche
    useEffect(() => {
        const filtered = promo_codes.filter(item =>
            item.code.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredPromoCodes(filtered);
    }, [searchTerm, promo_codes]);


    const handleConfirmAbonnement = async (item) => {

        if(item.usage_limit - item.times_used <= 0 || new Date(item.expiration_date) < new Date()) {
            showWarningMessage("Ce code promo a déjà été utilisé ou n'est plus valide.");
            return;
        }

        handleSentOffreInstructionClick(item.code, id);
    };

    return (
        <>
            {/* Modal Principal */}
            <Modal show={showModal} size="xl" onHide={handleClose} className="my-modal-xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Envoyer un code promo à {business_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Barre de recherche */}
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Rechercher..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Form.Group>

                    <div className="overflow-y-auto max-h-[50vh]">

                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Code</th>
                                    <th>Offre</th>
                                    <th>Nombre de mois offert</th>
                                    <th>Remise</th>
                                    <th>Usage restant</th>
                                    <th>Date limite</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-y-auto">
                                {filteredPromoCodes.length > 0 ? (
                                    filteredPromoCodes.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.code}</td>
                                            <td>{item.offre_name}</td>
                                            <td>{item.months_valid}</td>
                                            <td>{item.discount_percentage}</td>
                                            <td>{item.usage_limit - item.times_used}</td>
                                            <td>{format(item.expiration_date, 'dd/MM/yyyy')}</td>
                                            <td>
                                                <Button variant="primary" onClick={() => handleConfirmAbonnement(item)}>
                                                    Envoyer le code promo
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={4} className="text-center">Aucun enregistrement trouvé!</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SendPromoCodeModal;