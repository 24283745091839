import { API_URL } from "../utils/constants";

const abonnementApi = {

    getAbonnementByUserId: async (token, userId) => {
        const response = await fetch(`${API_URL}/abonnement/get-by-id/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response.json();
    },

    getAllAbonnementByUserId: async (token, userId) => {
        const response = await fetch(`${API_URL}/abonnement/${userId}/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response.json();
    },

    getStatistics: async (token) => {
        const response = await fetch(`${API_URL}/abonnement/stats`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response.json();
    },

    getAllAbonnementByType: async (token, type) => {
        const response = await fetch(`${API_URL}/abonnement/all-by-type?type=${type}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response.json();
    },

    getAbonnementByMonth: async (token, month, year) => {
        let query = `?month=${month}&year=${year}`
        const response = await fetch(`${API_URL}/abonnement/get-by-month${encodeURI(query)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response.json();
    },

    getCustomerInvoiceByCustomerId: async (token, customerId) => {
        const response = await fetch(`${API_URL}/abonnement/customer-invoices/${customerId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response.json();
    },

    createPaymentIntent: async (token, abonnementData) => {
        try {
            const response = await fetch(`${API_URL}/abonnement/create-payment-intent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(abonnementData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la création du paiement');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans createPaymentIntent:', error);
            throw error;
        }
    },

    createSubscription: async (token, abonnementData) => {
        try {
            const response = await fetch(`${API_URL}/abonnement/create-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(abonnementData)
            });

            const data = await response.json();

            // Check for error in the response body first
            if (!data.success) {
                throw new Error(data.error || data.details || 'Une erreur est survenue lors du traitement du paiement');
            }
            
            // Also check HTTP status
            if (!response.ok) {
                throw new Error(`Erreur serveur: ${response.status} ${response.statusText}`);
            }

            return data;
        } catch (error) {
            console.error('Erreur lors de la création de l\'abonnement :', error);
            throw error;
        }
    },

    savePaymentForFuture: async (abonnementData) => {
        try {
            const response = await fetch(`${API_URL}/abonnement/save-payment-for-future`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(abonnementData)
            });

            const data = await response.json();
        
            // Check for error in the response body first
            if (!data.success) {
                throw new Error(data.error || 'Une erreur est survenue lors du traitement du paiement');
            }
            
            // Also check HTTP status
            if (!response.ok) {
                throw new Error(`Erreur serveur: ${response.status} ${response.statusText}`);
            }
    
            // Check for required fields in the response
            // if (!data.subscriptionId || !data.customerId) {
            //     throw new Error('Réponse du serveur incomplète');
            // }
    
            return data;
        } catch (error) {
            console.error('Erreur dans createPaymentIntent:', error);
            throw new Error(error.message || 'Erreur lors de la sauvegarde des infos de paiement');
        }
    },

    deleteSubscription: async (subscription_id) => {
        try {
            const response = await fetch(`${API_URL}/abonnement/delete-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({subscription_id: subscription_id})
            });

            const data = await response.json();

            // Check for error in the response body first
            if (!data.success) {
                throw new Error(data.error || data.details || 'Une erreur est survenue lors de la suppression de l\'abonnement');
            }

            // Also check HTTP status
            if (!response.ok) {
                throw new Error(`Erreur serveur: ${response.status} ${response.statusText}`);
            }

            return data;
        } catch (error) {
            console.error('Erreur dans deleteSubscription:', error);
            throw new Error(error.message || 'Erreur lors de la suppression de l\'abonnement');
        }
    },


    addOrUpdateAbonnement: async (token, abonnementData) => {
        try {
            const response = await fetch(`${API_URL}/abonnement/create-or-update-abonnement`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(abonnementData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la sauvegarde de l\'abonnement!');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans saveAppointment:', error);
            throw new Error(error.message || 'Erreur lors de la sauvegarde de l\'abonnement!');
        }
    },

    attributeSubscriptionToBeautician: async (token, user_id, months, offre_id) => {
        try {
            const response = await fetch(`${API_URL}/abonnement/attribute-abonnement`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    user_id: user_id,
                    months: months,
                    offre_id: offre_id
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la sauvegarde de l\'abonnement!');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans attribute abonnement:', error);
            throw new Error(error.message || 'Erreur lors de la sauvegarde de l\'abonnement!');
        }
    },

    endActiveAbonnement: async (token, userId) => {
        try {
            const response = await fetch(`${API_URL}/abonnement/end-active-abonnement`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({user_id: userId})
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de annulation de l\'abonnement!');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans end active abonnement:', error);
            throw new Error(error.message || 'Erreur lors de annulation de l\'abonnement!');
        }
    },
    
    cancelSubscriptionRenewal: async (token, user_id) => {
        try {
            const response = await fetch(`${API_URL}/abonnement/cancel-subscription-renewal`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({user_id: user_id})
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de l\'annulation du renouvellement de l\'abonnement!');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans cancelSubscriptionRenewal:', error);
            throw new Error(error.message || 'Erreur lors de l\'annulation du renouvellement de l\'abonnement!');
        }
    },

    updateSubscriptionWhenAddingCollaborator: async (token, user_id, collaborator_count) => {
        try {
            const response = await fetch(`${API_URL}/abonnement/update-when-adding-collaborator`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({user_id: user_id, collaborator_count: collaborator_count})
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la mise à jour de l\'abonnement!');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans updateSubscriptionWhenAddingCollaborator:', error);
            throw new Error(error.message || 'Erreur lors de la mise à jour de l\'abonnement!');
        }
    },

    updateSubscriptionWhenRemovingCollaborator: async (token, user_id, collaborator_count) => {
        try {
            const response = await fetch(`${API_URL}/abonnement/update-when-removing-collaborator`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({user_id: user_id, collaborator_count: collaborator_count})
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la mise à jour de l\'abonnement!');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans updateSubscriptionWhenRemovingCollaborator:', error);
            throw new Error(error.message || 'Erreur lors de la mise à jour de l\'abonnement!');
        }
    }
}

export default abonnementApi;