import React, { useEffect, useContext, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async';
import './Abonnements.css';
import tarifs from '../../assets/fonctionnalites/tarifs.png';
import tarif1 from '../../assets/tarifs/1.png';
import tarif2 from '../../assets/tarifs/2.png';
import tarif3 from '../../assets/tarifs/3.png';
import tarif4 from '../../assets/tarifs/4.png';
import tarif5 from '../../assets/tarifs/5.png';
import { AppContext } from '../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import AbonnementCard from './AbonnementCard';

function SubscriptionToggle({ isAnnual, setIsAnnual }) {

  return (
    <div
      className="relative flex items-center bg-[#bc193b] rounded-lg p-2 cursor-pointer w-56"
    // onClick={() => setIsAnnual(!isAnnual)}
    >
      {/* Fond blanc qui se déplace */}
      <div
        className={`absolute top-1 left-1 w-[48%] h-[80%] bg-white rounded shadow-md transition-all duration-300 
          ${isAnnual ? "translate-x-full" : "translate-x-0"}`}
      />

      {/* Texte Mensuelle */}
      <div onClick={() => setIsAnnual(false)} className={`relative cursor-pointer flex-1 text-center text-sm font-semibold transition-colors duration-300 
          ${!isAnnual ? "text-black" : "text-white"}`}>
        Mensuelle
      </div>

      {/* Texte Annuelle */}
      <div onClick={() => setIsAnnual(true)} className={`relative cursor-pointer flex-1 text-center text-sm font-semibold transition-colors duration-300 
          ${isAnnual ? "text-black" : "text-white"}`}>
        Annuelle
      </div>
    </div>
  );
}

const Tarifs = () => {
  const { setActivePageForHeader } = useContext(AppContext);
  const navigate = useNavigate();
  const [isAnnual, setIsAnnual] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.setAttribute('data-page', 'search');
    setActivePageForHeader("search");

    return () => {
      document.body.removeAttribute('data-page');
      setActivePageForHeader("");
    }
  }, [setActivePageForHeader]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCardClick = () => {
    const trialSection = document.getElementById('trial-section');
    if (trialSection) {
      trialSection.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleRedirect = () => {
    window.location.href = "https://calendly.com/contact-planibeauty/inscription-site-planibeauty?month=2025-03"
  };

  const cards = [
    {
      title: "Formule Classic",
      price: "49",
      image: tarif1,
      features: [
        "Agenda en ligne",
        "Interface personnalisée",
        "Page professionnelle",
        "Moyen de paiement en ligne ou en espèces",
        "Possibilité d'acomptes",
        "Statistiques",
        "Priorité pour les formations"
      ],
      onClick: (e) => { e.preventDefault(); navigate('/ajouter-etablissement'); },
      // onClick: handleCardClick,
      is_dispo: true,
      add_button: true,
      buttonText: "Souscrire"
    },
    {
      title: "Formule Accompagnement",
      price: "55",
      description: "Accedez à la formule classique, avec un accompagnement pour vous lancer et une periode d'essai",
      image: tarif2,
      features: [
        "Assister à des ateliers hebdomadaires ou mensuel(Communication, perfectionnement métier, gestion, administration",
        "Pack communication offert sur un mois",
      ],
      // onClick: handleRedirect,
      onClick: (e) => { e.preventDefault(); navigate('/ajouter-etablissement'); },
      is_dispo: true,
      add_button: true,
      buttonText: "Commencer l'essai de 10j"
    },
    {
      title: "Formule Formation",
      price: "49",
      description: "Accedez à la formule accompagnement avec 6 mois offerts si souscription à une formation en ligne PlaniBeauty",
      image: tarif3,
      features: [
        "Suivi de vos nouvelles prestations par un référents",
        "Accompagnement continu",
        "Accès à la communauté des professionnels de la beauté",
      ],
      onClick: handleRedirect,
      is_dispo: true,
      add_button: true,
      buttonText: "Souscrire à une formation et aux 6 mois offers"
    },
    {
      title: "Formule équipe",
      price: "59",
      padd: "Inclus 3 membres de l'équipe",
      image: tarif4,
      description: "Accedez à la formule accompagnement avec une période d'essai ",
      features: [
        "Agenda de trois membres de votre équipe, afin d'optimiser au mieux les rendez-vous",
        "+5 € par utilisateur supplémentaire",
      ],
      onClick: (e) => { e.preventDefault(); navigate('/ajouter-etablissement'); },
      // onClick: handleRedirect,
      is_dispo: true,
      add_button: true,
      buttonText: "Commencer l'essai de 10j"
    },

    {
      title: "Formule Caisse",
      price: "00.0",
      image: tarif5,
      features: [
        "Formule Classic",
        "Outils caisse",
        "Priorité pour les formations pour votre équipe"
      ],
      onClick: handleRedirect,
      is_dispo: false,
      add_button: true,
      buttonText: "Rester informé"
    }
  ];

  const annual_offres = [
    {
      title: "Formule équipe",
      price: "550",
      padd: "Inclus 3 membres de l'équipe",
      image: tarif4,
      description: "Accedez à la formule accompagnement avec une période d'essai ",
      features: [
        "Agenda de trois membres de votre équipe, afin d'optimiser au mieux les rendez-vous",
        "+5 € par utilisateur supplémentaire",
      ],
      // onClick: () => {
      //     if(profileData.type_beautician === "établissement") {
      //     window.location.href = "/beautician-dashboard/settings?active=abonnement&offre=équipe";
      //     }
      //     else {
      //         showWarningMessage("Vous ne pouvez pas souscrire à cette offre car vous êtes un indépendant, choisissez plutôt la formule classique");
      //     }
      // },
      onClick: (e) => { e.preventDefault(); navigate('/ajouter-etablissement'); },
      // onClick: () => {
      //   window.location.href = "https://calendly.com/contact-planibeauty/inscription-site-planibeauty";
      // },
      is_dispo: true,
      m_auto: false,
      addButton: true,
      buttonText: "Commencer l'essai de 10 jours"
    },
    {
      title: "Formule Accompagnement",
      price: "470",
      description: "Accedez à la formule classique, avec un accompagnement pour vous lancer et une periode d'essai",
      image: tarif2,
      features: [
        "Assister à des ateliers hebdomadaires ou mensuel(Communication, perfectionnement métier, gestion, administration",
        "Pack communication offert sur un mois",
      ],
      onClick: (e) => { e.preventDefault(); navigate('/ajouter-etablissement'); },
      // onClick: () => {
      //   window.location.href = "https://calendly.com/contact-planibeauty/inscription-site-planibeauty";
      // },
      is_dispo: true,
      m_auto: false,
      addButton: true,
      buttonText: "Commencer l'essai de 10 jours"
    },
    {
      title: "Formule Formation",
      price: "390",
      description: "Accedez à la formule accompagnement avec 6 mois offerts si souscription à une formation en ligne PlaniBeauty",
      image: tarif3,
      features: [
        "Suivi de vos nouvelles prestations par un référents",
        "Accompagnement continu",
        "Accès à la communauté des professionnels de la beauté",
      ],
      onClick: () => {
        window.location.href = "https://calendly.com/contact-planibeauty/inscription-site-planibeauty";
      },
      is_dispo: true,
      m_auto: false,
      addButton: true,
      buttonText: "Souscrire à une formation et aux 6 mois offerts"
    },

    {
      title: "Formule Caisse",
      price: "00.0",
      image: tarif5,
      features: [
        "Formule Classic",
        "Outils caisse",
        "Priorité pour les formations pour votre équipe"
      ],
      onClick: () => {
        window.location.href = "https://calendly.com/contact-planibeauty/inscription-site-planibeauty";
      },
      is_dispo: true,
      m_auto: false,
      addButton: true,
      buttonText: "Rester informé"
    }
  ];

  return (
    <>
      <Helmet>
        <title>Nos Tarifs | PlaniBeauty</title>
        <meta name="description" content="Découvrez nos offres et tarifs adaptés pour développer votre business avec PlaniBeauty." />
        <link rel="canonical" href="https://www.planibeauty.fr/tarifs" />
      </Helmet>

      {/* Titre de la page */}
      <div className="tarifs-header">
        <h1>Nos tarifs <span className="accent-dot">✦</span></h1>
        <p>2 offres adaptées pour développer votre business</p>
      </div>

      {/* Hero Section */}
      <div className="bande-section">
        <LazyLoadImage src={tarifs} alt="Tarifs PlaniBeauty" />
        <div className="bande-overlay"></div>
      </div>

      {/* Section des tarifs */}
      <section className="tarifs-section">
        <div className="flex justify-end my-3 px-3">
          <SubscriptionToggle isAnnual={isAnnual} setIsAnnual={setIsAnnual} />
        </div>
        <div className="tarifs-cards-container">

          {!isAnnual && cards && cards.map((card, index) => (
            <AbonnementCard
              key={index}
              title={card.title}
              price={card.price}
              padd={card.padd}
              description={card.description}
              image={card.image}
              features={card.features}
              onClick={card.onClick}
              is_dispo={card.is_dispo}
              m_auto={true}
              addButton={card.add_button}
              buttonText={card.buttonText}
            />
          ))}

          {isAnnual && annual_offres.map((card, index) => (
            <AbonnementCard
              key={index}
              title={card.title}
              price={card.price}
              padd={card.padd}
              description={card.description}
              image={card.image}
              features={card.features}
              onClick={card.onClick}
              is_dispo={card.is_dispo}
              m_auto={true}
              addButton={card.addButton}
              buttonText={card.buttonText}
            />
          ))}
        </div>
      </section>
      {/* Ajoutez ce code dans votre composant Abonnements.js, juste avant la dernière balise de fermeture </> */}

      {/* Section d'essai gratuit */}
      <section className="trial-section" id="trial-section">
        <div className="trial-background">
          <svg className="beauty-icon icon-1" style={{ top: '10%', left: '10%' }} viewBox="0 0 24 24">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
            <path d="M12 6c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z" />
          </svg>
          <svg className="beauty-icon icon-2" style={{ top: '20%', right: '15%' }} viewBox="0 0 24 24">
            <path d="M20 12c0-4.42-3.58-8-8-8s-8 3.58-8 8 3.58 8 8 8 8-3.58 8-8zM6 12c0-3.31 2.69-6 6-6s6 2.69 6 6-2.69 6-6 6-6-2.69-6-6z" />
          </svg>
          <svg className="beauty-icon icon-3" style={{ bottom: '15%', right: '10%' }} viewBox="0 0 24 24">
            <path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" />
          </svg>
        </div>
        <div className="trial-content">
          <h2>Testez Planibeauty Pro gratuitement pendant 10 jours !</h2>
          <p>Découvrez une gestion simplifiée et optimisée de vos rendez-vous.</p>
          <p>Activez votre essai gratuit en prenant rendez-vous avec un de nos conseillers.</p>
          <div className='flex flex-wrap items-center justify-center gap-3'>

            <a
              href="#trial"
              onClick={(e) => { e.preventDefault(); navigate('/ajouter-etablissement'); }}
              className="trial-button"
            >
              ESSAI GRATUIT
            </a>
            <a
              href="https://calendly.com/contact-planibeauty/inscription-site-planibeauty"
              target="_blank"
              rel="noopener noreferrer"
              className="trial-button"
            >
              Demande informations
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tarifs;