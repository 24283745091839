import { File } from "lucide-react"

export const PDFIcons = ({ size=24 }) => {
    return (
        <div className="relative">
            <File
                size={size}
                stroke="#bd193b"
            />

            <h1 className="absolute w-5/6 text-white text-center bg-[#bd193b] top-1/2 left-1/2 -translate-x-1/2">PDF</h1>
        </div>
    )
}