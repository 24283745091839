import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Modal, Button, Table, Form } from "react-bootstrap";
import { showErrorMessage, showSuccessMessage, showWarningMessage } from "../../../utils/messageHelper";
import { subscriptionLinksApi } from "../../../api/subscription_links.api";
import emailApi from "../../../api/email.api";
import { BASE_URL } from "../../../utils/constants";

const SendSubscriptionLinkModal = ({ showModal, setShowModal, row, setActiveRow, setReloadTrigger }) => {

    const handleClose = () => {
        setActiveRow(null);
        setShowModal(false);
    };

    const offres = [
        {
            offre: 'Formule Classique 49 €',
            months: 1,
            duration: "1 mois"
        },
        {
            offre: 'Formule Classique 49 €',
            months: 2,
            duration: "1 mois"
        },
        {
            offre: 'Formule Classique 49 €',
            months: 6,
            duration: "1 mois"
        }
    ]


    const handleConfirmAbonnement = async (item) => {
        try {
            const doublon = await subscriptionLinksApi.getByBeauticianId(localStorage.getItem('token'), row.id);
            if(doublon) {
                showWarningMessage("Vous avez déjà envoyé un lien à ce compte!", handleClose);
                return;
            }
            const res = await subscriptionLinksApi.create(localStorage.getItem('token'), item.months, row.id, localStorage.getItem('userId'));
            await emailApi.sendEmailNotification(
                row.email, 
                "Lien activation abonnement", 
                row.business_name ?? row.first_name + " " + row.last_name, 
                `L'équipe Planibeauty vous a offert un abonnement avec essai gratuit de ${item.months} mois, 
                pour l'activer vous devez suivre le lien en cliquant sur le bouton en bas. Pour toute question, n'hesitez pas à contacter notre équipte! Profitez-bien de nos services!`, 
                `${BASE_URL}/subscription/${res.data.slug}`, 
                "Suivre le lien pour activer mon abonnement"
            );

            showSuccessMessage('Le lien a été bien envoyé au professionnel', () => {
                handleClose();
                setReloadTrigger();
            });
        } catch (error) {
            console.log(error);
            showErrorMessage('Une erreur s\'est produite!');
        }
    };

    return (
        <>
            {/* Modal Principal */}
            <Modal show={showModal} size="lg" onHide={handleClose} className="my-modal-xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Envoyer un lien pour abonnement à {row.business_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="overflow-y-auto max-h-[50vh]">

                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Offre</th>
                                    <th>Nombre de mois offert</th>
                                    <th>Durée de validité</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-y-auto">
                                {offres.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index}</td>
                                        <td>{item.offre}</td>
                                        <td>{item.months}</td>
                                        <td>{item.duration}</td>
                                        <td>
                                            <Button variant="primary" onClick={() => handleConfirmAbonnement(item)}>
                                                Envoyer le lien
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SendSubscriptionLinkModal;