import { useEffect, useState } from "react";
import MySpinner from "../../../components/loader/Spinner";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import { Table, Modal, Button, Form } from "react-bootstrap";
import offreApi from "../../../api/offre.api";
import Swal from "sweetalert2";
import { showErrorMessage, showSuccessMessage } from "../../../utils/messageHelper";
import abonnementApi from "../../../api/abonnement.api";
import AbonnementStats from "./AbonnementStats";
import PromoCodes from "./PromoCodes";

const OffreAbonnement = () => {
    const [loading, setLoading] = useState(true);
    const [offers, setOffers] = useState([]);
    const [newOffer, setNewOffer] = useState({ name: "", description: "", price_per_month: "" });
    const [editingOffer, setEditingOffer] = useState(null); // Pour stocker l'offre en cours de modification
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [statistics, setStatistics] = useState(null);

    // Charger les offres au montage
    useEffect(() => {
        const fetchOffers = async () => {
            try {
                setLoading(true);
                const data = await offreApi.getAllOffer();
                setOffers(data);
                const stats = await abonnementApi.getStatistics(localStorage.getItem('token'));
                setStatistics(stats);
            } catch (error) {
                console.error("Erreur lors du chargement des offres :", error);
            } finally {
                setLoading(false);
            }
        };

        fetchOffers();
    }, []);

    // Créer une nouvelle offre
    const handleCreateOffer = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("token");
            const createdOffer = await offreApi.createOffer(token, newOffer);
            setOffers([...offers, createdOffer]);
            showSuccessMessage("Offre créé avec succès!");
            setShowModal(false);
            setNewOffer({ name: "", description: "", price_per_month: "" });
        } catch (error) {
            showErrorMessage('Erreur lors de la création de l\'offre');
            console.error("Erreur lors de la création de l'offre :", error);
        } finally {
            setLoading(false);
        }
    };

    // Mettre à jour une offre existante
    const handleUpdateOffer = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("token");
            const updatedOffer = await offreApi.updateOffer(token, editingOffer.id, newOffer);
            setOffers(
                offers.map((offer) => (offer.id === editingOffer.id ? updatedOffer : offer))
            );
            setShowModal(false);
            showSuccessMessage("Offre modifié avec succès!");
            setEditingOffer(null);
            setNewOffer({ name: "", description: "", price_per_month: "" });
        } catch (error) {
            showErrorMessage("Erreur lors de la mise à jour de l'offre");
            console.error("Erreur lors de la mise à jour de l'offre :", error);
        } finally {
            setLoading(false);
        }
    };

    // Supprimer une offre avec confirmation
    const handleDeleteOffer = async (id) => {
        const result = await Swal.fire({
            title: "Êtes-vous sûr ?",
            text: "Cette action supprimera définitivement l'offre.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Oui, supprimer",
            cancelButtonText: "Annuler",
        });

        if (result.isConfirmed) {
            try {
                setLoading(true);
                const token = localStorage.getItem("token");
                await offreApi.deleteOffer(token, id);
                setOffers(offers.filter((offer) => offer.id !== id));
                showSuccessMessage(
                    "L'offre a été supprimée avec succès."
                );
            } catch (error) {
                console.error("Erreur lors de la suppression de l'offre :", error);
                showErrorMessage(
                    "Une erreur est survenue lors de la suppression de l'offre."
                );
            } finally {
                setLoading(false);
            }
        }
    };


    // Ouvrir le modal pour créer ou modifier une offre
    const openModal = (offer = null) => {
        setShowModal(true);
        if (offer) {
            setIsEditing(true);
            setEditingOffer(offer);
            setNewOffer({ name: offer.name, description: offer.description, price_per_month: offer.price_per_month });
        } else {
            setIsEditing(false);
            setNewOffer({ name: "", description: "", price_per_month: "" });
        }
    };

    return (
        <main className="min-h-[72vh]">
            {loading ? (
                <div className="w-full">
                    <MySpinner height={"60vh"} />
                </div>
            ) : (
                <div>
                    <div className="p-2 md:p-6 bg-gray-100 rounded-lg">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-6">Gestion des offres</h2>
                            <button
                                onClick={() => openModal()}
                                className="px-2 md:px-4 py-2 text-sm md:text-base text-white bg-[#bd193b] flex items-center gap-2 rounded shadow"
                            >
                                <FaPlus />
                                Ajouter une nouvelle offre
                            </button>
                        </div>

                        {/* Tableau des offres */}
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nom</th>
                                    <th>Description</th>
                                    <th>Prix mensuel</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {offers.length > 0 ? (
                                    offers.map((offer, index) => (
                                        <tr key={offer.id}>
                                            <td>{index + 1}</td>
                                            <td>{offer.name}</td>
                                            <td>{offer.description}</td>
                                            <td>{offer.price_per_month} €</td>
                                            <td className="flex gap-2">
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => openModal(offer)}
                                                >
                                                    <FaEdit />
                                                </button>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => handleDeleteOffer(offer.id)}
                                                >
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            Aucune offre disponible.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                        {/* Modal pour créer ou modifier une offre */}
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {isEditing ? "Modifier l'offre" : "Ajouter une nouvelle offre"}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Nom</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Nom de l'offre"
                                            value={newOffer.name}
                                            onChange={(e) => setNewOffer({ ...newOffer, name: e.target.value })}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Description de l'offre"
                                            value={newOffer.description}
                                            onChange={(e) => setNewOffer({ ...newOffer, description: e.target.value })}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Prix par mois (€)</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Prix par mois"
                                            value={newOffer.price_per_month}
                                            onChange={(e) =>
                                                setNewOffer({ ...newOffer, price_per_month: e.target.value })
                                            }
                                        />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    Annuler
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={isEditing ? handleUpdateOffer : handleCreateOffer}
                                >
                                    {isEditing ? "Modifier" : "Ajouter"}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <PromoCodes />
                    <AbonnementStats statistics={statistics} />
                </div>
            )}
        </main>
    );
};

export default OffreAbonnement;