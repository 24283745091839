import userApi from "../../../api/user.api";
import MySpinner from "../../../components/loader/Spinner";
import React, { useEffect, useState } from "react";

const Statistics = () => {

    const [loading, setLoading] = useState(true);
    const [counts, setCounts] = useState([]);

    const fetchCountUser = async () => {
        setLoading(true);
        const res = await userApi.countUserByType(localStorage.getItem('token'));
        setCounts(res.count || []);
        setLoading(false);
    }

    useEffect(() => {
        fetchCountUser();
    }, []);

    const USER_TYPE = {
        client: "Clients",
        beautician: "Professionnelles",
        admin: "Administrations",
        operator: "Operateurs",
        recruteur: "Recruteurs",
        tele_conseiller: "Conseillères beauté",
    }

    return (
        <main className="min-h-[72vh]">
            {loading ? (
                <div className="w-full">
                    <MySpinner height={"60vh"} />
                </div>
            ) : (
                <div>
                    <div className="md:p-6 p-2 bg-gray-100 rounded-lg">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6">Nombre d'utilisateurs</h2>

                        {/* Section des totaux */}
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
                            {counts.map((element, index) => {
                                return <div key={index} className="p-2 md:p-4 bg-white rounded shadow text-center">
                                <h3 className="text-base md:text-lg font-medium text-gray-600">{USER_TYPE[element.user_type]}</h3>
                                <p className="text-2xl font-bold text-blue-600">{element.count}</p>
                            </div>;
                            })}
                        </div>
                    </div>
                </div>)}
        </main>
    );
}

export default Statistics;