// src/api/recruiters.api.js
import { API_URL } from "../utils/constants"

const recruitersApi = {
    // Obtenir tous les recrutements
    getAllHirings: async () => {
        try {
            const response = await fetch(`${API_URL}/recruiters`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            return await response.json()
        } catch (error) {
            throw new Error('Erreur lors de la récupération des recrutements')
        }
    },

    // Obtenir un recrutement par ID
    getHiringById: async (recruitmentID) => {
        try {
            const response = await fetch(`${API_URL}/recruiters/${recruitmentID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            return await response.json()
        } catch (error) {
            throw new Error(`Erreur lors de la recherche du recrutement ${recruitmentID}`)
        }
    },

    // Obtenir les recrutements d'une esthéticienne
    getHiringsByBeautician: async (beauticianID) => {
        try {
            const response = await fetch(`${API_URL}/recruiters/beautician/${beauticianID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            return await response.json()
        } catch (error) {
            throw new Error('Erreur lors de la récupération des recrutements de l\'esthéticienne')
        }
    },

    // Obtenir les recrutements d'un recruteur
    getHiringsByRecruiter: async (recruiterID) => {
        try {
            const response = await fetch(`${API_URL}/recruiters/recruiter/${recruiterID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            return await response.json()
        } catch (error) {
            throw new Error('Erreur lors de la récupération des recrutements du recruteur')
        }
    },

    // Recruter une esthéticienne
    hireBeautician: async (token, beauticianID, recruiterID) => {
        try {
            const response = await fetch(`${API_URL}/recruiters`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    beautician_id: beauticianID,
                    recruiter_id: recruiterID,
                })
            })
            return await response.json()
        } catch (error) {
            console.log(error)
            throw new Error('Erreur lors du recrutement de l\'esthéticienne')
        }
    },

    // Confirmer un recrutement
    confirmHiring: async (token, recruitmentID) => {
        try {
            const response = await fetch(`${API_URL}/recruiters/confirm`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ recruitment_id: recruitmentID })
            })
            return await response.json()
        } catch (error) {
            console.log(error)
            throw new Error('Erreur lors de la confirmation du recrutement')
        }
    },

    // Annuler un recrutement
    cancelHiring: async (token, recruitmentID) => {
        try {
            const response = await fetch(`${API_URL}/recruiters/${recruitmentID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            return await response.json()
        } catch (error) {
            console.log(error)
            throw new Error('Erreur lors de l\'annulation du recrutement')
        }
    }
}

export default recruitersApi
