import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { motion } from "motion/react";
import { formatTime } from "../../../utils/functions";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import MySpinner from "../../loader/Spinner";
import Swal from "sweetalert2";
import classNames from "classnames";
import NoAppointmentMessage from "./NoAppointmentMessage";
import appointmentApi from "../../../api/appointement.api";
import ShowHistoryModal from "./historique/ShowHistoryModal";
import { sendEmailBookingStatusToBeautician } from "../../../utils/mailServices";
import CancelConfirmedAppointmentModal from "./CancelConfirmedAppointmentModal";
import { showErrorMessage, showSuccessMessage } from "../../../utils/messageHelper";
import paymentApi from "../../../api/payment.api";
import AppointmentActions from "./AppointmentActions";
import { sendSmsChangeRdvStatusNotification } from "../../../utils/smsServices";
import beauticianApi from "../../../api/beautician.api";

const AppointmentList = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [reloadTrigger, setReloadTrigger] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false); // Gère la modale d'annulation
    const [cancelReason, setCancelReason] = useState(''); // Stocke la raison de l'annulation
    const [isSubmitting, setIsSubmitting] = useState(false); // Gère l'état de soumission
    const [validationError, setValidationError] = useState(''); // Stocke les erreurs de validation
    const [selectedAppointmentToCancel, setSelectedAppointmentToCancel] = useState(null);

    const fetchAppointments = async () => {
        setIsLoading(true);
        try {
            const userId = localStorage.getItem("userId");
            const result = await appointmentApi.listAppointmentsValidByUserIdJoinBeauticianAndServices(localStorage.getItem('token'), userId);
            // console.log(result);
            setData(result);
        } catch (error) {
            console.error("Erreur lors de la récupération des rendez-vous :", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAppointments();
    }, [reloadTrigger]);

    const handleRedirect = (e, beautician_slug) => {
        if (!e.target.classList.contains("action"))
            window.location = window.location.origin + "/beauticians/" + beautician_slug
    }

    // cancel non confirmed appointment
    const handleCancel = async (appointment) => {
        Swal.fire({
            title: `Annulation de rendez-vous!`,
            icon: "question",
            text: `Voulez vous vraiment annuler le rendez-vous du ${format(appointment.appointment_date, 'dd MMMM yyyy', {locale: fr})} à ${formatTime(appointment.start_time)}?`,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Oui, annuler",
            cancelButtonText: "Non"
        }).then(async (result) => {
            if (result.isConfirmed) {
                //si payment par carte bancaire, on cancel le payement
                if (appointment.payment_type !== "onsite") {

                    // Conversion de la date de création en objet Date
                    const createdAt = new Date(appointment.created_at);
                    const now = new Date();

                    // Calcul de la différence en jours
                    const differenceInMs = now - createdAt;
                    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

                    // Vérification si la différence ne dépasse pas 6 jours
                    if (differenceInDays <= 6) {

                        const cancel_payment = await paymentApi.cancelPayment(localStorage.getItem('token'), appointment.payment_intent, appointment.id);
                        if (!cancel_payment.success) {
                            showErrorMessage("Une erreur s'est produite lors de l'annulation du payment!");
                            return;
                        }

                    } else {
                        await appointmentApi.cancelExpiredAppointment(localStorage.getItem('token'), appointment.id);
                    }
                } else {
                    await appointmentApi.updateAppointmentStatus(localStorage.getItem('token'), appointment.id, { status: "cancelled" });
                }
                showSuccessMessage('Rendez-vous annulé avec succès!');
                setReloadTrigger(prev => !prev);
            }
        });
    }



    const cancelConfirmedAppointment = async (
        appointment_id,
        beautician_id,
        client_name,
        appointment_date,
        start_time,
        end_time,
        is_planified_appointment,
        user_id,
        services,
        payment_type,
        payment_intent,
        old_appointment_id
    ) => {
        //pour l'instant on notifie l'utilisateur que le foncionnalité est en cours de développement
        // showInformationMessage("Cette fonctionnalité est en cours de développement! merci pour votre comprehension...");
        // return;

        if (!cancelReason.trim()) {
            setValidationError('Veuillez saisir une raison pour l\'annulation.');
            return;
        }

        setIsSubmitting(true);
        setValidationError('');

        try {

            const request = await appointmentApi.updateAppointmentStatus(
                localStorage.getItem('token'),
                appointment_id,
                {
                    status: 'cancelled',
                    reason: cancelReason,
                    // params pour savoir le type du rendez vous à annuler
                    is_planified_appointment: is_planified_appointment,
                    user_id: user_id,
                    services: services,
                    payment_type: payment_type,
                    payment_intent: payment_intent,
                    appointment_date: appointment_date,
                    parent_id: old_appointment_id,
                    is_from_client: true,
                }
            );

            sendEmailBookingStatusToBeautician(
                beautician_id,
                client_name,
                appointment_date,
                formatTime(start_time),
                formatTime(end_time),
                'cancel',
                true,
                cancelReason,
            );

            const beautician = await beauticianApi.getBeauticianById(beautician_id);

            sendSmsChangeRdvStatusNotification(
                beautician.data.phone,
                `Bonjour ${beautician.business_name || beautician.first_name + ' ' + beautician.last_name},\n${client_name} a annulé son rendez-vous :\n- ${format(appointment_date, 'dd/MM/yyyy')}\n- ${formatTime(start_time)} à ${formatTime(end_time)}\n- ${cancelReason}\nRDV & messages sur https://www.planibeauty.fr `
            );

            showSuccessMessage("Le rendez-vous a été annulé avec succès.");
            setReloadTrigger(prev => !prev);

        }
        catch (error) {
            showErrorMessage("Une erreur s'est produite lors de l'annulation du rendez-vous.");
        } finally {
            setIsSubmitting(false);
            setCancelReason('');
            setShowCancelModal(false);
        }
    }

    const handleItemClick = async (appointment) => {
        setSelectedAppointment(appointment);
    }

    const statusLabels = {
        pending: "En attente",
        cancelled: "Annulé",
        confirmed: "Confirmé",
        completed: "Terminé",
    };

    const getStatusClass = (status) =>
        classNames("p-1 w-full h-full rounded border-2 text-sm", {
            "bg-gray-100 text-gray-400 border-gray-300": status === "completed",
            "bg-orange-100 text-orange-400 border-orange-300": status === "pending",
            "bg-green-100 text-green-400 border-green-300": status === "confirmed",
            "bg-red-100 text-red-400 border-red-300": status === "cancelled",
        });

    return (
        <div className="w-full">
            {isLoading ? (
                <MySpinner height="70vh" />
            ) : (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="h-full w-full min-h-[90vh] overflow-y-scroll py-2 px-0 sm:py-3 lg:py-3 sm:px-3 lg:px-3"
                >
                    {data.length > 0 ? (
                        <Table bordered striped>
                            <thead>
                                <tr>
                                    <th className="text-center">Pro</th>
                                    <th className="text-center">Date/heure</th>
                                    {/* <th className="text-center">Prestation</th> */}
                                    <th className="text-center">Statut</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-auto">
                                {data.map((appointment, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{appointment.business_name || `${appointment.first_name} ${appointment.last_name}`}</td>
                                            <td>
                                                {format(
                                                    new Date(appointment.appointment_date),
                                                    "EEEE d MMMM yyyy",
                                                    { locale: fr }
                                                )} {formatTime(appointment.start_time)}
                                            </td>
                                            {/* <td>{appointment.services.map((service, index) => (<span key={index}>{service.subcategory_name}, </span>))}</td> */}
                                            <td className="text-center">
                                                <div className={getStatusClass(appointment.status)}>
                                                    {statusLabels[appointment.status]}
                                                </div>
                                            </td>
                                            <td className="relative">
                                                <AppointmentActions
                                                    appointment={appointment} 
                                                    handleItemClick={() => handleItemClick(appointment)} 
                                                    handleRedirect={(e) => handleRedirect(e, appointment.slug)} 
                                                    handleCancel={(e) => {
                                                        //e.preventDefault();
                                                        handleCancel(appointment);
                                                    }} 
                                                    setShowCancelModal={(e) => {
                                                        //e.preventDefault();
                                                        setSelectedAppointmentToCancel(appointment);
                                                        setShowCancelModal(true);
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    ) : (
                        <NoAppointmentMessage />
                    )}
                </motion.div>
            )}

            {/* Modal */}
            {selectedAppointment && (
                <ShowHistoryModal
                    appointment={selectedAppointment}
                    onClose={() => setSelectedAppointment(null)}
                />
            )}

            {selectedAppointmentToCancel && showCancelModal && (
                <CancelConfirmedAppointmentModal
                showCancelModal={showCancelModal} 
                setShowCancelModal={setShowCancelModal} 
                cancelReason={cancelReason} 
                setCancelReason={setCancelReason} 
                appointment={selectedAppointmentToCancel} 
                isSubmitting={isSubmitting} 
                validationError={validationError} 
                cancelConfirmedAppointment={cancelConfirmedAppointment}
                />
            )}

        </div>
    );
};

export default AppointmentList;
