import { ArrowRight, TrendingUp, Users } from "lucide-react"

export const Leads = ({ size = 24 }) => {
    return (
        <div className="relative w-fit h-fit">
            <Users
                size={size}
                className="relative z-0"
                style={{ clipPath: "polygon(0% 0%, 100% 0%, 40% 100%, 0% 80%)" }} 
            />
            <TrendingUp
                size={size}
                className="absolute top-[40%] left-[10%] z-10"
            />
        </div>
    )
}

export const Suivis = ({ size=24 }) => {
    return (
        <div className="relative w-fit h-fit border-2 border-s-transparent rounded-full">
            <ArrowRight
                size={size * .75}
                className="relative z-0"
            />
        </div>
    )
}