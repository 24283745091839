import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "../../utils/constants";
import RegisterForm from "./RegisterForm";
import { registerLinksApi } from "../../api/register_links.api";


const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const RegisterWithLink = () => {

    const { setActivePageForHeader, connectedUser } = useContext(AppContext);
    const [link, setLink] = useState(null);
    // const [selectedType, setSelectedType] = useState('');
    const [selectedType, setSelectedType] = useState('indépendant');


    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.setAttribute('data-page', 'search');
        setActivePageForHeader("search");

        return () => {
            document.body.removeAttribute('data-page');
            setActivePageForHeader("");
        }
    }, [setActivePageForHeader]);

    useEffect(() => {
        const checkIfLinkValid = async () => {
            try {
                const url_array = window.location.href.split('/');
                const slug = url_array[url_array.length - 1];
                let res = await registerLinksApi.getBySlug(slug);
                setLink(res);
            } catch (error) {
                window.location.href = "/404";
            }
        }

        if (connectedUser) {
            window.location.href = "/";
        }

        checkIfLinkValid();

    }, []);

    return <div className="py-[5vh] min-h-[70vh]">
        {/* {link && !selectedType &&
            <div className="text-center mt-5 px-3">
                <h1 className="text-2xl font-bold">Inscription des Pro sur Planibeauty</h1>
                <p className="text-gray-500 mt-3">Choisissez le type de compte :</p>
                <div className="flex justify-center flex-wrap gap-3 mt-5">
                    <div onClick={() => setSelectedType('indépendant')} className="text-xl font-semibold cursor-pointer p-5 rounded-lg shadow bg-gray-100 hover:bg-green-300 hover:scale-105 transition-all duration-500 ease-in-out">
                        <h1 className="text-3xl">Indépendant</h1>
                        <p className="text-sm text-gray-500">Formule <b>Classique</b> : 49 € par mois après {link.months ? link.months + ' mois' : link.days + ' jours'} d'essai gratuit</p>
                    </div>
                    <div onClick={() => setSelectedType('établissement')} className="text-xl font-semibold cursor-pointer p-5 rounded-lg shadow bg-gray-100 hover:bg-green-300 hover:scale-105 transition-all duration-500 ease-in-out">
                        <h1 className="text-3xl">Etablissement</h1>
                        <p className="text-sm text-gray-500">Formule <b>Equipe</b> : 59 € par mois après {link.months ? link.months + ' mois' : link.days + ' jours'} d'essai gratuit</p>
                    </div>
                </div>
            </div>} */}

        {/* {link && selectedType && */}
        {link &&
            <Elements stripe={stripePromise}>
                <RegisterForm link={link} selectedType={selectedType} />
            </Elements>}
    </div>
}


export default RegisterWithLink;