import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { motion } from "motion/react"

import defaultProfile from "../../assets/images/default-profile.png"

import { FaX } from "react-icons/fa6"
import { dashboardContext } from '../../pages/dashboard/Dashboard'
import { FaStar } from 'react-icons/fa'
import { BiStar } from 'react-icons/bi'
import { BASE_URL } from '../../utils/constants'
import MySpinner from '../loader/Spinner'
import { beauticiansSubCategoriesApi } from '../../api/beauticians_subcategories.api'
import { checkIfProCanBeAvailable, groupPrestationsByCategoryId } from '../../utils/functions'
import { sitesApi } from '../../api/sites.api'
import { differenceInDays, differenceInMonths, format } from 'date-fns'
import { fr } from 'date-fns/locale'
import beauticianApi from '../../api/beautician.api'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import ReactSwitch from 'react-switch';
import SiteInfoComponent from './SiteInfoComponent';
import userApi from '../../api/user.api';
import { sendEmailAccountStatusNotification } from '../../utils/mailServices';
import promoCodesApi from '../../api/promo_codes.api';
import emailApi from '../../api/email.api';
import { showInformationMessage, showSuccessMessage, showWarningMessage } from '../../utils/messageHelper';
import abonnementApi from '../../api/abonnement.api';
import offreApi from '../../api/offre.api';
import smsApi from '../../api/sms.api';
import { sendSmsAccountStatusNotification } from '../../utils/smsServices';
import SendPromoCodeModal from './usersheet/SendPromoCodeModal';

const formatTrialDate = (date) => {
    return new Date(date).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    }).replace(',', '')
}

const UserSheet = () => {
    const {
        showDeletionModal,
        showEditModal,
        userData,
        showUserSheetModal,
        setShowBeauticianCommentaire,
        setReloadTrigger,
        setIsFromSheet,
        dynamicUserData,
        setDynamicUserData
    } = useContext(dashboardContext)

    //const [data, setData] = useState(userData)
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const [creator, setCreator] = useState({});
    const [abonnement, setAbonnement] = useState(null);
    const [activeDetailsTab, setActiveDetailsTab] = useState("contact");

    const [localIsAvailable, setLocalIsAvailable] = useState(false);
    const [localIsBeauticianActive, setLocalIsBeauticianActive] = useState(false);
    const [localIsAccountActiveUser, setLocalIsAccountActiveUser] = useState(false);
    const [localIsSentInstructions, setLocalIsSendInstructions] = useState(false);
    const [showSendPromoCodeModal, setShowSendPromoCodeModal] = useState(false);

    const [offres, setOffres] = useState([{
        id: 'free-trial',
        name: 'Essaie gratuit de 10 jours sur l\'offre classique',
        code: ""
    }]);

    const [promoCodes, setPromoCodes] = useState([]);

    const getRating = (rate) => {
        return (
            <span className="flex items-center gap-2">
                {rate > 0 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
                {rate > 1 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
                {rate > 2 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
                {rate > 3 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
                {rate > 4 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
            </span>
        )
    }

    const acceptRegistration = (id, name, email, user_id) => {
        Swal.fire({
            title: "Voulez-vous vraiment accepter l'inscription?",
            icon: "question",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Non",
            confirmButtonText: "Oui, accepter"
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Afficher le loader
                Swal.fire({
                    title: 'Traitement en cours...',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                try {
                    await beauticianApi.acceptRegistration(
                        localStorage.getItem('token'),
                        id,
                        email,
                        name
                    );

                    // on active le free trial
                    /*const res = await freeTrialApi.create(localStorage.getItem('token'), { user_id: user_id });

                    if(res.success) {
                        showWarningMessage('L\'utilisateur ne peut plus souscrire à la periode d\'essaie!');
                    }*/

                    setLocalIsBeauticianActive(true);

                    // Indiquer le succès
                    Swal.fire({
                        title: "Succès!",
                        text: "L'inscription a été acceptée.",
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: false
                    });
                } catch (error) {
                    // Gestion des erreurs
                    Swal.fire({
                        title: "Erreur",
                        text: "Une erreur est survenue lors de l'acceptation de l'inscription.",
                        icon: "error",
                    });
                }
            }
        });
    };

    const MySwal = withReactContent(Swal);

    const handleAvailabilityChange = async (first_name, last_name, id, is_available) => {
        // Mettre à jour la disponibilité via une API ou une méthode
        const check = await checkIfProCanBeAvailable(id);

        if (!is_available && !check) {
            showWarningMessage('Vous devez ajouter au moins un service/prestation pour rendre le profil disponible!');
            return;
        }

        MySwal.fire({
            title: 'Confirmation',
            text: `Voulez-vous vraiment modifier la disponibilité de ${first_name} ${last_name} ?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Mettre à jour la disponibilité via une API ou une méthode
                setLocalIsAvailable(prev => !prev);
                await beauticianApi.updateAvailability(localStorage.getItem('token'), id, { is_available: !is_available });
                //setReloadTrigger(prev => !prev);
            }
        });
    };

    useEffect(() => {
        const getAllData = async () => {
            const token = localStorage.getItem("token")

            setIsLoading(true);
            setLocalIsAvailable(userData['is_available']);
            setLocalIsAccountActiveUser(userData['is_account_active']);
            const sites = await sitesApi.getSiteByBeauticianId(userData["id"]);
            userData["social_networks"] = sites.data;
            const services = await beauticiansSubCategoriesApi.getByBauticianId(userData["id"]);
            const abonnement = await abonnementApi.getAbonnementByUserId(token, userData["user_id"]);
            const grouped_cat = groupPrestationsByCategoryId(services);

            setAbonnement(abonnement || null);

            if (userData['creator_id']) {
                const beautician_creator = await userApi.getUserById(token, userData['creator_id'])

                setCreator(beautician_creator)
            }

            userData["prestations"] = grouped_cat;

            setLocalIsBeauticianActive(userData['is_active']);

            setDynamicUserData({
                ...userData
            })

            // get promo codes 
            const promo_codes = await promoCodesApi.getAllPromoCodes(localStorage.getItem('token'));

            setPromoCodes(promo_codes || []);

            const check_abonnements = await abonnementApi.getAllAbonnementByUserId(localStorage.getItem('token'), userData['user_id']);
            if (Array.isArray(check_abonnements) && check_abonnements.length > 0) {
                setLocalIsSendInstructions(true);
            }

            setIsLoading(false);
        }

        getAllData()

        return () => {
            setAbonnement({})
            setDynamicUserData([])
        }
    }, []);

    const budgetCategory = {
        'moderate': 'Modérée',
        'economic': 'Economique',
        'premium': 'Premium'
    };

    const getImageUrl = (profilePhoto) => {
        if (!profilePhoto) return defaultProfile;
        try {
            return `${BASE_URL}/images/beauticians/${encodeURIComponent(profilePhoto)}`;
        } catch (error) {
            console.error('Error with image path:', error);
            return defaultProfile;
        }
    };

    const handleDeleteClick = () => {
        setIsFromSheet(true);
        showDeletionModal(true);
    }

    const handleEditClick = () => {
        setIsFromSheet(true);
        showEditModal(true);
    }

    const handleChangeStatusCompteUser = (user_id) => {
        Swal.fire({
            title: `Voulez-vous vraiment ${localIsAccountActiveUser ? 'desactiver' : 'activer'} le compte?`,
            icon: "question",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Non",
            confirmButtonText: `Oui, ${localIsAccountActiveUser ? 'desactiver' : 'activer'}`
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    let raison = '';

                    // Pour la désactivation, on demande la raison
                    if (localIsAccountActiveUser) {
                        const { value: inputRaison } = await Swal.fire({
                            title: 'Raison de la désactivation',
                            input: 'textarea',
                            inputLabel: 'Raison',
                            inputPlaceholder: 'Raison de la désactivation',
                            inputAttributes: {
                                'aria-label': 'Raison de la désactivation'
                            },
                            showCancelButton: true,
                            confirmButtonText: 'Valider',
                            cancelButtonText: 'Annuler',
                            inputValidator: (value) => {
                                if (!value) {
                                    return 'Vous devez renseigner la raison de la désactivation!';
                                }
                            }
                        });

                        if (!inputRaison) {
                            // L'utilisateur a annulé ou n'a pas validé
                            return;
                        }

                        // La raison entrée par l'utilisateur
                        raison = inputRaison;
                    }

                    sendEmailAccountStatusNotification(user_id, !localIsAccountActiveUser ? 'active' : 'inactive', raison);
                    sendSmsAccountStatusNotification(user_id, !localIsAccountActiveUser ? 'active' : 'inactive');

                    // Afficher le loader
                    Swal.fire({
                        title: 'Traitement en cours...',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });

                    await userApi.changeAccountStatus(
                        localStorage.getItem('token'),
                        !localIsAccountActiveUser,
                        user_id
                    );

                    setLocalIsAccountActiveUser(prev => !prev);

                    // Indiquer le succès
                    Swal.fire({
                        title: "Succès!",
                        text: "Le status du compte a été modifié avec succès.",
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: false
                    });
                } catch (error) {
                    // Gestion des erreurs
                    Swal.fire({
                        title: "Erreur",
                        text: "Une erreur est survenue.",
                        icon: "error",
                    });
                }
            }
        });

    }

    const handleSentOffreInstructionClick = async (id, code) => {
        Swal.fire({
            title: "Confirmez l'action?",
            text: "Nous allons envoyer un email au professionnel pour les instructions à suvivre pour activer l'offre.",
            icon: 'question',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Oui, confirmer",
            cancelButtonText: "Annuler"
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (id === "free-trial") {
                    await emailApi.sendEmailInstructionsFreeTrial(dynamicUserData["email"], dynamicUserData['business_name'] ?? `${dynamicUserData["first_name"]} ${dynamicUserData["last_name"]}`)
                } else {
                    await emailApi.sendEmailInstructionsCodePromo(dynamicUserData["email"], dynamicUserData['business_name'] ?? `${dynamicUserData["first_name"]} ${dynamicUserData["last_name"]}`, code);
                }
                showSuccessMessage("L'email a été bien envoyé au professionnel!");
                setLocalIsSendInstructions(prev => !prev);
            }
        });
    }

    const handleSentPromoCodeInstructionClick = async  (code) => {
        Swal.fire({
            title: "Confirmez l'action?",
            text: "Nous allons envoyer un email au professionnel pour le code promo et les instructions à suvivre pour l'activer.",
            icon: 'question',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Oui, confirmer",
            cancelButtonText: "Annuler"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await emailApi.sendEmailInstructionsCodePromo(dynamicUserData["email"], dynamicUserData['business_name'] ?? `${dynamicUserData["first_name"]} ${dynamicUserData["last_name"]}`, code);
                await beauticianApi.updatePromoCodeSentStatus(localStorage.getItem('token'), dynamicUserData['id'], true); // user_id refers to beautician id
                showSuccessMessage("L'email a été bien envoyé au professionnel!", () => {
                    setLocalIsSendInstructions(prev => !prev);
                    setShowSendPromoCodeModal(false);
                });
            }
        });
    }

    const handleAttributeSubsciptionToBeautician = async (months) => {

        if (localIsSentInstructions) {
            showInformationMessage('L\'utilisateur a déjà un abonnement en cours!');
            return;
        }

        Swal.fire({
            title: "Confirmez l'action?",
            text: `Voulez-vous vraiment attribuer un abonnement de ${months} mois au professionnel?`,
            icon: 'question',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Oui, confirmer",
            cancelButtonText: "Annuler"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const req_offre = await offreApi.getAllOffer();
                let offre_classique = Array.isArray(req_offre) ? req_offre.find((obj) => obj.name.toLowerCase() === 'classique') : null;
                if (!offre_classique) {
                    showWarningMessage('L\'offre est introuvable!');
                    return;
                }

                await abonnementApi.attributeSubscriptionToBeautician(localStorage.getItem('token'), userData['user_id'], months, offre_classique.id);

                showSuccessMessage("L'abonnement a été effectué avec succès!");;
                setLocalIsSendInstructions(prev => !prev);
                setLocalIsAccountActiveUser(true);
                setLocalIsAvailable(true);
                setLocalIsBeauticianActive(true);

                let _abonnement = await abonnementApi.getAbonnementByUserId(localStorage.getItem('token'), userData['user_id']);
                setAbonnement(_abonnement);

                try {
                    await emailApi.sendEmailNotification(
                        dynamicUserData['email'],
                        `Abonnement de ${months} mois attribué`,
                        dynamicUserData['first_name'] + ' ' + dynamicUserData['last_name'],
                        `L'equipe Planibeauty vous informe que vous avez reçu un abonnement de <b>${months} mois</b> sur l'offre <b>classique</b>. 
                        Nous vous invitons completer votre profil : <b>ajouter vos préstations</b> et <b>gérér vos disponibilités</b>. 
                        Vous pouvez accéder à votre tableau de bord en cliquant sur le lien ci-dessous:`,
                        `${BASE_URL}/beautician-dashboard`,
                        "Accéder au tableau de bord"
                    );
                } catch (error) {
                    console.log(error);
                }
                try {
                    await smsApi.sendSmsNotification(
                        dynamicUserData['phone'],
                        "Vous avez reçu un abonnement de " + months + " mois sur l'offre classique. Veuillez completer votre profil (ajouter vos préstations et gérer vos disponibilités).\nRDV & messages sur https://www.planibeauty.fr"
                    );
                } catch (error) {
                    console.log(error);
                }
            }
        });
    }

    const handleSendPromoCodesClick = () => {
        setShowSendPromoCodeModal(true);
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="absolute top-0 z-20 left-0 w-screen h-screen bg-gray-700 bg-opacity-80 flex items-center justify-center"
        >
            <div
                onSubmit={(e) => e.preventDefault()}
                className={`relative ${dynamicUserData['user_type'] === 'beautician' ? 'w-11/12 min-h-[50vh]' : 'w-11/12 md:w-1/2 min-h-[20vh]'} max-h-[90vh] overflow-y-auto flex flex-col gap-2 bg-white border rounded shadow`}
            >
                <div className="sticky flex justify-between top-0 left-0 w-full z-30 bg-gray-100">
                    <div className="flex items-center justify-between gap-3 py-2 px-5">
                        <div className="flex flex-wrap gap-3 items-center">
                            {dynamicUserData['user_type'] === 'beautician' && <div className="px-3 py-2 text-sm bg-green-400 text-white rounded">
                                Abonnement : {abonnement ? (() => {
                                    if (new Date(abonnement.trial_end) > new Date())
                                        return `${abonnement.offre_name} (Essai)`

                                    return abonnement.offre_name
                                })() : "Aucun"}
                            </div>}
                            <button className='px-3 py-2 text-sm rounded bg-yellow-400 hover:bg-yellow-500' onClick={handleEditClick}>Modifier les informations</button>
                            <button className='px-3 py-2 text-sm rounded bg-red-500 hover:bg-red-600 text-white' onClick={handleDeleteClick}>Supprimer le profil</button>
                            <button className='px-3 py-2 text-sm rounded bg-yellow-400 hover:bg-yellow-500' onClick={() => handleChangeStatusCompteUser((dynamicUserData['user_type'] === "beautician" || dynamicUserData['user_type'] === "tele_conseiller") ? dynamicUserData["user_id"] : dynamicUserData['id'])}>{!localIsAccountActiveUser ? 'Activer' : 'Desactiver'} le compte</button>
                            {dynamicUserData['user_type'] === 'beautician' && <>
                                {!localIsBeauticianActive &&
                                    <button className='px-3 py-2 text-sm rounded text-white bg-green-500 hover:bg-green-600' onClick={() => acceptRegistration(dynamicUserData['id'], (dynamicUserData['first_name'] + ' ' + dynamicUserData['last_name']), dynamicUserData['email'], dynamicUserData['user_id'])}>Accepter l'inscription</button>
                                }
                                <div className="flex items-center gap-2">
                                    <ReactSwitch
                                        checked={localIsAvailable}
                                        onChange={() => handleAvailabilityChange(dynamicUserData['first_name'], dynamicUserData['last_name'], dynamicUserData['id'], dynamicUserData['is_available'])}
                                        offColor="#f87171" // Couleur désactivée
                                        onColor="#4ade80" // Couleur activée
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        width={40} // Largeur du switch
                                        height={20} // Hauteur du switch
                                        handleDiameter={15}
                                    />
                                    <label htmlFor=""> Disponibilité</label>
                                </div>
                            </>}
                        </div>
                        {dynamicUserData['user_type'] === 'beautician' && <>
                            <button className='px-3 py-2 text-sm rounded bg-gray-200 border border-gray-800 hover:bg-gray-300' onClick={() => navigate(`/beauticians/${dynamicUserData['slug']}`)}>Voir le profil</button>
                        </>}
                    </div>
                    <button
                        onClick={() => {
                            showUserSheetModal(false);
                            setReloadTrigger(prev => !prev);
                        }}
                        className="absolute top-6 right-6"
                    >
                        <FaX />
                    </button>
                </div>

                {isLoading ? <div>
                    <MySpinner height={"40vh"} />
                </div> :
                    <div>
                        <div className='flex flex-col md:flex-row w-full gap-3 px-4'>
                            <div className={`w-full md:w-1/2`}>
                                <section className="flex flex-col gap-2 mt-4 w-full">
                                    <section className={`flex flex-col items-center md:flex-row md:items-start gap-3 w-full`}>
                                        {
                                            dynamicUserData['profile_photo'] && <div className="size-48 border rounded shadow">
                                                <img className="w-full h-full" alt={dynamicUserData["last_name"]} src={dynamicUserData['profile_photo'] && dynamicUserData['profile_photo'] !== "default_profile_photo.jpg" ? getImageUrl(dynamicUserData['profile_photo']) : defaultProfile} />
                                            </div>
                                        }
                                        <div className="flex flex-col gap-1 w-full">
                                            <div className='mb-2 flex justify-between gap-2 border-b w-full'>
                                                <h5 className='mb-0 pb-2 duration-200'>Informations personnelles</h5>
                                            </div>
                                            <span className="text-base"><b>Nom complet:</b> {`${dynamicUserData["first_name"]} ${dynamicUserData["last_name"]}`}</span>
                                            {dynamicUserData['business_name'] && <span className="text-base"><b>Nom commercial:</b> {dynamicUserData["business_name"]}</span>}
                                            {dynamicUserData['address'] && <span className="text-base"><b>Adresse:</b> {dynamicUserData["address"]}</span>}
                                            {dynamicUserData['budget_category'] && <span className="text-base"><b>Catégorie budgétaire:</b> {budgetCategory[dynamicUserData["budget_category"]]}</span>}
                                            {dynamicUserData['average_rating'] && <div className='flex items-center gap-2'><b>Note globale :</b> {getRating(dynamicUserData['average_rating'])}</div>}
                                            <span><b>Date inscription: </b>{format(dynamicUserData['created_at'], 'd MMMM yyyy à HH:mm', { locale: fr })}</span>
                                            {creator && creator.first_name && creator.last_name &&
                                                <span><b>Inscrit par: </b>{creator.first_name} {creator.last_name}</span>
                                            }
                                        </div>
                                    </section>
                                    <div className="mt-2 flex flex-col gap-1">
                                        <div className="flex items-center gap-3 border-b mb-2 *:cursor-pointer">
                                            <h5 className={`${activeDetailsTab === "contact" ? "border-b-gray-800" : "text-gray-500 border-b-transparent"} mb-0 pb-2 border-b-4 duration-200`} onClick={() => setActiveDetailsTab("contact")}>Contacts</h5>
                                            {dynamicUserData['user_type'] === "beautician" && <><h5 className={`${activeDetailsTab === "abonnement" ? "border-b-gray-800" : "text-gray-500 border-b-transparent"} mb-0 pb-2 border-b-4 duration-200`} onClick={() => setActiveDetailsTab("abonnement")}>Abonnement</h5>
                                                <h5 className={`${activeDetailsTab === "cb" ? "border-b-gray-800" : "text-gray-500 border-b-transparent"} mb-0 pb-2 border-b-4 duration-200`} onClick={() => setActiveDetailsTab("cb")}>Carte bancaire</h5>
                                            </>}
                                        </div>
                                        {activeDetailsTab === "contact" && <>
                                            <div className="flex items-center gap-2">
                                                <span className="font-semibold">Email: </span>
                                                <span>{dynamicUserData["email"]}</span>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <span className="font-semibold">Téléphone: </span>
                                                <span>{dynamicUserData["phone"]}</span>
                                            </div>
                                        </>}
                                        {(activeDetailsTab === "cb" && dynamicUserData['user_type'] === "beautician") && <>
                                            {dynamicUserData["customer_id"] ?
                                                <>
                                                    L'utilisateur a déjà rempli les informations necessaires pour le renouvellement automatique de l'abonnement
                                                </> : <>
                                                    <p>L'utilisateur n'a pas encore rempli les informations necessaires pour le renouvellement automatique de l'abonnement</p>
                                                </>}
                                        </>}
                                        {(activeDetailsTab === "abonnement" && dynamicUserData['user_type'] === "beautician") && <>
                                            {abonnement ? <>
                                                {abonnement.offre_name &&
                                                    <div className="flex items-center gap-2">
                                                        <span className="font-semibold">Offre: </span>
                                                        <span><b>{abonnement.offre_name}</b> {abonnement.is_attribued ? `avec ${differenceInMonths(abonnement.end_date, abonnement.start_date)} mois d'essai` : (
                                                            abonnement.status === 'trialing'
                                                                ? (differenceInMonths(abonnement.trial_end, abonnement.trial_start) < 1
                                                                    ? `avec ${differenceInDays(abonnement.trial_end, abonnement.trial_start)} jours d'essai`
                                                                    : `avec ${differenceInMonths(abonnement.trial_end, abonnement.trial_start)} mois d'essai`)
                                                                : ''
                                                        )}</span>
                                                    </div>}
                                                {abonnement.price_per_month &&
                                                    <div className="flex items-center gap-2">
                                                        <span className="font-semibold">Prix: </span>
                                                        <span>{abonnement.price_per_month} €</span>
                                                    </div>}
                                                {abonnement.trial_start && abonnement.trial_end &&
                                                    <div className="flex items-center gap-2">
                                                        <span className="font-semibold">Essai: </span>
                                                        <span>{formatTrialDate(abonnement.trial_start)} au {formatTrialDate(abonnement.trial_end)}</span>
                                                    </div>}
                                                {abonnement.end_date &&
                                                    <div className="flex items-center gap-2">
                                                        <span className="font-semibold">Durée:</span>
                                                        <span>{formatTrialDate(abonnement.start_date)} au {formatTrialDate(abonnement.end_date)}</span>
                                                    </div>}
                                            </> :
                                                <p>Aucun abonnement en cours...</p>}
                                        </>}
                                    </div>
                                    {["beautician", 'tele_conseiller'].includes(dynamicUserData['user_type']) && <>
                                        <div className="flex flex-col gap-1">
                                            <h5 className='mb-0 pb-2 border-b duration-200'>A propos</h5>
                                            <p>{dynamicUserData['description'] || "Pas de description à afficher"}</p>
                                        </div>
                                        {dynamicUserData['user_type'] === "beautician" &&
                                            <div>
                                                <button className='px-3 py-2 border border-gray-500 bg-gray-200 hover:bg-gray-300 rounded' onClick={() => setShowBeauticianCommentaire(true)}>Voir les avis</button>
                                            </div>
                                        }
                                    </>}

                                    {(dynamicUserData['user_type'] === "beautician" && !abonnement) && <>
                                        <div className="flex flex-col gap-1 mt-3">
                                            <h5 className='underline'>Attribuer un abonnement au pro sans CB</h5>
                                            <button
                                                className='px-3 py-2 border border-gray-500 bg-gray-200 hover:bg-gray-300 rounded'
                                                onClick={() => handleAttributeSubsciptionToBeautician(1)}
                                            >
                                                Abonnement de 1 mois sur l'offre classique
                                            </button>
                                            <button
                                                className='px-3 py-2 border border-gray-500 bg-gray-200 hover:bg-gray-300 rounded'
                                                onClick={() => handleAttributeSubsciptionToBeautician(2)}
                                            >
                                                Abonnement de 2 mois sur l'offre classique
                                            </button>
                                            <button
                                                className='px-3 py-2 border border-gray-500 bg-gray-200 hover:bg-gray-300 rounded'
                                                onClick={() => handleAttributeSubsciptionToBeautician(6)}
                                            >
                                                Abonnement de 6 mois sur l'offre classique
                                            </button>
                                        </div>
                                        <div className="flex flex-col gap-1 mt-3">
                                            <h5 className='underline'>Envoyer les instructions pour activer un offre gratuit</h5>
                                            {offres.map(element =>
                                                <button
                                                    key={element.id}
                                                    className='px-3 py-2 border border-gray-500 bg-gray-200 hover:bg-gray-300 rounded'
                                                    onClick={() => handleSentOffreInstructionClick(element.id, element.code)}
                                                >
                                                    {element.name}
                                                </button>
                                            )}
                                        </div>
                                        {!dynamicUserData['promo_code_sent'] && <div className="flex flex-col gap-1 mt-3">
                                            <h5 className='underline'>Envoyer un code promo pour activer un abonnement avec instructions</h5>
                                            <button onClick={handleSendPromoCodesClick} className='px-3 py-2 border border-gray-500 bg-gray-200 hover:bg-gray-300 rounded'>Envoyer un code avec instructions</button>
                                        </div>}
                                        {showSendPromoCodeModal && <SendPromoCodeModal 
                                        showModal={showSendPromoCodeModal} 
                                        setShowModal={setShowSendPromoCodeModal} 
                                        promo_codes={promoCodes} 
                                        business_name={dynamicUserData['business_name'] ?? dynamicUserData['first_name'] + ' ' +  dynamicUserData['last_name']}
                                        handleSentOffreInstructionClick={handleSentPromoCodeInstructionClick}
                                        />}
                                    </>}
                                </section>
                            </div>
                            {dynamicUserData['user_type'] === 'beautician' && <div className='w-full md:w-1/2'>
                                <SiteInfoComponent />
                            </div>}
                        </div>
                    </div>
                }

            </div>
        </motion.div>
    )
}

export default UserSheet