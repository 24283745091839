import React, { useState, useContext } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import appointmentApi from '../../../../api/appointement.api';
import { BeauticianPortalContext } from '../../../../context/BeauticianPortalContext';
import { sendEmailBookingStatusToClient } from '../../../../utils/mailServices';
import { formatTime, validEmail, validatePhoneNumber } from '../../../../utils/functions';
import { showErrorMessage, showSuccessMessage } from '../../../../utils/messageHelper';
import { format } from "date-fns";
import { sendSmsChangeRdvStatusNotification } from "../../../../utils/smsServices";

const CancelAppointmentModal = ({ showModal, handleCloseModal, selectedEvent, handleCloseParentModal }) => {


    const { profileData, setReloadTrigger } = useContext(BeauticianPortalContext);

    const [cancelReason, setCancelReason] = useState(''); // Stocke la raison de l'annulation
    const [isSubmitting, setIsSubmitting] = useState(false); // Gère l'état de soumission
    const [validationError, setValidationError] = useState(''); // Stocke les erreurs de validation

    // Fonction pour annuler le rendez-vous
    const handleCancelAppointment = async () => {
        if (!cancelReason.trim()) {
            setValidationError('Veuillez saisir une raison pour l\'annulation.');
            return;
        }

        setIsSubmitting(true);
        setValidationError('');

        try {
            const request = await appointmentApi.updateAppointmentStatus(
                localStorage.getItem('token'),
                selectedEvent.extendedProps.appointment_id,
                {
                    status: 'cancelled',
                    reason: cancelReason,
                    is_planified_appointment: selectedEvent.extendedProps.is_planified_appointment,
                    user_id: selectedEvent.extendedProps.user_id,
                    services: selectedEvent.extendedProps.services,
                    payment_type: selectedEvent.extendedProps.payment_type,
                    payment_intent: selectedEvent.extendedProps.payment_intent,
                    appointment_date: selectedEvent.extendedProps.appointment_date,
                    parent_id: selectedEvent.extendedProps.old_appointment_id,
                    is_from_client: false,
                }
            );

            // Modification de la condition de succès
            if (request && request.appointment) { // Vérifie si request.appointment existe
                if (selectedEvent.extendedProps.user_id) {
                    sendEmailBookingStatusToClient(
                        {
                            email: selectedEvent.extendedProps.email,
                            first_name: selectedEvent.extendedProps.first_name,
                            last_name: selectedEvent.extendedProps.last_name,
                        },
                        `${profileData.first_name} ${profileData.last_name}`,
                        selectedEvent.extendedProps.appointment_date,
                        formatTime(selectedEvent.extendedProps.start_time),
                        formatTime(selectedEvent.extendedProps.end_time),
                        'cancel',
                        profileData.slug,
                        cancelReason,
                        true
                    );

                    sendSmsChangeRdvStatusNotification(
                        selectedEvent.extendedProps.phone,
                        `Bonjour ${selectedEvent.extendedProps.first_name} ${selectedEvent.extendedProps.last_name},\n${profileData.business_name ?? profileData.first_name + " " + profileData.last_name} a annulé votre rendez-vous :\n- ${format(selectedEvent.extendedProps.appointment_date, 'dd/MM/yyyy')}\n- ${formatTime(selectedEvent.extendedProps.start_time)} à ${formatTime(selectedEvent.extendedProps.end_time)}\n- Raison: ${cancelReason}\nRDV & messages sur https://www.planibeauty.fr `
                    );
                }

                if (selectedEvent.extendedProps.is_planified_appointment &&
                    !selectedEvent.extendedProps.user_id &&
                    validEmail(selectedEvent.extendedProps.email)) {
                    sendEmailBookingStatusToClient(
                        {
                            email: selectedEvent.extendedProps.email,
                            first_name: selectedEvent.extendedProps.first_name,
                            last_name: selectedEvent.extendedProps.last_name,
                        },
                        `${profileData.first_name} ${profileData.last_name}`,
                        selectedEvent.extendedProps.appointment_date,
                        formatTime(selectedEvent.extendedProps.start_time),
                        formatTime(selectedEvent.extendedProps.end_time),
                        'cancel',
                        profileData.slug,
                        cancelReason,
                        true
                    );
                }

                if (selectedEvent.extendedProps.is_planified_appointment &&
                    !selectedEvent.extendedProps.user_id &&
                    validatePhoneNumber(selectedEvent.extendedProps.phone)) {
                    sendSmsChangeRdvStatusNotification(
                        selectedEvent.extendedProps.phone,
                        `Bonjour ${selectedEvent.extendedProps.first_name} ${selectedEvent.extendedProps.last_name},\n${profileData.business_name ?? profileData.first_name + " " + profileData.last_name} a annulé votre rendez-vous :\n- ${format(selectedEvent.extendedProps.appointment_date, 'dd/MM/yyyy')}\n- ${formatTime(selectedEvent.extendedProps.start_time)} à ${formatTime(selectedEvent.extendedProps.end_time)}\n- Raison: ${cancelReason}\nRDV & messages sur https://www.planibeauty.fr`
                    );
                }

                setTimeout(() => {
                    setReloadTrigger(prev => !prev);
                }, 500);

                showSuccessMessage("Le rendez-vous a été annulé avec succès.");
                handleCloseParentModal();
            } else {
                // L'annulation a réussi mais la réponse n'a pas la structure attendue
                showSuccessMessage("Le rendez-vous a été annulé avec succès.");
                setTimeout(() => {
                    setReloadTrigger(prev => !prev);
                }, 500);
                handleCloseParentModal();
            }
        } catch (error) {
            console.error("Erreur d'annulation:", error);
            showErrorMessage("Une erreur s'est produite lors de l'annulation du rendez-vous.");
        } finally {
            setIsSubmitting(false);
            handleCloseModal();
        }
    };

    return (
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            centered
            backdropClassName="secondary-backdrop"
            style={{ zIndex: 1051 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Annuler le rendez-vous</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>{!selectedEvent.extendedProps.is_planified_appointment ?
                    "Rendez vous classique" :
                    (selectedEvent.extendedProps.user_id ?
                        "Rendez-vous multiple planifié" :
                        "Rendez-vous planifié manuellement")
                }</h5>
                {!selectedEvent.extendedProps.is_planified_appointment ?
                    <p className="text-yellow-500">NB: Pour l'annulation d'un rendez-vous classique, si il y a un service à multiple rendez-vous, tous les prochains rendez-vous planifiés seront annulés aussi!</p> :
                    (selectedEvent.extendedProps.user_id ?
                        <p className="text-yellow-500">NB: Pour l'annulation d'un rendez-vous multiple planifié, tous les prochains rendez-vous y compris le rendez-vous en question seront annulés!</p> :
                        <p className="text-yellow-500">NB: Pour l'annulation d'un rendez-vous manuel, le rendez-vous sera supprimé de votre agenda!</p>
                    )
                }
                {selectedEvent.extendedProps.payment_type !== "onsite" && <p className="text-yellow-500">Le client sera remboursé à 100% de la somme!</p>}

                <p className="mt-5">L'action est irréversible. Veuillez préciser la raison de l'annulation :</p>
                <Form>
                    <Form.Group controlId="cancelReason">
                        <Form.Control
                            as="textarea"
                            rows={4}
                            style={{ resize: 'none', minHeight: '100px' }}
                            placeholder="Saisissez la raison ici..."
                            value={cancelReason}
                            onChange={(e) => setCancelReason(e.target.value)}
                            disabled={isSubmitting}
                        />
                    </Form.Group>
                    {validationError && (
                        <div style={{ color: 'red', marginTop: '10px', fontSize: '14px' }}>
                            {validationError}
                        </div>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={handleCancelAppointment}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Annulation...' : 'Confirmer l\'annulation'}
                </Button>
                <Button
                    variant="secondary"
                    onClick={handleCloseModal}
                    disabled={isSubmitting}
                >
                    Annuler
                </Button>
            </Modal.Footer>
        </Modal>
    );

}

export default CancelAppointmentModal;