import React, { useState, useEffect } from 'react'
import BeauticianCard from './BeauticianCard'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import beauticianApi from '../../api/beautician.api'
import trendingApi from '../../api/trending.api'
import './BeauticianCardList.css'

const CarouselSection = ({ items, title }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [cardsPerPage, setCardsPerPage] = useState(window.innerWidth < 992 ? 2 : 3)

  useEffect(() => {
    const handleResize = () => {
      setCardsPerPage(window.innerWidth < 992 ? 2 : 3)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setCurrentPage(0)
  }, [cardsPerPage])

  const totalPages = Math.ceil(items.length / cardsPerPage)

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 0))
  }

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages - 1))
  }

  const getCurrentCards = () => {
    const start = currentPage * cardsPerPage
    return items.slice(start, start + cardsPerPage)
  }

  return (
    <div className="beautician-list-container">
      <div className="beauticians-carousel">
        <button
          className="carousel-button prev"
          onClick={handlePrevPage}
          disabled={currentPage === 0}
          aria-label="Précédent"
        >
          <ChevronLeft size={24} />
        </button>
        <div className={`beautician-grid grid-cols-${cardsPerPage}`}>
          {getCurrentCards().map(item => (
            <div key={`${item.id}-${currentPage}`} className="beautician-grid-item">
              <BeauticianCard beautician={item} />
            </div>
          ))}
        </div>
        <button
          className="carousel-button next"
          onClick={handleNextPage}
          disabled={currentPage === totalPages - 1}
          aria-label="Suivant"
        >
          <ChevronRight size={24} />
        </button>
      </div>
    </div>
  )
}

const BeauticianCardList = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [newBeauticians, setNewBeautician] = useState([])
  const [trendingBeauticians, setTrendingBeautician] = useState([])

  useEffect(() => {
    const fetchBeauticians = async () => {
      try {
        setLoading(true)
        const [trending, allBeauticians] = await Promise.all([
          trendingApi.getActiveTrending(),
          beauticianApi.getAllAvailableBeauticiansWithAbonnement(),
        ])

        const now = new Date()
        const sevenDaysAgo = new Date()
        sevenDaysAgo.setDate(now.getDate() - 7)

        let tmpTrendingBeauticians = trending.data.slice(0, 15).map(item => ({ ...item, card_status: "trending" }))
        let tmpNewBeauticians = allBeauticians.data
          .filter(b => new Date(b.created_at) >= sevenDaysAgo)
          .slice(0, 15)
          .map(item => ({ ...item, card_status: "news" }))

        let usedIds = new Set([...tmpTrendingBeauticians.map(b => b.id), ...tmpNewBeauticians.map(b => b.id)])

        let remainingBeauticians = allBeauticians.data.filter(b => !usedIds.has(b.id))

        while (tmpTrendingBeauticians.length < 15 && remainingBeauticians.length > 0) {
          const randomIndex = Math.floor(Math.random() * remainingBeauticians.length)
          tmpTrendingBeauticians.push({ ...remainingBeauticians[randomIndex] })
          remainingBeauticians.splice(randomIndex, 1)
        }

        while (tmpNewBeauticians.length < 15 && remainingBeauticians.length > 0) {
          const randomIndex = Math.floor(Math.random() * remainingBeauticians.length)
          tmpNewBeauticians.push({ ...remainingBeauticians[randomIndex] })
          remainingBeauticians.splice(randomIndex, 1)
        }

        setNewBeautician(tmpNewBeauticians)
        setTrendingBeautician(tmpTrendingBeauticians)

      } catch (err) {
        setError('Erreur lors du chargement des Professionnels de Beauté.')
      } finally {
        setLoading(false)
      }
    }

    fetchBeauticians()
  }, [])

  if (loading) {
    return <div className="beautician-list-container"><div className="beautician-grid" style={{ placeItems: 'center' }}><div>Chargement...</div></div></div>
  }

  if (error) {
    return <div className="beautician-list-container"><div className="beautician-grid" style={{ placeItems: 'center' }}><div>{error}</div></div></div>
  }

  return (
    <div className="space-y-16">
      <div className="grid grid-rows-2 xl:grid-rows-1 grid-cols-1 xl:grid-cols-2 items-center justify-center gap-1">
        <div className="">
          <h3 className="text-center text-2xl text-[#a01532]">Tendances</h3>
          {trendingBeauticians.length > 0 ? <CarouselSection items={trendingBeauticians} title="Professionnels de Beauté" /> : <h5 className='text-sm text-center'>Aucun Professionnel à afficher</h5>}
        </div>
        <div className="">
          <h3 className="text-center text-2xl text-[#a01532]">Nouveaux</h3>
          {newBeauticians.length > 0 ? <CarouselSection items={newBeauticians} title="Nouveaux Professionnels de Beauté" /> : <h5 className='text-sm text-center'>Aucun Professionnel à afficher</h5>}
        </div>
      </div>

      {trendingBeauticians.length <= 0 && newBeauticians.length <= 0 &&
        <div className="beautician-list-container"><div className="beautician-grid" style={{ placeItems: 'center' }}><div>Aucun Professionnel de Beauté disponible pour le moment.</div></div></div>}
    </div>
  )
}

export default BeauticianCardList
