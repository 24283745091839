import React, { useState } from "react"

import { UserRoundPen } from "lucide-react"
import { Abonnement, PackCM, Revenus, Trial } from "./CustomIcon"

const Resume = () => {
    const [data, setData] = useState([
        { title: "Pros inscris", value: 20, icon: (size=24) => <UserRoundPen size={size} className="text-violet-700" />},
        { title: "Essais", value: 15, icon: (size=24) => <Trial size={size} />},
        { title: "Abonnements", value: 20, icon: (_size) => <Abonnement size={20} />},
        { title: "Pack CM", value: 20, icon: (_size) => <PackCM size={20} />},
        { title: "Revenus Téléconseiller", value: "500 €", icon: (size=24) => <Revenus size={size} />}
    ])

    return (
        <section>
            <h1 className="text-2xl text-[#bd193b]">
                Bonjour <span className="font-bold">Joy Calver</span>
            </h1>
            <p className="text-gray-600 font-semibold">Votre activité depuis votre débuts chez PlaniBeauty</p>

            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-3">
                {data.map((item, key) =>
                    <div
                        key={`data-key-${key}`}
                        className="px-3 md:px-4 py-3 flex items-center gap-2 md:gap-3 rounded-lg bg-white shadow-sm"
                    >
                        <div>
                            {item.icon(32)}
                        </div>

                        <div className="flex flex-col">
                            <span className="text-sm">{item.title}</span>
                            <span className="text-xl font-bold">{item.value}</span>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

export default Resume
