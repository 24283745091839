import React from "react"
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { Calendar, CreditCard, Gift, Power, ShieldAlert } from "lucide-react";
import logo from "../../../../../assets/images/planibeauty192.png";
import tarif1 from '../../../../../assets/tarifs/1.png';
import AbonnementCard from "../../../../../pages/tarifs/AbonnementCard";

const AbonnementStatus = ({
    profileData,
    setShowFactureModal,
    loading,
    abonnement,
    abonnementAuto,
    handleChangeAbonnementAutoStatus,
    handleEndAbonnement,
    isAbonnementAttribued,
    isTrial,
    trial,
    monthsAttribued = 0,
    setShowForm
}) => {

    const classique = {
        title: "Formule Classic",
        price: "49",
        image: tarif1,
        features: [
            "Agenda en ligne",
            "Interface personnalisée",
            "Page professionnelle",
            "Moyen de paiement en ligne ou en espèces",
            "Possibilité d'acomptes",
            "Statistiques",
            "Priorité pour les formations"
        ],
        onClick: () => {

            if (setShowForm) {
                setShowForm(true);
                setTimeout(() => {
                    window.location.href = "#abonnement-form";
                }, 1000);
            }
        },
        is_dispo: true,
        m_auto: false,
        addButton: true,
        buttonText: "Prolonger"
    };

    const calculateDiffDay = (date1, date2) => {
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        console.log(diffDays);
        return diffDays;
    }

    return <React.Fragment>
        {!profileData.is_first_login && <div className="flex justify-end gap-2">
            <button
                onClick={() => setShowFactureModal(true)}
                className="mt-4 border border-gray-300 bg-gray-50 px-4 py-2 rounded hover:bg-gray-300"
            >
                Factures
            </button>
            {/* <button
                onClick={() => setShowHistoryModal(true)}
                className="mt-4 border border-gray-300 bg-gray-50 px-4 py-2 rounded hover:bg-gray-300"
            >
                Voir les historiques d'abonnement
            </button> */}
        </div>}
        <div className="mt-4">
            <h4>Etat de votre abonnement</h4>
            {loading ? (
                <div className="flex justify-center items-center">
                    <div className="loader border-t-4 border-blue-500 w-8 h-8 rounded-full animate-spin"></div>
                </div>
            ) : abonnement ? (
                <div className={(isAbonnementAttribued && calculateDiffDay(new Date(), new Date(abonnement.end_date)) <= 2) ? "flex flex-wrap justify-between items-start gap-3" : ''}>
                    <div>
                        <div className="flex items-center space-x-5 my-3">
                            <img src={logo} className="h-16" alt="logo planibeauty" />
                            <h4 className="font-bold">Formule {abonnement.offre_name} </h4>
                        </div>
                        <hr />
                        {
                            isAbonnementAttribued && <div className="flex items-center space-x-5 my-3">
                                <Gift color="#bd193b" size={32} />
                                <h5 className="mb-0 text-xl">{monthsAttribued} mois offerts</h5>
                            </div>
                        }

                        {isTrial && <div className="flex items-center space-x-5 my-3">
                            <Gift color="#bd193b" size={32} />
                            <h5 className="mb-0 text-xl">{calculateDiffDay(new Date(trial.start_date), new Date(trial.end_date))} jours d'essai</h5>
                        </div>
                        }

                        <div className="flex items-center space-x-5 my-3">
                            <CreditCard color="#bd193b" size={32} />
                            <h5 className="mb-0 text-xl">{isAbonnementAttribued ? 0 : abonnement.price_per_month} € {!isAbonnementAttribued && 'par mois'}</h5>
                        </div>
                        <div className="flex items-center space-x-5 my-3">
                            <Calendar color="#bd193b" size={32} />
                            {isAbonnementAttribued ? <div>
                                <h5 className="mb-0 text-xl">
                                    Fin de l'offre le {format(new Date(abonnement.end_date), 'd MMMM yyyy', { locale: fr })}
                                </h5>
                                <p className="mb-0">Sans souscription, coupure des outils</p>
                            </div> :
                                <h5 className="mb-0 text-xl">
                                    Renouvellement automatique le {format(new Date(isTrial ? trial.end_date : abonnement.end_date), 'd MMMM yyyy', { locale: fr })}
                                </h5> 
                            }
                        </div>
                        {!isAbonnementAttribued ?
                        <div className="flex items-center space-x-5 my-3">
                        {abonnementAuto ? <>
                            <Power color="#bd193b" size={32} />
                            <button className="mb-0 text-xl" onClick={() => handleChangeAbonnementAutoStatus(false)}>
                                Résilier l'abonnement
                            </button>
                        </> : <>
                            <Power color="#bd193b" size={32} />
                            <button className="mb-0 text-xl" onClick={() => handleChangeAbonnementAutoStatus(true)}>
                                Activer le renouvellement automatique
                            </button>
                        </>
                        }
                        </div> : <div className="flex items-center space-x-5 my-3">
                        <Power color="#bd193b" size={32} />
                            <button className="mb-0 text-xl" onClick={() => handleEndAbonnement()}>
                                Résilier l'abonnement
                            </button>
                        </div>
                        }
                    </div>
                    {(isAbonnementAttribued && calculateDiffDay(new Date(), new Date(abonnement.end_date)) <= 2) &&
                        <div className="">
                            <AbonnementCard {...classique} />
                        </div>
                    }
                </div>
            ) : (
                <div className="flex items-center space-x-5 my-3">
                    <ShieldAlert color="#bd193b" size={32} />
                    <div>
                        <h5 className="mb-0 text-xl text-red-500">
                            Aucun abonnement actif
                        </h5>
                        <p className="mb-0 text-red-400">Sans souscription, coupure des outils</p>
                    </div>
                </div>
            )}
        </div>
    </React.Fragment>
}


export default AbonnementStatus;