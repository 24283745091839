import React, { useContext } from "react";
import { Accordion } from "react-bootstrap";
import OpenTime from "./siteInfoAgenda/OpenTime";
import BlockedDay from "./siteInfoAgenda/BlockedDay";

import { dashboardContext } from '../../pages/dashboard/Dashboard';
import ListeCollaborateurs from "../portals/Beauticians/settings/availability/ListeCollaborateurs";

const SiteInfoAgenda = () => {

    const { activeID, userData } = useContext(dashboardContext);

    return (
        <React.Fragment>
            <Accordion.Header>Information sur mon agenda</Accordion.Header>
            <Accordion.Body>
                {
                    (userData && userData.type_beautician === 'établissement') &&
                    <ListeCollaborateurs from_admin={true} beautician_id={activeID} user_id={userData.user_id} />
                }
                <OpenTime activeID={activeID} />
                <BlockedDay activeID={activeID} />
            </Accordion.Body>
        </React.Fragment>
    );
};

export default SiteInfoAgenda;
