import React, { useContext, useState, useEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import SiteInfoApropos from '../../../dashboard/SiteInfoApropos';
import SiteInfoReseauSociaux from '../../../dashboard/SiteInfoReseauSociaux';
import SiteInfoBudgetCategorie from '../../../dashboard/SiteInfoBudgetCategorie';
import { sitesApi } from '../../../../api/sites.api';
import SiteInfoPhotoEtablissement from '../../../dashboard/SiteInfoPhotoEtablissement';
import { BeauticianPortalContext } from '../../../../context/BeauticianPortalContext';
import MySpinner from '../../../loader/Spinner';
import InfoPerso from './Profile/InfoPerso';
import DocumentsUpload from '../../../dashboard/DocumentsUpload';


const ProfileSettings = () => {

    const { 
        profileData, 
        setReloadTrigger, 
        activeAccordionSetting,
        setActiveAccordionSetting 
    } = useContext(BeauticianPortalContext);

    const [site, setSite] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchOrCreateSite = async () => {
        if (isLoading) return; // Évite les appels multiples

        try {
            setIsLoading(true);
            const response = await sitesApi.getOrCreateSiteByBeauticianId(profileData.beautician_id, localStorage.getItem("token"));
            if (response.success && response.data) {
                setSite(response.data);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (profileData) {
            fetchOrCreateSite();
        }
    }, [profileData]);

    const handleAccordionChange = (eventKey) => {
        setActiveAccordionSetting(eventKey);
      };

    return <div className="settings-form">
        {profileData ?
            <Accordion activeKey={activeAccordionSetting} onSelect={handleAccordionChange}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Informations générales</Accordion.Header>
                    <Accordion.Body className="flex flex-col gap-3">
                        <InfoPerso profileData={profileData} setReloadTrigger={setReloadTrigger} />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <SiteInfoBudgetCategorie activeID={profileData.beautician_id} userData={profileData} setReloadTrigger={setReloadTrigger} />
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    {<SiteInfoPhotoEtablissement activeID={profileData.beautician_id}/>}
                </Accordion.Item>
                {/* <Accordion.Item eventKey="3">
                    {site && <SiteInfoApropos site={site} activeID={profileData.beautician_id} setReloadTrigger={setReloadTrigger} />}
                </Accordion.Item> */}

                <Accordion.Item eventKey="3">
                    {site && <SiteInfoReseauSociaux site={site} activeID={profileData.beautician_id} fromPortal={true} />}
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <DocumentsUpload activeID={profileData.beautician_id} />
                </Accordion.Item>
            </Accordion> :
            <MySpinner height={'60vh'} />
        }
    </div>
}

export default ProfileSettings;