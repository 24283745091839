import aimg from "../../assets/fonctionnalites/manage_agenda.png";
const FonctionnaliteAgenda = () => {
  return <div className="fonctionnalites-content">
    <div className="fonctionnalite-wrapper1">
        <div className="textzone">
            <h2>Obtenez des rendez-vous et gérer votre agenda facilement.</h2>
          <h4>Réservations 24/7</h4>
            <p>Les clients peuvent peuvent prendre rendez-vous facilement en ligne à tout moment.</p>
          <h4>Agenda intelligent en ligne</h4>
          <p>Synchronisation en temps réel des créneaux disponibles</p>
          <h4>Gestion de votre temps de travail</h4>
            <p>Paramétrages de vos congés, votre pause déjeuner de fermetures récurrentes et exceptionnelles</p>
          <h4>Confirmation et rappels automatiques</h4>
            <p>Notifications par SMS pour confirmer, rappeler ou annuler un rendez-vous</p>
        </div>

        <img src={aimg} alt="" className="imgzone"/>
    </div>
  </div>
}

export default FonctionnaliteAgenda;