import React from 'react'

import { Search, SlidersHorizontal } from 'lucide-react'
import { Link } from 'react-router-dom'
import { PDFIcons } from './CustomIcon'
import useIsMobile from '../../../../utils/useIsMobile'

const Document = () => {
    const docs = [
        { title: "Discours étape 1", url: "" },
        { title: "Discours étape 2", url: "" },
        { title: "Traitement objection", url: "" },
        { title: "Utilisation crm", url: ""}
    ]

    const videos = [
        { title: "Discours étape 1", url: "" },
        { title: "Discours étape 2", url: "" },
        { title: "Utilisation crm", url: ""}
    ]

    const isMobile = useIsMobile()

    return (
        <section className="h-full flex flex-col">
            <h1 className="p-4 text-4xl font-bold text-center">Document</h1>

            <div className="flex-1 p-4 space-y-5 bg-gray-100 rounded-xl flex flex-col">
                <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
                    <h1 className="text-lg text-gray-600">
                        Mes document pour ma mission
                    </h1>

                    {/* Barre de recherche */}
                    <div className="w-full md:w-auto flex px-3 md:items-center gap-2">
                        <div className="relative flex-1 flex items-center">
                            <input type="text" placeholder="Rechercher un pro" className="px-4 py-2 w-full outline-[#bd193b] rounded-full" />
                            <Search size={24} className="absolute right-0 me-2 bg-white" />
                        </div>

                        <button className="cursor-pointer *:cursor-pointer">
                            <SlidersHorizontal />
                        </button>
                    </div>
                </div>

                <h1 className="text-lg text-gray-600">
                    Fichier PDF
                </h1>

                <div className="w-full grid grid-cols-2 md:flex m:items-center gap-3 m:flex-wrap">
                    {docs.map((doc, key) => (
                        <Link
                            to={doc.url}
                            key={`doc-key-${key}`}
                            className="flex flex-col items-center gap-2 text-gray-800 no-underline"
                        >
                            <PDFIcons size={isMobile ? 100 : 200} />
                            <h4 className="text-sm text-center">{doc.title}</h4>
                        </Link>
                    ))}
                </div>

                <h1 className="text-lg text-gray-600">
                    Fichier vidéo
                </h1>

                <div className="w-full grid grid-cols-2 md:flex m:items-center gap-3 m:flex-wrap">
                    {videos.map((video, key) => (
                        <div
                            key={`video-key-${key}`}
                            className="w-1/4 flex flex-col items-center text-gray-800 no-underline"
                        >
                            <video
                                src={video.url}
                                controls
                            ></video>
                            {video.title}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Document
