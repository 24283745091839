import React, { useContext } from 'react';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';
import './BeauticianProgrammeAffiliation.css';
import ProgrammeAffiliation from '../../common/ProgrammeAffiliation';
import MySpinner from '../../loader/Spinner';


const BeauticianProgrammeAffiliation = () => {

	const { profileData } = useContext(BeauticianPortalContext);

	if (!profileData) {
		return <MySpinner height={"80vh"} />
	}

	return <React.Fragment>
		<ProgrammeAffiliation slug={profileData.slug} beautician_id={profileData.beautician_id} />
	</React.Fragment>

};

export default BeauticianProgrammeAffiliation;