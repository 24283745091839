import { API_URL } from "../utils/constants";


export const subscriptionLinksApi = {

    getBySlug: async (slug) => {
        try {
            const response = await fetch(`${API_URL}/subscription-links/get-link/${slug}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération des données: ' + error.message);
        }
    },

    getByBeauticianId: async (token, id) => {
        try {
            const response = await fetch(`${API_URL}/subscription-links/get-link-by-beautician-id/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data.doublon;
        } catch (error) {
            throw new Error('Erreur lors de la récupération des données: ' + error.message);
        }
    },

    create: async (token, months, beautician_id, created_by) => {
        try {
            
            // Requête fetch avec le FormData
            const response = await fetch(`${API_URL}/subscription-links/create`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({months: months, beautician_id: beautician_id, created_by: created_by}),
            });
    
            // Traitement de la réponse
            if (!response.ok) {
                throw new Error(`Erreur serveur: ${response.statusText}`);
            }
    
            return await response.json();
        } catch (error) {
            console.error(error);
            throw new Error('Erreur lors de l\'enregistrement du lien');
        }
    },

    delete: async (id) => {
        try {
            const response = await fetch(`${API_URL}/subscription-links/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la suppression du lien: ' + error.message);
        }
    }
}