import React, { useEffect, useState } from "react"
import MySpinner from "../../../components/loader/Spinner"
import { Table, Pagination } from "react-bootstrap"
import beauticianApi from "../../../api/beautician.api"

import { ChevronLeft, ChevronRight } from "lucide-react"
import userApi from "../../../api/user.api"

const SuiviTeleprospecteurs = () => {
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [selectedTelePro, setSelectedTelePro] = useState({})

    const [filter, setFilter] = useState({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        thisWeek: false
    })

    const [telePros, setTelePros] = useState([])
    const [limitPerPage, setLimitPerPage] = useState(10)
    const [trigger, setTrigger] = useState(false)
    const [pages, setPages] = useState(1)
    const [maxPages, setMaxPages] = useState(0)

    const status = {
        unpayed: "Non payé",
        payed: "Payé",
        upcoming: "À venir"
    }

    const fetchTelePro = async () => {
        setLoading(true)
        const res = await userApi.selectUsersByType(localStorage.getItem("token"), "tele_conseiller")
        if (res.success) {
            setTelePros(res.data)

            setMaxPages(Math.ceil((res.data.length / limitPerPage) | 1)) // Calculer le nombre total de pages
        }

        setLoading(false)
    }

    const handleRowClick = (operator) => {
        setShowModal(true)
        setSelectedOperator(operator)
    }

    useEffect(() => {
        fetchTelePro()
    }, [filter, trigger])

    useEffect(() => {
        setMaxPages(Math.ceil(telePros.length / limitPerPage)) // Recalculer les pages si limitPerPage change
        setPages(1) // Réinitialiser la page active si la limite par page change
    }, [limitPerPage])

    // Filtrer les professionnels en fonction de la page active
    const displayedTelePro = telePros.slice((pages - 1) * limitPerPage, pages * limitPerPage)

    const months = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
    ]

    const handleFilterChange = (isYear, step) => {
        isYear ?
            setFilter(prev => ({ ...prev, year: prev.year + step })):
            setFilter(prev => {
                let nextMonth = prev.month + step
                let nextYear = prev.year

                if(nextMonth < 1) {
                    nextYear --
                    nextMonth = 12
                }
                else if(nextMonth > 12) {
                    nextYear ++
                    nextMonth = 1
                }
                
                return { year: nextYear, month: nextMonth }
            })
    }

    return (
        <main className="min-h-[72vh]">
            {loading ? (
                <div className="w-full">
                    <MySpinner height={"60vh"} />
                </div>
            ) : (
                <div>
                    <div className="p-6 bg-gray-100 rounded-lg">
                        <header>
                            <h2 className="text-2xl font-bold text-gray-800 mb-6">Suivi des Téléprospecteurs</h2>
                            <div className="flex items-center gap-2">
                                <div className="flex items-center">
                                    <button className="cursor-pointer" onClick={() => handleFilterChange(false, -1)}><ChevronLeft size="24" /></button>

                                    <button>{months[filter.month - 1]}</button>

                                    <button className="cursor-pointer" onClick={() => handleFilterChange(false, 1)}><ChevronRight size="24" /></button>
                                </div>

                                <div className="flex items-center">
                                    <button className="cursor-pointer" onClick={() => handleFilterChange(true, -1)}><ChevronLeft size="24" /></button>

                                    <button>{filter.year}</button>

                                    <button className="cursor-pointer" onClick={() => handleFilterChange(true, 1)}><ChevronRight size="24" /></button>
                                </div>
                        
                                <span className="ms-2 my-2 flex flex-row items-center gap-2">
                                    <input
                                        id="thisweek"
                                        type="checkbox"
                                        checked={filter.thisWeek}
                                        onChange={(e) => setFilter({ ...filter, thisWeek: e.target.checked })}
                                    />
                                    <label htmlFor="thisweek">Cette semaine</label>
                                </span>
                            </div>
                        </header>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nom</th>
                                    <th>Date d'inscription</th>
                                    <th>Pro inscrit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedTelePro.map((p, index) => (
                                    <tr
                                        key={p.id}
                                        className="cursor-pointer"
                                        onClick={() => handleRowClick(p)}
                                    >
                                        <td>{(pages - 1) * limitPerPage + index + 1}</td>
                                        <td>r</td>
                                        <td>r</td>
                                        <td>r</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    <div className="flex items-center justify-center">
                        <div className="flex items-center gap-2 pt-4 text-lg font-semibold">
                            <span>Limites par pages</span>
                            <select
                                onChange={(e) => setLimitPerPage(Number(e.target.value))}
                                defaultValue={limitPerPage}
                            >
                                {[10, 15, 20, 25, 30, 100].map((item, index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <Pagination>
                            <Pagination.First onClick={() => setPages(1)} disabled={pages === 1} />
                            <Pagination.Prev onClick={() => setPages(pages - 1)} disabled={pages === 1} />
                            {Array.from({ length: maxPages }, (_, index) => index + 1).map((item, index) => (
                                <Pagination.Item
                                    key={index}
                                    active={item === pages}
                                    onClick={() => setPages(item)}
                                >
                                    {item}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => setPages(pages + 1)} disabled={pages === maxPages} />
                            <Pagination.Last onClick={() => setPages(maxPages)} disabled={pages === maxPages} />
                        </Pagination>
                    </div>
                </div>
            )}
        </main>
    )
}

export default SuiviTeleprospecteurs