import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useBooking } from '../../context/BookingContext';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import paymentApi from '../../api/payment.api';
import { BASE_URL, STRIPE_PUBLISHABLE_KEY } from '../../utils/constants';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { sendEmailNotification } from '../../utils/mailServices';
import { formatTime } from '../../utils/functions';
import { sendSmsCreateRdvNotification } from '../../utils/smsServices';
import MySpinner from '../loader/Spinner';


const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = ({ token, appointmentDetails }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const {
    setSelectedDate,
    setSelectedTime,
    clearBooking
  } = useBooking();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      // Créer un intent de paiement
      const paymentIntentData = {
        amount: appointmentDetails.payed,
        currency: 'eur',
      };

      const { clientSecret } = await paymentApi.createPaymentIntent(
        token,
        paymentIntentData 
      );

      // Confirmer le paiement
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: appointmentDetails.name,
          },
        },
      });

      if (result.error) {
        setPaymentStatus('Échec du paiement');
        setIsProcessing(false);
        return;
      }

      if (result.paymentIntent.status === 'requires_capture') {
        const appointmentSaveData = {
          ...appointmentDetails,
          paymentIntentId: result.paymentIntent.id,
          paymentStatus: 'completed',
          payment_intent: result.paymentIntent.id,
          status: 'pending', // on le remet a pending
        };

        await paymentApi.saveAppointment(token, appointmentSaveData);

        let url = process.env.NODE_ENV === "production" ? `${BASE_URL}/beautician-dashboard` : 'http://localhost:3000/beautician-dashboard';
        sendEmailNotification(
          appointmentDetails.beautician_id,
          appointmentDetails.appointment_date,
          formatTime(appointmentDetails.start_time),
          formatTime(appointmentDetails.end_time),
          url,
          'Accéder à mon tableau de bord'
        );

        sendSmsCreateRdvNotification(appointmentDetails.beautician_id , "Vous avez un nouveau rendez-vous en attente de confirmation, accédez à votre tableau de bord pour le confirmer.\nRDV & messages sur https://www.planibeauty.fr");

        setPaymentStatus('Paiement et réservation réussis !');

        handlePaymentSuccess();

      }
    } catch (error) {
      setPaymentStatus(error.message || 'Erreur de paiement');
    }

    setIsProcessing(false);
  };

  const handlePaymentSuccess = () => {
    Swal.fire('Succès', 'Réservation effectué avec succès!', 'success').then(() => {
      clearBooking();
      setSelectedDate(null);
      setSelectedTime(null);
      navigate('/client-dashboard');
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col space-y-3 mt-2 w-full"
    >
      <h4 className=''>Informations du paiement</h4>
      {paymentStatus && (
        <div
          className={`text-center px-4 py-2 rounded-md w-full ${paymentStatus.includes('réussis')
              ? 'bg-green-100 text-green-600'
              : 'bg-red-100 text-red-600'
            }`}
        >
          {paymentStatus}
        </div>
      )}
      <CardElement
        className="p-3 border rounded-lg w-full"
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#32325d',
              fontFamily: 'Arial, sans-serif',
              '::placeholder': { color: '#a0aec0' },
            },
            invalid: { color: '#fa755a' },
          },
        }}
      />
      <button
        type="submit"
        disabled={isProcessing}
        className={`px-6 py-3 rounded-lg w-full text-white font-medium ${isProcessing
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-blue-600 hover:bg-blue-700'
          }`}
      >
        {isProcessing ? 'Traitement...' : `Payer ${appointmentDetails.payed} €`}
      </button>
    </form>
  );
};

const StripePaymentForm = ({ token, appointmentDetails }) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // Si appointmentDetails devient disponible
    if (appointmentDetails) {
      // Optionnel : ajouter un petit délai pour être sûr que tout est synchronisé
      const timer = setTimeout(() => {
        setIsReady(true);
      }, 200);
      
      return () => clearTimeout(timer);
    } else {
      setIsReady(false);
    }
  }, [appointmentDetails]);

  if (!isReady) {
    return <MySpinner height={"20vh"} />;
  }

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        token={token}
        appointmentDetails={appointmentDetails}
      />
    </Elements>
  );
};

export default StripePaymentForm;
