import React, { useState, useRef, useEffect } from 'react';
import { Send, Image, MoreVertical, ArrowLeft } from 'lucide-react';
import { useMessage } from '../../contexts/MessageContext';
import { generateColorFromString } from '../../utils/colorUtils';
import Swal from 'sweetalert2';
import MessageAttachment from './MessageAttachment';
import { MessageGalleryProvider } from '../../contexts/MessageGalleryContext';


const getInitials = (name) => {
  if (!name) return '??';
  const words = name.split(' ');
  if (words.length >= 2) {
    return (words[0][0] + words[1][0]).toUpperCase();
  }
  return name.substring(0, 2).toUpperCase();
};



const Chat = ({ conversation, messages, setMessages, onBack }) => {
  const { sendMessage } = useMessage();
  const [inputMessage, setInputMessage] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});

  const MAX_FILES = 5;
  
  const { socket, markConversationAsRead, fetchUnreadCounts } = useMessage();
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null); 
  const previousConversationId = useRef(null);

  useEffect(() => {
    const markMessagesAsRead = async () => {
      try {
        if (conversation?.id && conversation.id !== previousConversationId.current) {
          await markConversationAsRead(conversation.id);
          await fetchUnreadCounts();
          previousConversationId.current = conversation.id;
        }
      } catch (error) {
        console.error('Erreur lors du marquage des messages:', error);
      }
    };

    markMessagesAsRead();
  }, [conversation?.id, markConversationAsRead, fetchUnreadCounts]);

  useEffect(() => {
    if (messages.length > 0) {
      // Ajout d'un petit délai pour s'assurer que les messages sont rendus
      setTimeout(() => {
        scrollToBottom('instant');
      }, 100);
    }
  }, [conversation?.id, messages]);

  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      const currentUserId = String(localStorage.getItem('userId'));
      const isOwnMessage = currentUserId === String(lastMessage.sender_id);
      
      const shouldScroll = isOwnMessage || isNearBottom();
      if (shouldScroll) {
        scrollToBottom();
      }
    }
  }, [messages]);

 /*  useEffect(() => {
    if (socket) {
        const handleNewMessage = (newMessage) => {
            if (newMessage.conversation_id === conversation.id) {
                // Mettre à jour les messages localement
                setMessages(prevMessages => [...prevMessages, newMessage]);
                
                // Scroll vers le bas pour le nouveau message
                scrollToBottom();
            }
        };

        socket.on('receive-message', handleNewMessage);

        return () => {
            socket.off('receive-message', handleNewMessage);
        };
    }
  }, [socket, conversation?.id]); */

  useEffect(() => {
    if (socket && conversation) {
      // console.log('Socket connecté pour la conversation:', conversation.id);
      
      const handleNewMessage = (newMessage) => {
        // console.log('Nouveau message reçu:', newMessage);
        if (newMessage.conversation_id === conversation.id) {
          setMessages(prevMessages => {
            // Vérifier si le message existe déjà par son ID
            if (prevMessages.some(msg => msg.id === newMessage.id)) {
              // console.log('Message déjà existant, ignoré');
              return prevMessages;
            }
  
            // Pour les messages des autres, simplement ajouter
            if (newMessage.sender_id !== localStorage.getItem('userId')) {
              return [...prevMessages, newMessage];
            }
  
            // Pour nos propres messages, remplacer le temporaire s'il existe
            // et éviter d'ajouter des doublons
            return prevMessages.map(msg => 
              msg.id?.toString().startsWith('temp-') ? newMessage : msg
            );
          });
        }
      };
  
      // Nettoyer les messages quand on change de conversation
      setMessages([]); 
  
      socket.on('receive-message', handleNewMessage);
      return () => {
        socket.off('receive-message', handleNewMessage);
      };
    }
  }, [socket, conversation?.id]); // Ajout de la dépendance conversation?.id au lieu de conversation

  const scrollToBottom = (behavior = 'smooth') => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior });
    }
  };

  const isNearBottom = () => {
    if (chatContainerRef.current) {
      const container = chatContainerRef.current;
      const threshold = 150;
      const distanceFromBottom = 
        container.scrollHeight - container.scrollTop - container.clientHeight;
      return distanceFromBottom <= threshold;
    }
    return false;
  };

  /* const handleSendMessage = async () => {
    if (inputMessage.trim() || selectedFiles.length > 0) {
        try {
            const formData = new FormData();
            formData.append('content', inputMessage.trim());
            
            // Ajouter chaque fichier
            selectedFiles.forEach(file => {
                formData.append('files', file);
            });

            const token = localStorage.getItem('token');
            const response = await fetch(`/api/messages/conversations/${conversation.id}/messages`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Erreur lors de l\'envoi du message');
            }

            const messageData = await response.json();
            socket?.emit('send-message', messageData);

            setInputMessage('');
            setSelectedFiles([]);
        } catch (error) {
            console.error('Erreur lors de l\'envoi:', error);
            alert('Erreur lors de l\'envoi du message');
        }
    }
  }; */

  // Dans Chat.jsx, modifiez la fonction handleSendMessage :
    const handleSendMessage = async () => {
      if (selectedFiles.length > MAX_FILES) {
          Swal.fire({
              icon: 'error',
              title: 'Trop de fichiers',
              text: `Maximum ${MAX_FILES} images autorisées`,
              confirmButtonColor: '#e11d48'
          });
          return;
      }

      if (inputMessage.trim() || selectedFiles.length > 0) {
          try {
              await sendMessage(conversation.id, inputMessage.trim(), selectedFiles);
              setInputMessage('');
              setSelectedFiles([]);
          } catch (error) {
              Swal.fire({
                  icon: 'error',
                  title: 'Erreur',
                  text: 'Erreur lors de l\'envoi du message',
                  confirmButtonColor: '#e11d48'
              });
          }
      }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }

    if (socket && conversation) {
      socket.emit('typing', { conversationId: conversation.id });
    }
  };

  const handleFilesSelected = (files) => {
    if (files.length + selectedFiles.length > MAX_FILES) {
      Swal.fire({
        icon: 'error',
        title: 'Trop de fichiers',
        text: `Vous ne pouvez pas sélectionner plus de ${MAX_FILES} images à la fois`,
        confirmButtonColor: '#e11d48'
      });
      return;
    }
    setSelectedFiles(prev => [...prev, ...files]);
  };
  

  const handleRemoveFile = (index) => {
      setSelectedFiles(prev => {
          const newFiles = [...prev];
          newFiles.splice(index, 1);
          return newFiles;
      });
  };

  if (!conversation) {
    return (
      <div className="h-full flex items-center justify-center">
        <p className="text-gray-500">Sélectionnez une conversation pour commencer</p>
      </div>
    );
  }

  const initials = getInitials(conversation.name);
  const getAllImages = () => {
    return messages.reduce((acc, message) => {
      const messageImages = message.attachments?.filter(att => att.file_type?.startsWith('image/')) || [];
      return [...acc, ...messageImages];
    }, []);
  };

  return (
    <MessageGalleryProvider>
    <div className="h-full flex flex-col">
      <div className="flex items-center gap-1 p-2 border-b border-gray-200">
        <button 
          onClick={onBack}
          className="md:hidden p-2 hover:bg-gray-100 rounded-full"
        >
          <ArrowLeft size={20} />
        </button>
        
        <div className="flex items-center gap-3 flex-1">
          <div 
              className="min-w-[40px] w-10 h-10 rounded-full flex items-center justify-center text-white font-medium text-sm"
              style={{ backgroundColor: generateColorFromString(`${localStorage.getItem('accountId')}-${conversation.id}`) }}
          >
              {initials}
          </div>
          <div>
            <h2 className="font-medium">{conversation.business_name || conversation.name}</h2>
            {conversation.online && (
              <span className="text-sm text-green-500">En ligne</span>
            )}
          </div>
        </div>

        <button className="p-2 hover:bg-gray-100 rounded-full">
          <MoreVertical size={20} className="text-gray-500" />
        </button>
      </div>

      <div 
        ref={chatContainerRef}
        className="flex-1 overflow-y-auto messages-container custom-scrollbar min-h-0"
      >
        {messages.map((message, index) => {
          const currentUserId = String(localStorage.getItem('userId'));
          const messageSenderId = String(message.sender_id);
          const isOwnMessage = currentUserId === messageSenderId;

          const uniqueKey = `${message.id}-${index}-${message.created_at}`;
          const messageImages = message.attachments?.filter(att => att.file_type?.startsWith('image/')) || [];


          return (
            <div
              key={`${message.id}-${index}`}
              className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'} p-4`}
            >
              <div
                className={`max-w-[60%] rounded-lg shadow-sm ${
                  isOwnMessage
                    ? 'bg-gradient-to-r from-pink-400 to-rose-400 text-white rounded-br-none'
                    : 'bg-white/90 backdrop-blur-sm text-gray-900 rounded-bl-none border border-pink-100'
                }`}
              >
                {/* Message content */}
                {message.content && (
                  <div className="px-4 py-2">
                    <p>{message.content}</p>
                  </div>
                )}
                
                {/* Attachments */}
                {message.attachments && message.attachments.length > 0 && (
                  <div className="space-y-2">
                    {message.attachments.map((attachment, attIndex) => (
                      <MessageAttachment 
                        key={attIndex} 
                        attachment={attachment}
                        messageImages={getAllImages()} // Passez toutes les images ici
                        imageIndex={getAllImages().findIndex(img => img.id === attachment.id)}
                      />
                    ))}
                  </div>
                )}
                
                {/* Timestamp and sender */}
                <div className={`px-4 py-2 text-xs ${
                  isOwnMessage ? 'text-blue-100' : 'text-gray-500'
                }`}>
                  {new Date(message.created_at).toLocaleTimeString([], {
                    hour: '2-digit', 
                    minute:'2-digit'
                  })} · {message.sender_name}
                </div>
              </div>
            </div>
          );
    })}
        <div ref={messagesEndRef} />
      </div>

      <div className="flex-shrink-0 p-1 border-t border-gray-200">
      <div className="flex flex-col gap-2">
  {selectedFiles.length > 0 && (
    <div className="flex flex-wrap gap-2 px-4">
      {selectedFiles.map((file, index) => (
        <div key={index} className="bg-gray-100 rounded-full px-3 py-1 text-sm flex items-center gap-2">
          <span className="truncate max-w-[200px]">{file.name}</span>
          <button onClick={() => handleRemoveFile(index)} className="text-gray-500 hover:text-gray-700">
            ×
          </button>
        </div>
      ))}
    </div>
  )}
  <div className="flex items-center gap-2">
    <input
      type="file"
      id="file-upload"
      className="hidden"
      multiple
      accept="image/*"
      max={MAX_FILES}
      onChange={(e) => handleFilesSelected(Array.from(e.target.files))}
    />
    <label 
      htmlFor="file-upload" 
      className="p-2 hover:bg-gray-100 rounded-full cursor-pointer"
      title={`Ajouter des images (max ${MAX_FILES})`}
    >
      <Image size={20} className="text-gray-500" /> 
    </label>
    
    <input
      type="text"
      placeholder="Écrivez votre message..."
      value={inputMessage}
      onChange={(e) => setInputMessage(e.target.value)}
      onKeyPress={handleKeyPress}
      className="flex-1 py-2 px-4 bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
    
    <button 
      onClick={handleSendMessage}
      className="p-3 bg-gradient-to-r from-pink-400 to-rose-400 hover:from-pink-500 hover:to-rose-500 text-white rounded-full transition-all duration-300"
    >
      <Send size={20} />
    </button>
  </div>
</div>
</div>
    </div>
    </MessageGalleryProvider>
  );
};

export default Chat;