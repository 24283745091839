import React from 'react';
import './LeadsTableRow.css';

function LeadsTableRow({ lead, isSelected, onSelect }) {
    const getStatusClass = (status) => {
        switch (status) {
            case 'Ok inscription':
                return 'status-green';
            case 'Liens supprimés':
                return 'status-red';
            case 'Leads attribué':
                return 'status-blue';
            default:
                return 'status-default';
        }
    };

    return (
        <tr className={isSelected ? 'selected' : ''}>
            <td className="td-checkbox">
                <div className="id-container">
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={onSelect}
                    />
                    <span>{lead.id}</span>
                </div>
            </td>
            <td className="td-link">
                <a href={lead.lien} target="_blank" rel="noopener noreferrer">
                    {lead.lien}
                </a>
            </td>
            <td>{lead.telephone}</td>
            <td>{lead.dateLeads}</td>
            <td>{lead.ville}</td>
            <td>{lead.cp}</td>
            <td className={lead.statut === 'Ok inscription' ? 'date-green' : ''}>
                {lead.dateStatut}
            </td>
            <td className={getStatusClass(lead.statut)}>
                {lead.statut}
            </td>
            <td>
                <a href="#" className="comment-link">
                    écrire un commentaire
                </a>
            </td>
            <td>
                <a href="#" className="assign-link">
                    {lead.attribuerA}
                </a>
            </td>
            <td className="td-pro">
                {lead.pro && (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="pro-icon">
                        <circle cx="12" cy="8" r="5"></circle>
                        <path d="M20 21v-2a7 7 0 0 0-14 0v2"></path>
                    </svg>
                )}
            </td>
            <td className="td-actions">
                <button className="btn-action">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="12" cy="5" r="1"></circle>
                        <circle cx="12" cy="19" r="1"></circle>
                    </svg>
                </button>
            </td>
        </tr>
    );
}

export default LeadsTableRow;