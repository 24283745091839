import Swal from "sweetalert2";

const showMessage = (type, message, callback = null) => {
    // Mapping des types vers les titres et icônes
    const config = {
        success: { title: 'Succès!', icon: 'success' },
        warning: { title: 'Attention!', icon: 'warning' },
        info: { title: 'Informations!', icon: 'info' },
        error: { title: 'Erreur', icon: 'error' }
    };
    
    // Récupération de la configuration pour le type donné
    const { title, icon } = config[type];
    
    // Création de l'alerte avec les paramètres communs
    const alert = Swal.fire({
        title,
        text: message,
        icon,
        confirmButtonColor: '#bd193b'
    });
    
    // Exécution du callback si fourni
    if (callback) {
        alert.then(() => callback());
    }
    
    return alert;
};

// Fonctions spécifiques qui utilisent la fonction générique
const showSuccessMessage = (message, callback = null) => 
    showMessage('success', message, callback);

const showWarningMessage = (message, callback = null) => 
    showMessage('warning', message, callback);

const showInformationMessage = (message, callback = null) => 
    showMessage('info', message, callback);

const showErrorMessage = (message, callback = null) => 
    showMessage('error', message, callback);

export { showSuccessMessage, showErrorMessage, showWarningMessage, showInformationMessage };