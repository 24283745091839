import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';
import abonnementApi from '../../api/abonnement.api';
import offreApi from '../../api/offre.api';
import { API_URL, BASE_URL } from '../../utils/constants';
import { showErrorMessage, showWarningMessage } from '../../utils/messageHelper';
import { subscriptionLinksApi } from '../../api/subscription_links.api';

const SubscriptionForm = ({ link }) => {

    const [nom, setNom] = useState(link.last_name);
    const [prenom, setPrenom] = useState(link.first_name);
    const [nomCommercial, setNomCommercial] = useState(link.business_name);
    const [adresse, setAdresse] = useState(link.address);
    const [email, setEmail] = useState(link.email);
    const [numero, setNumero] = useState(link.phone);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState('');

    // States for error messages
    const [errors, setErrors] = useState({
        nom: '',
        prenom: '',
        nomCommercial: '',
        adresse: '',
        email: '',
        numero: '',
        agree_terms: '',
    });



    const handleCreateUser = async () => {

        setIsLoading(true);
        setMessage('');

        if (!stripe || !elements) {
            showErrorMessage('Stripe n\'est pas chargé correctement.');
            setIsLoading(false);
            return;
        }

        try {

            // on recupere l'offre classique
            const offres = await offreApi.getAllOffer();
            let offre = null;
            if (Array.isArray(offres)) {
                offre = offres.find((obj) => obj.name.toLowerCase().includes("classique"));
            }

            if (!offre) {
                showErrorMessage('Aucune offre n\'a été trouvé!');
                setIsLoading(false);
                return;
            }

            // Confirmez le paiement
            const cardElement = elements.getElement(CardElement);
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: `${nom} ${prenom}`,
                    email: email,
                },
            });

            if (error) {
                setMessage(error.message);
                setIsLoading(false);
            } else {

                Swal.fire({
                    title: 'Chargement...',
                    text: 'Veuillez patienter...',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });


                try {

                    const data = {
                        userId: link.user_id,
                        offre_id: offre.id,
                        offre_name: offre.name,
                        offre_pricing: offre.price_per_month,
                        is_trial: true,
                        trial_days: link.months * 30,
                        promo_code_id: null,
                        existingPromoCode: null,
                        beautician_id: link.beautician_id,
                        user_info: {
                            name: `${nom} ${prenom}`,
                            email: email
                        },
                        paymentMethodId: paymentMethod.id,
                    }

                    const { clientSecret } = await abonnementApi.savePaymentForFuture(data);

                    await subscriptionLinksApi.delete(link.id);

                    Swal.close();
                    setIsLoading(false);
                    setTimeout(() => {
                        Swal.fire(
                            'Abonnement effectué avec succès',
                            'Vous pouvez maintenant vous connecter pour beneficier de nos services',
                            'success'
                        ).then(() => {
                            window.location.href = "/?status=new-user";
                        });
                    }, 200);

                } catch (error) {
                    Swal.close();
                    setIsLoading(false);
                    setMessage(error.message);
                    return;
                }

            }
        } catch (error) {
            setIsLoading(false);
            setTimeout(() => {
                Swal.fire('Erreur', error.message || 'Erreur lors de la création de l\'utilisateur.', 'error');
            }, 200);
        }
    };

    const handleSubmit = async () => {

        await handleCreateUser();

    }

    return <React.Fragment>
        <div className="max-w-4xl px-3 py-5 shadow-lg bg-gray-50 rounded mx-auto">
            <div className="form-header">
                <h2>Formulaire activation abonnement</h2>
                <h4>Formule Classique 49 € par mois après {link.months ? link.months + ' mois' : link.days + ' jours'} d'essai gratuit</h4>
                <p>Ce lien vous a été envoyé par l'équipe Planibeauty, Remplissez le formulaire pour activer votre abonnement</p>
            </div>
            <Form onSubmit={(e) => e.preventDefault()}>


                <Row className='mb-2'>
                    <Col md={6}>
                        <Form.Group controlId="formNom">
                            <Form.Label>Nom*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nom"
                                value={nom}
                                readOnly
                                onChange={(e) => setNom(e.target.value)}
                            />
                            {errors.nom && <div className="text-danger">{errors.nom}</div>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group as={Col} controlId="formPrenom">
                            <Form.Label>Prénom(s)*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Prénom(s)"
                                readOnly
                                value={prenom}
                                onChange={(e) => setPrenom(e.target.value)}
                            />
                            {errors.prenom && <div className="text-danger">{errors.prenom}</div>}
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Col md={6}>
                        <Form.Group controlId="formNomCommercial">
                            <Form.Label>Nom commercial</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nom commercial"
                                value={nomCommercial}
                                readOnly
                                onChange={(e) => setNomCommercial(e.target.value)}
                            />
                            {errors.nomCommercial && <div className="text-danger">{errors.nomCommercial}</div>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formAdresse">
                            <Form.Label>Adresse postal*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Adresse postal"
                                value={adresse}
                                readOnly
                                onChange={(e) => setAdresse(e.target.value)}
                            />
                            {errors.adresse && <div className="text-danger">{errors.adresse}</div>}
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mb-2'>
                    <Col md={6}>
                        <Form.Group as={Col} controlId="formEmail">
                            <Form.Label>E-mail*</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="E-mail"
                                value={email}
                                readOnly
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group as={Col} controlId="formNumero">
                            <Form.Label>Numéro de téléphone*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Numéro de téléphone"
                                value={numero}
                                readOnly
                                onChange={(e) => setNumero(e.target.value)}
                            />
                            {errors.numero && <div className="text-danger">{errors.numero}</div>}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <p className='mt-3 text-info text-justify'>
                            Vous allez beneficier d'une période d'essai de {link.months} mois.
                            Pour activer le renouvellement automatique de votre abonnement,
                            veuillez entrer les informations de votre carte bancaire.
                            Ce processus est sécurisé et conforme aux normes PCI DSS,
                            garantissant la protection de vos données de paiement.
                            Vous conservez la possibilité de désactiver le renouvellement automatique à tout moment.
                        </p>
                        <label htmlFor="" className="block text-sm font-medium mb-2 text-gray-700">Informations de la carte :</label>
                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#424770',
                                        '::placeholder': {
                                            color: '#aab7c4',
                                        },
                                    },
                                    invalid: {
                                        color: '#9e2146',
                                    },
                                },
                            }}
                            className="p-3 border rounded"
                        />
                        {message && (
                            <div className="mt-4 text-red-500" >{message}</div>
                        )}

                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col md={12}>
                        <Form.Group controlId="formAgreeTerms" className="mb-0">
                            <Form.Check
                                type="checkbox"
                                label="J'accepte les termes et les conditions d'utilisation (CGU et CGV)"
                                checked={agreeTerms}
                                onChange={(e) => setAgreeTerms(e.target.checked)}
                            />
                            {errors.agree_terms && <div className="text-danger">{errors.agree_terms}</div>}
                            <p className='mt-3 text-justify'>Lire les <a href={`${process.env.NODE_ENV === 'production' ? BASE_URL : 'http://localhost:3000'}/ConditionsGenerales`} target='_blank' rel="noreferrer" className='text-blue-600 hover:text-blue-700 hover:underline cursor-pointer'>Conditions Générales d'Utilisation et Conditions Générales de Ventes</a></p>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <div className="w-full">
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="submit-button"
                    disabled={isLoading}
                >
                    Activer mon abonnement
                </button>
            </div>
        </div>
    </React.Fragment>
}

export default SubscriptionForm;